<div *ngIf="!compact" class="tile {{bgClass}} d-flex flex-column gap-2">
  <div class="icon">
    <fa-icon [icon]="icon" />
  </div>
  <div class="value">
    <ng-container *ngIf="loading">
      <app-loading-spinner />
    </ng-container>
    <ng-container *ngIf="!loading">
      {{value}}
      <span class="unit" *ngIf="unit">{{unit}}</span>
    </ng-container>
  </div>
  <div class="title">{{title}}</div>
  <p class="subtitle" *ngIf="subtitle">{{subtitle}}</p>
</div>

<div *ngIf="compact" class="tile {{bgClass}} d-flex flex-column gap-2">

  <div class="title">
    <fa-icon [icon]="icon" />
    {{title}}
  </div>

  <div class="value">
    <ng-container *ngIf="loading">
      <app-loading-spinner />
    </ng-container>
    <ng-container *ngIf="!loading">
      {{value}}
      <span class="unit" *ngIf="unit">{{unit}}</span>
    </ng-container>
  </div>
  <p class="subtitle" *ngIf="subtitle">{{subtitle}}</p>
</div>
