import { createAction, createFeature, createReducer, on, props } from '@ngrx/store';

import { OnboardingStatusDto } from '../api/services/UserService';
import { IUser, IUserPaymentMethod } from '../models/User';

const authFeatName = 'Auth'

export interface AuthState {
  token: string|null,
  loading: boolean
  error: any
}
const initialState: AuthState = {
  token: null,
  loading: false,
  error: null
};

// Actions
const prefix = `[${authFeatName}]`
const authenticateViaOauth = createAction(`${prefix} Check Oauth CallbackToken`, props<{ platform: string, oauthToken: string, returnAfterLogin?: string|null }>());
const authenticateOK = createAction(`${prefix} Check Oauth CallbackToken Success`, props<{ token: string }>());
const authenticateKO = createAction(`${prefix} Check Oauth CallbackToken Failure`, props<{ error: any }>());

const authenticateViaCredentials = createAction(`${prefix} Check credentials `, props<{ email: string, password: string, returnAfterLogin?: string|null }>());

const logout = createAction(`${prefix} Logout`);
const logoutOK = createAction(`${prefix} LogoutOK`);
// Reducer
const reducer = createReducer<AuthState>(
  initialState,
  on(authenticateViaOauth, (state) => ({...state, loading: true})),
  on(authenticateViaCredentials, (state) => ({...state, loading: true})),
  on(authenticateOK, (state, {token}) => ({...state, token, error: null, loading: false})),
  on(authenticateKO, (state, { error }) => ({...state, loading: false, error})),

  on(logout, (state)=>initialState),
  on(logoutOK, (state)=>state)
);

// Feature
export const authFeat = createFeature({
  name: authFeatName,
  reducer
})

export const authActions = {
  authenticateViaOauth,
  authenticateOK,
  authenticateKO,
  authenticateViaCredentials,
  logout,
  logoutOK
}
