import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IUser, IUserPaymentMethod } from '../../../models/User';
import { UiPaymentService } from '../../services/ui-payment.service';

@Component({
  selector: 'app-account-payment-methods-manager',
  templateUrl: './account-payment-methods-manager.component.html',
  styleUrls: ['./account-payment-methods-manager.component.scss']
})
export class AccountPaymentMethodsManagerComponent {
  @Input({required: true}) user!: IUser
  @Output() onPaymentMethodAdded = new EventEmitter<IUserPaymentMethod>()
  get paymentMethods(){
    return this.user.paymentMethods
  }
  constructor(private uiPaymentSvc: UiPaymentService){

  }
  openAddPaymentMethodModal(){
      this.uiPaymentSvc.openAddPaymentMethodModal().subscribe((paymentMethod) => {
        this.onPaymentMethodAdded.emit(paymentMethod)
      })
  }
}
