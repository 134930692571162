import { Component } from '@angular/core';
import { AuctionChannel, AuctionState, IAuctionExpanded } from '../../../models/Auction';
import { userFeat } from '../../../store/user.store';
import { StreamingStatus } from '../../../models/Merchant';
import { ActivatedRoute, Router } from '@angular/router';
import { PublicService } from '../../../api/services/PublicService';
import { RealTimeService } from '../../../api/services/RealTimeService';
import { AppStore } from '../../../store';
import { faTwitch } from '@fortawesome/free-brands-svg-icons';
import { IUser } from '../../../models/User';
import { UiService } from '../../../ui/services/ui.service';

@Component({
  selector: 'app-regular-auction-page',
  templateUrl: './regular-auction-page.component.html',
  styleUrls: ['./regular-auction-page.component.scss']
})
export class RegularAuctionPageComponent {
  user: IUser | null = null
  auction!: IAuctionExpanded
  twitchIcon = faTwitch
  showAllBids = false
  constructor(
    private route: ActivatedRoute,
    private publicSvc: PublicService,
    private uiCommonSvc: UiService,
    private rtService: RealTimeService,
    private store: AppStore
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(({ auctionId }) => {

      this.uiCommonSvc.setTitle('Subasta')

      this.publicSvc.getAuction(auctionId).subscribe(auction => {
        this.auction = auction
        this.rtService.subToAuction(auction.id, true).subscribe(({data: auction})=>{
          this.auction = auction
        })
      })
    })

    this.store.select(userFeat.selectUser).subscribe((user) => {
      this.user = user
    })
  }
  get merchantProfileLink () {
    return `/viewer/mercado/${this.auction.merchant.alias}`
  }
  get onboardingStatus$() {
    return this.store.select(userFeat.selectStatus)
  }

  get defaultPaymentMethod() {
    if (!this.user) {
      return null
    }
    return this.user.paymentMethods.find(p => p.default) || null
  }

  get isAuctionClosed() {
    return this.auction.state === AuctionState.Closed
  }
  get allBidsTogglerText(){
    return this.showAllBids ? 'ocultar pujas' : 'ver más pujas'
  }
  get isAuctionStarted() {
    return this.auction.state === AuctionState.Started
  }
  get isAuctionCreated() {
    return this.auction.state === AuctionState.Created
  }

  get lastBid() {
    return this.auction.bids[0]
  }


  get otherBids(){
    return this.auction.bids.filter(bid=>bid.id !== this.lastBid.id)
  }

  get isClosed(){
    return this.auction.state === AuctionState.Closed
  }
}
