import { environment } from "../../environments/environment"

export const debugVar = (variable: any, label: string) => {
    if(environment.production){
        return
    }
    const win = (window as any)
    win['dbg'] = win['dbg'] || {} as any

    win['dbg'][label] = variable
}
