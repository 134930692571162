import { Injectable } from '@angular/core';
import { ActivatedRoute, CanActivate, Router } from '@angular/router';
import { AuthService } from './AuthService';
import { Observable, map, of, switchMap } from 'rxjs';
import { UserService } from '../api/services/UserService';
import { AppStore } from '../store';
import { userFeat } from '../store/user.store';

@Injectable({
  providedIn: 'root',
})
export class MerchantOnboardedGuard implements CanActivate {
  constructor(private userSvc: UserService, private router: Router, private store: AppStore) { }

  canActivate(): Observable<boolean> {
    return this.store.select(userFeat.selectStatus).pipe(
      switchMap((status) => {
        if (status) {
          return of(status)
        }
        return this.userSvc.getOnboardingStatus()
      })
    ).pipe(map((status) => {
      if (status.merchant.completed) {
        return true;
      }
      this.router.navigate(['/streamer/onboarding']);
      return false;
    }));

  }
}
