import { Component, OnInit } from '@angular/core';
import { UiService } from '../../../../ui/services/ui.service';
import { AuctionTemplate, IAuction } from '../../../../models/Auction';
import { ActivatedRoute, Router } from '@angular/router';
import { createSubs, unsubAll } from '../../../../utils/rxjs.utils';
import { MerchantService } from '../../../../api/services/MerchantService';

@Component({
  selector: 'app-create-auction-page',
  templateUrl: './create-auction-page.component.html',
  styleUrls: ['./create-auction-page.component.scss']
})
export class CreateAuctionPageComponent implements OnInit {

  subs = createSubs()
  loading = true
  auction: AuctionTemplate | null = null

  showTips = false

  constructor(private uiCommonSvc: UiService, private router: Router, private route: ActivatedRoute, private merchantSvc: MerchantService) { }

  onAuctionCreated(auction: IAuction) {
    this.router.navigate(['/streamer/subastas'])
  }

  ngOnInit(): void {

    this.uiCommonSvc.setTitle('Subasta')
    this.auction = null
    this.subs.routeParams = this.route.params.subscribe(({ id }) => {
      if (id) {
        this.merchantSvc.getAuction(id).subscribe((auction) => {
          this.auction = auction
          this.loading = false
        })
      } else {
        this.loading = false
      }
    })

  }

  ngOnDestroy() {
    unsubAll(this.subs)
  }
}
