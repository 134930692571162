import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IProduct } from '../../../models/Product';
import { createSubs } from '../../../utils/rxjs.utils';
import { AppStore } from '../../../store';
import { mProductFormStore } from '../../../store/merchant/product/merchant.product.store';
import { filter, map } from 'rxjs';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { Actions, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { debugVar } from '../../../utils/debug.utils';
import BigNumber from 'bignumber.js';
import { capitalizeFirstLetter } from '../../../utils/common.utils';


@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.scss']
})
export class ProductFormComponent implements OnInit {

  constructor(
    private store: AppStore,
    private actions$: Actions,
    private router: Router
  ) { }

  @Input() product: IProduct | null = null
  subs$ = createSubs()
  auxForm = new FormGroup({
    live: new FormControl(false),
    isScheduling: new FormControl(false)
  })
  form: FormGroup = new FormGroup({
    id: new FormControl(undefined),
    name: new FormControl('', [Validators.required]),
    description: new FormControl('', []),
    netPrice: new FormControl(null, [Validators.required, Validators.min(1)]),
    netDeliveryPrice: new FormControl(null, [Validators.required, Validators.min(0)]),
    picture: new FormGroup({
      path: new FormControl('', [Validators.required]),
    }),
    stock:new FormGroup({
      amount: new FormControl(null, [Validators.required, Validators.min(0)])
    }),
    // helpers
    price: new FormControl(null, [Validators.required, Validators.min(1)]),
    deliveryPrice: new FormControl(null, [Validators.required, Validators.min(0)]),
  })

  loading$ = this.store.select(mProductFormStore.feat.selectLoading)

  ngOnInit(): void {
    debugVar(this, 't')
    this.actions$.pipe(ofType(mProductFormStore.actions.createOK)).subscribe(() => {
      this.router.navigate(['/streamer/productos'])
    });

    ['price', 'deliveryPrice'].forEach(field=>{
      const netField = `net${capitalizeFirstLetter(field)}`
      this.subs$[field] = this.form.get(field)!.valueChanges.subscribe(net => {
        this.form.get(netField)?.setValue(new BigNumber(net).dividedBy(1.21).toFixed(2), {
          emitEvent: false
        })
      })
      this.subs$[netField] = this.form.get(netField)!.valueChanges.subscribe(net => {
        this.form.get(field)?.setValue(new BigNumber(net).multipliedBy(1.21).toFixed(2), {
          emitEvent: false
        })
      })

    })



    this.store.select(mProductFormStore.feat.selectData)
      .pipe(filter(v => v))
      .subscribe(product => {
        this.form.get('picture')?.get('path')?.removeValidators(Validators.required)
        this.form.patchValue({
          id: product.id,
          name: product.name,
          description: product.description,
          price: product.price,
          deliveryPrice: product.deliveryPrice,
          picture: {
            path: null
          },
          path: product.path,
          url: product.url,
          stock: {
            amount: product.stock.amount
          }
        })
      })

  }

  get pictureSrc() {
    return this.form.get('picture')?.get('url')?.value
  }
  onPictureUpload(picture: any) {
    this.form.patchValue({
      picture
    })
    this.form.markAsDirty()
  }


  /** Submitting */
  get ctaText() {
    return this.loading$.pipe(map(loading => {
      if (loading) {
        return 'Cargando...'
      }
      return 'Guardar producto'
    }))
  }

  get isSaveDisabled() {
    return this.form.invalid || this.form.pristine
  }

  save() {
    if (!this.form.valid) {
      return;
    }
    const payload: any = this.preparePayload(this.form)
    if (payload.id) {
      const { id, ...editPayload } = payload
      return this.store.dispatch(mProductFormStore.actions.update({ id, payload: editPayload }))
    }
    return this.store.dispatch(mProductFormStore.actions.create({ payload }))
  }

  private preparePayload(form: FormGroup) {
    const { stock, ...otherFields } = form.getRawValue()
    if(!otherFields.picture.path){
      delete otherFields.picture
    }
    return {
      ...otherFields as any,
      stock:{
        amount: Number(stock.amount),
      }
    }
  }

  /** Icons */
  addImageIcon = faImage
}
