import { Component, Input } from '@angular/core';
import { getApiErrorMessage, isApiErrorResponse } from '../../../utils/api.utils';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-api-error-block',
  templateUrl: './api-error-block.component.html',
  styleUrls: ['./api-error-block.component.scss']
})
export class ApiErrorBlockComponent {

  @Input({alias: 'error'}) error?: any

  get httpError(){
    return this.error as HttpErrorResponse
  }
  get message() {
    return isApiErrorResponse(this.error) ? getApiErrorMessage(this.error) : 'Error desconocido'
  }

  get errorList() {

    return this.httpError.error.error.errors || []
  }


}
