import { Pipe, PipeTransform } from '@angular/core';
import { CustomerOnboardingTask } from '../api/services/UserService';

@Pipe({
  name: 'customerTaskLabel'
})
export class CustomerTaskLabelPipe implements PipeTransform {

  transform(value: CustomerOnboardingTask): unknown {
    return {
      [CustomerOnboardingTask.AddPaymentMethod] : 'Añadir método de pago',
      [CustomerOnboardingTask.CompletePersonalDetails] : 'Completar tus datos personales',
      [CustomerOnboardingTask.ConnectPlatformAccount] : 'Conecta tu cuenta de Twitch',
    }[value];
  }

}
