import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MerchantService } from '../../../api/services/MerchantService';
import { MerchantOrderDto, RefundReason } from '../../../models/Order';

enum State {
  Loading = 'loading',
  Idle = 'idle',
}


@Component({
  selector: 'app-refund-modal',
  templateUrl: './refund-modal.component.html',
  styleUrls: ['./refund-modal.component.scss']
})
export class RefundModalComponent implements OnInit {
  @Output() onSuccess = new EventEmitter<MerchantOrderDto>()
  @Input({ required: true }) order!: MerchantOrderDto
  @Input({ required: true }) orderItemId!: string
  @Input({ required: true }) amount: string | null = null
  modalRef!: NgbModalRef
  error: any
  state = State.Idle
  form = new FormGroup({
    orderItemId: new FormControl('', {}),
    amount: new FormControl('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    reason: new FormControl(RefundReason.RequestedByCustomer, {
      validators: [Validators.required],
      nonNullable: true,
    })
  })


  constructor(private merchantSvc: MerchantService) { }

  ngOnInit(): void {
    this.form.get('orderItemId')?.setValue(this.orderItemId)
    if (this.amount) {
      this.form.get('amount')?.setValue(this.amount)
    }
  }

  get isSaveDisabled() {
    return this.state !== State.Idle || this.form.invalid
  }

  get isLoading() {
    return this.state === State.Loading
  }

  onCallSuccess(order: MerchantOrderDto) {
    this.onSuccess.emit(order)
    this.modalRef?.close()
    this.form.reset()
  }
  onCallError(error: any) {
    this.state = State.Idle
    this.error = error
  }

  get orderItem(){
    return this.order.items.find(item => item.id === this.orderItemId)!
  }

  onSubmit() {
    if (!this.form.valid || !this.modalRef || this.state !== State.Idle) {
      return;
    }

    this.state = State.Loading;
    const payload = this.form.getRawValue()


    this.merchantSvc.refundOrderItem(this.order.id, this.orderItemId, payload.amount).subscribe({
      next: this.onCallSuccess.bind(this),
      error: this.onCallError.bind(this),
    })

  }
}
