import { MerchantWidgetSettings } from "./Order";

export enum AuctionChannel {
  Live = 'live',
  Offline = 'offline' // Scheduled Auctions will always be offline
}

export enum TimeUnit {
  Seconds = 's',
  Minutes = 'm',
  Hours = 'h',
  Days = 'd',
}
export interface IAuction {
  id: string;
  merchantId:string
  orderId?:string
  title: string;
  description: string;
  state: AuctionState;
  startingPrice: string;
  deliveryPrice: string
  minBidIncrement: string;
  createdAt: number;
  startsAt: number;
  endsAt: number | null;
  startedAt: number | null;
  endedAt: number | null;
  pictureUrl: string;
  bids: IBid[];
  currencySymbol: string;
  currency: string;
  currentPrice: string;
  nextBidAmount: string;
  // duration
  durationUnit: TimeUnit
  duration: number
  extPeriodUnit: TimeUnit
  extPeriod: number
  channels: AuctionChannel[]
}
export type AuctionTemplate = IAuction


export type IBidExpanded = IBid & { alias: string, avatar: string }
export type IAuctionExpanded = Omit<IAuction, 'bids'> & {
  bids: Array<IBidExpanded>
  merchant:{
    alias: string,
    avatar: string
  }
  widgetSettings?: MerchantWidgetSettings
}



export enum AuctionState {
  Created = "created",
  Started = "started",
  Closed = "closed",
  Scheduled= 'scheduled'
}
export interface IBid {
  id: string;
  auctionId: string;
  bidderId: string;
  amount: string;
  createdAt: Date;
  trigger: "manual" | "auto";
}

export interface IAuctionPayment {
  id: string;
  bid: IBid

  amountDist: {
    merchant: string,
    psp: string
    fee: string
  }
  providerData: {
    name: string;
    id: string;
  };
}

export interface IAuctionItem {
  name: string;
  photoUrl: string;
}
