import { ActionCreator, MemoizedSelector, createAction, createFeature, createReducer, on, props } from '@ngrx/store';
import { IApiPaginationData } from '../../api/services/ApiService';
import { merge } from 'lodash';
import { FullPartial } from '../../../types.util';
import { TypedAction } from '@ngrx/store/src/models';

type DefaultFiltersType = Record<string, any>

export interface GenericListStore<DataType, ParamsType = any, FiltersType = DefaultFiltersType> {
  filters: FiltersType
  params: ParamsType,
  pagination: IApiPaginationData
  data: DataType[]
  error:Error | null
  loading: boolean
}

const defInitialState: GenericListStore<any, any, any> = {
  data: [],
  params: {},
  error: null,
  loading: false,
  filters: {},
  pagination:{
    page: 1,
    pageSize: 10,
    totalPages: 0,
    total: 0,
    prevPage: null,
    nextPage: null,
    sortBy: {}
  },
}

type PaginationParams = { page?: number, pageSize?:number, sortBy?:Record<string, 'asc'|'desc'> }
type FiltersType = Record<string, any>
export const buildListStore = <DataType = any, ParamsType = any >(
  resourceName: string,
  partialInitialState: FullPartial<GenericListStore<DataType, ParamsType>> = {}
) => {
  const featureName = `${resourceName}List`

  // Actions
  const prefix = `[${featureName}]`

  const load = createAction(`${prefix} Load ${resourceName}`, props<{ params: ParamsType, filters?: FiltersType }>());
  const loadOK = createAction(`${prefix} Load ${resourceName} OK`, props<{ data: DataType[], pagination: IApiPaginationData }>());
  const loadKO = createAction(`${prefix} Load ${resourceName} KO`, props<{ error: Error }>());

  const setFilters = createAction(`${prefix} Set Filters ${resourceName}`, props<{ filters: FiltersType }>());
  const setPagination = createAction(`${prefix} Set Pagination ${resourceName}`, props<{ pagination: Partial<PaginationParams>}>());
  const setFiltersAndPagination = createAction(`${prefix} setFiltersAndPagination ${resourceName}`, props<{
    pagination: Partial<PaginationParams>,
    filters: FiltersType

  }>())
  // Reducer
  const initialState = merge(defInitialState, partialInitialState)
  const reducer = createReducer(
    initialState,
    on(load, (state, {params, filters}) => ({...state, params, filters, loading: true})),
    on(loadOK, (state, { data, pagination }) => ({ ...state, data, pagination, loading: false })),
    on(loadKO, (state, { error }) => ({ ...state, error, loading: false })),

    on(setFilters, (state, { filters })=>({
      ...state,
      filters,
      pagination: {
        ...state.pagination,
        page: 1
      }
    })),

    on(setPagination, (state, { pagination })=>({
      ...state, pagination: {
        ...state.pagination,
        ...pagination
    }})),

    on(setFiltersAndPagination, (state, { filters, pagination })=>({
      ...state,
      filters,
      pagination: {
        ...state.pagination,
        ...pagination,
        page:1 // as filters changed
    }}))
  );

  // Feature
  const feat = createFeature({
    name: featureName,
    reducer,
  })

  return {
    feat,
    actions: {
      setFilters,
      setPagination,
      setFiltersAndPagination,
      load,
      loadOK,
      loadKO
    }
  }
}

interface ListStoreActions<DataType, ParamsType, FiltersType> {
  load: ActionCreator<string, (props: { params: ParamsType; filters?: any }) => { params: ParamsType; filters?: any; } & TypedAction<string>>
  loadOK: ActionCreator<string, (props: { data: DataType[]; pagination: IApiPaginationData }) => { data: DataType[]; pagination: IApiPaginationData } & TypedAction<string>>
  loadKO: ActionCreator<string, (props: { error: Error }) => { error: Error } & TypedAction<string>>
  setPagination: ActionCreator<string, (props: {pagination: Partial<PaginationParams>}) => {pagination: Partial<PaginationParams>} & TypedAction<string>>
  setFilters: ActionCreator<string, (props: {filters: FiltersType}) => { filters: FiltersType} & TypedAction<string>>,
  setFiltersAndPagination: ActionCreator<string, (props: {pagination: Partial<PaginationParams>, filters: FiltersType}) => { pagination: Partial<PaginationParams>, filters: FiltersType} & TypedAction<string>>,
}
interface ListStoreSelectors<DataType, ParamsType, FiltersType> {
  selectData: MemoizedSelector<Record<string, any>, any[] & ((DataType extends object ? FullPartial<DataType> : DataType) | undefined)[], (featureState: GenericListStore<any, any, any> & DataType) => any[] & ((DataType extends object ? DataType : DataType) | undefined)[]>
  selectPagination: MemoizedSelector<Record<string, any>, IApiPaginationData & FullPartial<IApiPaginationData>, (featureState: GenericListStore<any, any> & FullPartial<GenericListStore<DataType, ParamsType>>) => IApiPaginationData & FullPartial<GenericListStore<DataType, ParamsType>>>
  selectParams: MemoizedSelector<Record<string, any>, any, (featureState: GenericListStore<DataType, ParamsType> & FullPartial<GenericListStore<DataType, ParamsType>>) => any>
  selectFilters: MemoizedSelector<Record<string, any>, any, (featureState: GenericListStore<any, any, any> & FullPartial<GenericListStore<DataType, ParamsType, FiltersType>>) => any>
}

export interface ListStore<DataType = any, ParamsType = any, FiltersType = DefaultFiltersType> {
  actions: ListStoreActions<DataType, ParamsType, FiltersType>
  feat: ListStoreSelectors<DataType, ParamsType, FiltersType>
}
