import { Component, OnInit } from '@angular/core';
import { AppStore } from '../../../store';
import { pMerchantProfileList } from '../../../store/public/merchant.profile.store';

@Component({
  selector: 'app-merchant-list-page',
  templateUrl: './merchant-list-page.component.html',
  styleUrls: ['./merchant-list-page.component.scss']
})
export class MerchantListPageComponent implements OnInit {

  constructor(
    private store: AppStore
  ) {}

  loading$ = this.store.select(pMerchantProfileList.feat.selectLoading)
  error$ = this.store.select(pMerchantProfileList.feat.selectError)
  data$ = this.store.select(pMerchantProfileList.feat.selectData)

  ngOnInit(): void {
    this.store.dispatch(pMerchantProfileList.actions.load({ id: 'todo_fix' }))
  }
}
