<div class="h-100 d-flex flex-column">


<div class="header" [routerLink]="routerLink">
  <div class="banner">
    <div class="image">
      <img [src]="bannerUrl" />
    </div>
  </div>
</div>
<div class="card wrapper flex-grow-1">
  <div class="sub-header d-flex gap-3 justify-content-between align-items-center">
    <!-- Avatar -->

    <div class="avatar" [routerLink]="routerLink">
      <img [src]="user.avatar" />
    </div>
    <div class="d-flex flex-grow-1  justify-content-between align-items-center">
      <!-- Heading  -->
      <div class="heading flex-grow-1">
        <div>
          <div class="d-flex gap-1 align-items-center">
            <h2 [routerLink]="routerLink" class="alias">{{user.alias}}</h2>

            <div class="rating" *ngIf="rating">
              <span class="icon">
                <fa-icon [icon]="starIcon" />
              </span>
              <span class="rate">{{ rating }}</span>
            </div>
            <div class="ms-auto">
              <app-merchant-follow-button [merchant]="user.alias" />
            </div>

          </div>
        </div>
        <div class="title">{{user.title}}</div>
        <div class="links d-flex justify-content-between align-items-center">
          <ul class="socials list-unstyled d-flex gap-2 align-items-center mb-0">
            <li *ngFor="let social of socials">
              <a target="_blank" [href]="social.link"><fa-icon [icon]="social.icon" /> </a>
            </li>
          </ul>
        </div>
      </div>


    </div>
  </div>

</div>
</div>
