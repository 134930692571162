import { Component } from '@angular/core';

@Component({
  selector: 'app-customer-order-list-page',
  templateUrl: './customer-order-list-page.component.html',
  styleUrls: ['./customer-order-list-page.component.scss']
})
export class CustomerOrderListPageComponent {

}
