import { Component, Input } from '@angular/core';
import { faTwitch, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { StreamingPlatform } from '../../../models/Enum';

@Component({
  selector: 'app-stream-platform-icon',
  template: `
  <ng-container *ngIf="platform === 'suass'; else other">
  <img *ngIf="!withText" class="icon" src="/assets/logo/net_purple_logo_icon.png" height="16" />
  <img *ngIf="withText" class="icon" src="/assets/logo/net_purple_logo.png" height="16" />
  </ng-container>
  <ng-template #other>
    <fa-icon [icon]="icon" [style]="{color}" />
  </ng-template>

  `,
  styles: []
})
export class StreamPlatformIconComponent {
  @Input({required: true}) platform!: string
  @Input() withText = false
  icons = {
    twitch: {
      icon: faTwitch,
      color: 'purple'
    },
    youtube:{
      icon: faYoutube,
      color: 'red'
    }
  }

  get icon (){
    return this.icons[this.platform as StreamingPlatform]!.icon
  }
  get color (){
    return this.icons[this.platform as StreamingPlatform]!.color
  }
}
