import { Component, HostListener, Input } from '@angular/core';
import { createSubs, unsubAll } from '../../../utils/rxjs.utils';
import { AppStore } from '../../../store';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { MemoizedSelector } from '@ngrx/store';

@Component({
  selector: 'button[dispatch-action]',
  templateUrl: './dispatch-action.component.html',
  styleUrls: ['./dispatch-action.component.scss']
})
export class DispatchActionComponent {
  @Input() dispatcherBtn!: Function
  @Input() actionParams: any;
  @Input() loadingSelector?: MemoizedSelector<any, any, any>
  @Input() loadingText?: string = 'Cargando'
  @Input() icon?: IconDefinition

  private subs$ = createSubs()

  loading = false

  constructor(
    private store: AppStore
  ) { }


  @HostListener('click') onMouseEnter() {
    this.store.dispatch(this.dispatcherBtn(this.actionParams))
  }

  ngOnInit(): void {
    if (this.loadingSelector) {
      this.store.select(this.loadingSelector).subscribe(loading => {
        this.loading = loading
      })
    }


  }
  ngOnDestroy(): void {
    unsubAll(this.subs$)
  }


}
