import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { AppStore } from '../store';
import { userFeat, userFeatActions } from '../store/user.store';
import { Observable, filter, map, of, switchMap, take, tap } from 'rxjs';
import { AuthService } from '../auth/AuthService';

export const userResolver: ResolveFn<Observable<boolean>> = (route, state) => {
  const store = inject(AppStore)
  const authSvc = inject(AuthService)
  if(!authSvc.getToken()){
    return of(false)
  }
  return store.select(userFeat.selectUserState).pipe(

    filter(state=>!state.isFetchingUser),
    take(1),
    map(state=>{
      return !!(state.user && state.status)
    }),
    switchMap(fetched=>{
      if(!fetched){
        store.dispatch(userFeatActions.fetchMe())
        return store.select(userFeat.selectUserState).pipe(
          filter(state=>!state.isFetchingUser),
          take(1),
          map(state=>{
            return !!(state.user && state.status)
          }),
        )
      }
      return of(true)
    })
  )
};
