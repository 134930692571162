import { Component, OnInit } from '@angular/core';
import { MerchantService } from '../../../../api/services/MerchantService';
import { TopCustomerDto } from '../../../../models/Dashboard';
import {  faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-dashboard-top-customers',
  templateUrl: './dashboard-top-customers.component.html',
  styleUrls: ['./dashboard-top-customers.component.scss']
})
export class DashboardTopCustomersComponent implements OnInit{
  constructor(
    private merchantSvc: MerchantService
  ) {}
  helpIcon = faQuestionCircle
  data: TopCustomerDto[]|null = null

  ngOnInit(): void {
    this.load()
  }

  load(){
    this.merchantSvc.getTopCustomers().subscribe(data=>this.data=data)
  }
}
