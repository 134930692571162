import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { IUserPaymentMethod } from '../../models/User';
import { MerchantAddOrderDeliveryModalComponent } from '../components/merchant-add-order-delivery-modal/merchant-add-order-delivery-modal.component';
import { CustomerOrderDto, MerchantOrderDto } from '../../models/Order';
import { environment } from '../../../environments/environment';
import { ReviewOrderModalComponent } from '../../review-order-modal/review-order-modal.component';

@Injectable({
  providedIn: 'root'
})
export class UiOrderService {



  constructor(private modalSvc: NgbModal) {

  }

  openAddDeliveryModal(orderId: string, { later }: {later: boolean}): Observable<MerchantOrderDto>{
    const modalRef = this.modalSvc.open(MerchantAddOrderDeliveryModalComponent);
    modalRef.componentInstance.orderId = orderId
    modalRef.componentInstance.sendLater = later
    return modalRef.componentInstance.onSuccess
  }

  openReviewOrderModal(order: CustomerOrderDto): Observable<MerchantOrderDto>{
    const modalRef = this.modalSvc.open(ReviewOrderModalComponent);
    modalRef.componentInstance.order = order
    return modalRef.componentInstance.onSuccess
  }

  downloadDeliveryLabel(orderId:string){
    window.open(`${environment.apiBaseUrl}/merchant/orders/${orderId}/download-delivery-label`, '_blank')
  }
  downloadPendingOrdersDeliveryLabel(){
    window.open(`${environment.apiBaseUrl}/merchant/orders/download-pending-orders-delivery-label`, '_blank')
  }
}
