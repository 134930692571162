import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AppStore } from '../../../store';
import { pMarketProductList } from '../../../store/public/market.product.list.store';
import { createSubs, loadingSuccessDelay, unsubAll } from '../../../utils/rxjs.utils';
import { debounceTime } from 'rxjs';

const sortByOptions = [
  { label: 'Novedades primero', value: 'createdAt:DESC' },
  { label: 'De menor a mayor precio', value: 'netPrice:DESC' },
  { label: 'De mayor a menor precio', value: 'netPrice:ASC' }
]

@Component({
  selector: 'app-market-page',
  templateUrl: './market-page.component.html',
  styleUrls: ['./market-page.component.scss']
})
export class MarketPageComponent implements OnInit, OnDestroy {

  constructor(
    private store: AppStore
  ) { }

  subs$ = createSubs()
  ngOnInit(): void {
    this.subs$.filterForm = this.filterForm.valueChanges.pipe(debounceTime(500)).subscribe(this.onFilterChange.bind(this))
    this.store.dispatch(pMarketProductList.actions.load({ params: null }))
  }

  ngOnDestroy(): void {
    unsubAll(this.subs$)
  }

  sortByOptions = sortByOptions
  listStore = pMarketProductList

  products$ = this.store.select(pMarketProductList.feat.selectData)
  loading$ = this.store.select(pMarketProductList.feat.selectLoading).pipe(loadingSuccessDelay(100))
  /* Filters */
  filterForm = new FormGroup({
    search: new FormControl(''),
    sortBy: new FormControl(sortByOptions[0].value)
  })

  onFilterChange({ search, sortBy }: { search?: string | null, sortBy?: string | null }) {
    let filters: any = {}
    let pagination: any = {}
    if ((search || '').trim().length > 0){
      filters.search = search
    }
    if(sortBy){
      const [field, order] = sortBy.split(':')
      pagination.sortBy = {
        [field]: order as any
      }
    }
    this.store.dispatch(pMarketProductList.actions.setFiltersAndPagination({  filters, pagination }))
  }
}
