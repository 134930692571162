import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../../../auth/AuthService';
import { AccountMode } from '../../../../enums';
import { ActivatedRoute } from '@angular/router';
import { createSubs, unsubAll } from '../../../../utils/rxjs.utils';

@Component({
  selector: 'app-customer-account-page',
  templateUrl: './customer-account-page.component.html',
  styleUrls: ['./customer-account-page.component.scss']
})
export class CustomerAccountPageComponent implements OnInit, OnDestroy {
  constructor(private authSvc: AuthService, private route: ActivatedRoute) {

  }

  get isStreamerMode() {
    return this.authSvc.accountMode === AccountMode.Merchant
  }

  subs$= createSubs()
  tab: string = 'datos'
  ngOnInit(): void {
    this.tab = location.pathname.includes('pedido') ? 'pedidos' : 'datos'
  }
  ngOnDestroy(): void {
    unsubAll(this.subs$)
  }
}
