import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, of, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import jwtDecode from "jwt-decode";
import { IUser } from '../models/User';
import { AccountMode } from '../enums';
import { debugVar } from '../utils/debug.utils';
import { v4 } from 'uuid';

interface IApiRes<T> {
  data: T
}
interface Credentials {
  email: string
  password: string
}

interface JwtPayload {
  id: string
  email: string
  type: string
  iat: number
  exp: number
}

interface LoginParams extends Credentials { }
interface SignupParams extends Credentials { }

const getModeFromLocalStorage = (): AccountMode => {
  return localStorage.getItem('accountMode') as AccountMode || AccountMode.Customer
}
const setModeToLocalStorage = (mode: AccountMode | null) => {
  if (mode === null) {
    localStorage.removeItem('accountMode')
    return
  }
  localStorage.setItem('accountMode', mode)
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public readonly accountMode$ = new BehaviorSubject<AccountMode>(getModeFromLocalStorage())
  private readonly authBaseUrl: string = `${environment.apiBaseUrl}/auth`;

  constructor(private http: HttpClient, private cookieService: CookieService) {
    debugVar(this, 'authsvc')
  }

  public logout() {
    this.setToken('')
  }

  set accountMode(mode: AccountMode) {
    setModeToLocalStorage(mode)
    this.accountMode$.next(mode)
  }
  get accountMode() {
    return this.accountMode$.value
  }
  get isMerchantMode$() {
    return this.accountMode$.asObservable().pipe(map(mode => mode === AccountMode.Merchant))
  }

  get isCustomerMode$() {
    return this.accountMode$.asObservable().pipe(map(mode => mode === AccountMode.Customer))
  }

  public setToken(token: string) {
    const oneWeekLater = new Date();
    oneWeekLater.setDate(oneWeekLater.getDate() + 7);
    const cookieOpts = {
      expires: oneWeekLater,
      path: '/'
    }
    if (token === '') {
      this.cookieService.delete('authToken', cookieOpts.path)
      return
    }
    this.cookieService.set('authToken', token, cookieOpts);
  }

  public getTwitchOauthUrl({ returnAfterLogin }: {returnAfterLogin?:string|null} = {}) {
    const scope = encodeURIComponent('user:read:email')
    const baseUrl = window.location.origin;
    const redirectUri = `${baseUrl}/oauth/callback/twitch`
    const state = v4()
    if(returnAfterLogin){
      localStorage.setItem(`oauth.callback.${state}.returnAfter`, returnAfterLogin)
    }
    return `https://id.twitch.tv/oauth2/authorize?client_id=${environment.twitchClientId}&state=${state}&scope=${scope}&response_type=code&redirect_uri=${redirectUri}`
  }

  public processOauthCallback(platform: string, code: string): Observable<any> {
    return this.http.get<any>(`${environment.apiBaseUrl}/vendor/${platform}/oauth/authentication/callback`, { params: { code } }).pipe(map(res => res.data))
  }

  public getToken(): string | null {
    const token = this.cookieService.get('authToken');
    if (!token) {
      return null
    }
    const jwt = jwtDecode<JwtPayload>(token)
    if (jwt.exp * 1000 < Date.now()) {
      this.setToken('')
      return null
    }
    return token
  }

  public me(): Observable<IUser | null> {
    return this.http.get<IApiRes<any>>(`${this.authBaseUrl}/me`).pipe(
      map(res => res.data),
    );
  }

  public login({ password, email }: LoginParams) {
    return this.http.post<IApiRes<{ token: string }>>(`${this.authBaseUrl}/login`, { email, password }).pipe(map(res => res.data));
  }


  public confirm(code: string): Observable<{ token: string }> {
    return this.http.get<IApiRes<{ token: string }>>(`${this.authBaseUrl}/confirm/${code}`).pipe(map(res => res.data));
  }

  public signup({ password, email }: SignupParams) {
    return this.http.post<IApiRes<{ token: string }>>(`${this.authBaseUrl}/signup`, { email, password });
  }

  public performPasswordReset({ code, newPassword }: { code: string, newPassword: string }): Observable<{ token?: string }> {
    return this.http.post<IApiRes<{ token: string }>>(`${this.authBaseUrl}/perform-password-reset`, { code, newPassword }).pipe(map(res => res.data));
  }

  public requestPasswordReset({ email }: { email: string }) {
    return this.http.post<IApiRes<{ token: string }>>(`${this.authBaseUrl}/request-password-reset`, { email });
  }

}
