import { Component, Input, OnInit } from '@angular/core';
import { CustomerOrderDto, IRefundDto, MerchantOrderDto, MerchantOrderItemDto, OrderStatus, PaymentStatus, RefundReason, isCancelable, orderAcceptNewItems } from '../../../models/Order';
import { CustomerService } from '../../../api/services/CustomerService';
import { UiPaymentService } from '../../services/ui-payment.service';
import BigNumber from 'bignumber.js';
import { RefundModalComponent } from '../refund-modal/refund-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IUser } from '../../../models/User';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faCancel, faCopy, faPlus, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { AddOrderItemModalComponent } from '../add-order-item-modal/add-order-item-modal.component';
import { mOrderListActions, mOrderListFeat } from '../../../store/merchant.order-list.store';
import { AppStore } from '../../../store';
import { exhaustMap, map } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { prettyId } from '../../../utils/common.utils';
import { UiOrderService } from '../../services/ui-order.service';

@Component({
  selector: 'app-order-view',
  templateUrl: './order-view.component.html',
  styleUrls: ['./order-view.component.scss']
})
export class OrderViewComponent implements OnInit {
  @Input({ required: true }) merchantMode: boolean = false
  @Input({ required: true }) order!: CustomerOrderDto
  @Input({ alias: 'user' }) _user: IUser | null = null
  helpIcon = faQuestionCircle
  cancelIcon = faCancel
  copyIcon = faCopy
  refreshIcon = faRefresh
  orderItemActionIcon = faPlus
  highlightPaymentId = null
  retryingPayment: string|null = null
  public constructor(
    private customerSvc: CustomerService,
    private store: AppStore,
    private actions$: Actions,
    private modalSvc: NgbModal,
    private uiOrderSvc: UiOrderService
  ) {
  }

  ngOnInit(): void {
    this.actions$.pipe(
      ofType(mOrderListActions.cancelOrderOK),
    ).subscribe(({order})=>this.order = order)

    this.actions$.pipe(
      ofType(mOrderListActions.refreshOrderPaymentsOK),
    ).subscribe(({order})=>this.order = order)
  }
  get title(){
    return `Pedido #${prettyId(this.order.id)}`
  }
  get netTotal(){
    return this.order.items.reduce((total: BigNumber, oi: any)=>total.plus(oi.netAmount), new BigNumber(0)).toFixed(2)
  }
  get taxTotal(){
    return this.order.items.reduce((total: BigNumber, oi: any)=>total.plus(oi.tax.amount), new BigNumber(0)).toFixed(2)
  }
  get myself() {
    if (!this._user) {
      throw new Error('User not set')
    }
    return this._user
  }
  get hasProcessingPayment(){
    return this.order.payments.some((payment: any)=> payment.status === PaymentStatus.Processing)
  }
  refreshPayments(){
    this.store.dispatch(mOrderListActions.refreshOrderPayments({
      orderId: this.order.id
    }))
  }
  getPaymentMethodExcept(usedMethodId?: string) {
    if (usedMethodId) {
      return this.myself.paymentMethods.filter((pm) => pm.id !== this.order.payments[0].paymentMethod.id)
    }
    return this.myself.paymentMethods
  }

  get customerMode() {
    return !this.merchantMode
  }

  get canReview(){
    return [OrderStatus.Canceled, OrderStatus.Completed].includes(this.order.status)
  }

  onPaymentDone(order: CustomerOrderDto){
    this.order = order;
    this.retryingPayment = null
  }

  refreshOrder() {
    this.customerSvc.getOrder(this.order.id).subscribe((order) => {
      this.order = order
    })
  }

  paymentSucceeded(payment: any){
    return payment.status === PaymentStatus.Succeeded
  }
  isPaymentPending(payment: any){
    return PaymentStatus.Pending === payment.status
  }

  isPaymentProcessing(payment: any){
    return PaymentStatus.Processing === payment.status
  }


  paymentRefundableAmount(payment: any) {
    if(!this.paymentSucceeded(payment)){
      return new BigNumber('0')
    }
    const refundableAmount = payment.refunds.reduce((remaining: any, refund: any) => remaining.minus(refund.amount), new BigNumber(payment.amount))
    return refundableAmount
  }

  isPaymentFullyRefunded(payment: any) {
    return this.paymentRefundableAmount(payment).eq(0)
  }
  paymentAmountLeft(payment: any) {
    return this.paymentRefundableAmount(payment).toString()
  }
  hasRefundableAmount(payment: any) {
    return this.paymentRefundableAmount(payment).gt(0)
  }


  refundReason(refund: IRefundDto) {
    if (refund.reason === RefundReason.RequestedByCustomer) {
      return 'Solicitado por el cliente'
    }
    return '-'
  }


  get defaultPayment(){
    return this.order.payments[0]
  }

  paymentsAreBeingRefreshed$ = this.store.select(mOrderListFeat.selectRefreshingPaymentsOrderId).pipe(
    map(id => id ===this.order.id)
  )

  /**
   * Order Items
   */
  isBeingCanceled$ = this.store.select(mOrderListFeat.processingIds).pipe(
    map(ids => ids.includes(this.order.id))
  )




  isRefundable(item: any){
    const amountLeft = new BigNumber(item.total).minus(item.refundedAmount || 0).dp(2)

    return amountLeft.gte(0) && !this.itemPaymentIsNotSuccessful(item)
  }

  itemPayment(item: MerchantOrderItemDto){
    return this.order.payments.find((p: any) => p.id === item.paymentId)
  }

  itemPaymentIsNotSuccessful(item: MerchantOrderItemDto){
    return this.order.payments.find((p: any) => p.id === item.paymentId).status !== PaymentStatus.Succeeded
  }

  refundBtnTooltip(item: MerchantOrderItemDto){
    if(this.itemPaymentIsNotSuccessful(item)){
      return 'Pago asociado no completado'
    }
    return this.isRefundable(item) ? 'Reembolsar' : 'Ya fue desembolsado'
  }

  canCancel() {
    return isCancelable(this.order.status)
  }

  cancel() {
    if (!confirm('¿Seguro que quieres cancelar? Esto hará un reembolso completo')) {
      return
    }

    this.store.dispatch(mOrderListActions.cancelOrder({ orderId: this.order.id }))
  }
  downloadDeliveryLabel(){
    this.uiOrderSvc.downloadDeliveryLabel(this.order.id)
  }
  /**
   * Merchant actions
   */
  openRefundForm(orderItemId: string, amount?: string) {
    const modalRef = this.modalSvc.open(RefundModalComponent);

    modalRef.componentInstance.order = this.order
    modalRef.componentInstance.orderItemId = orderItemId
    modalRef.componentInstance.amount = amount
    modalRef.componentInstance.modalRef = modalRef
    modalRef.componentInstance.onSuccess.subscribe((order: MerchantOrderDto) => {
      this.order = order
    })
  }

  get canAddItems(){
    return orderAcceptNewItems(this.order.status)
  }
  openAddOrderItemModal(){
    const modalRef = this.modalSvc.open(AddOrderItemModalComponent);
    modalRef.componentInstance.order = this.order
    modalRef.componentInstance.modalRef = modalRef
    modalRef.componentInstance.onSuccess.subscribe((order: MerchantOrderDto)=>{
      this.order = order
    })
  }

  onMouseOverItem(item: any){
    this.highlightPaymentId = item.paymentId
  }

  onMouseLeaveItem(item: any){
    this.highlightPaymentId = null
  }
}



