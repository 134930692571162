import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomerOrderDto } from '../../../models/Order';
import { CustomerService } from '../../../api/services/CustomerService';
import { UiPaymentService } from '../../services/ui-payment.service';

@Component({
  selector: 'app-fast-pay-button',
  templateUrl: './fast-pay-button.component.html',
  styleUrls: ['./fast-pay-button.component.scss']
})
export class FastPayButtonComponent implements OnInit {
  @Input() disabled = false
  @Input() orderId!: string
  @Input() paymentId!: string
  @Input() klass: string = 'btn-sm btn-primary'

  @Output() onProcess = new EventEmitter<void>
  @Output() onSuccess = new EventEmitter<CustomerOrderDto>
  @Output() onError = new EventEmitter<any>
  @Input() paymentMethods: any[] = []
  processing = false
  paymentMethodId = null

  constructor(
    private customerSvc: CustomerService,
    private uiPaymentSvc: UiPaymentService
  ){}

  get btnDisabled(){
    return this.processing || this.disabled
  }

  ngOnInit(): void {

    // this.store.select(userFeat.selectUser).subscribe(user=>{
    //   this.paymentMethods = user?.paymentMethods || []
    // })
  }

  openAddMethodModal(){
    this.uiPaymentSvc.openAddPaymentMethodModal().subscribe((paymentMethod)=>{
      this.performPayment(paymentMethod.id)
    })
  }

  performPayment(paymentMethodId: string) {
    if(this.processing){
      return
    }
    this.processing = true
    this.onProcess.emit()

    this.customerSvc.retryOrderPayment(this.orderId, this.paymentId, paymentMethodId).subscribe({
      next: (order) => {
        this.processing = false
        this.onSuccess.emit(order)
      },
      error: (err) => {
        this.processing = false
        this.onError.emit(err)
      }
    })
  }
}
