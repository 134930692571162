import { Component, Input } from '@angular/core';
import { MerchantOrderItemDto } from '../../../models/Order';
import { prettyId } from '../../../utils/common.utils';

@Component({
  selector: 'app-small-order-item-list',
  templateUrl: './small-order-item-list.component.html',
  styleUrls: ['./small-order-item-list.component.scss']
})
export class SmallOrderItemListComponent {
  @Input() items: MerchantOrderItemDto[] = []

  prettyOrderId(id: string){
    return prettyId(id)
  }
}
