import { Component, Input } from '@angular/core';
import { IUserPaymentMethod } from '../../../models/User';

@Component({
  selector: 'app-payment-method-label',
  templateUrl: './payment-method-label.component.html',
  styleUrls: ['./payment-method-label.component.scss']
})
export class PaymentMethodLabelComponent {
  @Input({required: true}) paymentMethod!: IUserPaymentMethod
  get brandLabel(){
    if(this.paymentMethod.type === 'paypal'){
      return 'Paypal'
    }
    if(this.paymentMethod.data.brand){
      return {
        'visa': 'Visa',
        'amex': 'American Express',
        'mastercard': 'Mastercard',
      }[this.paymentMethod.data.brand]
    }
    return ''
  }

  get isPaypal(){
    return this.paymentMethod.type === 'paypal'
  }

  get src(){
    let file = `${this.paymentMethod.data.brand}.svg`
    if(this.paymentMethod.type === 'paypal'){
      file = `paypal.png`
    }
    return `/assets/img/payment-methods/${file}`
  }
}
