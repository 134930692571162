<form [formGroup]="form" (ngSubmit)="save()" style="max-width: 800px;">
  <div class="row">
    <div class="col-8 col-xl-7">

      <app-form-input [form]="form" controlName="name" label="Nombre" marginBottom="3" />
      <app-form-input [form]="form" controlName="description" label="Descripción" type="textarea" marginBottom="3" />
      <div class="row">
        <div class="col-6 mb-3">
          <app-form-input label="Precio (sin IVA)" type="number" controlName="netPrice" [form]="form" prefix="€" />
        </div>
        <div class="col-6 mb-3">
          <app-form-input label="Precio venta (con IVA)" type="number" controlName="price" [form]="form" prefix="€" />
        </div>
        <div class="col-6 mb-3">
          <app-form-input label="Precio envío (sin IVA)" type="number" controlName="netDeliveryPrice" [form]="form" prefix="€" />
        </div>
        <div class="col-6 mb-3">
          <app-form-input label="Precio envío (con IVA)" type="number" controlName="deliveryPrice" [form]="form" prefix="€" />
        </div>
        <div class="col-6 mb-3">
          <app-form-input [form]="form" controlName="stock.amount" label="Cantidad disponible" type="number"
            marginBottom="3" />
        </div>
      </div>
    </div>

    <!-- Img start -->
    <div class="col-4 col-xl-5 d-flex flex-column">
      <div class="form-label mb-1">Imágen</div>
      <app-file-upload [autoUpload]="false" fileType="product_pic" (onUpload)="onPictureUpload($event)" />
      <!-- Img end -->
    </div>

  </div>
  <div class=" text-end mt-3 cta-wrapper">
    <button class="btn btn-primary" [disabled]="isSaveDisabled" (click)="save()">
      {{ctaText|async}}
    </button>
  </div>
</form>
