import { ActionReducer } from "@ngrx/store";
import { environment } from "../../../environments/environment";

export const debuggerMetaReducer = (
  reducer: ActionReducer<any>
): ActionReducer<any> => {
  return (state, action) => {
    const nextState = reducer(state, action);
    if (!environment.production) {
      console.groupCollapsed(action.type)
      console.debug('pre', state)
      console.debug('post', nextState)
      console.groupEnd()
    }
    return nextState
  };
};
