import { HttpErrorResponse } from "@angular/common/http";

const errorCodeToMsgMap = {
  'NOT_FOUND': 'No encontrado'
}

export const isApiErrorResponse = (error: any) => error instanceof HttpErrorResponse
export const getApiErrorMessage = (error: HttpErrorResponse)=>{
  const code = error?.error?.error?.code
  const message = error?.error?.error?.message
  if(code){
    return (errorCodeToMsgMap as any)[error.error.code] || message || 'Error Desconocido'
  }
  return 'Error desconocido'
}
