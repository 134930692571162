<div class="card card-sm-compact" [ngClass]="{'border-warning':missingPersonalDetails$ | async, 'border-2':missingPersonalDetails$ | async}">
  <div class="card-header">
    Dirección de envío
  </div>
  <div class="card-body">
    <app-address-form (onChange)="onPersonalDataChange($event)"
      [firstName]="user.firstName"
      [lastName]="user.lastName"
      [phone]="user.phone"
      [address]="user.address" />
  </div>
  <div class="card-footer text-end">
    <app-btn-async
      [disabled]="!this.value"
      submitText="Guardar"
      loadingText="Guardando..."
      [action]="saveAddress.bind(this)"
      (onSuccess)="onSuccess($event)"
      (onError)="onError($event)"
      />
  </div>
</div>
