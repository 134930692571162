import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-carrier-label',
  templateUrl: './carrier-label.component.html',
  styleUrls: ['./carrier-label.component.scss']
})
export class CarrierLabelComponent {
  @Input({required: true}) provider!: string
}
