import { Component, Input } from '@angular/core';
import { faTwitch } from '@fortawesome/free-brands-svg-icons';
import { IUser } from '../../../models/User';

@Component({
  selector: 'app-customer-tag',
  templateUrl: './customer-tag.component.html',
  styleUrls: ['./customer-tag.component.scss']
})
export class CustomerTagComponent {
  @Input({required: true}) customer!: IUser
  platform = 'twitch'
  get pictureUrl (){
    return this.customer.avatar
  }

  get platformIcon(){
    return faTwitch
  }

  get username(){
    return this.customer.alias
  }
}
