<div class="bid {{template}}" [ngClass]="{highest}" *ngIf="bid">
  <div class="picture">
    <img [src]="bid.avatar" />
  </div>
  <div class="text-start flex-grow-1 bid-data">
    <b class="amount">{{bid.amount}}{{auction.currencySymbol}}</b>
    <span class="bidder">{{bid.alias}}</span>
    <div class="time-ago" timeago [date]="bidCreatedAt" [live]="true"></div>
  </div>
  <ng-content/>
</div>
