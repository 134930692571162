<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="mb-2 d-flex gap-2 align-items-end">
    <div>
      <app-form-input label="Transportista" [form]="form" placeholder="Elige opción" controlName="fixedProvider" type="select" [options]="providerOptions"/>
    </div>
    <div *ngIf="providerSelected">
      <img style="border-radius: 80%" height="30" src="assets/img/carriers/{{providerSelected}}.jpg"/>
    </div>
    <div class="flex-grow-1">
      <app-form-input *ngIf="otherProviderSelected" label="Nombre transportista" [form]="form" controlName="provider" type="text"  />
    </div>
  </div>
  <app-form-input label="Cod. Seguimiento" [form]="form" controlName="trackingNumber" type="text" />

  <app-api-error-block [error]="error"/>
  <div class="d-flex align-items-center justify-content-end gap-1 mt-3">
    <ng-content/>
    <button class="btn btn-primary" [disabled]="submitDisabled" type="submit">Guardar</button>
  </div>
</form>
