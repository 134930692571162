<ng-template #mainBtnContent>
  <ng-container *ngIf="processing; else doPaymentIdle"><app-loading-spinner/> Procesando</ng-container>
  <ng-template #doPaymentIdle><ng-content/></ng-template>
</ng-template>

<button
  *ngIf="paymentMethods.length === 0"
  [class]="'btn '+klass" (click)="openAddMethodModal()">
  Añadir método
</button>

<button
  *ngIf="paymentMethods.length === 1"
  [disabled]="btnDisabled"
  [class]="'btn '+klass"
  (click)="performPayment(paymentMethods[0].id)">
    <ng-template [ngTemplateOutlet]="mainBtnContent"/>
</button>


<div ngbDropdown class="btn-group" *ngIf="paymentMethods.length > 1">
  <button [disabled]="btnDisabled" type="button" [class]="'btn '+klass" ngbDropdownToggle>
    <ng-template [ngTemplateOutlet]="mainBtnContent"/>
  </button>
  <div ngbDropdownMenu>
    <button *ngFor="let method of paymentMethods" ngbDropdownItem (click)="performPayment(method.id)">
      <app-payment-method-label [paymentMethod]="method"/>
    </button>
    <button ngbDropdownItem (click)="openAddMethodModal()" class="text-center">Añadir método</button>
  </div>
</div>
