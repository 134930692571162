import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ApiService, IApiPaginatedResponse, IApiRes } from './ApiService';
import { MerchantProfileDto, MerchantWithAuctionsDto } from '../../models/Merchant';
import { IAuctionExpanded } from '../../models/Auction';
import { objectToQueryParam } from '../../utils/common.utils';
import { OrderReviewDto } from './CustomerService';
import { IProduct } from '../../models/Product';
import { IPaginationParams } from './MerchantService';

export interface AuctionListingDto {
    liveMerchants: MerchantWithAuctionsDto[]
    offlineStartedAuctions: IAuctionExpanded[]
    upcomingAuctions: IAuctionExpanded[]
}


@Injectable({
  providedIn: 'root'
})
export class PublicService extends ApiService {

  getAuction(id: string, {widgetSettings} = {widgetSettings: false}): Observable<IAuctionExpanded> {
    const qp = widgetSettings ? `?widgetSettings=1` : ''
    return this.get<IApiRes<IAuctionExpanded>>(`public/auctions/${id}${qp}`,).pipe(
      map((res) => res.data)
    );
  }

  getMerchantLiveAuction(channel:string): Observable<IAuctionExpanded>{
    return this.get<IApiRes<IAuctionExpanded>>(`public/merchants/live-auction/${channel}`,).pipe(
      map((res) => res.data)
    );
  }

  getAuctionListing(): Observable<AuctionListingDto> {
    return this.get<IApiRes<AuctionListingDto>>(`public/auctions`,).pipe(
      map((res) => res.data)
    );
  }

  getMerchantWithAuctions(merchantId:string, filters: any = null): Observable<MerchantWithAuctionsDto> {let queryParams = ''
    if(filters){
      queryParams = `?${objectToQueryParam({ q: filters})}`
    }
    return this.get<IApiRes<MerchantWithAuctionsDto>>(`public/merchants/${merchantId}${queryParams}`).pipe(
      map((res) => res.data)
    );
  }

  getMerchantProfile(merchantAlias: string): Observable<MerchantProfileDto>{
    return this.get<IApiRes<MerchantProfileDto>>(`public/merchants/profile/${merchantAlias}`).pipe(
      map((res) => res.data)
    );
  }

  getMerchantProfiles(){
    return this.get<IApiPaginatedResponse<MerchantProfileDto>>(`public/merchants`).pipe(
      map((res) => res.data)
    );
  }

  getMerchantReviews(merchantId: string){
    return this.get<IApiPaginatedResponse<OrderReviewDto>>(`public/merchants/${merchantId}/reviews`);
  }

  getMerchantProducts(merchantId: string, {page, pageSize}: IPaginationParams) {
    return this.get<IApiPaginatedResponse<IProduct>>(`public/merchants/${merchantId}/products?page=${page||1}&pageSize=${pageSize || 5}`);
  }

  getMerchantAuctions(merchantId: string){
    return this.get<IApiPaginatedResponse<IAuctionExpanded>>(`public/merchants/${merchantId}/auctions`);
  }



}
