import { Component, Input } from '@angular/core';
import { IAuctionExpanded } from '../../../models/Auction';
import { AppStore } from '../../../store';
import { mAuctionListFeat } from '../../../store/merchant.store';

@Component({
  selector: 'app-merchant-auction-list',
  templateUrl: './merchant-auction-list.component.html',
  styleUrls: ['./merchant-auction-list.component.scss']
})
export class MerchantAuctionListComponent {

  @Input({ required: true }) auctions: IAuctionExpanded[] = []
  @Input() loading = false

  constructor(private store: AppStore) {

  }

  get error$() {
    return this.store.select(mAuctionListFeat.selectError)
  }
}

