import { Component, ContentChildren, Input, OnInit, QueryList, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { AppStore } from '../../../store';
import { debounceTime, distinctUntilChanged, forkJoin, map } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { faCaretLeft, faCaretRight, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { mAuctionListActions, mAuctionListFeat } from '../../../store/merchant.store';
import { ActionCreator, MemoizedSelector } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { Actions } from '@ngrx/effects';
import { DatatableColumn, DatatableColumnList, DatatableRow } from './datatable.interface';
import { IApiPaginationData } from '../../../api/services/ApiService';
import { ListStore } from '../../../store/utils/factory.list.store';


@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss']
})
export class DatatableComponent implements OnInit {

  constructor(
    private store: AppStore,
  ) { }


  ngOnInit(): void {
    this.loadDataWithParams()

    this.items$.subscribe(items => {
      if (!this.container) {
        return
      }
      this.container.clear(); // Clear the container

      items.forEach((item) => {
        const componentRef = this.container.createComponent(this.rowComponent);
        (<DatatableRow>componentRef.instance).item = item;
      });
    })

    this.store.select(this.paginationSelector).subscribe(pagination => {
      if(!pagination){
        return
      }
      this.pagination = pagination
      const pages = []
      const start = Math.max(1, pagination.page-3)
      const end = Math.min(pagination.totalPages, pagination.page+3)
      for (let i = start; i <= end; i++) {
        pages.push(i)
      }
      this.paginationList = pages
    })

    this.filterForm.get('search')!.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(()=>this.loadDataWithParams())
  }

  private loadDataWithParams(){
    this.store.dispatch(this.listStore.actions.load(this.loadParams))
  }
  /** Rendering */
  @ViewChild('rowContainer', { read: ViewContainerRef }) container!: ViewContainerRef;
  @Input() rowComponent: any
  /** State selector and actions */
  @Input() listStore!: ListStore<any, any>
  @Input() paginationSelector!: MemoizedSelector<any, IApiPaginationData, any>
  @Input() loadData!: ActionCreator<string, (props: { params:any, filters?: any; }) => { params:any, filters?: any } & TypedAction<string>>
  @Input() loadingSelector!: MemoizedSelector<any, boolean, any>
  @Input() dataSelector!: MemoizedSelector<any, any[], any>


  /** Columns */
  _columns: DatatableColumn[] = []
  @Input() set columns(columns: DatatableColumnList) {
    this._columns = columns.map(col => {
      if (typeof col === 'string') {
        return { title: col }
      }
      return col
    })
  }
  /** Data related */

  get loading$() { return this.store.select(this.loadingSelector) }
  get items$() { return this.store.select(this.dataSelector) }
  get count$() { return this.items$.pipe(map(items => items ? items.length : 0)) }
  get noItems$() {
    return forkJoin({
      loading: this.loading$,
      items: this.items$
    }).pipe(
      map(( {loading, items })=>!loading && items.length === 0)
    )
  }
  get hasItems$(){
    return this.noItems$.pipe(map(v=>!v))
  }

  /** Filters related */
  filterForm = new FormGroup({
    page: new FormControl(1),
    pageSize: new FormControl(10),
    sortBy: new FormControl({'createdAt': 'desc' }),
    list: new FormControl(''),
    search: new FormControl('')
  })

  get loadParams() {
    let { list, search, page, pageSize, sortBy } = this.filterForm.getRawValue()

    search = search || ''

    const filters: any = {
      search,
      list
    }

    return { params: null , filters, page, pageSize, sortBy }
  }

  /** Icons */
  refreshIcon = faRefresh
  prevIcon = faCaretLeft
  nextIcon = faCaretRight

  /** Pagination */
  pagination?: IApiPaginationData
  paginationList: number[] = []
  get isFirstPage() {
    return this.pagination?.page === 1
  }
  get isLastPage() {
    return this.pagination?.page === this.pagination?.totalPages
  }

  goPage(page:number|null|undefined){
    if(!page){
      return
    }
    this.filterForm.get('page')?.setValue(page)
    this.loadDataWithParams()
  }
}
