<tr [ngClass]="{live: isLive, started: isStarted}">
  <td class="align-middle">
    <div class="d-flex align-items-center gap-1">
      <div class="picture">
        <img [src]="auction.pictureUrl" />
      </div>
      <div>
        <div>{{auction.title}}</div>
        <div *ngIf="isLiveNotStarted"><app-stream-platform-icon platform="twitch"/></div>
        <div *ngIf="isLive"><span class="badge text-bg-danger">En directo</span></div>
        <div *ngIf="!isLive && isStarted"><span class="badge text-bg-primary">En curso</span></div>
      </div>

    </div>

  </td>

  <td class="align-middle" >
    <div>
       {{ auction.startingPrice }}{{auction.currencySymbol}}
      <small>+{{ auction.minBidIncrement }}</small>
    </div>
    <div >
       {{ auction.duration }}{{auction.durationUnit}}
      <small>+{{ auction.extPeriod }}{{ auction.extPeriodUnit }}</small>
    </div>
  </td>
  <td class="align-middle" >
    <div>{{ auction.startsAt | date: 'shortDate'}}</div>
    <div>{{ auction.startsAt | date: 'HH:mm:ss'}}</div>
  </td>
  <td class="align-middle">
    <app-remaining-time-blocks [date]="auction.endsAt" [compact]="true"/>
    <div>{{ auction.endsAt | date: 'short'}}</div>
  </td>
  <td class="align-middle">

    <app-bid [auction]="auction" [bid]="winningBid" template="compact" *ngIf="winningBid; else noBidder" />
    <ng-template #noBidder>No hay pujas</ng-template>
  </td>
  <!-- Actions -->
  <td class="align-middle text-end">
    <div class="d-flex gap-1 align-items-center justify-content-end">
      <button
        *ngIf="isCreated"
        (dblclick)="start()"
        placement="start" ngbTooltip="Pulsa 2 veces"
        triggers="click:blur"
        class="btn btn-sm btn-outline-primary">
        <fa-icon [icon]="playIcon" /> Empezar
      </button>
      <div class="btn-group btn-group-sm">
        <ng-container [ngTemplateOutlet]="repeatAuctionDropdown" />
        <!-- <a *ngIf="isEditable" class="btn btn-outline-secondary" [routerLink]="editLink">Editar</a> -->

        <div ngbDropdown class="btn-group" placement="bottom-end">
          <button type="button" class="btn btn-sm btn-outline-secondary" id="otherActions" ngbDropdownToggle>

          </button>
          <div ngbDropdownMenu aria-labelledby="otherActions">
            <button ngbDropdownItem (click)="showCopiedToClipboardNotification()" ngxClipboard
              [cbContent]="widgetUrl"><fa-icon [icon]="copyIcon" /> Widget</button>
              <a ngbDropdownItem [routerLink]="viewLink" target="_blank"><fa-icon [icon]="viewIcon"/> Ver subasta pública</a>
              <a ngbDropdownItem *ngIf="auction.orderId" [routerLink]="orderLink" target="_blank"><fa-icon [icon]="viewIcon"/> Ver pedido</a>
              <button ngbDropdownItem class="text-danger" (click)="del()"
                [disabled]="!!((isDeleting$|async)||(isDuplicating$|async))">
                <ng-container *ngIf="(isAuctionBeingDeleted$|async); else deleteIconText">
                  <app-loading-spinner />
                </ng-container>
                <ng-template #deleteIconText>
                  <fa-icon [icon]="deleteIcon"/> Borrar
                </ng-template>
              </button>
          </div>
        </div>


      </div>
    </div>
  </td>
  <!-- End actions -->

</tr>



<ng-template #repeatAuctionDropdown>
  <div ngbDropdown placement="bottom-end" class="btn-group">
    <button type="button" class="btn btn-sm btn-outline-secondary" id="dropdownBasic1" ngbDropdownToggle>
      <ng-container *ngIf="(isAuctionBeingRepeated$|async); else repeatContent">
        <app-loading-spinner />
      </ng-container>
      <ng-template #repeatContent>
        <fa-icon [icon]="repeatIcon" />
      </ng-template>
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
      <button ngbDropdownItem (click)="duplicateAuction()"><fa-icon [icon]="repeatIcon"/> Copiar</button>
      <a ngbDropdownItem [routerLink]="['/streamer/subastas',auction.id,'copiar']"><fa-icon [icon]="editIcon"/> Modificar</a>
    </div>
  </div>
</ng-template>
