import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, exhaustMap, catchError, tap, take, filter } from 'rxjs/operators';
import { AuthService } from '../auth/AuthService';
import { authActions } from './auth.store';
import { Router } from '@angular/router';
import { AppStore } from './app.store';
import { userFeat } from './user.store';

@Injectable()
export class AuthEffects {

  getTokenFromOauthCallbackToken$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.authenticateViaOauth),
    exhaustMap(({ oauthToken, platform, returnAfterLogin }) => this.authSvc.processOauthCallback(platform, oauthToken)
      .pipe(
        map(({ token }) => {
          this.authSvc.setToken(token)
          return authActions.authenticateOK({ token })
        }),
        tap(() => {
          this.redirectAfterAuthOk({ returnAfterLogin })

        }),
        catchError((error) => of(authActions.authenticateKO({ error }))))
    )
  ))

  redirectAfterAuthOk({returnAfterLogin}: {returnAfterLogin?:string|null} = {}){
    this.store.select(userFeat.selectUser).pipe(filter(v=>!!v),take(1)).subscribe(user=>{
      setTimeout(() => {
        if(returnAfterLogin && !returnAfterLogin.includes('logout')){
          return this.router.navigateByUrl(returnAfterLogin)
        }
        if(user!.address && user!.paymentMethods.length > 0){
            return this.router.navigateByUrl('/viewer/subastas')
        }
        return this.router.navigate(['/viewer'])
      }, 1000)

    })
  }


  onLogin$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.authenticateViaCredentials),
    exhaustMap(({email, password, returnAfterLogin}) => this.authSvc.login({ email, password })
      .pipe(
        map(({ token }) => {
          this.authSvc.setToken(token)
          return authActions.authenticateOK({ token })
        }),
        tap(() => {
          this.redirectAfterAuthOk({ returnAfterLogin })
        }),
        catchError((error) => of(authActions.authenticateKO({ error }))))
    )
  ))

  onLogout$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.logout),
    map(() => {
      this.authSvc.logout()
      return authActions.logoutOK()
    }),

  ))

  constructor(
    private router: Router,
    private store: AppStore,
    private actions$: Actions,
    private authSvc: AuthService
  ) { }
}
