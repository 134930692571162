import { Component, Input } from '@angular/core';
import { IUser } from '../../../models/User';
import { MerchantService } from '../../../api/services/MerchantService';
import { MerchantOnboardingTask, OnboardingStatusDto } from '../../../api/services/UserService';
import { faTwitch } from '@fortawesome/free-brands-svg-icons';
import { faContactCard, faUserTie } from '@fortawesome/free-solid-svg-icons';
import { trackEvent } from '../../../../tracker';


@Component({
  selector: 'app-merchant-onboarding-wizard',
  templateUrl: './merchant-onboarding-wizard.component.html',
  styleUrls: ['./merchant-onboarding-wizard.component.scss']
})
export class MerchantOnboardingWizardComponent {
  @Input({ required: true }) status!: OnboardingStatusDto
  @Input({ required: true }) user!: IUser

  _tasks = [
    {
      task: MerchantOnboardingTask.RequestMerchantApproval,
      icon: faContactCard,
      title: 'Toma de contacto',
      description: 'Nos encantaría tener una llamada contigo y entender tus necesidades antes de comenzar',
      cta: 'Solicitar',
      loading: false
    },{
      task: MerchantOnboardingTask.ConnectStreamingChannel,
      icon: faTwitch,
      title: 'Conecta tu canal de Twitch',
      description: 'Necesitamos permisos para nuestro bot para poder operar y verificar tu canal.',
      loading: false
    },{
      task: MerchantOnboardingTask.CompleteMerchantDetails,
      icon: faUserTie,
      title: 'Datos de Particular o Empresa',
      description: 'Los necesitamos junto a tu cuenta de banco para mandarte tu dinero!',
      loading: false
    }
  ]

  constructor(private merchantSvc: MerchantService) {

  }


  get tasks(){
    return this._tasks.filter(task=>this.status.merchant.tasks.some(t=>t.name === task.task))
  }

  isCompleted(taskName: MerchantOnboardingTask) {
    return this.status.merchant.tasks.find(task=>task.name === taskName && task.completed)
  }

  goToTask(taskName: MerchantOnboardingTask) {
    const task = this.tasks.find(t => t.task === taskName)
    if(!task){
      return
    }
    trackEvent(`merchant.onboarding.task.start.${taskName}`)
    if(taskName === MerchantOnboardingTask.RequestMerchantApproval){
      window.location.href = 'https://calendly.com/suass'
      return
    }
    if (taskName === MerchantOnboardingTask.CompleteMerchantDetails) {
      task.loading = true

      this.merchantSvc.getMerchantOnboardingUrl().subscribe({
        next:({url})=> window.location.href = url,
        error:(err)=> task.loading = false,
      })
    } else {
      this.merchantSvc.getChannelAuthorizationUrl('twitch').subscribe({
        next:({url})=> window.location.href = url,
        error:(err)=> task.loading = false,
      })
    }
  }
}
