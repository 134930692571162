import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, exhaustMap, catchError} from 'rxjs/operators';
import { cDirectBuyFormStore } from './customer/customer.shop.store';
import { CustomerService } from '../api/services/CustomerService';
import { cOrderReviewForm } from './customer/review.store';

@Injectable()
export class CustomerEffects {



  createDirectBuy$ = createEffect(() => this.actions$.pipe(
    ofType(cDirectBuyFormStore.actions.create),
    exhaustMap(({ payload }) => this.customerSvc.directBuy(payload)
      .pipe(
        map(({orderId}) => {
          return cDirectBuyFormStore.actions.createOK({ orderId })
        }),
        catchError((error) => {
          return of(cDirectBuyFormStore.actions.createKO({ error }))
        }))
      )
  ))

  loadOrderReview$ = createEffect(() => this.actions$.pipe(
    ofType(cOrderReviewForm.actions.load),
    exhaustMap(({ id }) => this.customerSvc.getOrderReview(id)
      .pipe(
        map(data => {
          return cOrderReviewForm.actions.loadOK({ data })
        }),
        catchError((error) => {
          return of(cOrderReviewForm.actions.loadKO({ error }))
        }))
      )
  ))

  createOrderReview$ = createEffect(() => this.actions$.pipe(
    ofType(cOrderReviewForm.actions.create),
    exhaustMap(({ payload }) => this.customerSvc.createReview(payload)
      .pipe(
        map(data => {
          return cOrderReviewForm.actions.createOK({ data })
        }),
        catchError((error) => {
          return of(cOrderReviewForm.actions.createKO({ error }))
        }))
      )
  ))



  constructor(
    private actions$: Actions,
    private customerSvc: CustomerService
  ) { }
}
