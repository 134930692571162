import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IAuctionExpanded } from '../../../models/Auction';
import { ActivatedRoute } from '@angular/router';
import { MerchantWidgetSettings, WidgetOrientation } from '../../../models/Order';

const sampleBid: any = {
  "idempotencyKey": "test3",
  "amount": "10",
  "auctionId": "auc_6d4b736a-4074-49ed-9516-3728ce94f6e9",
  "trigger": "manual",
  "bidderId": "user_22db1b88-36ef-4be2-9f15-8f90caa011d4",
  "id": "bid_3f50a8e9-02df-41ca-b10e-32c61847540b",
  "createdAt": "2023-12-13T21:17:45.224Z",
  "alias": "Sarah",
  "avatar": "https://static-cdn.jtvnw.net/jtv_user_pictures/d5226db0-11f4-4b6a-8033-91c108ba254e-profile_image-300x300.png"
}

@Component({
  selector: 'app-auction-widget-editor',
  templateUrl: './auction-widget-editor.component.html',
  styleUrls: ['./auction-widget-editor.component.scss']
})
export class AuctionWidgetEditorComponent {
  @Input() settings!: MerchantWidgetSettings
  @Output() onChange = new EventEmitter<MerchantWidgetSettings>
  form = new FormGroup({
    horizontal: new FormControl(false, [Validators.required]),
    displayProductPicture: new FormControl(true, [Validators.required]),
    displaySecondToLastBid: new FormControl(true, [Validators.required])
  })

  auction!: IAuctionExpanded

  constructor(
    private route: ActivatedRoute,

  ) { }

  ngOnInit(): void {
    this.auction = {
      "id": "auc_6d4b736a-4074-49ed-9516-3728ce94f6e9",
      "endsAt": Date.now() + 5000,
      "startedAt": 1702495971421,
      "endedAt": null,
      "bids": [],
      "title": "Zapdos PGO 029",
      "state": "started",
      "startingPrice": "10",
      "minBidIncrement": "1",
      "pictureUrl": "/assets/img/samples/PGO_EN_29.png",
      "biddingTimeExtensionInSec": 20,
      "currencySymbol": "€",
      "currency": "EUR",
      "createdAt": 1702495971421,
      "startsAt": 1702495971421,
      "currentPrice": "12",
      "nextBidAmount": "13"
    } as any

    if(this.settings){
      const {displayProductPicture, displaySecondToLastBid, orientation} = this.settings
        this.form.setValue({
          horizontal: orientation === WidgetOrientation.Horizontal,
          displayProductPicture,
          displaySecondToLastBid
        })
    }


    this.form.valueChanges.subscribe(value=>{
      this.onChange.emit({
        orientation: value.horizontal ? WidgetOrientation.Horizontal : WidgetOrientation.Vertical,
        displayProductPicture: value.displayProductPicture || false,
        displaySecondToLastBid: value.displaySecondToLastBid || false
      })
    })
  }

  simulateBid() {
    const prevBid = this.auction.bids[0]
    this.auction = {
      ...this.auction,
      bids: [
        {
          ...sampleBid,
          amount: prevBid ? `${Number(prevBid.amount) + 1}` : sampleBid.amount,
        },
        ...this.auction.bids
      ]
    }
  }

  get orientation() {
    return this.form.get('horizontal')?.value ? WidgetOrientation.Horizontal : WidgetOrientation.Vertical
  }
  get displayProductPicture() {
    return this.form.get('displayProductPicture')?.value!
  }
  get displaySecondToLastBid() {
    return this.form.get('displaySecondToLastBid')?.value!
  }

}
