<app-page-title title="Mi cuenta">
  <a [routerLink]="isStreamerMode ? '/viewer' : '/streamer'" class="form-check form-switch">
    <input class="form-check-input" type="checkbox" role="switch" id="accMode" [checked]="isStreamerMode">
    <label class="form-check-label" for="accMode">Modo streamer</label>
  </a>
</app-page-title>
<ul ngbNav #nav="ngbNav" [activeId]="tab" class="nav-tabs mb-3 flex-grow-1 align-items-center">
  <li ngbNavItem="datos">
    <a routerLink="datos" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" ngbNavLink>Mis datos</a>
    <ng-template ngbNavContent><app-merchant-profile-shop-page /></ng-template>
  </li>
  <li ngbNavItem="pedidos">
    <a routerLink="pedidos" routerLinkActive="active" ngbNavLink>Pedidos</a>
    <ng-template ngbNavContent><app-merchant-profile-auctions-page /></ng-template>
  </li>
</ul>
<router-outlet></router-outlet>


<div class="d-block d-sm-none mt-3">
  <a routerLink="/logout" class="btn btn-lg w-100 btn-outline-danger">Cerrar sesión</a>
</div>
