<div class="wrapper" [ngClass]="{ready: loadedImages === 2}">


  <div class="header">
    <div class="banner">
      <div class="image">
        <img [src]="user.banner" (load)="onImgLoad()" />
      </div>
    </div>
  </div>
  <div class="sub-header px-3">
    <div class="d-flex align-items-start gap-3">
      <div class="avatar">
        <img [src]="user.avatar" (load)="onImgLoad()" />
      </div>
      <div class="flex-grow-1">
        <!-- Title, Rating & Follow -->
        <div class="d-flex align-items-center">
          <div class="heading">
            <div class="d-flex gap-2">
              <h2 class="alias">{{user.alias}}</h2>
              <div class="following" ngbTooltip="Le sigues!" placement="bottom">
                <fa-icon [icon]="followingIcon"/>
              </div>
              <div [routerLink]="['/viewer','mercado',user.alias,'valoraciones']" class="rating pointer" *ngIf="reviewStats?.avgRating">
                <span class="icon">
                  <fa-icon [icon]="starIcon" />
                </span>
                <span class="rate">{{ reviewStats?.avgRating }}</span>
              </div>
            </div>
            <p class="title">{{user.title}}</p>
          </div>
          <div class="ms-auto">
            <app-merchant-follow-button [merchant]="user.alias"/>
          </div>
        </div>
        <!-- socials -->
        <!-- <ul class="d-flex gap-1 list-unstyled">
          <li *ngFor="let social of userSocialNetworks">
            <fa-icon [icon]="social.icon"/> {{social.user}}
          </li>
        </ul> -->
      </div>
    </div>

    <!-- Bio -->
    <div class="bio mb-2" *ngIf="user.bio">{{ user.bio }}</div>
  </div>
</div>
