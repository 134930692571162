<app-isolated-layout>
  <section>
    <div class="d-flex justify-content-center">

      <div style="max-width: 450px; width: 100%">
        <div>
          <div class="mb-3">
            <img class="full" src="/assets/logo/net_purple_logo_small.png" height="40" />
          </div>
          <div>
            <div class="my-3">
              <h5 class="card-title">Recupera tu acceso</h5>
              <p>Establece una nueva contraseña</p>
            </div>
          </div>
        </div>
        <div class="mt-2 border border-2 p-2 rounded">
          <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
            <app-form-input type="password" placeholder="*******" label="Nueva contraseña" controlName="newPassword"
              [form]="form" marginBottom="3" />
            <app-form-input type="password" placeholder="*******" label="Confirmar contraseña"
              controlName="repeatPassword" [form]="form" marginBottom="3" />

            <app-form-errors [form]="form" />

            <div *ngIf="apiError" class="mb-4">
              <div class="alert alert-danger" role="alert">
                {{apiError.error.message}}
              </div>
            </div>

            <div class="d-grid mb-3">
              <button class="btn btn-block btn-primary" [disabled]="isCtaDisabled" type="submit"
                [ngClass]="{loading: isLoading}">
                <ng-container *ngIf="isIdle">Cambiar contraseña</ng-container>
                <ng-container *ngIf="isLoading">
                  <div class="spinner-border  spinner-border-sm" role="status"></div>
                  Cambiando contraseña
                </ng-container>
                <ng-container *ngIf="success"><fa-icon [icon]="btnSuccessIcon"></fa-icon> Listo!</ng-container>
              </button>
            </div>

          </form>
        </div>
      </div>


    </div>
  </section>

</app-isolated-layout>
