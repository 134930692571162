import { Component, OnInit } from '@angular/core';
import { AppStore } from '../../../../store';
import { pMerchantProductList, pMerchantProfile } from '../../../../store/public/merchant.profile.store';
import { map } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-merchant-profile-shop-page',
  templateUrl: './merchant-profile-shop-page.component.html',
  styleUrls: ['./merchant-profile-shop-page.component.scss']
})
export class MerchantProfileShopPageComponent implements OnInit {
  constructor(
    private store: AppStore
  ) {}

  profile$ = this.store.select(pMerchantProfile.feat.selectData)
  products$ = this.store.select(pMerchantProductList.feat.selectData)
  loading$ = this.store.select(pMerchantProductList.feat.selectLoading)
  hasProducts$ = this.products$.pipe(map(products=>products.length >0 ))


  listStore = pMerchantProductList


  filterForm = new FormGroup({
    search: new FormControl('')
  })

  ngOnInit(): void {
    this.profile$.subscribe((profile)=>{
      if(!profile){
        return
      }
      this.store.dispatch(pMerchantProductList.actions.load({
        params:{
          merchantId: profile.user.id
        }
      }))
    })

    this.filterForm.valueChanges.subscribe((filters)=>{
      this.store.dispatch(pMerchantProductList.actions.setFilters({ filters }))
    })
  }

}
