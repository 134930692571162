import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UiService } from '../../../../ui/services/ui.service';
import { MerchantService } from '../../../../api/services/MerchantService';
import { IAuction } from '../../../../models/Auction';
import { createSubs, unsubAll } from '../../../../utils/rxjs.utils';

@Component({
  selector: 'app-edit-auction-page',
  templateUrl: './edit-auction-page.component.html',
  styleUrls: ['./edit-auction-page.component.scss']
})
export class EditAuctionPageComponent {
  auction: IAuction | null = null
  subs = createSubs()
  public constructor(private route: ActivatedRoute, private router: Router, private uiCommonSvc: UiService, private merchantSvc: MerchantService) {
  }


  ngOnInit(): void {
    this.subs.routeParams = this.route.params.subscribe(({ id }) => {
      this.merchantSvc.getAuction(id).subscribe((auction) => {
        this.auction = auction
      })
    })
    this.uiCommonSvc.setTitle('Editar subasta')
  }
  ngOnDestroy() {
    unsubAll(this.subs)
  }
}
