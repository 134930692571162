import { Component, Input } from '@angular/core';
import { AuthService } from '../../../auth/AuthService';
import { IApiErrorResponse } from '../../../api/services/ApiService';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { faCheck, faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { AppStore } from '../../../store';
import { authActions, authFeat } from '../../../store/auth.store';
import { map, of, switchMap } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  form = new FormGroup({
    email: new FormControl('', {
      validators: [Validators.required, Validators.email],
      nonNullable: true,
    }),
    password: new FormControl('', {
      validators: [Validators.required, Validators.minLength(6)],
      nonNullable: true,
    }),
  })

  btnSuccessIcon = faCheck
  emailIcon = faEnvelope
  passIcon = faLock
  returnAfterLogin?:string|null
  constructor(
    private store: AppStore,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe(({ returnAfterLogin })=>{
      this.returnAfterLogin = returnAfterLogin
    })
  }


  get isIdle$() {
    return this.isLoading$.pipe(map(v => !v))
  }
  get isLoading$() {
    return this.store.select(authFeat.selectLoading)
  }

  get error$() {
    return this.store.select(authFeat.selectError)
  }

  get formHasErrors() {
    return this.form.invalid && this.form.touched;
  }

  get isCtaDisabled$() {
    return this.success$.pipe(switchMap(success => {
      return this.isLoading$
    }))
  }

  get success$() {
    return this.store.select(authFeat.selectToken).pipe(map(v => !!v))
  }


  onSubmit() {
    if (!this.form.valid) {
      return;
    }

    const { email, password } = this.form.getRawValue()
    this.store.dispatch(authActions.authenticateViaCredentials({ email, password, returnAfterLogin: this.returnAfterLogin }))
  }

}
