import { Component, Input } from '@angular/core';
import { OrderStatus } from '../../../models/Order';

@Component({
  selector: 'app-order-status-label',
  template: `
    <span class="badge status {{status}}"><span *ngIf="showIcon">{{icon}}</span> {{text}}</span>
  `,
  styleUrls: ['./order-status-label.component.scss']
})
export class OrderStatusLabelComponent {
  @Input({required: true}) status!: OrderStatus
  @Input() showIcon = true

  get text(){
    return {
      [OrderStatus.Created] : 'Creado',
      [OrderStatus.PendingPayment] : 'Pendiente de pago',
      [OrderStatus.Paid] : 'Pagado',
      [OrderStatus.Sent] : 'Enviado',
      [OrderStatus.AwaitingCustomerDeliveryApproval] : 'Esperando aprobación',
      [OrderStatus.PendingDelivery] : 'Envío pospuesto',
      [OrderStatus.Canceled] : 'Cancelado',
      [OrderStatus.Completed] : 'Completado',
      [OrderStatus.Refunded] : 'Reembolsado',
    }[this.status];
  }

  get icon(){
    return {
      [OrderStatus.Created] : '😺',
      [OrderStatus.PendingPayment] : '💳',
      [OrderStatus.Paid] : '🤑',
      [OrderStatus.AwaitingCustomerDeliveryApproval] : '🕰️',
      [OrderStatus.Sent] : '🚚',
      [OrderStatus.PendingDelivery] : '📦',
      [OrderStatus.Canceled] : '✖️',
      [OrderStatus.Completed] : '☑️',
      [OrderStatus.Refunded] : '👋',
    }[this.status];
  }

}
