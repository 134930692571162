import { Component } from '@angular/core';
import { DatatableColumnList } from '../../../../ui/components/datatable/datatable.interface';
import { mProductListStore } from '../../../../store/merchant/product/merchant.product.store';
import { MerchantProductListItemComponent } from '../../../../ui/components/merchant-product-list-item/merchant-product-list-item.component';

@Component({
  selector: 'app-merchant-products-page',
  templateUrl: './merchant-products-page.component.html',
  styleUrls: ['./merchant-products-page.component.scss']
})
export class MerchantProductsPageComponent {
  store = mProductListStore
  loadingSelector = mProductListStore.feat.selectLoading
  loadData = mProductListStore.actions.load
  dataSelector = mProductListStore.feat.selectData
  rowComponent = MerchantProductListItemComponent
  columns: DatatableColumnList = [
    'Artículo',
    'Precio',
    'Precio envío',
    'Cantidad',
    {title: 'Acciones', classes: 'text-end'}
  ]
}
