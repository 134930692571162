import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UiService } from '../../../../ui/services/ui.service';
import { CustomerService } from '../../../../api/services/CustomerService';
import { CustomerOrderDto } from '../../../../models/Order';
import { IUser } from '../../../../models/User';
import { AppStore } from '../../../../store';
import { userFeat } from '../../../../store/user.store';
import { createSubs, unsubAll } from '../../../../utils/rxjs.utils';
import { interval } from 'rxjs';

@Component({
  selector: 'app-customer-order-view-page',
  templateUrl: './customer-order-view-page.component.html',
  styleUrls: ['./customer-order-view-page.component.scss']
})
export class CustomerOrderViewPageComponent implements OnInit, OnDestroy {

  order: CustomerOrderDto | null = null
  user: IUser | null = null
  subs = createSubs()
  public constructor(
    private route: ActivatedRoute,
    private uiCommonSvc: UiService,
    private customerSvc: CustomerService,
    private store: AppStore
  ) {
  }

  ngOnInit(): void {
    this.subs.routeParams = this.route.params.subscribe(({ id }) => {
      this.fetchOrder(id)
    })
    this.subs.user = this.store.select(userFeat.selectUser).subscribe((user) => {
      this.user = user
    })

    this.subs.refreshOrder = interval(5000).subscribe(() => {
      if(this.order?.id){
        this.fetchOrder(this.order.id)
      }
    })

    this.uiCommonSvc.setTitle(`Pedido`)
  }

  private fetchOrder(id: string){
    this.customerSvc.getOrder(id).subscribe((order) => {
      this.order = order
    })
  }

  ngOnDestroy(): void {
    unsubAll(this.subs)
  }


}



