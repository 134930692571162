import { Component, OnDestroy, OnInit } from '@angular/core';
import { OnboardingStatusDto } from '../../../../api/services/UserService';
import { IUser } from '../../../../models/User';
import { AppStore } from '../../../../store';
import { userFeat } from '../../../../store/user.store';
import { createSubs, unsubAll } from '../../../../utils/rxjs.utils';
import { AccountMode } from '../../../../enums';
import { AuthService } from '../../../../auth/AuthService';


@Component({
  selector: 'app-merchant-onboarding-page',
  templateUrl: './merchant-onboarding-page.component.html',
  styleUrls: ['./merchant-onboarding-page.component.scss']
})
export class MerchantOnboardingPageComponent implements OnInit, OnDestroy {
  status: OnboardingStatusDto | null = null
  user: IUser | null = null
  subs = createSubs()
  constructor(private store: AppStore, private authSvc: AuthService) { }
  ngOnInit(): void {
    this.subs.userState = this.store.select(userFeat.selectUserState).subscribe(({ status, user }) => {
      this.status = status
      this.user = user
    })
  }

  ngOnDestroy() {
    unsubAll(this.subs)
  }
}
