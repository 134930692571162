<div class="table-responsive table-bottom-spacing">
    <table class="table w-100">
      <thead>
        <tr>
          <th>Artículo</th>
          <th >
            Precio<br/>
            Duracion
          </th>
          <th >Empieza</th>
          <th>Termina</th>
          <th>Ganador</th>
          <th class="text-end">Acciones</th>
        </tr>
      </thead>
    <tbody [auction]="auction" app-merchant-auction-list-item *ngFor="let auction of auctions"></tbody>
    </table>
  </div>
