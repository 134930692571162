import { Component, Input, OnInit } from '@angular/core';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { IProduct } from '../../../models/Product';
import { DatatableRow } from '../datatable/datatable.interface';
import { AppStore } from '../../../store';
import { mProductListStore_Backup } from '../../../store/merchant/product/merchant.product.store';
import { map } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';

@Component({
  selector: 'tbody[app-merchant-product-list-item]',
  templateUrl: './merchant-product-list-item.component.html',
  styleUrls: ['./merchant-product-list-item.component.scss']
})
export class MerchantProductListItemComponent implements DatatableRow, OnInit {
  constructor(
    private store: AppStore,
    private actions$: Actions
  ) { }

  deleted = false

  @Input({ required: true }) item!: IProduct

  deletingId$ = this.store.select(mProductListStore_Backup.feat.selectDeletingId)
  isBeingDeleted$ = this.deletingId$.pipe(map(deletingId => {
    return deletingId === this.item.id
  }))

  ngOnInit(): void {
    this.actions$.pipe(ofType(mProductListStore_Backup.actions.removeOK)).subscribe(({deletedId})=>{
      this.deleted = deletedId === this.item.id
    })
  }

  get hasStock() {
    return this.item.stock.amount > 0
  }
  remove() {
    this.store.dispatch(mProductListStore_Backup.actions.remove({ deletingId: this.item.id }))
  }



  deleteIcon = faTrash
  editIcon = faEdit
}
