export const emailToMailboxUrl = (email: string): string|null=> {
    
    const [mailbox, domain] = email.split('@');
    if(!mailbox || !domain){
      return null;
    }
    if(domain.includes('gmail')){
        return `https://mail.google.com`;
    }
    if(domain.includes('yahoo')){
        return `https://mail.yahoo.com`
    }

    return null;
}
