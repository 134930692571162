<ng-container *ngIf="order">
  <app-page-title [title]="title" *ngIf="merchantMode">
    <div class="text-end d-flex gap-1" >
      <button class="btn btn-outline-secondary" (click)="downloadDeliveryLabel()">
        Descargar etiqueta
      </button>
      <button class="btn btn-outline-danger" (click)="cancel()" *ngIf="canCancel()"
        [disabled]="(isBeingCanceled$|async)">
        <ng-container *ngIf="(isBeingCanceled$|async);else cancelBtnLabel"><app-loading-spinner /> Cancelando
          pedido</ng-container>
        <ng-template #cancelBtnLabel>Cancelar pedido</ng-template>
      </button>
    </div>
  </app-page-title>
  <div class="row">
    <div class="col-12 col-lg-6 col-xl-6">
      <div class="mb-3">
        <app-customer-order-view-review wrapper="card" [order]="order" *ngIf="customerMode && canReview" />
      </div>
      <div class="card mb-4">
        <div class="card-header">Detalles</div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item d-flex gap-2 p-1">
            <b>ID</b>
            <span>{{order.id}}</span>
            <button class="btn btn-sm btn-link" ngbTooltip="Copiar" ngxClipboard [cbContent]="order.id"><fa-icon
                [icon]="copyIcon" /></button>
          </li>
          <li class="list-group-item d-flex gap-2 p-1">
            <b>Estado</b>
            <app-order-status-label [status]="order.status" />
          </li>
          <li class="list-group-item d-flex gap-2 p-1">
            <b>Cliente</b>
            <div><app-customer-tag [customer]="order.customer" /></div>
          </li>
          <li class="list-group-item d-flex gap-2 p-1" *ngIf="merchantMode" >
            <b>Comisión</b>
            <ng-template #merchantFeeTooltip>
              <div class="text-start">
                Suass: {{order.fee.app}}{{order.currencySymbol}}<br/>
                Stripe: {{order.fee.provider}}{{order.currencySymbol}}
              </div>
            </ng-template>
            <span [ngbTooltip]="merchantFeeTooltip">{{order.fee.total}} {{order.currencySymbol}}</span>
          </li>
          <li class="list-group-item d-flex gap-2 p-1">
            <b>Email</b>
            <a class="flex-grow-1" href="mailto:{{order['customer'].email}}">{{order['customer'].email}}</a>
            <button class="btn btn-sm btn-link" ngbTooltip="Copiar" ngxClipboard
              [cbContent]="order['customer'].phone"><fa-icon [icon]="copyIcon" /></button>
          </li>
          <li class="list-group-item d-flex gap-2 p-1">
            <b>Teléfono</b>
            <a class="flex-grow-1" href="tel:{{order['customer'].phone}}">{{order['customer'].phone}}</a>
            <button class="btn btn-sm btn-link" ngbTooltip="Copiar" ngxClipboard
              [cbContent]="order['customer'].phone"><fa-icon [icon]="copyIcon" /></button>
          </li>
        </ul>
        <div class="card-header">Direcciones</div>
        <div class="card-body">
          <div class="row g-0">
            <div class="col-12 col-sm-6 mb-3">
              <app-address-block [address]="order.deliveryAddress" title="Envío"></app-address-block>
            </div>
            <div class="col-12 col-sm-6">
              <app-address-block [address]="order.billingAddress" title="Facturación"></app-address-block>
            </div>
          </div>
        </div>

      </div>

      <div class="card mb-3">
        <div class="card-header">Pedido</div>
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <button *ngIf="merchantMode && canAddItems" class="btn btn-sm btn-outline-secondary"
              (click)="openAddOrderItemModal()">Añadir</button>

          </div>
        </div>
        <div class="table-responsive">
          <table class="table items-table table-sm table-striped mb-0 rounded">
            <thead>
              <tr>
                <th>Descripción</th>
                <th class="text-end">Cantidad</th>
                <th class="text-end">Impuestos</th>
                <th class="text-end">Total</th>
                <th *ngIf="merchantMode"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of order.items" class="order-item"
              (mouseenter)="onMouseOverItem(item)"
              (mouseleave)="onMouseLeaveItem(item)"
                [ngClass]="{'table-warning': itemPaymentIsNotSuccessful(item)}">
                <td class="align-middle img-name"><img class="image" [src]="item.imageUrl" /> {{item.description}}</td>
                <td class="align-middle text-end text-nowrap">{{item.netAmount}}{{order.currencySymbol }}</td>
                <td class="align-middle text-nowrap text-end">
                  <ng-template #taxTooltip>{{item.tax.name}} {{item.tax.percent}}%</ng-template>
                  <div [ngbTooltip]="taxTooltip"><fa-icon [icon]="helpIcon" /> {{item.tax.amount}}{{order.currencySymbol
                    }}
                  </div>
                </td>
                <td class="align-middle text-end text-nowrap">
                  <div class="d-flex gap-1 justify-content-end">
                    <s ngbTooltip="Cantidad reembolsada"
                      *ngIf="item.refundedAmount && item.refundedAmount !== '0'"><small>{{item.refundedAmount}}{{order.currencySymbol
                        }}</small></s>
                    <b>{{item.total}}{{order.currencySymbol }}</b>
                  </div>
                </td>
                <td class="align-middle px-1" *ngIf="merchantMode">
                  <div [ngbTooltip]="refundBtnTooltip(item)">
                    <button [disabled]="!isRefundable(item)" (click)="openRefundForm(item.id, item.total)"
                      class="btn btn-link text-danger">
                      <fa-icon [icon]="cancelIcon" />
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="1"></td>
                <td class="text-end"><b>{{netTotal}}{{order.currencySymbol}}</b></td>
                <td class="text-end"><b>{{taxTotal}}{{order.currencySymbol}}</b></td>
                <td class="text-end"><b>{{order.total}}{{order.currencySymbol}}</b></td>
                <td *ngIf="merchantMode"></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>


    </div>

    <div class="col-12 col-lg-6 col-xl-6 mb-3">
      <div class="card mb-4">
        <div class="card-header">Pagos</div>
        <div class="card-body p-3 pt-0">
          <div class="d-flex align-items-center mb-3">
            <div class="ms-auto" *ngIf="merchantMode">
              <button class="btn btn-sm btn-outline-secondary"
                (click)="refreshPayments()"
                *ngIf="hasProcessingPayment"
                [disabled]="paymentsAreBeingRefreshed$|async"
                >
                <ng-container *ngIf="paymentsAreBeingRefreshed$|async; else refreshPaymentIdleText">
                  <app-loading-spinner/> Refrescando
                </ng-container>
                <ng-template #refreshPaymentIdleText>
                  <fa-icon [icon]="refreshIcon"/>
                </ng-template>
              </button>
            </div>
          </div>
          <div class="alert alert-info" *ngIf="order.payments.length === 0">No hay pagos aún</div>
          <div class="payment-list">
            <div *ngFor="let payment of order.payments" class="payment-list-item"
              [ngClass]="{
                'shadow': (customerMode && payment.status === 'requires_action' ),
                'highligthed': highlightPaymentId === payment.id,
                'warning': isPaymentPending(payment)
              }">
              <div class="d-flex align-items-center gap-2">
                <div class="flex-grow-1">
                  <app-payment-method-label *ngIf="payment.paymentMethod;else noPaymentMethodLabel"
                    [paymentMethod]="payment.paymentMethod" />
                  <ng-template #noPaymentMethodLabel>
                    {{payment.description}}
                  </ng-template>
                </div>

                <app-payment-vendor-link [payment]="payment" *ngIf="merchantMode && paymentSucceeded(payment)" />
                <div *ngIf="isPaymentProcessing(payment)">
                  <app-loading-spinner/>
                </div>
                <span *ngIf="payment.refunds.length === 0" class="amount">
                  <b>{{ payment.amount }}{{payment.currencySymbol}}</b>
                </span>
                <span *ngIf="payment.refunds.length > 0" class="amount d-flex gap-1">
                  <s><small>{{ payment.amount }}{{order.currencySymbol }}</small></s>
                  <b>{{paymentRefundableAmount(payment)}}{{ payment.currencySymbol}}</b>
                </span>

                <app-fast-pay-button [disabled]="!!retryingPayment" [orderId]="order.id" [paymentId]="payment.id"
                  [paymentMethods]="getPaymentMethodExcept()" *ngIf="customerMode && isPaymentPending(payment)"
                  klass="btn btn-sm btn-primary" (onProcess)="retryingPayment = payment.id"
                  (onError)="retryingPayment = null" (onSuccess)="onPaymentDone($event)">
                  Realizar pago
                </app-fast-pay-button>


              </div>

              <div class="alert alert-danger d-flex flex-column gap-2 mt-2" *ngIf="payment.status === 'failed'">
                <div>El intento de cobro ha fallado. Por favor, intenta de nuevo o añade un método de pago adicional.
                </div>

                <app-fast-pay-button *ngIf="customerMode" [disabled]="!!retryingPayment" [orderId]="order.id"
                  [paymentId]="payment.id" [paymentMethods]="getPaymentMethodExcept()" klass="btn btn-sm btn-primary"
                  (onProcess)="retryingPayment = payment.id" (onError)="retryingPayment = null"
                  (onSuccess)="onPaymentDone($event)">
                  Reintentar
                </app-fast-pay-button>
              </div>


              <div class="mt-3" *ngIf=" customerMode && payment.status === 'requires_action'">
                <app-perform-payment-required-action *ngIf="retryingPayment !== payment.id" [payment]="payment"
                  (onSuccess)="refreshOrder()" (onError)="refreshOrder()" />
                <div class="try-another-method d-flex gap-2 justify-content-between">
                  También puedes elegir otro método de pago.
                  <app-fast-pay-button *ngIf="customerMode" [disabled]="!!retryingPayment" [orderId]="order.id"
                    [paymentId]="payment.id" klass="btn btn-sm btn-outline-primary"
                    [paymentMethods]="getPaymentMethodExcept()" (onProcess)="retryingPayment = payment.id"
                    (onError)="retryingPayment = null" (onSuccess)="onPaymentDone($event)">
                    Reintentar
                  </app-fast-pay-button>
                </div>
              </div>

              <div *ngIf="payment.refunds.length>0" style="margin: 0rem -0.25rem -0.25rem -0.25rem">
                <table class="table table-striped mb-0 table-sm">
                  <thead>
                    <tr>
                      <th>Reembolso</th>
                      <th>Razón</th>
                      <th>Fecha</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let refund of payment.refunds">
                      <td>{{refund.amount}}{{order.currencySymbol}}</td>
                      <td>{{refundReason(refund)}}</td>
                      <td>{{ refund.createdAt | date: 'short'}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>



          </div>

        </div>
      </div>

      <app-customer-order-delivery-info [order]="order" *ngIf="customerMode" (onOrderUpdate)="order = $event" />
      <app-manage-order-delivery [order]="order" *ngIf="merchantMode" (onSuccess)="order = $event" />

    </div>
  </div>
</ng-container>
