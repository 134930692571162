import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { IUserPaymentMethod } from '../../../models/User';
import { BehaviorSubject } from 'rxjs';
import { CustomerService } from '../../../api/services/CustomerService';
import {  OrderPaymentDto } from '../../../models/Order';
import { UiPaymentService } from '../../services/ui-payment.service';

enum State {
  Idle = 'idle',
  Loading = 'loading',
  Success = 'success'
}


type MethodValue = IUserPaymentMethod;

@Component({
  selector: 'app-perform-payment-required-action',
  templateUrl: './perform-payment-required-action.component.html',
  styleUrls: ['./perform-payment-required-action.component.scss'],
})
export class PerformPaymentRequiredActionComponent implements OnInit {
  @ViewChild('paymentForm', { static: true }) paymentFormRef!: ElementRef;
  @Input({ required: true }) payment!: OrderPaymentDto;
  state: State = State.Idle;
  elements: any;
  paymentForm: any;
  methodReady = false;
  paymentMethod$ = new BehaviorSubject<MethodValue | null>(null);
  displayForm = false

  @Output() onError = new EventEmitter<null>();
  @Output() onSuccess = new EventEmitter<null>();


  constructor(
    private uiPaymentSvc: UiPaymentService,
    private customerSvc: CustomerService
  ) { }

  get isIdle() {
    return this.state === State.Idle
  }

  get isLoading() {
    return this.state === State.Loading
  }

  get isSuccess() {
    return this.state === State.Success
  }

  ngOnInit() {

  }

  performAction(){
    this.state = State.Loading
    this.customerSvc.getPaymentRequiredAction(this.payment.id).subscribe((res) => {
      this.loadStripe(res);
    });
  }

  async loadStripe({ clientSecret, stripeAccount }: { clientSecret: string, stripeAccount?:string }) {
    const stripe = await this.uiPaymentSvc.getStripe({ stripeAccount })
    await stripe.handleNextAction({
      clientSecret,
    }).then((res)=>{
      setTimeout(()=>{
        this.state = State.Success
        this.onSuccess.emit(null)
        window.location.reload()
      }, 3000) // FIXME: this is a hack to give time to the payment to be processed
    }).catch(err=>{
      this.state = State.Idle
      this.onError.emit(null)
    })


  }
}
