import { Component, Input } from '@angular/core';
import { faHeart as faHeartEmpty } from '@fortawesome/free-regular-svg-icons';
import { faHeart} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-merchant-follow-button',
  templateUrl: './merchant-follow-button.component.html',
  styleUrls: ['./merchant-follow-button.component.scss']
})
export class MerchantFollowButtonComponent {
  @Input({required: true}) merchant!: string
  following = Math.floor(Math.random() * 10) % 3 === 0;

  get icon(){
    return this.following ? faHeart : faHeartEmpty
  }
  get label(){
    return this.following ? "Dejar de seguir" :  "Seguir"
  }
}
