import { Component, Input } from '@angular/core';
import { CustomerOrderDto } from '../../../models/Order';
import { faTwitch } from '@fortawesome/free-brands-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { prettyId } from '../../../utils/common.utils';
import { CustomerService } from '../../../api/services/CustomerService';
import { UiOrderService } from '../../services/ui-order.service';

@Component({
  selector: 'tr[app-customer-order-list-item]',
  templateUrl: './customer-order-list-item.component.html',
  styleUrls: ['./customer-order-list-item.component.scss']
})
export class CustomerOrderListItemComponent {
  constructor(private uiOrderSvc: UiOrderService, private customerSvc: CustomerService){

  }
  @Input({required: true})order!: CustomerOrderDto
  viewMoreIcon = faPlus

  get prettyOrderId(){
    return prettyId(this.order.id);
  }

  get prettyAmount(){
    return `${this.order.total}${this.order.currencySymbol}`
  }
  get merchantPlatformIcon(){
    return faTwitch
  }

  markAsCompleted(){

    this.customerSvc.markOrderAsDelivered(this.order.id).subscribe(()=>{
      this.review()
    })
  }

  review(){
    this.uiOrderSvc.openReviewOrderModal(this.order)
  }
}
