import { Component, Input } from '@angular/core';
import { IUser, IUserPaymentMethod } from '../../../models/User';
import { CustomerService } from '../../../api/services/CustomerService';
import { faInfoCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { UiService } from '../../services/ui.service';
import { faStar } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-user-payment-method-list',
  templateUrl: './user-payment-method-list.component.html',
  styleUrls: ['./user-payment-method-list.component.scss']
})
export class UserPaymentMethodListComponent {
  @Input() paymentMethods: IUserPaymentMethod[] = []
  @Input() cardEmbedList = false
  deleteMethodIcon = faTrash
  helpIcon = faInfoCircle
  defaultIcon = faStar
  constructor(private customerSvc: CustomerService, private uiSvc: UiService) { }

  get paymentMethodList() {
    return this.paymentMethods
  }

  setDefaultMethod(method: IUserPaymentMethod) {
    this.customerSvc.setDefaultPaymentMethod(method.id).subscribe((user: IUser) => {
      this.paymentMethods = user.paymentMethods
    })
  }

  deleteMethod(method: IUserPaymentMethod) {
    if (!confirm('¿Estás seguro?')) {
      return
    }
    this.customerSvc.deletePaymentMethod(method.id).subscribe({
      next: (user) => {
        this.paymentMethods = user.paymentMethods
      },
      error: (error) => {
        this.uiSvc.showErrorToast(error)
      }
    })
  }
}
