<tr *ngIf="!deleted">
  <td class="align-middle">
    <div class="d-flex align-items-center gap-1">
      <div class="picture">
        <img [src]="item.picture.url" />
      </div>
      <div>
        <div>{{item.name}}</div>
      </div>

    </div>

  </td>

  <td class="align-middle" >{{ item.price }}€</td>
  <td class="align-middle" >{{ item.deliveryPrice }}€</td>
  <td class="align-middle" >
    <ng-container *ngIf="hasStock">
      {{ item.stock.amount }} ud
    </ng-container>
    <span *ngIf="!hasStock" class="badge border border-danger text-danger">Sin stock</span>
  </td>

  <!-- Actions -->
  <td class="align-middle text-end">
    <div class="d-flex gap-1 align-items-center justify-content-end">
      <a [routerLink]="[item.id]"
        class="btn btn-sm btn-outline-secondary">
        <fa-icon [icon]="editIcon"/>
      </a>
      <button
        [disabled]="deletingId$|async"
        (dblclick)="remove()"
        placement="start" ngbTooltip="Pulsa 2 veces"
        triggers="click:blur"
        class="btn btn-sm btn-outline-danger">

        <fa-icon [icon]="deleteIcon" *ngIf="!(isBeingDeleted$|async)"/>
        <app-loading-spinner *ngIf="(isBeingDeleted$|async)"/>
      </button>
    </div>
  </td>
  <!-- End actions -->

</tr>
