import { Component, Input } from '@angular/core';
import { userFeat, userFeatActions } from '../../../store/user.store';
import { map } from 'rxjs';
import { AppStore } from '../../../store';

@Component({
  selector: 'app-update-avatar-form',
  templateUrl: './update-avatar-form.component.html',
  styleUrls: ['./update-avatar-form.component.scss']
})
export class UpdateAvatarFormComponent {
  @Input() previewSize: number = 100
  constructor(
    private store: AppStore
  ) {}
  get previewSizePx(){
    return `${this.previewSize}px`
  }

  get avatarUrl$(){
    return this.store.select(userFeat.selectUser).pipe(map(user=>user?.avatar))
  }

  refreshUser(){
    this.store.dispatch(userFeatActions.fetchMe())
  }
}
