<div *ngIf="isStaging" class="stg-indicator"></div>
<nav class="topbar">
  <div class="container">
    <div class="topbar-content navbar navbar-expand-lg" [ngStyle]="{background}"
      [ngClass]="{expanded: !isMenuCollapsed}">

      <a routerLink="/" class="navbar-brand"><img src="/assets/logo.png" height="40"/></a>
      <button class="navbar-toggler" type="button" (click)="uiSvc.toggleMenu()">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse gap-2" [ngbCollapse]="isMenuCollapsed" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
              <a class="nav-link" routerLink="/" [routerLinkActiveOptions]="{ exact: true }"
                routerLinkActive="active">Inicio</a>
            </li>
            <li class="nav-item">
              <a routerLink="/subastas" class="nav-link" routerLinkActive="active">Subastas</a>
            </li>
        </ul>
        <ng-container *ngIf="!user">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a routerLink="/login" class="nav-link" type="submit" id="loginMenu">Iniciar sesión</a>
            </li>

            <li class="nav-item">
              <a class="nav-link text-primary" routerLink="/crear-cuenta">Crear cuenta</a>
            </li>
          </ul>
        </ng-container>
        <ng-container *ngIf="user">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link" routerLink="/panel">Mi cuenta</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/logout">Logout</a>
            </li>
          </ul>
        </ng-container>


      </div>
    </div>
    <div class="topbar-bg z-99"></div>
  </div>
</nav>
