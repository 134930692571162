import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './auth/AuthInterceptor';
import { LoginComponent } from './ui/components/login/login.component';
import { SignupComponent } from './ui/components/signup/signup.component';
import { AuthService } from './auth/AuthService';
import { ApiService } from './api/services/ApiService';
import { SignupPageComponent } from './pages/auth/signup-page/signup-page.component';
import { FormInputComponent } from './ui/components/form-input/form-input.component';
import { NgbModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TopbarComponent } from './ui/components/topbar/topbar.component';
import { LoadingIndicatorComponent } from './ui/components/loading-indicator/loading-indicator.component';
import { ApiRequestInterceptor } from './api/services/interceptors/ApiRequestInterceptor';
import { ConfirmAccountPageComponent } from './pages/account/confirm-account-page/confirm-account-page.component';
import { LoginPageComponent } from './pages/auth/login-page/login-page.component';
import { AccountPageComponent } from './pages/account/account-page/account-page.component';
import { AccountHeaderComponent } from './ui/components/account-header/account-header.component';
import { TwitchAuthBtnComponent } from './ui/components/twitch-auth-btn/twitch-auth-btn.component';
import { CookieService } from 'ngx-cookie-service';
import { AddressFormComponent } from './ui/components/address-form/address-form.component';
import { OauthCallbackPageComponent } from './pages/auth/oauth-callback-page/oauth-callback-page.component';
import { PaymentMethodLabelComponent } from './ui/components/payment-method-label/payment-method-label.component';
import { FancyIconSteps } from './ui/components/assets/fancy-icon-steps';
import { MerchantOnboardingWizardComponent } from './ui/components/merchant-onboarding-wizard/merchant-onboarding-wizard.component';
import { LogoutPageComponent } from './pages/auth/logout-page/logout-page.component';
import { FancyIconByeBye } from './ui/components/assets/fancy-icon-byebye';
import { FancyIconAuction } from './ui/components/assets/fancy-icon-auction';
import { LoadingSpinnerComponent } from './ui/components/loading-spinner/loading-spinner.component';
import { MerchantAuctionsPageComponent } from './pages/account/merchant/merchant-auctions-page/merchant-auctions-page.component';
import { MerchantAuctionListComponent } from './ui/components/merchant-auction-list/merchant-auction-list.component';
import { FancyIconCreator } from './ui/components/assets/fancy-icon-creator';
import { FancyIconViewer } from './ui/components/assets/fancy-icon-viewer';
import { FileUploaderComponent } from './ui/components/file-uploader/file-uploader.component';
import { FileDropperDirective } from './directives/file-dropper.directive';
import { AuctionFormComponent } from './ui/components/auction-form/auction-form.component';
import { CreateAuctionPageComponent } from './pages/account/merchant/create-auction-page/create-auction-page.component';
import { AccountNavComponent } from './ui/components/account-nav/account-nav.component';
import { EditAuctionPageComponent } from './pages/account/merchant/edit-auction-page/edit-auction-page.component';
import { CustomerOrderListComponent } from './ui/components/customer-order-list/customer-order-list.component';
import { CustomerOrderListItemComponent } from './ui/components/customer-order-list-item/customer-order-list-item.component';
import { CustomerOrderViewPageComponent } from './pages/account/customer/customer-order-view-page/customer-order-view-page.component';
import { AuctionWidgetComponent } from './ui/components/auction-widget/auction-widget.component';
import { ClipboardModule } from 'ngx-clipboard';
import { MerchantOrderListComponent } from './ui/components/merchant-order-list/merchant-order-list.component';
import { MerchantOrderListItemComponent } from './ui/components/merchant-order-list-item/merchant-order-list-item.component';
import { MerchantOrdersPageComponent } from './pages/account/merchant/merchant-orders-page/merchant-orders-page.component';
import { MerchantOrderViewPageComponent } from './pages/account/merchant/merchant-order-view-page/merchant-order-view-page.component';
import { LiveAuctionPageComponent } from './pages/customer/live-auction-page/live-auction-page.component';
import { RefundModalComponent } from './ui/components/refund-modal/refund-modal.component';
import { AddressBlockComponent } from './ui/components/address-block/address-block.component';
import { CustomerAccountPlatformsComponent } from './ui/components/customer-account-platforms/customer-account-platforms.component';
import { PerformPaymentRequiredActionComponent } from './ui/components/perform-payment-required-action/perform-payment-required-action.component';
import { AddPaymentMethodModalComponent } from './ui/components/add-payment-method-modal/add-payment-method-modal.component';
import { UserPaymentMethodListComponent } from './ui/components/user-payment-method-list/user-payment-method-list.component';
import { AccountPaymentMethodsManagerComponent } from './ui/components/account-payment-methods-manager/account-payment-methods-manager.component';
import { BtnAsyncComponent } from './ui/components/btn-async/btn-async.component';
import { UserAddressFormCardComponent } from './ui/components/user-address-form-card/user-address-form-card.component';
import { ToastContainerComponent } from './ui/components/toast-container/toast-container.component';
import { MessageComponent } from './ui/components/message/message.component';
import { OrderViewComponent } from './ui/components/order-view/order-view.component';
import { ManageOrderDeliveryComponent } from './ui/components/manage-order-delivery/manage-order-delivery.component';

import { FancyIconCustomer } from './ui/components/assets/fancy-icon-customer';
import { FancyIconMerchant } from './ui/components/assets/fancy-icon-merchant';
import { FooterComponent } from './ui/components/footer/footer.component';
import { ApiErrorBlockComponent } from './ui/components/api-error-block/api-error-block.component';
import { FancyIconEmptyBox } from './ui/components/assets/fancy-icon-emtpy-box';
import { FancyIconShopClosed } from './ui/components/assets/fancy-icon-shop-closed';
import { CustomerProfilePageComponent } from './pages/account/customer/customer-profile-page/customer-profile-page.component';
import { MerchantOnboardingPageComponent } from './pages/account/merchant/merchant-onboarding-page/merchant-onboarding-page.component';
import { CustomerOrderListPageComponent } from './pages/account/customer/customer-order-list-page/customer-order-list-page.component';
import { StoreModule, provideState, provideStore } from '@ngrx/store';
import { userFeat } from './store/user.store';
import { provideEffects } from '@ngrx/effects';
import { MerchantEffects } from './store/merchant.effects';
import { mAuctionFormFeat, mAuctionListFeat } from './store/merchant.store';
import { AppStore, debuggerMetaReducer } from './store';
import { debugReducer } from './store/helper';
import { SelectPaymentMethodComponent } from './ui/components/select-payment-method/select-payment-method.component';
import { PaymentVendorLinkComponent } from './ui/components/payment-vendor-link/payment-vendor-link.component';
import { IsolatedLayoutComponent } from './ui/layouts/isolated-layout/isolated-layout.component';
import { AuctionWidgetEditorComponent } from './ui/components/auction-widget-editor/auction-widget-editor.component';
import { AuctionWidgetPageComponent } from './pages/public/auction-widget-page/auction-widget-page.component';
import { TwitchEmbedComponent } from './ui/components/streaming/twitch-embed/twitch-embed.component';
import { TimeagoModule } from 'ngx-timeago';
import { BidWithActionComponent } from './ui/components/bid-with-action/bid-with-action.component';
import { BidFormComponent } from './ui/components/bid-form/bid-form.component';
import { RemainingTimeBlocksComponent } from './ui/components/remaining-time-blocks/remaining-time-blocks.component';
import { BidComponent } from './ui/components/bid/bid.component';
import { authFeat } from './store/auth.store';
import { AuthEffects } from './store/auth.effects';
import { UserEffects } from './store/user.effects';
import { uiFeat } from './store/ui.store';
import { CustomerTaskLabelPipe } from './pipes/customer-task-label.pipe';
import { pAuctionFeat } from './store/public/auction.store';
import { PublicEffects } from './store/public/public.effects';
import { AddOrderItemModalComponent } from './ui/components/add-order-item-modal/add-order-item-modal.component';
import { FastPayButtonComponent } from './ui/components/fast-pay-button/fast-pay-button.component';
import { OrderStatusLabelComponent } from './ui/components/order-status-label/order-status-label.component';
import { CustomerOrderDeliveryInfoComponent } from './ui/components/customer-order-delivery-info/customer-order-delivery-info.component';
import { CarrierLabelComponent } from './ui/components/carrier-label/carrier-label.component';
import { CustomerTagComponent } from './ui/components/customer-tag/customer-tag.component';
import { MerchantAddOrderDeliveryModalComponent } from './ui/components/merchant-add-order-delivery-modal/merchant-add-order-delivery-modal.component';
import { mOrderListFeat } from './store/merchant.order-list.store';
import { MerchantOrderListEffects } from './store/merchant.order-list.effects';
import { DeliveryFormComponent } from './ui/components/delivery-form/delivery-form.component';
import { CustomerOrderViewReviewComponent } from './ui/components/customer-order-view-review/customer-order-view-review.component';
import { MerchantAuctionListItemComponent } from './ui/components/merchant-auction-list-item/merchant-auction-list-item.component';
import { MerchantDashboardPageComponent } from './pages/account/merchant/merchant-dashboard-page/merchant-dashboard-page.component';
import { DashboardTopCustomersComponent } from './ui/components/dashboard/dashboard-top-customers/dashboard-top-customers.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { DashboardChartBoxComponent } from './ui/components/dashboard/dashboard-chart-box/dashboard-chart-box.component';
import { DashboardTileComponent } from './ui/components/dashboard/dashboard-tile/dashboard-tile.component';
import { DispatchActionComponent } from './ui/components/dispatch-action/dispatch-action.component';
import { MerchantSettingsPageComponent } from './pages/account/merchant/merchant-settings-page/merchant-settings-page.component';
import { AppLayoutComponent } from './ui/layouts/app-layout/app-layout.component';
import { PageTitleComponent } from './ui/components/section-title/page-title.component';
import { TermsAndConditionsPageComponent } from './pages/public/terms-and-conditions-page/terms-and-conditions-page.component';
import { PrivacyPolicyPageComponent } from './pages/public/privacy-policy-page/privacy-policy-page.component';
import { SmallOrderItemListComponent } from './ui/components/small-order-item-list/small-order-item-list.component';
import { FancyIconDesert } from './ui/components/assets/fancy-icon-desert';
import { AuctionListPageComponent } from './pages/customer/auction-list-page/auction-list-page.component';
import { AuctionWmListItemComponent } from './ui/components/auction-wm-list-item/auction-wm-list-item.component';
import { FastBidBtnComponent } from './ui/components/fast-bid-btn/fast-bid-btn.component';
import { StreamPlatformIconComponent } from './ui/components/stream-platform-icon/stream-platform-icon.component';
import { RegularAuctionPageComponent } from './pages/customer/regular-auction-page/regular-auction-page.component';
import { MerchantLiveAuctionsWidgetPageComponent } from './pages/public/merchant-live-auctions-widget-page/merchant-live-auctions-widget-page.component';
import { RecoverPasswordPageComponent } from './pages/auth/recover-password-page/recover-password-page.component';
import { RequestPasswordResetPageComponent } from './pages/auth/request-password-reset-page/request-password-reset-page.component';
import { FormErrorsComponent } from './ui/components/form-errors/form-errors.component';
import { DatatableComponent } from './ui/components/datatable/datatable.component';
import { MerchantProductsPageComponent } from './pages/account/merchant/merchant-products-page/merchant-products-page.component';
import { MerchantProductEffects } from './store/merchant/product/merchant.product.effects';
import { CreateProductPageComponent } from './pages/account/merchant/create-product-page/create-product-page.component';
import { ProductFormComponent } from './ui/components/product-form/product-form.component';
import { mProductFormStore, mProductListStore, mProductListStore_Backup } from './store/merchant/product/merchant.product.store';
import { FileUploadComponent } from './ui/components/file-upload/file-upload.component';
import { DropzoneDirective } from './directives/dropzone.directive';
import { MerchantProductListItemComponent } from './ui/components/merchant-product-list-item/merchant-product-list-item.component';
import { EditProductPageComponent } from './pages/account/merchant/edit-product-page/edit-product-page.component';
import { MerchantEditProfileComponent } from './pages/account/merchant/merchant-edit-profile/merchant-edit-profile.component';
import { EditSocialPlatformsComponent } from './ui/components/edit-social-platforms/edit-social-platforms.component';
import { UpdateAvatarFormComponent } from './ui/components/update-avatar-form/update-avatar-form.component';
import { UpdateBannerFormComponent } from './ui/components/update-banner-form/update-banner-form.component';
import { MerchantProfileFormComponent } from './ui/components/merchant-profile-form/merchant-profile-form.component';
import { ApiValidationErrorPipe } from './pipes/api-validation-error.pipe';
import { MerchantProfilePageComponent } from './pages/public/merchant-profile/merchant-profile-page/merchant-profile-page.component';
import { pMerchantAuctionList, pMerchantProductList, pMerchantProfile, pMerchantProfileList, pMerchantReviewList } from './store/public/merchant.profile.store';
import { MerchantProfileShopPageComponent } from './pages/public/merchant-profile/merchant-profile-shop-page/merchant-profile-shop-page.component';
import { MerchantProfileReviewsPageComponent } from './pages/public/merchant-profile/merchant-profile-reviews-page/merchant-profile-reviews-page.component';
import { MerchantProfileAuctionsPageComponent } from './pages/public/merchant-profile/merchant-profile-auctions-page/merchant-profile-auctions-page.component';
import { MerchantProfileHeaderComponent } from './pages/public/merchant-profile/merchant-profile-header/merchant-profile-header.component';
import { ShopProductItemComponent } from './ui/components/shop-product-item/shop-product-item.component';
import { BuyConfirmationModalComponent } from './ui/components/buy-confirmation-modal/buy-confirmation-modal.component';
import { cDirectBuyFormStore } from './store/customer/customer.shop.store';
import { CustomerEffects } from './store/customer.effects';
import { FancyIconBuySuccess } from './ui/components/assets/fancy-icon-buy-success';
import { MerchantListPageComponent } from './pages/public/merchant-list-page/merchant-list-page.component';
import { MerchantProfileCardComponent } from './ui/components/merchant-profile-card/merchant-profile-card.component';
import { cOrderReviewForm } from './store/customer/review.store';
import { CustomerReviewComponent } from './ui/components/customer-review/customer-review.component';
import { PaginationComponent } from './pagination/pagination.component';
import { MerchantProductListEffects } from './store/public/merchant.product.list.effects';
import { MerchantAuctionListEffects } from './store/public/merchant.auction.list.effects';
import { MerchantReviewListEffects } from './store/public/merchant.review.list.effects';
import { CustomerAccountPageComponent } from './pages/account/customer/customer-account-page/customer-account-page.component';
import { MerchantFollowButtonComponent } from './ui/components/merchant-follow-button/merchant-follow-button.component';
import { MarketPageComponent } from './pages/public/market-page/market-page.component';
import { pMarketProductList } from './store/public/market.product.list.store';
import { MarketProductListEffects } from './store/public/market.product.list.effects';
import { MerchantPrivateProductListEffects } from './store/merchant/product/merchant.product.list.effects';
import { SlideButtonComponent } from './ui/components/slide-button/slide-button.component';
import {DragDropModule } from '@angular/cdk/drag-drop';
import { PlaygroundComponent } from './pages/playground/playground.component'
import { playgroundStore } from './pages/playground/playground.store';
import { PlaygroundEffects } from './pages/playground/playground.effects';
import { ReviewOrderModalComponent } from './review-order-modal/review-order-modal.component';
import { PricePipe } from './pipes/price.pipe';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    SignupPageComponent,
    FormInputComponent,
    TopbarComponent,
    LoadingIndicatorComponent,
    ConfirmAccountPageComponent,
    LoginPageComponent,
    AccountPageComponent,
    AccountHeaderComponent,
    TwitchAuthBtnComponent,
    AddressFormComponent,
    OauthCallbackPageComponent,
    PaymentMethodLabelComponent,
    FancyIconShopClosed,
    FancyIconBuySuccess,
    FancyIconDesert,
    FancyIconSteps,
    FancyIconByeBye,
    FancyIconAuction,
    FancyIconCreator,
    FancyIconViewer,
    FancyIconCustomer,
    FancyIconMerchant,
    FancyIconEmptyBox,
    MerchantOnboardingWizardComponent,
    LogoutPageComponent,
    PerformPaymentRequiredActionComponent,
    MerchantAddOrderDeliveryModalComponent,
    LoadingSpinnerComponent,
    MerchantAuctionsPageComponent,
    MerchantAuctionListComponent,
    FileUploaderComponent,
    FileDropperDirective,
    AuctionFormComponent,
    CreateAuctionPageComponent,
    AccountNavComponent,
    EditAuctionPageComponent,
    CustomerOrderListComponent,
    CustomerOrderListItemComponent,
    CustomerOrderViewPageComponent,
    AuctionWidgetComponent,
    MerchantOrderListComponent,
    MerchantOrderListItemComponent,
    MerchantOrdersPageComponent,
    MerchantOrderViewPageComponent,
    LiveAuctionPageComponent,
    RefundModalComponent,
    AddOrderItemModalComponent,
    AddressBlockComponent,
    CustomerAccountPlatformsComponent,
    AddPaymentMethodModalComponent,
    UserPaymentMethodListComponent,
    AccountPaymentMethodsManagerComponent,
    BtnAsyncComponent,
    UserAddressFormCardComponent,
    ToastContainerComponent,
    MessageComponent,
    OrderViewComponent,
    ManageOrderDeliveryComponent,
    FooterComponent,
    ApiErrorBlockComponent,
    CustomerProfilePageComponent,
    MerchantOnboardingPageComponent,
    CustomerOrderListPageComponent,
    SelectPaymentMethodComponent,
    PaymentVendorLinkComponent,
    IsolatedLayoutComponent,
    AuctionWidgetEditorComponent,
    AuctionWidgetPageComponent,
    TwitchEmbedComponent,
    BidWithActionComponent,
    BidFormComponent,
    RemainingTimeBlocksComponent,
    BidComponent,
    CustomerTaskLabelPipe,
    AuctionListPageComponent,
    FastPayButtonComponent,
    OrderStatusLabelComponent,
    CustomerOrderDeliveryInfoComponent,
    CarrierLabelComponent,
    CustomerTagComponent,
    DeliveryFormComponent,
    CustomerOrderViewReviewComponent,
    MerchantAuctionListItemComponent,
    MerchantDashboardPageComponent,
    DashboardTopCustomersComponent,
    DashboardChartBoxComponent,
    DashboardTileComponent,
    DispatchActionComponent,
    MerchantSettingsPageComponent,
    AppLayoutComponent,
    PageTitleComponent,
    TermsAndConditionsPageComponent,
    PrivacyPolicyPageComponent,
    SmallOrderItemListComponent,
    AuctionWmListItemComponent,
    FastBidBtnComponent,
    StreamPlatformIconComponent,
    RegularAuctionPageComponent,
    MerchantLiveAuctionsWidgetPageComponent,
    RecoverPasswordPageComponent,
    RequestPasswordResetPageComponent,
    FormErrorsComponent,
    DatatableComponent,
    MerchantProductsPageComponent,
    CreateProductPageComponent,
    ProductFormComponent,
    FileUploadComponent,
    DropzoneDirective,
    MerchantProductListItemComponent,
    EditProductPageComponent,
    MerchantEditProfileComponent,
    EditSocialPlatformsComponent,
    UpdateAvatarFormComponent,
    UpdateBannerFormComponent,
    MerchantProfileFormComponent,
    ApiValidationErrorPipe,
    MerchantProfilePageComponent,
    MerchantProfileShopPageComponent,
    MerchantProfileReviewsPageComponent,
    MerchantProfileAuctionsPageComponent,
    MerchantProfileHeaderComponent,
    ShopProductItemComponent,
    BuyConfirmationModalComponent,
    MerchantListPageComponent,
    MerchantProfileCardComponent,
    CustomerReviewComponent,
    PaginationComponent,
    CustomerAccountPageComponent,
    MerchantFollowButtonComponent,
    MarketPageComponent,
    SlideButtonComponent,
    PlaygroundComponent,
    ReviewOrderModalComponent,
    PricePipe

  ],
  imports: [
    DragDropModule,
    ClipboardModule,
    NgApexchartsModule,
    TimeagoModule.forRoot(),
    FontAwesomeModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    NgbModule,
    NgbTimepickerModule,
    StoreModule.forRoot({}, { metaReducers: [debugReducer] })
  ],
  providers: [
    AuthService,
    ApiService,
    CookieService,
    AppStore,
    provideStore({}, {
      metaReducers: [
        debuggerMetaReducer
      ]
    }),
    provideState(authFeat),
    provideState(userFeat),
    provideState(uiFeat),
    provideState(mAuctionListFeat),
    provideState(mAuctionFormFeat),
    provideState(mProductFormStore.feat),
    provideState(cDirectBuyFormStore.feat),
    provideState(cOrderReviewForm.feat),
    provideState(mProductListStore_Backup.feat),
    provideState(pMerchantProfile.feat),
    provideState(pMerchantProfileList.feat),
    provideState(pMerchantReviewList.feat),
    provideState(pMerchantAuctionList.feat),
    provideState(pMerchantProductList.feat),
    provideState(pMarketProductList.feat),
    provideState(mProductListStore.feat),
    /* Playground */
    provideState(playgroundStore.feat),
    provideEffects(PlaygroundEffects),
    /* / Playground */

    provideState(mOrderListFeat),
    provideState(pAuctionFeat),
    provideEffects(MarketProductListEffects, MerchantPrivateProductListEffects , MerchantEffects, MerchantOrderListEffects, AuthEffects, UserEffects, PublicEffects,
      MerchantProductEffects,
      CustomerEffects,
      MerchantProductListEffects,
      MerchantAuctionListEffects,
      MerchantReviewListEffects
      ),
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiRequestInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
