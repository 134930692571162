import { Component, Input } from '@angular/core';
import { IAuctionExpanded } from '../../../models/Auction';

@Component({
  selector: 'app-bid',
  templateUrl: './bid.component.html',
  styleUrls: ['./bid.component.scss']
})
export class BidComponent {
  @Input() auction!: IAuctionExpanded;
  @Input() template: string|null = null
  @Input() bid!: IAuctionExpanded['bids'][0]
  @Input() highest = false

  get isOneLiner(){
    return this.template !== 'one-liner'
  }

  get createdAt(){
    return typeof this.auction.createdAt === 'string' ? new Date(this.auction.createdAt) : this.auction.createdAt
  }

  get bidCreatedAt(){
    return this.bid.createdAt
  }
}
