import { Component, Input } from '@angular/core';
import { MerchantOrderDto } from '../../../models/Order';
import { UiOrderService } from '../../services/ui-order.service';

@Component({
  selector: 'app-merchant-order-list',
  templateUrl: './merchant-order-list.component.html',
  styleUrls: ['./merchant-order-list.component.scss']
})
export class MerchantOrderListComponent  {
  @Input({ required: true }) orders!: MerchantOrderDto[]

  constructor(private uiOrderSvc: UiOrderService) {}
}
