import { Component, Input } from '@angular/core';
import { IUser } from '../../../../models/User';
import { faHeart, faStar } from '@fortawesome/free-solid-svg-icons';
import { socialPlatforms } from '../../../../utils/social.utils';
import { BehaviorSubject } from 'rxjs';
import { MerchantProfileDto } from '../../../../models/Merchant';

@Component({
  selector: 'app-merchant-profile-header',
  templateUrl: './merchant-profile-header.component.html',
  styleUrls: ['./merchant-profile-header.component.scss']
})
export class MerchantProfileHeaderComponent {
  @Input() reviewStats?: MerchantProfileDto['reviewStats']
  @Input({ required: true}) set user (user: IUser) {
    this._user = user
    this.userSocialNetworks = Object.entries(user.socials).reduce<any>((obj, [network, user])=>{
      if(!user){
        return obj
      }
      const social = socialPlatforms[network as keyof typeof socialPlatforms]
      return [...obj, {...social, user }]
    }, [] as any)
  }
  _user!: IUser
  get user(){
    return this._user
  }
  // images
  loadedImages = 0
  onImgLoad(){
    this.loadedImages += 1
  }


  userSocialNetworks: any = {}

  starIcon = faStar
  followingIcon = faHeart
  get bannerStyle(){
    return {
      'background-image': `url(${this.user.banner})`
    }
  }
}
