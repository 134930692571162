import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './AuthService';
import { Observable, of } from 'rxjs';
import { AccountMode } from '../enums';


@Injectable({
  providedIn: 'root',
})
export class AccountPathGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) { }

  canActivate(): Observable<boolean> {
    if(this.authService.accountMode === AccountMode.Customer){
      this.router.navigate(['/viewer']);
    } else {
      this.router.navigate(['/streamer']);
    }

    return of(true)
  }
}
