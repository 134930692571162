import { Component } from '@angular/core';
import { OrderStatus } from '../../../../models/Order';
import { UiService } from '../../../../ui/services/ui.service';
import { createSubs } from '../../../../utils/rxjs.utils';
import { FormControl, FormGroup } from '@angular/forms';
import { faDownload, faRefresh, faSearch } from '@fortawesome/free-solid-svg-icons';
import { map } from 'rxjs';
import { AppStore } from '../../../../store';
import { mOrderListActions, mOrderListFeat } from '../../../../store/merchant.order-list.store';
import { ActivatedRoute } from '@angular/router';
import { UiOrderService } from '../../../../ui/services/ui-order.service';
import { Actions } from '@ngrx/effects';



const stageToStatuses = {
  'pending': [
    OrderStatus.AwaitingCustomerDeliveryApproval,
    OrderStatus.Created,
    OrderStatus.Paid,
    OrderStatus.PendingPayment
  ],
  'sent': [
    OrderStatus.Sent
  ],
  'pending_delivery':[
    OrderStatus.PendingDelivery
  ],
  'completed': [
    OrderStatus.Completed,
    OrderStatus.Refunded,
    OrderStatus.Canceled
  ],
  'all': null
}


@Component({
  selector: 'app-merchant-orders-page',
  templateUrl: './merchant-orders-page.component.html',
  styleUrls: ['./merchant-orders-page.component.scss']
})
export class MerchantOrdersPageComponent {

  refreshIcon= faRefresh
  downloadIcon = faDownload
  filterForm = new FormGroup({
    stage: new FormControl(''),
    search: new FormControl('')
  })
  searchIcon = faSearch
  loading$ = this.store.select(mOrderListFeat.selectLoading)
  orders$ = this.store.select(mOrderListFeat.selectOrders)
  orderCount$ = this.orders$.pipe(map(orders=>orders ? orders.length : 0))
  loadOrdersAction = mOrderListActions.loadOrders
  loadOrdersLoading = mOrderListFeat.selectLoading
  subs$= createSubs()
  constructor(
    private store: AppStore,
    private uiCommonSvc: UiService,
    private route:ActivatedRoute,
    private uiOrderSvc: UiOrderService,
    private uiSvc: UiService,
    private actions$: Actions
  ) {

  }

  ngOnInit(): void {
    this.uiCommonSvc.setTitle('Pedidos')

    this.subs$.routeParams = this.route.params.subscribe(({stage})=>{
      this.filterForm.patchValue({stage: stage || 'pending'})
      this.filterForm.get('search')!.valueChanges.subscribe(()=>this.loadOrderList())
      this.filterForm.get('stage')!.valueChanges.subscribe(()=> this.loadOrderList())
      this.loadOrderList()
    })
  }



  get filters(){
    let {stage, search} = this.filterForm.getRawValue()
    if(!stage){
      return { filters: { }}
    }

    search = search || ''
    const status = stageToStatuses[stage as keyof typeof stageToStatuses]
    const filters: any = {
      search,
      order: {
        status
      }
    }
    if(!status){
      delete filters.order.status
    }
    return { filters }
  }


  loadOrderList() {
    this.store.dispatch(mOrderListActions.loadOrders(this.filters))
  }

  refresh(){
    this.loadOrderList()
  }


  onOrderTabChange(stage: keyof typeof stageToStatuses) {
    this.filterForm.patchValue({ stage })
  }
  downloadPendingOrdersDeliveryLabel(){
    this.uiOrderSvc.downloadPendingOrdersDeliveryLabel()
  }
  get stage(){
    return this.filterForm.get('stage')?.getRawValue()
  }

}

