import { createAction, createFeature, createReducer, on, props } from '@ngrx/store';

const uiFeatName = 'Ui'

export interface UiState {
  theme: 'dark'|'light'
}
const initialState: UiState = {
  theme: localStorage.getItem('ui.theme') as any || 'light',
};

// Actions
const prefix = `[${uiFeatName}]`
const reset = createAction(`${prefix} Reset`);
const setTheme = createAction(`${prefix} SetTheme`, props<{ theme: UiState['theme'] }>());

// Reducer
const reducer = createReducer<UiState>(
  initialState,
  on(reset, () => initialState),
  on(setTheme, (state, {theme}) => {
    localStorage.setItem('ui.theme', theme)
    return {...state, theme }
  }),
);

// Feature
export const uiFeat = createFeature({
  name: uiFeatName,
  reducer
})

export const uiFeatActions = {
  reset,
  setTheme
}
