import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CustomerOrderDto, OrderStatus } from '../../../models/Order';
import { CustomerService } from '../../../api/services/CustomerService';

@Component({
  selector: 'app-customer-order-delivery-info',
  templateUrl: './customer-order-delivery-info.component.html',
  styleUrls: ['./customer-order-delivery-info.component.scss']
})
export class CustomerOrderDeliveryInfoComponent {
  @Input({ required: true }) order: CustomerOrderDto

  @Output() onOrderUpdate = new EventEmitter<CustomerOrderDto>

  constructor(private customerSvc: CustomerService) {

  }

  get awaitingSendLaterAnswer() {
    return this.order.sendLater && !this.order.sendLater.rejectedByCustomerAt && !this.order.sendLater.approvedByCustomerAt
  }

  get sendLaterApproved() {
    return this.order.sendLater.approvedByCustomerAt
  }
  get sendLaterRejected() {
    return this.order.sendLater.rejectedByCustomerAt
  }
  get isDelivered() {
    return this.order.status === OrderStatus.Completed
  }

  markingAsDelivered = false

  answerSendLater(answer: 'approve' | 'reject') {
    const answerMsg = {
      approve: '¿Estás seguro de que quieres que se envíe tu pedido más adelante?',
      reject: `¿Estás seguro de que quieres que se te envíe el pedido ya?`
    }[answer]

    if (!confirm(answerMsg)) {
      return
    }
    this.customerSvc.answerSendLater(this.order.id, answer).subscribe(order => {
      this.onOrderUpdate.emit(order)
    })
  }

  orderReceived() {
    this.markingAsDelivered = true
    this.customerSvc.markOrderAsDelivered(this.order.id).subscribe({
      next: (order) => {
        this.onOrderUpdate.emit(order)
        this.markingAsDelivered = false
      }, error: (error) => {
        this.markingAsDelivered = false
      }
    })
  }
}
