import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { IUser } from '../../../models/User';
import { AuthService } from '../../../auth/AuthService';
import { AccountMode } from '../../../enums';
import { Router } from '@angular/router';

import { faB, faBox, faCartShopping, faCogs, faDashboard, faEllipsisVertical, faHammer, faLandmark, faListDots, faShop, faSignOut, faUser, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { OnboardingStatusDto, UserService } from '../../../api/services/UserService';
import { userFeat } from '../../../store/user.store';
import { AppStore } from '../../../store';

interface AccountNavItem {
  label: string,
  link: string,
  class?: string,
  icon: IconDefinition,
  disabled?: string
  exact?: boolean
  hideSmall?: boolean,
  pullEnd?: boolean
}

const merchantNav: AccountNavItem[] = [
  { label: 'Dashboard', link: '/streamer', icon: faDashboard, exact: true },
  { label: 'Subastas', link: '/streamer/subastas', icon: faHammer },
  { label: 'Productos', link: '/streamer/productos', icon: faBox },
  { label: 'Pedidos', link: '/streamer/pedidos', icon: faCartShopping },
  { label: 'Ajustes', link: '/streamer/configuracion', icon: faCogs, hideSmall: true },
  { label: 'Cuenta', link: '/streamer/perfil', icon: faUser, exact: true },
  { label: 'Salir', link: '/logout', icon: faSignOut, pullEnd: true, hideSmall: true },
]
const customerNav: AccountNavItem[] = [
  { label: 'Subastas', link: '/viewer/subastas', icon: faHammer },
  { label: 'Mercado', link: '/viewer/mercado', icon: faShop },
  { label: 'Cuenta', link: '/viewer/cuenta', icon: faUser },
  { label: 'Salir', link: '/logout', icon: faSignOut, pullEnd: true, hideSmall: true },
  // { label: 'Suass Street', link: 'c/suass-street', icon: faLandmark },
  //{label: 'Productos', link:'productos'},
]
const nav = {
  [AccountMode.Merchant]: merchantNav,
  [AccountMode.Customer]: customerNav
}
@Component({
  selector: 'div[app-account-nav]',
  templateUrl: './account-nav.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./account-nav.component.scss']
})
export class AccountNavComponent implements OnInit {
  moreIcon = faEllipsisVertical
  showExtraMenu = false
  status: OnboardingStatusDto | null = null
  user: IUser | null = null
  sideBarClasses = {
    hover: false
  }
  logoutIcon = faSignOut
  navItems: Array<AccountNavItem> = []

  constructor(private authSvc: AuthService, private userSvc: UserService, private router: Router, private store: AppStore) { }

  get isStreamerMode() {
    return this.authSvc.accountMode === AccountMode.Merchant
  }

  ngOnInit(): void {
    this.store.select(userFeat.selectUserState).subscribe(({ user, status }) => {
      this.user = user
      this.status = status
      this.setup(this.authSvc.accountMode)
    })
  }

  setup(mode: AccountMode) {
    const merchantOnboardingCompleted = this.status?.merchant.completed

    if (!merchantOnboardingCompleted && mode === AccountMode.Merchant) {
      this.navItems = [
        { label: 'Completar registro', link: '/streamer/onboarding', icon: faDashboard }
      ]
    }
    else {
      this.navItems = [
        ...nav[mode].map(item => ({ ...item }))
      ]
      // if (!merchantOnboardingCompleted) {
      //   this.navItems.push({ label: 'Quiero vender', class: 'wanna-sell-btn', link: '/streamer/onboarding', icon: faShop })
      // }
    }


  }
}
