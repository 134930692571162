<div class="card mb-3"
  [ngClass]="{'border-warning':awaitingSendLaterAnswer, 'border-2':awaitingSendLaterAnswer}">
  <div class="card-body">
    <h5 class="card-title">
      Envío
    </h5>
    <div *ngIf="!order.delivery && !order.sendLater" class="alert alert-info">
      Tu pedido no tiene actualmente ningún envío asociado.
    </div>
    <div *ngIf="order.sendLater">
      <div class="alert alert-info mb-2" *ngIf="awaitingSendLaterAnswer">
        <p>El vendedor ha realizado la solicitud de mandar el producto más adelante.</p>

        <b>¿Qué significa ésto?</b>
        Que tu pedido se quedará a su buen recaudo y más adelante podrás recibir tus compras:
        <ul>
          <li>Pagando el envío junto a otro pedido o por separado</li>
        </ul>

      </div>
      <div class="alert alert-success" *ngIf="sendLaterApproved">
        Has aprobado que se te envíe el pedido más adelante.
        El cliente ha aprobado la solicitud de enviarlo más adelante.
        <div><b>Fecha aprobación:</b> {{ order.sendLater.approvedByCustomerAt | date: 'short' }}</div>
      </div>
      <div class="alert alert-danger" *ngIf="sendLaterRejected">
        Has rechazado que el vendedor te envíe más adelante este pedido.<br />
        El vendedor tendrá que actualizar pronto tu pedido con los datos de envío.
      </div>


    </div>

    <ng-container *ngIf="order.delivery">
      Tu pedido ha sido enviado el día {{ order.delivery.createdAt | date: 'short' }}.

      <div class="border rounded p-2">
        <div class="d-flex gap-2 justify-content-between align-items-center mb-2">
          <app-carrier-label [provider]="order.delivery.provider" />
          <a *ngIf="order.delivery.trackingNumber" class="btn btn-sm btn-outline-primary">Seguimiento</a>
        </div>
        <div *ngIf="order.delivery.trackingNumber"
          class="bg-secondary py-1 px-2 rounded d-flex gap-2 justify-content-between">
          <b>#</b>
          <span>{{order.delivery.trackingNumber}}</span>
        </div>
      </div>
      <div class="mt-2 text-end" *ngIf="!isDelivered">
        <button class="btn btn-primary" [disabled]="markingAsDelivered" (click)="orderReceived()">
          <ng-container *ngIf="markingAsDelivered">
            <app-loading-spinner/> Procesando
          </ng-container>
          <ng-container *ngIf="!markingAsDelivered">
            He recibido el pedido
          </ng-container>
        </button>
      </div>
    </ng-container>

  </div>
  <div class="card-footer" *ngIf="awaitingSendLaterAnswer">
    <div class="d-flex gap-2 align-items-center justify-content-center">
      <button class="btn btn-outline-danger" (click)="answerSendLater('reject')">Rechazar</button>
      <button class="btn btn-outline-primary" (click)="answerSendLater('approve')">Aprobar</button>
    </div>
  </div>
</div>
