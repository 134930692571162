import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faInstagram, faTelegram, faTwitch } from '@fortawesome/free-brands-svg-icons';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { AppStore } from '../../../store';
import { createSubs } from '../../../utils/rxjs.utils';
import { userFeat, userFeatActions } from '../../../store/user.store';
import { IUpdateUserDto } from '../../../api/services/UserService';
import { regexValidator } from '../../../utils/form.utils';
import { socialPlatforms } from '../../../utils/social.utils';


const socialPlatformsList = Object.values(socialPlatforms)

@Component({
  selector: 'app-merchant-profile-form',
  templateUrl: './merchant-profile-form.component.html',
  styleUrls: ['./merchant-profile-form.component.scss']
})
export class MerchantProfileFormComponent implements OnInit {
  constructor(
    private store: AppStore
  ) { }
  subs$ = createSubs()
  socialPlatforms = socialPlatformsList
  form = new FormGroup({
    alias: new FormControl('', [
      Validators.required,
      regexValidator(/^[A-Za-z0-9_]{3,}$/, 'Al menos 3 caracteres. Se aceptan letras, números y barra baja (_)')
    ]),
    title: new FormControl(''),
    bio: new FormControl(''),
    socials: new FormGroup(socialPlatformsList.reduce((controls, platform) => {
      return {
        ...controls,
        [platform.name]: new FormControl()
      }
    }, {}))
  })

  error$ = this.store.select(userFeat.selectUpdatingUserError)
  updating$ = this.store.select(userFeat.selectIsUpdatingUser)

  ngOnInit(): void {


    this.subs$.userState = this.store.select(userFeat.selectUser).subscribe((user) => {
      if (!user) {
        return
      }
      this.form.get('alias')?.setValue(user.alias)
      this.form.patchValue({
        alias: user.alias,
        title: user.title,
        bio: user.bio,
        socials:{
          ...user.socials
        }
      })
    })
  }
  get formDisabled(){
    return !this.form.valid
  }
  saveProfile() {
    if(this.formDisabled){
      return
    }
    this.form.markAsPristine()
    const data: IUpdateUserDto = this.form.value
    this.store.dispatch(userFeatActions.update({ data }))
  }
}
