import { ICreateProductDto, IUpdateProductDto } from "../../../api/services/MerchantService";
import { IProduct } from "../../../models/Product";
import { buildFormStore } from "../../utils/factory.form.store";
import { buildCrudStore } from "../../utils/factory.crud.store";
import { buildListStore } from "../../utils/factory.list.store";

export const mProductListStore_Backup = buildCrudStore<IProduct>('merchantProduct_Backup', {
  pagination: {
    sortBy: {
      createdAt: 'desc'
    }
  }
})


export const mProductListStore = buildListStore<IProduct>('merchantProduct')

export const mProductFormStore = buildFormStore<IProduct, ICreateProductDto, IUpdateProductDto>('merchantProduct')
