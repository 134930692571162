import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, exhaustMap, catchError, delay } from 'rxjs/operators';
import { MerchantService } from '../api/services/MerchantService';
import { mAuctionFormActions, mAuctionListActions } from './merchant.store';



@Injectable()
export class MerchantEffects {

  loadAuctions$ = createEffect(() => this.actions$.pipe(
    ofType(mAuctionListActions.loadAuctions),
    exhaustMap(({filters, page}) => this.merchantSvc.getAuctions(filters, { page })
      .pipe(
        map(auctions => mAuctionListActions.loadAuctionsOK({ auctions })),
        catchError((error) => of(mAuctionListActions.loadAuctionsKO({ error }))))
      )
  ))

  createAuction$ = createEffect(() => this.actions$.pipe(
    ofType(mAuctionFormActions.submitAuctionCreateForm),
    exhaustMap(({auction}) =>this.merchantSvc.createAuction(auction)
      .pipe(
        map(auctions => mAuctionFormActions.auctionFormOk({ auctions })),
        catchError((error) => of(mAuctionFormActions.auctionFormKO({ error }))))
      )
  ))

  updateAuction$ = createEffect(() => this.actions$.pipe(
    ofType(mAuctionFormActions.submitAuctionUpdateForm),
    exhaustMap(({auction}) =>this.merchantSvc.updateAuction(auction.id, auction)
      .pipe(
        map(auction => mAuctionFormActions.auctionFormOk({ auctions: [auction] })),
        catchError((error) => of(mAuctionFormActions.auctionFormKO({ error }))))
      )
  ))

  deleteAuction$ = createEffect(() => this.actions$.pipe(
    ofType(mAuctionListActions.deleteAuction),
    exhaustMap(({auctionId}) => this.merchantSvc.deleteAuction(auctionId)
      .pipe(
        map(() => mAuctionListActions.deleteAuctionOK({deletedAuctionId: auctionId})),
        catchError((error) => of(mAuctionListActions.deleteAuctionKO({ error }) ))
      )
    )
  ))

  duplicateAuction$ = createEffect(() => this.actions$.pipe(
    ofType(mAuctionListActions.duplicateAuction),
    exhaustMap(({duplicateAuctionId}) => this.merchantSvc.duplicateAuction(duplicateAuctionId)
      .pipe(
        map(newAuctions => mAuctionListActions.duplicateAuctionOK({ newAuctions })),
        catchError((error) => of(mAuctionListActions.duplicateAuctionKO({ error }) ))
      )
    )
  ))

  startAuction$ = createEffect(() => this.actions$.pipe(
    ofType(mAuctionListActions.startAuction),
    exhaustMap(({startAuctionId}) => this.merchantSvc.startAuction(startAuctionId)
      .pipe(
        map(startedAuction => mAuctionListActions.startAuctionOK({ startedAuction })),
        catchError((error) => of(mAuctionListActions.startAuctionKO({ error }) ))
      )
    )
  ))



  constructor(
    private actions$: Actions,
    private merchantSvc: MerchantService
  ) { }
}
