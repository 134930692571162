import { Component } from '@angular/core';
import { IApiErrorResponse } from '../../../api/services/ApiService';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { faCheck, faLock, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../../auth/AuthService';
import { ActivatedRoute, Router } from '@angular/router';
import { emailToMailboxUrl } from '../../../utils/mail';
import { HttpErrorResponse } from '@angular/common/http';
import { AppStore } from '../../../store';
import { authActions } from '../../../store/auth.store';

enum FormState {
  Loading = 'loading',
  Idle = 'idle',
  Success = 'success',
}

@Component({
  selector: 'app-request-password-reset-page',
  templateUrl: './request-password-reset-page.component.html',
  styleUrls: ['./request-password-reset-page.component.scss']
})
export class RequestPasswordResetPageComponent {
  apiError: IApiErrorResponse | null = null;

  state = FormState.Idle;
  form = new FormGroup({
    email: new FormControl('', {
      validators: [Validators.email, Validators.required],
      nonNullable: true,
    }),
  })

  btnSuccessIcon = faCheck
  btnLoadingIcon = faSpinner
  passIcon = faLock

  constructor(
    private store: AppStore,
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute) {

  }

  get loginLink() {
    return `/login`
  }

  ngOnInit() {

  }

  get isIdle() {
    return this.state === FormState.Idle;
  }
  get isLoading() {
    return this.state === FormState.Loading;
  }

  get formHasErrors() {
    return this.form.invalid && this.form.touched;
  }

  get success() {
    return this.state === FormState.Success;
  }

  get isCtaDisabled() {
    return this.state !== FormState.Idle || this.form.invalid
  }

  onSubmit() {
    if (!this.form.valid) {
      return;
    }

    this.state = FormState.Loading;
    const { email } = this.form.getRawValue()
    this.authService.requestPasswordReset({ email }).subscribe({
      next: this.onSuccess.bind(this),
      error: this.onError.bind(this),
    })
  }

  protected onSuccess() {
    this.apiError = null
    this.state = FormState.Success
  }

  protected onError(res: HttpErrorResponse) {
    this.apiError = res.error
    this.state = FormState.Idle
  }

  get suggestedMailboxUrl(): string | null {
    const email = this.form.get('email')?.value;
    if (!email) {
      return null;
    }
    return emailToMailboxUrl(email);
  }
}
