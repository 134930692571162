import { Injectable } from '@angular/core';
import { ApiService, IApiPaginatedResponse, IApiRes } from './ApiService';
import { Observable, map} from 'rxjs';
import { IProduct } from '../../models/Product';
import { IAuction, IAuctionExpanded, TimeUnit } from '../../models/Auction';
import { MerchantOrderDto, MerchantSettingsDto, RefundReason } from '../../models/Order';
import { objectToQueryParam } from '../../utils/common.utils';
import { TopCustomerDto } from '../../models/Dashboard';
import { isString } from 'lodash';

export interface IPaginationParams {
  pageSize?:number
  page?:number
  sortBy?: Record<string, 'asc'|'desc'>
}
export interface ICreateProductDto {
  name: string
  description: string
  netPrice: string
  pictureData: {
    id: string
    url: string
    path: string
  }
  stock: {
    amount: number
  }
}

export interface IUpdateProductDto extends ICreateProductDto {}


export interface ICreateAuctionDto {
  title: string
  description: string
  minBidIncrement: string;
  startingPrice: string;
  deliveryPrice: string;
  pictureData: string; // required if no productId
  startsAt: number,
  // duration
  durationUnit: TimeUnit
  duration: number
  extPeriodUnit: TimeUnit
  extPeriod: number
}

export type IUpdateAuctionDto = Omit<ICreateAuctionDto, 'pictureData'> & { id: string; pictureData?: string }

@Injectable({
  providedIn: 'root'
})
export class MerchantService extends ApiService {

  getMerchantOnboardingUrl() {
    return this.post<IApiRes<{ url: string, completed: boolean }>>('merchant/onboarding/create', {}).pipe(map(res => res.data))
  }

  getChannelAuthorizationUrl(platform: string) {
    return this.post<IApiRes<{ url: string }>>(`merchant/onboarding/authorize-channel/${platform}`, {}).pipe(map(res => res.data))
  }

  getAuctions(filters = null, {page}: IPaginationParams): Observable<IAuctionExpanded[]> {
    let queryParams = ''
    if(filters){
      queryParams = `?page=${page||1}&${objectToQueryParam({ q: filters})}`
    }
    return this.get<IApiRes<IAuctionExpanded[]>>('merchant/auctions'+queryParams).pipe(map(res => res.data))
  }

  getAuction(id: string): Observable<IAuction> {
    return this.get<IApiRes<IAuction>>(`merchant/auctions/${id}`).pipe(map(res => res.data))
  }

  createAuction(payload: ICreateAuctionDto): Observable<IAuction[]> {
    return this.post<IApiRes<IAuction[]>>('merchant/auctions', payload).pipe(map(res => res.data))
  }

  updateAuction(id: string, payload: IUpdateAuctionDto): Observable<IAuction> {
    return this.put<IApiRes<IAuction>>(`merchant/auctions/${id}`, payload).pipe(map(res => res.data))
  }

  deleteAuction(id: string): Observable<boolean> {
    return this.delete<IApiRes<boolean>>(`merchant/auctions/${id}`).pipe(map(()=>true))
  }

  duplicateAuction(id: string): Observable<IAuctionExpanded[]> {
    return this.post<IApiRes<IAuctionExpanded[]>>(`merchant/auctions/${id}/duplicate`, {}).pipe(map((res)=>res.data))
  }

  startAuction(id: string): Observable<IAuctionExpanded> {
    return this.post<IApiRes<IAuctionExpanded>>(`merchant/auctions/${id}/start`, {}).pipe(map((res)=>res.data))
  }
  /**
   * Products
   */
  getProducts(filters = null, {page,pageSize, sortBy}: IPaginationParams): Observable<IApiPaginatedResponse<IProduct>> {
    const queryParams = objectToQueryParam({
      q: filters,
      pageSize,
      page,
      sortBy
    })

    return this.get<IApiPaginatedResponse<IProduct>>(`merchant/products?${queryParams}`)
  }

  getProduct(id: string): Observable<IApiRes<IProduct>> {
    return this.get<IApiRes<IProduct>>(`merchant/products/${id}`)
  }

  createProduct(payload: ICreateProductDto): Observable<IProduct> {
    return this.post<IApiRes<IProduct>>('merchant/products', payload).pipe(map(res => res.data))
  }

  updateProduct(id: string, payload: IUpdateProductDto): Observable<IProduct> {
    return this.put<IApiRes<IProduct>>(`merchant/products/${id}`, payload).pipe(map(res => res.data))
  }

  deleteProduct(id: string): Observable<boolean> {
    return this.delete<IApiRes<boolean>>(`merchant/products/${id}`).pipe(map(() => true))
  }

  // Orders
  getOrders(filters: any = null): Observable<MerchantOrderDto[]> {
    let queryParams = ''
    if(filters){
      queryParams = `?${objectToQueryParam({ q: filters})}`
    }
    return this.get<IApiRes<MerchantOrderDto[]>>('merchant/orders'+queryParams).pipe(
      map((res) => res.data)
    );
  }
  getOrder(id: string): Observable<MerchantOrderDto> {
    return this.get<IApiRes<MerchantOrderDto>>(`merchant/orders/${id}`).pipe(
      map((res) => res.data)
    );
  }
  updateOrder(orderId: string, payload: any): Observable<MerchantOrderDto>{
    return this.patch<IApiRes<MerchantOrderDto>>(`merchant/orders/${orderId}`,payload).pipe(
      map((res) => res.data)
    );
  }

  refreshOrderPayments(orderId: string){
   return this.post<IApiRes<MerchantOrderDto>>(`merchant/orders/${orderId}/refresh-payments`, {}).pipe(map((res) => res.data));
  }

  cancelOrder(orderId: string){
    return this.post<IApiRes<MerchantOrderDto>>(`merchant/orders/${orderId}/cancel`, {}).pipe(map((res) => res.data));
  }

  updateOrderDelivery(orderId: string, payload: any): Observable<MerchantOrderDto>{
    return this.patch<IApiRes<MerchantOrderDto>>(`merchant/orders/${orderId}/delivery`,payload).pipe(
      map((res) => res.data)
    );
  }

  setOrderSendLater(orderId: string): Observable<MerchantOrderDto>{
    return this.patch<IApiRes<MerchantOrderDto>>(`merchant/orders/${orderId}/send-later`,{}).pipe(
      map((res) => res.data)
    );
  }

  refundOrder(orderId: string, payload: {amount: string, reason: RefundReason}): Observable<MerchantOrderDto>{
    return this.post<IApiRes<MerchantOrderDto>>(`merchant/orders/${orderId}/refund`,payload).pipe(
      map((res) => res.data)
    );
  }

  addOrderItem(orderId: string, payload: {netAmount: string, description: string, tags:string[]}): Observable<MerchantOrderDto>{
    return this.post<IApiRes<MerchantOrderDto>>(`merchant/orders/${orderId}/item`, payload).pipe(
      map((res) => res.data)
    );
  }

  refundOrderItem(orderId: string, orderItemId: string, amount: string): Observable<MerchantOrderDto>{
    return this.post<IApiRes<MerchantOrderDto>>(`merchant/orders/${orderId}/items/${orderItemId}/refund`, { amount }).pipe(
      map((res) => res.data)
    );
  }


  // Dashboard
  getTopCustomers(): Observable<TopCustomerDto[]>{
    return this.get<IApiRes<TopCustomerDto[]>>(`merchant/dashboard/top-customers`).pipe(
      map((res) => res.data)
    );
  }

  getOrderStats(): Observable<any>{
    return this.get<IApiRes<any>>(`merchant/dashboard/order-stats`).pipe(
      map((res) => res.data)
    );
  }

  getRevenue(): Observable<any>{
    return this.get<IApiRes<any>>(`merchant/dashboard/revenue`).pipe(
      map((res) => res.data)
    );
  }

  // Settings
  saveSettings(settings: any): Observable<MerchantSettingsDto>{
    return this.put<IApiRes<MerchantSettingsDto>>(`merchant/settings`, settings).pipe(
      map((res) => res.data)
    );
  }

  getSettings(): Observable<MerchantSettingsDto>{
    return this.get<IApiRes<MerchantSettingsDto>>(`merchant/settings`).pipe(
      map((res) => res.data)
    );
  }
}
