import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

export interface IApiRes<T>{
  data: T
}

export interface IApiErrorResponse {
  error:{
    code: string
    message: string
  }
}

export interface IApiPaginationData {
  total: number;
  pageSize: number;
  page: number;
  totalPages: number;
  nextPage: number | null;
  prevPage: number | null;
  sortBy: Record<string, 'desc'|'asc'>
}

export interface IApiPaginatedResponse<Payload> extends IApiRes<Payload[]> {
  pagination: IApiPaginationData
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  protected readonly baseUrl: string;

  constructor(protected http: HttpClient) {
    this.baseUrl = environment.apiBaseUrl;
  }

  protected get<T>(url: string): Observable<T> {
    return this.http.get<T>(`${this.baseUrl}/${url}`);
  }

  protected post<T>(url: string, data: any): Observable<T> {
    return this.http.post<T>(`${this.baseUrl}/${url}`, data);
  }

  protected patch<T>(url: string, data: any): Observable<T> {
    return this.http.patch<T>(`${this.baseUrl}/${url}`, data);
  }

  protected put<T>(url: string, data: any): Observable<T> {
    return this.http.put<T>(`${this.baseUrl}/${url}`, data);
  }

  protected delete<T>(url: string): Observable<T> {
    return this.http.delete<T>(`${this.baseUrl}/${url}`);
  }

}
