<nav class="d-flex gap-3 align-items-center" *ngIf="!shouldHide">
  <ul class="pagination mb-0">
    <li class="page-item">
      <button [disabled]="isFirstPage" (click)="goPage(pagination?.prevPage)" class="page-link">
        <fa-icon [icon]="prevIcon" />
      </button>
    </li>
    <li *ngFor="let pageNum of paginationList" class="page-item">
      <button [ngClass]="{active: pageNum === pagination?.page }" (click)="goPage(pageNum)"
        [disabled]="pageNum === pagination?.page" class="page-link">{{pageNum}}</button>
    </li>
    <li class="page-item">
      <button [disabled]="isLastPage" class="page-link" (click)="goPage(pagination?.nextPage)">
        <fa-icon [icon]="nextIcon" />
      </button>
    </li>
  </ul>
  <form [formGroup]="paginationForm" class="d-flex gap-1 align-items-center">
    Mostrar
    <select class="form-select" [formControl]="pageSizeControl">
      <option value="10">10</option>
      <option value="20">20</option>
      <option value="50">50</option>
    </select>
  </form>
</nav>
