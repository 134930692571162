import { CustomerOrderDto } from '../../../models/Order';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { faStar as faEmptyStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as faFullStar } from '@fortawesome/free-solid-svg-icons';
import { AppStore } from '../../../store';
import { cOrderReviewForm } from '../../../store/customer/review.store';
import { CreateOrderReviewDto, OrderReviewDto } from '../../../api/services/CustomerService';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-customer-order-view-review',
  templateUrl: './customer-order-view-review.component.html',
  styleUrls: ['./customer-order-view-review.component.scss']
})
export class CustomerOrderViewReviewComponent implements OnInit {
  @Input({required: true}) order!: CustomerOrderDto
  @Input() activeModal?: NgbActiveModal

  ratings = [1,2,3,4,5]
  tmpRating = 5
  reviewForm = new FormGroup({
    orderId: new FormControl('', [Validators.required]),
    rating: new FormControl(5, [Validators.required]),
    message: new FormControl(null, [Validators.minLength(5)])
  })
  loading$ = this.store.select(cOrderReviewForm.feat.selectLoading)
  review$ = this.store.select(cOrderReviewForm.feat.selectData)
  error$ = this.store.select(cOrderReviewForm.feat.selectError)

  review: OrderReviewDto|null = null
  constructor(
    private store: AppStore
  ) {}

  ngOnInit(): void {
    this.reviewForm.get('orderId')?.patchValue(this.order.id)
    this.store.dispatch(cOrderReviewForm.actions.load({ id: this.order.id}))
    this.store.select(cOrderReviewForm.feat.selectData).subscribe((data)=>{
      if(!data){
        return
      }
      this.reviewForm.patchValue(data)
      this.review = data
      this.reviewForm.disable()
    })
  }

  setTmpRating(rating: number){
    this.tmpRating =rating
  }
  setRating(rating: number){
    this.reviewForm.get('rating')?.patchValue(rating)
    this.tmpRating = rating
  }
  getRateLabel(rating:number){
    return [
      'Mala', 'Así así...', 'Ok', 'Buena', 'Estupenda!'
    ][rating-1]
  }
  get selectedRating(): number{
    return this.reviewForm.get('rating')?.getRawValue()!
  }

  emptyStarIcon = faEmptyStar
  fullStarIcon = faFullStar


  get isSubmitDisabled(){
    return !this.reviewForm.valid
  }

  sendReview(){
    if(this.isSubmitDisabled){
      return
    }
    const payload: CreateOrderReviewDto = this.reviewForm.value as any

    return this.store.dispatch(cOrderReviewForm.actions.create({ payload }))
  }
}
