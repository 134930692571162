import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IUserPaymentMethod } from '../../../models/User';
import { UiPaymentService } from '../../services/ui-payment.service';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-select-payment-method',
  templateUrl: './select-payment-method.component.html',
  styleUrls: ['./select-payment-method.component.scss']
})
export class SelectPaymentMethodComponent implements OnInit {
  @Input() paymentMethods: IUserPaymentMethod[] = []
  @Input() autoSelectDefault = false
  @Input() addEnabled = false
  @Input() lock = false
  @Input({ required: true}) control!: FormControl

  constructor(private uiPaymentSvc: UiPaymentService) { }

  selectedPaymentMethod?: IUserPaymentMethod

  ngOnInit(): void {
    if(this.autoSelectDefault){
      this.select(this.paymentMethods.find(pm=>pm.default) || this.paymentMethods[0])
    }
  }
  get options (){
    return this.paymentMethods.filter(pm=>pm.id !== this.selectedPaymentMethod?.id)
  }
  select(method: IUserPaymentMethod) {
    this.control.setValue(method.id)
    this.selectedPaymentMethod = method
  }

  openAddMethodModal(){
    this.uiPaymentSvc.openAddPaymentMethodModal().subscribe((paymentMethod)=>{
      this.select(paymentMethod)
    })
  }
}
