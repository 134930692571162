import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TwitchOauthCb } from '../../../auth/auth.types';
import { UiService } from '../../../ui/services/ui.service';

@Component({
  selector: 'app-signup-page',
  templateUrl: './signup-page.component.html',
  styleUrls: ['./signup-page.component.scss']
})
export class SignupPageComponent implements OnInit {
  twitchCb: TwitchOauthCb | undefined
  public constructor(private route: ActivatedRoute, private uiCommonSvc: UiService, private router: Router) {

    const { twitch_callback, code, state } = this.route.snapshot.queryParams
    if (twitch_callback === '1') {
      this.twitchCb = { code, state }
    }
  }

  ngOnInit(): void {
    this.uiCommonSvc.setTitle('Crear cuenta')
    this.route.data.subscribe(data => {
      if (data.userResolver) {
        this.router.navigate(['/viewer'])
      }
    })
  }
}
