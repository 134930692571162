import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import { MerchantService } from '../api/services/MerchantService';
import { mOrderListActions } from './merchant.order-list.store';



@Injectable()
export class MerchantOrderListEffects {

  loadOrders$ = createEffect(() => this.actions$.pipe(
    ofType(mOrderListActions.loadOrders),
    exhaustMap(({filters}) => this.merchantSvc.getOrders(filters)
      .pipe(
        map(orders => mOrderListActions.loadOrdersOK({ orders })),
        catchError((error) => of(mOrderListActions.loadOrdersKO({ error }))))
      )
  ))

  cancelOrder$ = createEffect(() => this.actions$.pipe(
    ofType(mOrderListActions.cancelOrder),
    exhaustMap(({orderId}) => this.merchantSvc.cancelOrder(orderId)
      .pipe(
        map((order) => mOrderListActions.cancelOrderOK({ order })),
        catchError((error) => of(mOrderListActions.cancelOrderKO({ error }) ))
      )
    )
  ))


  refreshOrderPayments$ = createEffect(() => this.actions$.pipe(
    ofType(mOrderListActions.refreshOrderPayments),
    exhaustMap(({orderId}) => this.merchantSvc.refreshOrderPayments(orderId)
      .pipe(
        map((order) => mOrderListActions.refreshOrderPaymentsOK({ order })),
        catchError((error) => of(mOrderListActions.refreshOrderPaymentsKO({ error }) ))
      )
    )
  ))

  refundOrderItem$ = createEffect(() => this.actions$.pipe(
    ofType(mOrderListActions.refundOrderItem),
    exhaustMap(({orderId, orderItemId, amount}) => this.merchantSvc.refundOrderItem(orderId, orderItemId, amount)
      .pipe(
        map((order) => mOrderListActions.refundOrderItemOK({ order })),
        catchError((error) => of(mOrderListActions.refundOrderItemKO({ error }) ))
      )
    )
  ))

  constructor(
    private actions$: Actions,
    private merchantSvc: MerchantService
  ) { }
}
