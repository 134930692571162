<ng-template #theContent>
  <ng-content></ng-content>
</ng-template>

<ng-template #errorTemplate>
  <div *ngIf="shouldDisplayError" class="invalid-feedback">
    <div *ngIf="hasApiError">{{controlApiErrors | apiValidationError}}</div>
    <div *ngIf="errors?.['required']">Campo requerido</div>
    <div *ngIf="errors?.['passwordMatch']">Las contraseñas no coinciden</div>
    <div *ngIf="errors?.['minlength']"> Longitud mínima de {{errors['minlength'].requiredLength }}</div>
    <div *ngIf="errors?.['regexInvalid']"> {{ errors['regexInvalid'].message }}</div>
  </div>
</ng-template>

<ng-template #inputText>
  <input [id]="controlName" class="form-control" [ngClass]="{
      'is-invalid': (control.touched || this.hasApiError) && isInvalid,
      'is-valid': shouldApplyIsValidClass
  }"
    lang="en"
    [autocomplete]="type === 'password'"
    [placeholder]="placeholder"
    [formControl]="control"
    [type]="type" />

</ng-template>

<ng-template #inputSelect>
  <select class="form-select" [id]="controlName" [formControl]="control">
    <option *ngIf="placeholder" [value]="null">{{placeholder}}</option>
    <option *ngFor="let option of options" [value]="option.value" [disabled]="option.disabled">
      {{option.label}}
    </option>
  </select>
</ng-template>

<ng-template #dropdownAddon>
  <select [style]="{'max-width': dropdownMaxWidth}" *ngIf="dropdownControl && dropdownOptions" class="form-select"
    [id]="controlName" [formControl]="dropdownControl">
    <option *ngFor="let option of dropdownOptions" [value]="option.value" [disabled]="option.disabled">
      {{option.label}}
    </option>
  </select>
</ng-template>


<div [ngClass]="wrapperClasses" >

  <ng-container *ngIf="isBasicInput;">
    <label *ngIf="label" [for]="controlName" class="form-label mb-1 d-flex gap-3">
      {{label}}
      <ng-container [ngTemplateOutlet]="theContent" />
    </label>
    <ng-container *ngIf="hasPrefix || dropdownControlName; else noInputGroup">
      <div class="input-group" [ngClass]="{'has-validation': shouldDisplayError}">
        <div *ngIf="prefix || iconPrefix" class="input-group-text">
          <ng-container *ngIf="iconPrefix"><fa-icon [icon]="iconPrefix" /></ng-container>
          <ng-container *ngIf="prefix">{{prefix}}</ng-container>
        </div>
        <ng-container *ngIf="isSelect; else inputText" [ngTemplateOutlet]="inputSelect" />
        <ng-container *ngIf="dropdownControl && dropdownOptions" [ngTemplateOutlet]="dropdownAddon" />

        <div *ngIf="sufix" class="input-group-text">
          <ng-container *ngIf="sufix">{{sufix}}</ng-container>
        </div>
        <ng-template [ngTemplateOutlet]="errorTemplate" />
      </div>
    </ng-container>
    <ng-template #noInputGroup>
      <ng-container *ngIf="isSelect; else inputText" [ngTemplateOutlet]="inputSelect" />
      <ng-template [ngTemplateOutlet]="errorTemplate" />
    </ng-template>
    <p class="form-text" *ngIf="formText">{{formText}}</p>
  </ng-container>

  <ng-container *ngIf="isTextarea">
    <label *ngIf="label" [for]="controlName" class="form-label mb-1">
      {{label}}
      <ng-container [ngTemplateOutlet]="theContent" />
    </label>
    <textarea [id]="controlName" class="form-control" [ngClass]="{
        'is-invalid': control.touched && isInvalid,
        'is-valid': control.valid
    }" [placeholder]="placeholder" [formControl]="control" [ngStyle]="{'min-height': minHeight+'px'}"></textarea>
    <ng-template [ngTemplateOutlet]="errorTemplate" />
  </ng-container>

  <ng-container *ngIf="isToggler">
    <label *ngIf="label" [for]="controlName" class="form-label mb-1">
      {{label}}
      <ng-container [ngTemplateOutlet]="theContent" />
    </label>
    <div class="btn-group toggler">
      <ng-container *ngFor="let option of options">
        <button [id]="controlName" type="button" class="btn btn-outline-primary" [disabled]="option.disabled"
          [ngClass]="{'active': control.value === option.value}" (click)="control.setValue(option.value)">
          <span class="check-icon"><fa-icon [icon]="checkIcon" /></span>
          {{option.label}}
        </button>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="isCheck">
    <div class="form-check pointer">
      <input class="form-check-input" type="checkbox" value="" [id]="controlName" [formControl]="control">
      <label class="form-check-label" [for]="controlName">
        <ng-container [ngTemplateOutlet]="theContent" />
      </label>
    </div>
  </ng-container>
</div>
