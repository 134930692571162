<app-page-title title="Subastas">

  <div class="d-flex gap-2">
    <button dispatch-action class="btn btn-sm btn-secondary" [dispatcherBtn]="loadAuctionsAction"
      [actionParams]="filters" [loadingSelector]="loadAuctionsLoading" [icon]="refreshIcon"
      loadingText="Cargando">
    </button>
    <form [formGroup]="filterForm" style="width: 100px">
      <app-form-input placeholder="Buscar..." controlName="search" [form]="filterForm" />
    </form>
  </div>
  <div class="d-flex gap-2">
    <button (click)="displayLiveWidgetCopiedNotification()"
      ngbTooltip="Éste widget se actualiza cada vez que se crea y empieza una puja automáticamente"
      class="btn btn-outline-secondary" ngxClipboard [cbContent]="liveWidgetUrl || ''" [disabled]="!liveWidgetUrl">
      <fa-icon [icon]="copyIcon" />
      <span class="d-none d-sm-inline"> Live Widget</span>
      <span class="d-inline d-sm-none"></span>
    </button>
    <a class="btn btn-primary" routerLink="/streamer/subastas/crear">
      <span class="d-none d-sm-inline">Crear subasta</span>
      <span class="d-inline d-sm-none">Crear</span>
    </a>
  </div>
</app-page-title>


<ul ngbNav #nav="ngbNav" [activeId]="status" class="nav-tabs" (activeIdChange)="onOrderTabChange($event)">
  <li ngbNavItem="live"><button ngbNavLink>Directos</button></li>
  <li ngbNavItem="started"><button ngbNavLink>En curso</button></li>
  <li ngbNavItem="scheduled"><button ngbNavLink>Programadas</button></li>
  <li ngbNavItem="closed"><button ngbNavLink>Terminadas</button></li>
  <li ngbNavItem="all"><button ngbNavLink>Todos</button></li>
</ul>

<div class="p-2" *ngIf="loading$|async;else auctionsTable">
  <app-loading-indicator />
</div>
<ng-template #auctionsTable>
  <ng-container *ngIf="(count$|async) === 0">
    <div class="row align-items-center justify-content-center">
      <div class="col-4 col-lg-5 col-xl-3">
        <app-fancy-icon-empty-box />
      </div>
      <div class="col-8 col-lg-7 col-xl-4">
        <ng-container *ngIf="isRunningStatusTab;else notFoundMsg">
          <h5>No tienes ninguna subasta aún</h5>
          <p>Pero si estás aquí es por algo... así que vamos a ello!</p>
          <a routerLink="/streamer/subastas/crear" class="btn btn-primary">Crear subasta</a>
        </ng-container>
        <ng-template #notFoundMsg>
          <h5>No encontramos nada 😔</h5>
          <p>Prueba con otros términos de búsqueda!</p>
        </ng-template>
      </div>

    </div>
  </ng-container>

  <app-merchant-auction-list *ngIf="count$|async" [auctions]="(items$|async)!" />
</ng-template>
