<div *ngIf="onlyButton;else uploader" class="uploader" appDropZone (fileDropped)="onFileDropped($event)">
  <div class="text-center d-flex flex-column gap-2 align-items-center">
    <div>
      <label class="btn btn-sm btn-outline-secondary btn-file" [ngClass]="{'btn-disabled': isUploading}">
        <ng-container *ngIf="isUploading; else uploadText">
          <app-loading-spinner/> {{ progress }}%
        </ng-container>
        <ng-template #uploadText>
          <fa-icon [icon]="fileIcon" /> {{ chooseButtonText }}
          <input type="file" accept="image/*" [disabled]="isUploading" (change)="selectFile($event)">
        </ng-template>
      </label>
    </div>
    <div class="text">
      Permitido {{extensionText}}.
      <br /> Tamaño máximo {{fileSize}}. <ng-container *ngIf="imgDimension"><br/>Dimensión {{imgDimension}}</ng-container>
    </div>
  </div>
</div>
<ng-template #uploader>


  <div class="card p-2 uploader" appDropZone (fileDropped)="onFileDropped($event)">
    <div class="text-center d-flex flex-column gap-2 align-items-center">
      <div>
        <label class="btn btn-sm btn-outline-secondary btn-file">
          <fa-icon [icon]="fileIcon" />
          <input type="file" accept="image/*" (change)="selectFile($event)">
        </label>
        Arrastra el fichero aquí.
      </div>
      <div class="text">Soporta JPG, PNG, WEBP.<br /> Tamaño máximo 10MB.</div>

    </div>
    <div *ngIf="fileData">

      <div class="d-flex gap-1 align-items-center">
        <div class="preview">
          <div class="image" [ngStyle]="{'background-image': 'url(' + fileData + ')'}">
          </div>
        </div>
        <div class="name flex-grow-1">
          {{fileName}}
        </div>
        <div>
          <button type="button" (click)="upload()" [disabled]="progress > 0" class="btn btn-sm"
            [ngClass]="{'btn-outline-primary': progress !== 100, 'btn-outline-success': progress === 100}">
            <ng-container *ngIf="progress===100; else uploadText">
              <fa-icon [icon]="successIcon" /> Completado
            </ng-container>
            <ng-template #uploadText>
              <fa-icon [icon]="uploadIcon" /> Subir
            </ng-template>
          </button>
        </div>
      </div>
      <app-loading-indicator [progress]="progress" *ngIf="isUploading" />
    </div>

    <div *ngIf="message" class="alert alert-secondary" role="alert">{{ message }}</div>
    <ng-content />
  </div>
</ng-template>
