<!-- https://themesbrand.com/velzon/html/corporate/apps-nft-creators.html -->
<app-loading-indicator *ngIf="loading$|async" />
<ng-container *ngIf="profile">
  <div class="profile-page">
    <app-merchant-profile-header [user]="profile.user" [reviewStats]="profile.reviewStats" />
    <ul ngbNav #nav="ngbNav" [activeId]="tab" class="nav-tabs mb-3 justify-content-center" (activeIdChange)="onTabChange($event)">
      <li ngbNavItem="tienda">
        <button ngbNavLink>Tienda</button>
        <ng-template ngbNavContent><app-merchant-profile-shop-page/></ng-template>
      </li>
      <li ngbNavItem="subastas">
        <button ngbNavLink>Subastas</button>
        <ng-template ngbNavContent><app-merchant-profile-auctions-page/></ng-template>
      </li>
      <li ngbNavItem="valoraciones">
        <button ngbNavLink>Valoraciones</button>
        <ng-template ngbNavContent><app-merchant-profile-reviews-page/></ng-template>
      </li>
    </ul>
    <div class="content">
      <div [ngbNavOutlet]="nav"></div>
    </div>
  </div>
</ng-container>
