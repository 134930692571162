<app-isolated-layout>
    <div class="d-flex justify-content-center">

        <div class="mt-5" style="max-width: 400px; width: 100%;">
            <div class="card-body">

                <h5 class="card-title text-center">Nos vemos pronto</h5>
                <app-fancy-icon-byebye/>

            </div>
        </div>

    </div>
</app-isolated-layout>
