import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AuctionState, IAuctionExpanded, IBidExpanded } from '../../../models/Auction';
import { faGavel } from '@fortawesome/free-solid-svg-icons';
import { userFeat, userFeatActions } from '../../../store/user.store';
import { AppStore } from '../../../store';
import { forkJoin, map, interval, take, tap, first, BehaviorSubject } from 'rxjs';
import { pAuctionFeat, pAuctionFeatActions } from '../../../store/public/auction.store';
import BigNumber from 'bignumber.js';
import { Actions, ofType } from '@ngrx/effects';
import { createSubs, unsubAll } from '../../../utils/rxjs.utils';
import { trackEvent } from '../../../../tracker';
import { UiService } from '../../services/ui.service';
import { apiErrorToErrorParams } from '../../../utils/common.utils';


@Component({
  selector: 'app-fast-bid-btn',
  templateUrl: './fast-bid-btn.component.html',
  styleUrls: ['./fast-bid-btn.component.scss']
})
export class FastBidBtnComponent implements OnChanges, OnInit, OnDestroy {
  @Input() auction!: IAuctionExpanded;
  @Input() size = ''

  constructor(
    private store: AppStore,
    private uiSvc: UiService,
    private actions$: Actions
  ) { }

  bidIcon = faGavel
  nextBidAmount = new BigNumber(0)
  nextBidAmountDelayer: any = null
  lastBid: IBidExpanded | null = null

  bidDisabled = true
  isUserLastBidder = false

  user$ = this.store.select(userFeat.selectUser)
  onboardingStatus$ = this.store.select(userFeat.selectStatus)

  subs$ = createSubs()
  ngOnInit(): void {
    // this.subs$.bidOk = this.actions$.pipe(ofType(pAuctionFeatActions.bidOK)).subscribe(()=>{
    //   trackEvent('bid.fast');
    // })

    this.subs$.bidError = this.actions$.pipe(ofType(pAuctionFeatActions.bidKO)).subscribe(({ error }) => {
      error && this.uiSvc.showToast({ type: 'danger', text: apiErrorToErrorParams(error) })
    })
  }

  ngOnDestroy() {
    unsubAll(this.subs$)
  }

  ngOnChanges(changes: SimpleChanges): void {
    const delay = changes.auction?.isFirstChange() ? 0 : 1000
    const auction: IAuctionExpanded = changes.auction.currentValue


    this.lastBid = auction?.bids[0]

    forkJoin({
      user: this.user$.pipe(first()),
      onboardingStatus: this.onboardingStatus$.pipe(first())
    }).pipe(first()).subscribe(({ user, onboardingStatus }) => {
      this.isUserLastBidder = user?.id === this.lastBid?.bidderId
      this.bidDisabled = this.isUserLastBidder || !onboardingStatus?.customer.completed
    })

    interval(delay).pipe(take(1)).subscribe(() => {
      this.nextBidAmount = new BigNumber(auction.nextBidAmount)
    })

  }


  placeBid(amount: string) {
    this.store.dispatch(pAuctionFeatActions.bid({ auctionId: this.auction.id, amount }))
  }

  get hasDeliveryPrice() {
    return this.auction.deliveryPrice && new BigNumber(this.auction.deliveryPrice).gte(0)
  }
  get processing$() {
    return this.store.select(pAuctionFeat.selectProcessingBid)
  }
  get processingThis$(){
    return this.store.select(pAuctionFeat.selectProcessingBid).pipe(map(id=>id === this.auction.id))
  }
  get isAuctionOpened() {
    return this.auction.state === AuctionState.Started
  }


}
