import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuctionChannel, AuctionState, IAuctionExpanded } from '../../../models/Auction';
import { RealTimeService } from '../../../api/services/RealTimeService';
import { UiService } from '../../../ui/services/ui.service';
import { PublicService } from '../../../api/services/PublicService';
import { ActivatedRoute, Router } from '@angular/router';
import { IUser } from '../../../models/User';
import { AppStore } from '../../../store';
import { userFeat } from '../../../store/user.store';
import { faTwitch } from '@fortawesome/free-brands-svg-icons';
import { MerchantWithAuctionsDto, StreamingStatus } from '../../../models/Merchant';
import { config } from '../../../../config';
import { createSubs, unsubAll } from '../../../utils/rxjs.utils';
import { fromEvent, interval, map, throttle, throttleTime } from 'rxjs';
import { faCartPlus, faComment, faCommentSlash, faMessage } from '@fortawesome/free-solid-svg-icons';
import { exitFullscreen, launchIntoFullscreen } from '../../../utils/common.utils';
import { IProduct } from '../../../models/Product';
import { pMarketProductList } from '../../../store/public/market.product.list.store';
import { UiShopService } from '../../../ui/services/UiShopService';

@Component({
  selector: 'app-live-auction-page',
  templateUrl: './live-auction-page.component.html',
  styleUrls: ['./live-auction-page.component.scss']
})
export class LiveAuctionPageComponent implements OnInit, OnDestroy {
  user: IUser | null = null
  currentAuction?: IAuctionExpanded
  merchantWithAuctions?: MerchantWithAuctionsDto
  buyIcon = faCartPlus
  products: IProduct[] = []
  twitchIcon = faTwitch
  get toggleChatIcon() { return this.showChat ? faCommentSlash : faComment }
  showChat = true
  subs$ = createSubs()
  isNarrowSize = false

  nextLiveAuctions: IAuctionExpanded[] = []

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private publicSvc: PublicService,
    private uiCommonSvc: UiService,
    private rtService: RealTimeService,
    private store: AppStore,
    private uiShopSvc: UiShopService
  ) { }

  get merchantProfileLink() {
    return `/viewer/mercado/${this.merchantWithAuctions?.alias}`
  }

  products$ = this.store.select(pMarketProductList.feat.selectData)
  hasProducts$ = this.products$.pipe(map(list=>list.length > 0))
  loadingProduct$ = this.store.select(pMarketProductList.feat.selectLoading)
  buyDirectModal(product: IProduct){
    this.uiShopSvc.openBuyConfirmationModal(product)
  }
  ngOnDestroy() {
    unsubAll(this.subs$)
  }
  ngOnInit(): void {
    this.route.params.subscribe(({ merchantAlias }) => {
      this.uiCommonSvc.setTitle(merchantAlias)

      this.publicSvc.getMerchantWithAuctions(merchantAlias, { auctionChannels: [AuctionChannel.Live] }).subscribe({
        next: merchantWithAuctions => {
          this.merchantWithAuctions = merchantWithAuctions
          if (!this.isLive) {
            this.router.navigateByUrl('/viewer/subastas')
            return
          }
          this.onMerchantFetched()
          //this.initResizeController()
        },
        error: () => {
          this.router.navigateByUrl('/viewer/subastas')
        }
      })
    })

    this.store.select(userFeat.selectUser).subscribe((user) => {
      this.user = user
    })
  }

  get title() {
    if (this.currentAuction) {
      return this.currentAuction.title
    }

    return this.merchantWithAuctions?.alias
  }

  onMerchantFetched() {

    if (!this.merchantWithAuctions || this.merchantWithAuctions.auctions.length === 0) {
      this.router.navigate(['/viewer/subastas'])
      return
    }

    this.store.dispatch(pMarketProductList.actions.load({
      params: null,
      filters: {
        merchantId: this.merchantWithAuctions.id
      }
    }));

    const startedAuction = this.merchantWithAuctions.auctions.find(auction => auction.state === AuctionState.Started)
    this.nextLiveAuctions = this.merchantWithAuctions.auctions
    if (startedAuction) {
      this.setAuction(startedAuction)
    }

    this.subs$.merchantLive = this.rtService.subToMerchantAuctions(this.merchantWithAuctions.id, true).subscribe(({ event, data: auction }) => {
      switch (event) {
        case 'initial':
          if (auction.state === AuctionState.Started) {
            this.setAuction(auction)
          } else {
            this.setAuction()
          }
          break
        case 'auction.started':
          this.setAuction(auction)
          break
        case 'auction.closed':
          this.setAuction(undefined)
          break
        case 'auction.recreated':
          if (this.currentAuction?.id === auction.id) {
            this.setAuction(auction)
          } else {
            this.nextLiveAuctions.unshift(auction)
          }
          break
        case 'auction.created':
          this.nextLiveAuctions.unshift(auction)
          break
        case 'auction.bid.placed':
          this.setAuction(auction)
          break
        case 'auction.deleted':
          if (this.currentAuction?.id === auction.id) {
            this.setAuction(undefined)
          } else {
            this.nextLiveAuctions = this.nextLiveAuctions.filter(existingAuction => existingAuction.id !== auction.id)
          }
          break
      }
    })
  }

  private setAuction(auction?: IAuctionExpanded) {
    this.currentAuction = auction
    if (!auction) {
      return
    }
    this.nextLiveAuctions = this.nextLiveAuctions.filter(auction => {
      return auction.id !== this.currentAuction?.id && auction.channels.includes(AuctionChannel.Live)
    })
  }
  get isLive() {
    return this.merchantWithAuctions?.channels.find(channel => channel.status === StreamingStatus.Live)
  }

  get twitchChannel() {
    return this.merchantWithAuctions?.channels.find(c => c.platform === 'twitch')?.channel
  }

  get onboardingStatus$() {
    return this.store.select(userFeat.selectStatus)
  }

  get defaultPaymentMethod() {
    if (!this.user) {
      return null
    }
    return this.user.paymentMethods.find(p => p.default) || null
  }

  get isAuctionClosed() {
    return this.currentAuction?.state === AuctionState.Closed
  }
  get isAuctionStarted() {
    return this.currentAuction?.state === AuctionState.Started
  }
  get isAuctionCreated() {
    return this.currentAuction?.state === AuctionState.Created
  }

  get lastBid() {
    return this.currentAuction?.bids[0]
  }
  get orderedBids() {
    return [...this.currentAuction?.bids || []].sort((a, b) => a.createdAt > b.createdAt ? -1 : 1).slice(1, 7)
  }

  get isClosed() {
    return this.currentAuction?.state === AuctionState.Closed
  }

  get showingChatDisabled() {
    return this.isNarrowSize
  }


  initResizeController() {

    const narrowController = (window: Window) => {
      if (!window) {
        this.isNarrowSize = false
      }
      const { innerWidth } = window
      if (!innerWidth) {
        this.isNarrowSize = false
      }
      this.isNarrowSize = innerWidth <= config.mdBreakpoint
      this.showChat = this.showChat && !this.isNarrowSize

    }
    this.subs$.resizeSubscription = fromEvent(window, 'resize').pipe(
      // throttle(() => interval(1000))
      throttleTime(1000, undefined, { leading: true, trailing: true })
    ).subscribe(evt => {
      narrowController(evt.target as Window)
    })
    narrowController(window)

  }

  toggleChatMobile() {
    this.showChat = !this.showChat
  }
}
