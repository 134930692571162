import { Component, inject } from '@angular/core';
import { UiService } from '../../services/ui.service';

@Component({
	selector: 'app-toast-container',
	templateUrl: 'toast-container.component.html',
	styleUrls: ['toast-container.component.scss'],
	host: { class: 'toast-container position-fixed top-0 end-0 p-3', style: 'z-index: 1200' },
})
export class ToastContainerComponent {
	toastService = inject(UiService);
  infinity = 2200
  get isTemplate(){
    return
  }
}
