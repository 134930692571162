import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './AuthService';
import { Observable, filter, forkJoin, map, of, skip, switchMap, take } from 'rxjs';
import { UserService } from '../api/services/UserService';
import { AppStore } from '../store';
import { userFeat, userFeatActions } from '../store/user.store';


@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authSvc: AuthService, private router: Router, private userSvc: UserService, private store: AppStore) { }

  canActivate(): Observable<boolean> {
    if(!this.authSvc.getToken()){
      let returnAfterLogin=''
      if(location.pathname && location.pathname !== '/' && !location.pathname.includes('logout')){
        returnAfterLogin = `?returnAfterLogin=${location.pathname}`
      }
      this.router.navigateByUrl(`/login${returnAfterLogin}`)
      return of(false)
    }
    return this.store.select(userFeat.selectUserState).pipe(

      filter(state=>!state.isFetchingUser),
      take(1),
      map(state=>{
        return !!(state.user && state.status)
      }),
      switchMap(fetched=>{
        if(!fetched){
          this.store.dispatch(userFeatActions.fetchMe())
          return this.store.select(userFeat.selectUserState).pipe(
            filter(state=>!state.isFetchingUser),
            take(1),
            map(state=>{
              return !!(state.user && state.status)
            }),
          )
        }
        return of(true)
      })
    )
  }
}
