<div class="modal-header">
  <h5 class="modal-title" id="modal-basic-title">Añadir método de pago</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>

</div>
<form>
  <div class="modal-body">
    <div #sdkContainer>
      <div class="alert alert-info">Cargando</div>
    </div>
    <app-message [message]="error" type="danger"/>
  </div>
  <div class="modal-footer d-flex justify-content-between">
    <img height="30" src="/assets/img/powered-by-stripe.svg"/>
    <app-btn-async [action]="this.onSubmit.bind(this)" [disabled]="!submitEnabled" submitText="Guardar" loadingText="Guardando"
      (onSuccess)="onSuccess($event)" (onError)="onError($event)"/>

  </div>
</form>
