<div class="card card-sm-compact" [ngClass]="{'border-warning':noPlatforms, 'border-2':noPlatforms}">
  <div class="card-header">Cuentas conectadas</div>
  <div class="card-body" *ngIf="noPlatforms">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <div class="alert alert-secondary d-flex gap-2 align-items-center">
        No tienes tu cuenta de Twitch vinculada.
        <button class="btn btn-sm btn-outline-primary" [disabled]="loading" (click)="connectTwitch()">
          <ng-container *ngIf="!loading">
            Conectar
          </ng-container>
          <ng-container *ngIf="loading">
            <app-loading-spinner /> Redirigiendo a twitch
          </ng-container>
        </button>
      </div>
    </div>
    <ng-template #connectFirstAccount>
      <div class="alert alert-secondary">
        Si quieres participar en subastas desde la plataforma de Twitch debes conectar tu cuenta.
      </div>
    </ng-template>
  </div>
  <ul class="list-group list-group-flush platform-list" *ngIf="!noPlatforms">
    <li class="list-group-item p-2" *ngFor="let platform of user.platforms">
      <div class="d-flex align-items-center gap-2">
        <div>
          <img [src]="platform.profileImageUrl" class="profile-avatar" />
        </div>
        <div class="flex-grow-1">
          <app-stream-platform-icon [platform]="platform.platform" /> {{platform.username}}
        </div>
        <div class="d-flex gap-3 align-items-center">
          <span ngbTooltip="Los cambios aquí no afectan a la cuenta que tienes conectada como vendedor 🙂.">
            <fa-icon [icon]="iconHelp" />
          </span>
          <button class="btn btn-sm btn-outline-secondary" placement='top' ngbTooltip="Actualizar" [disabled]="loading"
            (click)="connectTwitch()">
            <ng-container *ngIf="!loading">
              <fa-icon [icon]="iconUpdate" />
            </ng-container>
            <ng-container *ngIf="loading">
              <app-loading-spinner />
            </ng-container>
          </button>
          <button class="btn btn-sm btn-outline-secondary" placement='top' ngbTooltip="Desvincular" [disabled]="loading" (click)="disconnectTwitch()">
            <ng-container *ngIf="!loading">
              <fa-icon [icon]="iconDisconnect" />
            </ng-container>
            <ng-container *ngIf="loading">
              <app-loading-spinner />
            </ng-container>
          </button>
        </div>
      </div>
    </li>
  </ul>
</div>
