import { createAction, createFeature, createReducer, on, props } from '@ngrx/store';

import { IUpdateUserDto, OnboardingStatusDto } from '../api/services/UserService';
import { IUser, IUserPaymentMethod } from '../models/User';


const userFeatName = 'User'

export interface UserState {
  user: IUser|null
  status: OnboardingStatusDto|null,
  isFetchingUser: boolean
  fetchingUserError: any

  isUpdatingUser: boolean
  updatingUserError: any
}

const initialState: UserState = {
  user: null,
  status: null,
  isFetchingUser: false,
  fetchingUserError: null,
  isUpdatingUser: false,
  updatingUserError: null
};

// Actions
const prefix = `[${userFeatName}]`
const reset = createAction(`${prefix} Reset`);
const statusUpdated = createAction(`${prefix} Status updated`, props<{ status: OnboardingStatusDto }>());
const userUpdated = createAction(`${prefix} User updated`, props<{ user: IUser|null }>());
const userPaymentMethodAdded = createAction(`${prefix} User payment method added`, props<{ paymentMethod: IUserPaymentMethod }>());

const fetchMe = createAction(`${prefix} FetchMe`);
const fetchMeOK = createAction(`${prefix} FetchMeOK`, props<{ user: IUser|null, status: OnboardingStatusDto|null }>());
const fetchMeKO = createAction(`${prefix} FetchMeKO`, props<{ error: any }>());

const update = createAction(`${prefix} update`, props<{ data: IUpdateUserDto}>());
const updateOK = createAction(`${prefix} updateOK`, props<{ user: IUser|null }>());
const updateKO = createAction(`${prefix} updateKO`, props<{ error: any }>());

// Reducer
const reducer = createReducer<UserState>(
  initialState,
  on(reset, () => initialState),
  on(statusUpdated, (state, {status}) => ({...state, status})),
  on(userUpdated, (state, {user}) => ({...state, user})),
  on(userPaymentMethodAdded, (state, {paymentMethod}) => ({
    ...state,
    user:{
      ...state.user!,
      paymentMethods: [...(state.user?.paymentMethods||[]), paymentMethod]
    }
  })),
  // fetching
  on(fetchMe, (state)=>({...state, isFetchingUser: true, error: null})),
  on(fetchMeOK, (state, {user, status})=>({...state, status, user, isFetchingUser: false, fetchingUserError: null})),
  on(fetchMeKO, (state, error)=>({...state, isFetchingUser: false, fetchingUserError: error })),

  //
  on(update, (state)=>({...state, isUpdatingUser: true, error: null})),
  on(updateOK, (state, {user})=>({...state, user, isUpdatingUser: false, updatingUserError: null})),
  on(updateKO, (state, { error })=>({...state, isUpdatingUser: false, updatingUserError: error }))


);

// Feature
export const userFeat = createFeature({
  name: userFeatName,
  reducer
})

export const userFeatActions = {
  reset,
  fetchMe,
  fetchMeOK,
  fetchMeKO,
  update,
  updateOK,
  updateKO,
  statusUpdated,
  userUpdated,
  userPaymentMethodAdded
}
