import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MerchantWidgetSettings, WidgetOrientation } from '../../../models/Order';
import { IAuctionEvent, RealTimeService } from '../../../api/services/RealTimeService';
import { UiService } from '../../../ui/services/ui.service';
import { ActivatedRoute } from '@angular/router';
import { IAuctionExpanded } from '../../../models/Auction';
import { createSubs, unsubAll } from '../../../utils/rxjs.utils';
import { merge, pickBy } from 'lodash';



@Component({
  selector: 'app-merchant-live-auctions-widget-page',
  templateUrl: './merchant-live-auctions-widget-page.component.html',
  styleUrls: ['./merchant-live-auctions-widget-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MerchantLiveAuctionsWidgetPageComponent implements OnDestroy {

  auction?: IAuctionExpanded
  settings?: MerchantWidgetSettings
  subs$ = createSubs()
  forcedSettings: Partial<MerchantWidgetSettings> = {}

  constructor(
    private route: ActivatedRoute,
    private uiCommonSvc: UiService,
    private rtService: RealTimeService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(({ merchant, orientation, displayProductPicture, displaySecondToLastBid }) => {
      this.forcedSettings = {
        orientation: Object.values(WidgetOrientation).includes(orientation) ? orientation : undefined,
        displayProductPicture: typeof displayProductPicture === 'string' ? displayProductPicture === '1' : undefined,
        displaySecondToLastBid: typeof displaySecondToLastBid === 'string' ? displaySecondToLastBid === '1' : undefined
      }
      this.loadMerchant(merchant)
    })
  }

  ngOnDestroy(): void {
    unsubAll(this.subs$)
  }

  loadMerchant(merchant: string) {
    this.uiCommonSvc.setTitle('Subasta')
    this.subs$.merchant = this.rtService.subToMerchantAuctions(merchant, true).subscribe(this.onAuctionEvent.bind(this))

    this.subs$.widgetSettings = this.rtService.subToWidgetSettings(merchant, true).subscribe((settings: MerchantWidgetSettings) => {
      const forcedSettings = pickBy(this.forcedSettings, (v)=>typeof v !== 'undefined')
      this.settings =merge(settings, forcedSettings)
    })
  }

  onAuctionEvent({data: auction, event}: IAuctionEvent) {
    if(event === 'auction.deleted'){
      return
    }
    if(event === 'auction.created' && this.auction){
      return
    }

    this.auction = auction

  }

}
