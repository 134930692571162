import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { IconDefinition, faCheck } from '@fortawesome/free-solid-svg-icons';

export interface IFormInputOption {
  label: string
  value: string
  disabled?:boolean

}
@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss']
})
export class FormInputComponent implements OnInit, OnDestroy {
  @Input() form!: FormGroup
  @Input() apiError?: HttpErrorResponse
  @Input() controlName!: string
  @Input() prefix: string | undefined;
  @Input() minHeight: number = 50;
  @Input() sufix: string | undefined;
  @Input() iconPrefix: IconDefinition | undefined;
  @Input() options: IFormInputOption[] | undefined;
  @Input() label = ''
  @Input() placeholder = ''
  @Input() type: 'select'|'password' | 'text' | 'textarea' | 'checkbox' | 'date' | 'time' | 'toggler' | 'number' = 'text'
  @Input() marginBottom: string | undefined;
  @Input() formText: string | undefined;
  // dropdowns
  @Input() dropdownControlName?: string
  @Input() dropdownOptions?: IFormInputOption[]
  @Input() dropdownMaxWidth?:string = 'auto'



  ngOnInit(){}

  ngOnDestroy(){}

  get dropdownControl(){
    return this.dropdownControlName ? this.form.get(this.dropdownControlName) as FormControl: null
  }
  get shouldApplyIsValidClass () {
    return this.control.valid && this.control.validator
  }
  checkIcon = faCheck
  get wrapperClasses(){
    const classes: string[] = []
    if(this.marginBottom){
      classes.push('mb-'+this.marginBottom)
    }
    return classes
  }
  get isBasicInput() {
    return !this.isCheck && !this.isToggler && !this.isTextarea
  }
  get isToggler() {
    return this.type === 'toggler'
  }
  get isSelect(){
    return this.type === 'select'
  }
  get isTextarea() {
    return this.type === 'textarea'
  }
  get hasPrefix() {
    return this.prefix || this.iconPrefix || this.sufix
  }
  get isCheck() {
    return this.type === 'checkbox'
  }
  get control(): FormControl {
    return this.form.get(this.controlName) as FormControl
  }

  get controlApiErrors(){
    return this.apiError?.error?.error.errors.find((error: any)=>error?.property === this.controlName)
  }

  get hasApiError(){
    return !!this.controlApiErrors
  }
  get isInvalid(){
    return this.control.invalid || (this.hasApiError && this.control.pristine)
  }
  get shouldDisplayError() {
    if(this.hasApiError && this.control.pristine){
      return true
    }
    return this.isInvalid && (this.control.dirty || this.control.touched)
  }
  get controlApiErrorMsg(){
    return this.controlApiErrors?.[0]?.msg
  }

  get errors(): ValidationErrors {
    return this.control.errors!
  }
}
