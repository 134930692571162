import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, exhaustMap, catchError, withLatestFrom, switchMap } from 'rxjs/operators';
import { pAuctionFeatActions } from './auction.store';
import { CustomerService } from '../../api/services/CustomerService';
import { pMerchantAuctionList, pMerchantProductList, pMerchantProfile, pMerchantProfileList, pMerchantReviewList } from './merchant.profile.store';
import { PublicService } from '../../api/services/PublicService';
import { AppStore } from '../app.store';



@Injectable()
export class PublicEffects {

  bid$ = createEffect(() => this.actions$.pipe(
    ofType(pAuctionFeatActions.bid),
    exhaustMap(({auctionId, amount}) => this.customerSvc.bid(auctionId, {amount})
      .pipe(
        map((res) => pAuctionFeatActions.bidOK({bidderId: res.bids[0].bidderId, amount, auctionId})),
        catchError((error) => of(pAuctionFeatActions.bidKO({ error }))))
      )
  ))

  loadMerchantProfile$ = createEffect(() => this.actions$.pipe(
    ofType(pMerchantProfile.actions.load),
    exhaustMap(({id}) => this.publicSvc.getMerchantProfile(id)
      .pipe(
        map((data) => pMerchantProfile.actions.loadOK({ data })),
        catchError((error) => of(pMerchantProfile.actions.loadKO({ error }))))
      )
  ))

  loadMerchantProfiles$ = createEffect(() => this.actions$.pipe(
    ofType(pMerchantProfileList.actions.load),
    exhaustMap(() => this.publicSvc.getMerchantProfiles()
      .pipe(
        map((data) => pMerchantProfileList.actions.loadOK({ data })),
        catchError((error) => of(pMerchantProfileList.actions.loadKO({ error }))))
      )
  ))

  constructor(
    private actions$: Actions,
    private customerSvc: CustomerService,
    private publicSvc: PublicService
  ) { }
}
