import { OrderReviewDto } from "../../api/services/CustomerService";
import { IAuctionExpanded } from "../../models/Auction";
import { MerchantProfileDto } from "../../models/Merchant";
import { IProduct } from "../../models/Product";
import { buildCrudStore } from "../utils/factory.crud.store";
import { buildListStore } from "../utils/factory.list.store";
import { buildResourceFetchStore } from "../utils/factory.resource-fetch.store";

export const pMerchantProfile = buildResourceFetchStore<MerchantProfileDto>('merchantProfile')
export const pMerchantProfileList = buildResourceFetchStore<MerchantProfileDto[]>('merchantProfileList')

export const pMerchantReviewList = buildListStore<OrderReviewDto, {merchantId: string}>('merchantShopReviews')
export const pMerchantProductList = buildListStore<IProduct, {merchantId: string}>('merchantShopProducts')
export const pMerchantAuctionList = buildListStore<IAuctionExpanded, {merchantId: string}>('merchantShopAuctions')
