import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { IUser, IUserPaymentMethod } from '../../models/User';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { environment } from '../../../environments/environment';
import { AddPaymentMethodModalComponent } from '../components/add-payment-method-modal/add-payment-method-modal.component';

@Injectable({
  providedIn: 'root'
})
export class UiPaymentService {

  private _stripe: Stripe|null = null

  constructor(private modalSvc: NgbModal) {

  }

  async getStripe({ stripeAccount }:{stripeAccount?:string} = {}): Promise<Stripe>{
    if(!this._stripe){
      let stripeOpts: any = undefined
      if(stripeAccount){
        stripeOpts = { stripeAccount }
      }
      this._stripe = await loadStripe(environment.stripePublishableKey, stripeOpts);
      if (!this._stripe) {
        throw new Error('Stripe failed to load');
      }
    }
    return this._stripe
  }

  openAddPaymentMethodModal(): Observable<IUserPaymentMethod>{
    const modalRef = this.modalSvc.open(AddPaymentMethodModalComponent, {backdrop:'static'});
    return modalRef.componentInstance.onPaymentSaved
  }
}
