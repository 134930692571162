import { Component } from '@angular/core';
import { Chart } from '../../../../ui/components/dashboard/dashboard-chart-box/dashboard-chart-box.component';
import { faMoneyBill, faUsersGear } from '@fortawesome/free-solid-svg-icons';
import { MerchantService } from '../../../../api/services/MerchantService';
import { AuthService } from '../../../../auth/AuthService';
import { AccountMode } from '../../../../enums';

@Component({
  selector: 'app-merchant-dashboard-page',
  templateUrl: './merchant-dashboard-page.component.html',
  styleUrls: ['./merchant-dashboard-page.component.scss']
})
export class MerchantDashboardPageComponent {
  constructor(
    private merchantSvc: MerchantService,
    private authSvc: AuthService
  ) { }

  orderStats: any | null = null
  usersIcon = faUsersGear
  incomeIcon = faMoneyBill
  revenueChart: any | null

  ngOnInit(): void {
    this.load()
  }

  load() {
    this.merchantSvc.getOrderStats().subscribe(orderStats => {
      setTimeout(() => {
        this.orderStats = orderStats
      }, 500)
    })


    this.merchantSvc.getRevenue().subscribe(this.createRevenueChart.bind(this))
  }

  get isStreamerMode() {
    return this.authSvc.accountMode === AccountMode.Merchant
  }

  createRevenueChart(revenue: any) {
    const revenueSerie = {
      name: 'Ingresos',
      data: revenue.entries.map((e: any) => ({ x: e.id, y: e.revenue }))
    }
    const feeeSerie = {
      name: 'Comisiones',
      data: revenue.entries.map((e: any) => ({ x: e.id, y: e.fees }))
    }
    const categories = revenue.entries.map((e: any) => e.id)
    const userEvolutionChart: Chart = {
      chart: {
        height: 200,
        stacked: true,
        type: 'bar',
      },
      dataLabels: {
        enabled: true
      },
      series: [revenueSerie, feeeSerie],
      yaxis: {
        logarithmic: true,
        labels: {
          formatter: (val) => `${val}€`
        }
      },
      xaxis: {
        type: "datetime" as any,
        categories
      }

    }

    this.revenueChart = userEvolutionChart
  }


}
