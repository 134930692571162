<form class="card" [formGroup]="form" (submit)="saveProfile()">
  <div class="card-header">
    Perfil
  </div>
  <div class="card-body">
    <app-form-input [form]="form" [apiError]="error$|async" controlName="alias" label="Alias" marginBottom="3" />
    <app-form-input [form]="form" controlName="title" label="Titular"
      marginBottom="3" />
      <app-form-input type="textarea" [form]="form" controlName="bio" label="Bio" [minHeight]="100"
      marginBottom="3" />

      <h6>Redes sociales</h6>
      <div class="d-flex flex-column gap-2 mb-3">
        <ng-container *ngFor="let platform of socialPlatforms">
          <app-form-input [iconPrefix]="platform.icon" [controlName]="'socials.'+platform.name" [form]="form" />
        </ng-container>
      </div>
      <app-api-error-block [error]="error$|async"/>
  </div>
  <div class="card-footer text-end">
    <button class="btn btn-outline-primary" [disabled]="this.formDisabled">
      <ng-container *ngIf="updating$|async;else readyTxt">
        <app-loading-spinner/> Guardando
      </ng-container>
      <ng-template #readyTxt>
        Guardar
      </ng-template>

    </button>
  </div>
</form>
