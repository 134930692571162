import { IAuctionExpanded } from "./Auction"
import { StreamingPlatform } from "./Enum"
import { IProduct } from "./Product"
import { IUser } from "./User"


export enum StreamingStatus {
  Live = 'live',
  Off = 'off',
  Unknown = 'unknown'
}
export class StreamingDetails {
  title!: string
  thumbnailUrl!: string
  viewerCount?: number
}

export interface MerchantWithAuctionsDto {
  id: string;
  avatar: string
  alias: string
  channels: Array<{
      platform: StreamingPlatform
      channel: string
      status: StreamingStatus
      details: StreamingDetails | null
  }>
  auctions: IAuctionExpanded[]
}

export interface MerchantProfileDto {
  user: IUser
  reviewStats: {
    merchantId: string
    avgRating: number;
    reviewCount: number
  }
}
