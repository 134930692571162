<div class="home-header container ">
    <div class="row align-items-center jumbo z-5">
        <div class="col-8">
            <div class="caption">
                <h1>
                    Bienvenido, <b class="platform-name fancy-underline">Sergio</b>
                </h1>
            </div>
        </div>
        <div class="col-4">
            <img src="assets/img/control-panel.svg"/>
        </div>
    </div>
</div>
