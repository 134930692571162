import { createAction, createFeature, createReducer, on, props } from '@ngrx/store';

import { IAuction, IAuctionExpanded } from '../models/Auction';
import { ICreateAuctionDto, IUpdateAuctionDto } from '../api/services/MerchantService';


const merchantFeatName = 'merchant'
const mAuctionListFeatName = `${merchantFeatName}AuctionList`
const mAuctionFormFeatName = `${merchantFeatName}AuctionForm`


export enum ViewStatus {
  Idle = 'idle',
  Processing = 'processing',
  Success = 'success',
}

export interface MerchantAuctionFormState {
  auction: IAuction|null
  error: Error | null
  status: ViewStatus
}
export interface MerchantAuctionListState {
  filters: any
  deletingAuctionId: string|null
  duplicateAuctionId: string|null
  startAuctionId: string|null
  auctions: IAuctionExpanded[]
  error:Error | null
  loading: boolean
}
export interface MerchantAuctionDeleteState {
  auctionId: string|null
  loading: boolean
  error:Error | null
}

export const auctionListInitialState: MerchantAuctionListState = {
  auctions: [],
  filters: {},
  deletingAuctionId: null,
  duplicateAuctionId: null,
  startAuctionId: null,
  error: null,
  loading: false,
}

const auctionFormInitialState: MerchantAuctionFormState = {
    status: ViewStatus.Idle,
    auction: null,
    error: null
};

// Actions
const prefix = `[${merchantFeatName}]`

const loadAuctions = createAction(`${prefix} Load merchant auctions`, props<{ filters: any, page?: number }>());
const loadAuctionsOK = createAction(`${prefix} Load merchant auctions OK`, props<{auctions: IAuctionExpanded[]}>());
const loadAuctionsKO = createAction(`${prefix} Load merchant auctions Err`, props<{error: Error}>());
const deleteAuction = createAction(`${prefix} Delete auction`, props<{auctionId: string}>());
const deleteAuctionOK = createAction(`${prefix} Delete auction ok`, props<{deletedAuctionId: string}>());
const deleteAuctionKO = createAction(`${prefix} Delete auction failed`, props<{error: Error}>());

const duplicateAuction = createAction(`${prefix} Duplicate auction`, props<{duplicateAuctionId: string}>());
const duplicateAuctionOK = createAction(`${prefix} Duplicate auction ok`, props<{newAuctions: IAuctionExpanded[]}>());
const duplicateAuctionKO = createAction(`${prefix} Duplicate auction failed`, props<{error: Error}>());

const startAuction = createAction(`${prefix} Start auction `, props<{startAuctionId: string}>());
const startAuctionOK = createAction(`${prefix} Start auction  ok`, props<{startedAuction: IAuctionExpanded}>());
const startAuctionKO = createAction(`${prefix} Start auction  failed`, props<{error: Error}>());

const updateAuctionOK = createAction(`${prefix} Update auction  ok`, props<{updatedAuction: IAuctionExpanded}>());

const resetAuctionForm = createAction(`${prefix} Reset Auction form`);
const submitAuctionCreateForm = createAction(`${prefix} Submit create`, props<{auction: ICreateAuctionDto}>());
const submitAuctionUpdateForm = createAction(`${prefix} Submit update`, props<{auction: IUpdateAuctionDto}>());
const auctionFormOk = createAction(`${prefix} Create auction ok`, props<{auctions: IAuction[]}>());
const auctionFormKO = createAction(`${prefix} Create auction failed`, props<{error: Error}>());


// Reducer
const auctionListReducer = createReducer(
  auctionListInitialState,
  on(loadAuctions, (state, { filters }) => ({...state, filters, loading: true, deletingAuctionId: null, error: null })),
  on(loadAuctionsOK, (state, {auctions}) => ({...state, auctions,deletingAuctionId: null, loading: false })),
  on(loadAuctionsKO, (state, {error}) => ({...state, error, loading : false })),
  // delete
  on(deleteAuction, (state, {auctionId}) => ({...state, deletingAuctionId:auctionId })),
  on(deleteAuctionOK, (state, {deletedAuctionId}) => ({
    ...state,
    deletingAuctionId: null,
    error: null,
    auctions: state.auctions.filter(a => a.id !== deletedAuctionId)
  })),
  on(deleteAuctionKO, (state, {error}) => ({...state, deletingAuctionId: null, error })),
  // repeat
  on(duplicateAuction, (state, {duplicateAuctionId}) => ({...state, duplicateAuctionId })),
  on(duplicateAuctionOK, (state, {newAuctions}) => ({
    ...state,
    duplicateAuctionId: null,
    error: null,
    auctions: [...newAuctions, ...state.auctions]
  })),
  on(duplicateAuctionKO, (state, {error}) => ({...state, duplicateAuctionId: null, error })),
  // start
  on(startAuction, (state, {startAuctionId}) => ({...state, startAuctionId })),
  on(startAuctionOK, (state, {startedAuction}) => ({
    ...state,
    duplicateAuctionId: null,
    error: null,
    auctions: state.auctions.map(auction=>auction.id === startedAuction.id ? startedAuction : auction)
  })),
  on(startAuctionKO, (state, {error}) => ({...state, startAuctionId: null, error })),
  // update
  on(updateAuctionOK, (state, {updatedAuction}) => ({
    ...state,
    duplicateAuctionId: null,
    error: null,
    auctions: state.auctions.map(auction=>auction.id === updatedAuction.id ? updatedAuction : auction)
  })),

);

const auctionFormReducer = createReducer(
  auctionFormInitialState,
  on(resetAuctionForm, (state)=>({...state, status: ViewStatus.Idle, error: null, auction: null })),
  on(submitAuctionCreateForm, (state) => ({...state, status: ViewStatus.Processing})),
  on(submitAuctionUpdateForm, (state) => ({...state, status: ViewStatus.Processing})),
  on(auctionFormOk, (state, {auctions}) => ({...state, status: ViewStatus.Success, auction: null, error: null, })),
  on(auctionFormKO, (state, {error}) =>   ({...state, status: ViewStatus.Idle, error })),
);



// Feature
export const mAuctionListFeat = createFeature({
  name: mAuctionListFeatName,
  reducer: auctionListReducer,
})

export const mAuctionFormFeat = createFeature({
  name: mAuctionFormFeatName,
  reducer: auctionFormReducer,
})


export const mAuctionListActions = {
  loadAuctions,
  loadAuctionsOK,
  loadAuctionsKO,

  duplicateAuction,
  duplicateAuctionOK,
  duplicateAuctionKO,

  updateAuctionOK,

  startAuction,
  startAuctionOK,
  startAuctionKO,

  deleteAuction,
  deleteAuctionOK,
  deleteAuctionKO
}

export const mAuctionFormActions = {
  resetAuctionForm,
  submitAuctionCreateForm,
  submitAuctionUpdateForm,
  auctionFormOk,
  auctionFormKO,
}
