import { createAction, createFeature, createReducer, on, props } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';

export interface UserState {
  state: 'idle'
  error: any
}
const initialState: UserState = {
  state: 'idle',
  error: null
};

// Actions`

const doIt = createAction(`DO`);
const ok = createAction(`OK`);
const ko = createAction(`KO`);

// Reducer
const reducer = createReducer<UserState>(
  initialState,
  on(doIt, (state)=>state),
  on(ok, (state)=>state),
  on(ko, (state)=>state)
);

// Feature
const feat = createFeature({
  name: 'playground',
  reducer
})

export const playgroundStore = {
  feat,
  actions: { doIt, ok, ko }
}


