<app-page-title title="Dashboard">
  <a [routerLink]="isStreamerMode ? '/viewer' : '/streamer'" class="form-check form-switch">
    <input class="form-check-input" type="checkbox" role="switch" id="accMode" [checked]="isStreamerMode">
    <label class="form-check-label" for="accMode">Modo streamer</label>
  </a>
</app-page-title>
<div class="row ">
  <div class="col-12 col-lg-6 mb-4">
    <div class="card mb-4">
      <div class="card-header">Algunas métricas</div>
      <div class="card-body">
        <div class="row">
          <div class="col-6">
            <app-dashboard-tile [compact]="false" theme="teal" [icon]="incomeIcon" title="Ingresos brutos"
              [loading]="!orderStats" [value]="orderStats?.totalIncome" unit="€" />
          </div>
          <div class="col-6">
            <app-dashboard-tile [compact]="false" theme="indigo" [icon]="usersIcon" title="Clientes únicos"
              [loading]="!orderStats" [value]="orderStats?.customerIds?.length" />
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        Ingresos
      </div>
      <!-- <div class="card-body"> -->
      <app-dashboard-chart-box [chartOptions]="revenueChart" />

      <!-- </div> -->
    </div>
  </div>
  <div class="col-12 col-lg-6 mb-4">
    <app-dashboard-top-customers />
  </div>


  <!-- end charts -->
  <div class="d-block d-sm-none">
    <a routerLink="/logout" class="btn btn-lg w-100 btn-outline-danger">Cerrar sesión</a>
  </div>
