import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { faL } from '@fortawesome/free-solid-svg-icons';

declare const Twitch: any

enum TwitchStreamLayout {
  Video = 'video',
  VideoWithChat = 'video-with-chat'
}
@Component({
  selector: 'app-twitch-embed',
  templateUrl: './twitch-embed.component.html',
  styleUrls: ['./twitch-embed.component.scss']
})
export class TwitchEmbedComponent implements OnInit, OnChanges {

  constructor(
    protected _sanitizer: DomSanitizer
  ) {}
  @ViewChild('streamView', { static: true }) streamViewRef!: ElementRef;
  @Input({ required: true }) channel!: string
  @Input() width = '100%'
  @Input() height = '100%'
  @Input() showChat = true

  twitch: any = null

  ngOnInit(): void {
    this.loadStream()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['showChat'] && !changes['showChat'].firstChange) {
      this.loadStream()
    }
  }


  get streamLayout(): TwitchStreamLayout {
    return this.showChat ? TwitchStreamLayout.VideoWithChat : TwitchStreamLayout.Video
  }

  loadStream() {
    if (this.twitch) {
      this.twitch.destroy()
    }
    this.twitch = new Twitch.Embed(this.streamViewRef.nativeElement, {
      width: this.width,
      height: this.height,
      channel: this.channel,
      parent: window.location.hostname,
      layout: this.streamLayout,
      theme: 'dark'
    });

  }
}
