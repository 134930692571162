import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Stripe } from '@stripe/stripe-js';
import { stripeElementsAppearance } from '../../../utils/stripe.utils';
import { IUser, IUserAddress } from '../../../models/User';
import { UiPaymentService } from '../../services/ui-payment.service';
import { UiService } from '../../services/ui.service';
import { UiState } from '../../../store/ui.store';
import { CustomerService } from '../../../api/services/CustomerService';
import { forkJoin, from } from 'rxjs';

export type AddressFormValue = Pick<IUser, 'address' | 'firstName' | 'lastName' | 'phone'>;

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent {
  stripe!: Stripe;
  @Input() firstName?: string
  @Input() phone?: string
  @Input() lastName?: string
  @Input() address?: IUserAddress
  @ViewChild('addressForm', { static: true }) addressFormRef!: ElementRef;
  @Output() onChange = new EventEmitter<AddressFormValue>();

  elements: any;
  addressForm: any;

  constructor(
    private uiPaymentSvc: UiPaymentService,
    private uiSvc: UiService,
    private customerSvc: CustomerService
  ) { }

  ngOnInit() {
    forkJoin({
      stripe: from(this.uiPaymentSvc.getStripe()),
      allowedCountries: this.customerSvc.getAllowedCountries()
    }).subscribe(({stripe, allowedCountries}) => {
      this.uiSvc.theme$.subscribe(theme=>{
        this.loadAddressForm(stripe, allowedCountries, theme);
      })
    });
  }

  async loadAddressForm(stripe: Stripe, allowedCountries: string[], theme: UiState['theme']) {
    this.elements = stripe.elements({
      appearance: stripeElementsAppearance[theme],
    });

    const { postalCode , ...address}= this.address || {}

    this.addressForm = this.elements.create('address', {
      mode: 'shipping',
      defaultValues: {
        country: 'ES',
        firstName: this.firstName,
        lastName: this.lastName,
        phone: this.phone,
        address: {
          ...address,
          postal_code: postalCode,
        },
      },
      display: {
        name: 'split',
      },
      fields: {
        phone: 'always'
      },
      validation:{
        phone: {
          required: 'always'
        }
      },
      allowedCountries,
    });

    this.addressForm.mount(this.addressFormRef.nativeElement);
    let skip = !!this.address ? 2 : 0

    this.addressForm.on('change', (event: any) => {
      if(skip > 0){
        skip --
        return
      }
      if (!event.complete) {
        return
      }
      const {postal_code:postalCode, ...address} = event.value.address
      const {firstName, lastName, phone} = event.value
      this.onChange.emit({
        firstName,
        lastName,
        address: { ...address, postalCode },
        phone
      });
    });
  }
}
