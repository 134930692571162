import { DirectBuyPayloadDto } from "../../api/services/CustomerService";
import { createAction, createFeature, createReducer, on, props } from '@ngrx/store';
import { CustomerOrderDto } from "../../models/Order";

const featureName = `customerDirectBuy`

interface DirectBuyState {
  order: CustomerOrderDto|null
  loading: boolean
  error: any
}
// Actions
const prefix = `[${featureName}]`

const initialState: DirectBuyState = {
  order: null,
  loading: false,
  error: null
}

const reset = createAction(`${prefix} Reset DirectBuy`);
const create = createAction(`${prefix} Create DirectBuy`, props<{ payload: DirectBuyPayloadDto }>());
const createOK = createAction(`${prefix} Create DirectBuy OK`, props<{ orderId: string }>());
const createKO = createAction(`${prefix} Create DirectBuy KO`, props<{ error: Error }>());

// Reducer

const reducer = createReducer(
  initialState,
  on(reset, ()=>({...initialState})),
  on(create, (state) => ({ ...state, loading: true })),
  on(createOK, (state) => ({ ...state, loading: false })),
  on(createKO, (state, { error }) => ({ ...state, error, loading: false }))
);

const feat = createFeature({
  name: featureName,
  reducer
})

export const cDirectBuyFormStore = {
  feat,
  actions: {
    reset,

    create,
    createOK,
    createKO,
  }
}
