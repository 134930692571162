import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, tap } from 'rxjs';

@Injectable()
export class ApiRequestInterceptor implements HttpInterceptor {

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap({ error: this.errorResponseHandler.bind(this) })
        );
    }

    private errorResponseHandler(errRes: any): void {
        const error = errRes?.error?.error
        if (error?.code) {
            const { code, message } = error
        }
    }
}
