import { createAction, createFeature, createReducer, on, props } from '@ngrx/store';
import { merge } from 'lodash';

export interface GenericListStore<ElementDto> {
  data: ElementDto | null
  error:Error | null
  loading: boolean
}

const defInitialState: GenericListStore<any> = {
  data: null,
  error: null,
  loading: false
}

export const buildResourceFetchStore = <ElementDto>(
  resourceName: string,
  partialInitialState: Partial<GenericListStore<ElementDto>> = {}
) => {

  const featureName = `${resourceName}Resource`

  // Actions
  const prefix = `[${featureName}]`

  const load = createAction(`${prefix} Load ${resourceName}`, props<{ id: string }>());
  const loadOK = createAction(`${prefix} Load ${resourceName} OK`, props<{ data: ElementDto }>());
  const loadKO = createAction(`${prefix} Load ${resourceName} KO`, props<{ error: Error }>());

  // Reducer
  const initialState = merge(defInitialState as GenericListStore<ElementDto>, partialInitialState)
  const reducer = createReducer(
    initialState,
    on(load, (state) => ({ ...state, data: null, loading: true })),
    on(loadOK, (state, { data }) => ({ ...state, data, loading: false })),
    on(loadKO, (state, { error }) => ({ ...state, data: null, error, loading: false })),
  );


  return {
    feat: createFeature({
      name: featureName,
      reducer,
    }),
    actions: {
      load,
      loadOK,
      loadKO
    }
  }
}
