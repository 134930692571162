<div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-4 d-none d-lg-block">
        <app-fancy-icon-steps />
      </div>
      <div class="col-md-12 col-lg-8">
        <div class="d-flex align-items-center">
          <div class="col-md-2 col-sm-3 d-sm-block d-md-block d-lg-none"><app-fancy-icon-steps /></div>
          <div>
            <h5>Configura tu cuenta!</h5>
            <p>Estás solo a unos pasos de empezar a vender en tu canal</p>
          </div>
        </div>
        <ul class="list-group">
          <li class="list-group-item" *ngFor="let task of tasks;">
            <div class="d-flex gap-2 align-items-center">

              <div class="flex-grow-1">
                <div class="d-flex gap-1 align-items-center"><fa-icon [icon]="task.icon"/> <b>{{task.title}}</b></div>
                <div [innerHTML]="task.description"></div>
              </div>
              <div>
                <button *ngIf="!isCompleted(task.task)" class="btn btn-primary" [disabled]="task.loading" (click)="goToTask(task.task)">
                  <ng-container *ngIf="task.loading">
                    <div class="spinner-border spinner-border-sm" role="status"></div>
                    Redirigiendo
                  </ng-container>
                  <ng-container *ngIf="!task.loading">{{ task.cta || 'Completar'}}</ng-container>
                </button>
                <button *ngIf="isCompleted(task.task)" class="btn btn-success">Completado</button>
              </div>
            </div>
          </li>
        </ul>


      </div>

    </div>
  </div>
