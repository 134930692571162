import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MerchantOrderDto} from '../../../../models/Order';
import { UiService } from '../../../../ui/services/ui.service';
import { MerchantService } from '../../../../api/services/MerchantService';


@Component({
  selector: 'app-merchant-order-view-page',
  templateUrl: './merchant-order-view-page.component.html',
  styleUrls: ['./merchant-order-view-page.component.scss']
})
export class MerchantOrderViewPageComponent {

  order!: MerchantOrderDto
  public constructor(
    private route:ActivatedRoute,
    private uiCommonSvc: UiService,
    private merchantSvc: MerchantService) {
  }



  ngOnInit(): void {
    this.route.params.subscribe(({id})=>{
      this.merchantSvc.getOrder(id).subscribe((order)=>{
        this.order = order
      })
    })
    this.uiCommonSvc.setTitle(`Pedido`)
  }

}

