import { faInstagram, faTelegram, faTwitch } from "@fortawesome/free-brands-svg-icons";
import { IconDefinition, faX } from "@fortawesome/free-solid-svg-icons";

export interface IUserAddress {
  line1: string;
  line2: string | null;
  city: string;
  country: string;
  postalCode: string;
  state: string;
  phone: string
}
interface PaymentMethodData {
  brand: string;
  last4: string;
  country: string | null
  email: string | null,
  id: string | null
}

export interface IUserPaymentMethod {
  id: string;
  alias?: string
  status: string;
  default: boolean;
  provider: 'stripe';
  type: 'card' | 'paypal',
  data: PaymentMethodData;
}

export interface IUserPlatform { platform: string, username: string, profileImageUrl: string }

export interface IUser {
  id: string;
  alias: string;
  avatar: string;
  banner: string;
  email: string;
  firstName?: string;
  lastName?: string;
  address?: IUserAddress;
  phone?: string;
  paymentMethods: IUserPaymentMethod[];
  platforms: Array<IUserPlatform>
  flags: {
    confirmed: boolean;
    merchantOnboardingCompleted: boolean
  };
  // only merchant for now
  title?: string
  bio?: string
  socials: {
    x?: string,
    instagram?: string
    telegram?: string
    twitch?: string
  }
}

const socialToIcon: Record<keyof IUser['socials'], IconDefinition> = {
  x: faX,
  instagram: faInstagram,
  telegram: faTelegram,
  twitch: faTwitch
}
const socialLinkBuilder = (network: string, username: string) => {
  switch (network) {
    case 'telegram':
      return `https://t.me/${username}`
    case 'x':
      return `https://x.com/${username}`
    case 'instagram':
      return `https://instagram.com/${username}`
    case 'twitch':
      return `https://twitch.tv/${username}`
  }
  return ''
}

export const socialListBuilder = (socials: IUser['socials']) => Object.entries(socials).reduce<any>((obj, [network, username]) => {
  return [
    ...obj,
    { network, username, link: socialLinkBuilder(network, username), icon: socialToIcon[network as keyof IUser['socials']] as IconDefinition }
  ]
}, [])
