import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import { MerchantService } from '../../../api/services/MerchantService';
import { mProductFormStore, mProductListStore_Backup } from './merchant.product.store';


@Injectable()
export class MerchantProductEffects {
  /** List  */
  load$ = createEffect(() => this.actions$.pipe(
    ofType(mProductListStore_Backup.actions.load),
    exhaustMap(({filters, page, pageSize, sortBy }) => this.merchantSvc.getProducts(filters, { page, pageSize, sortBy})
      .pipe(
        map(res => mProductListStore_Backup.actions.loadOK(res)),
        catchError((error) => of(mProductListStore_Backup.actions.loadKO({ error }))))
      )
  ))

  remove$ = createEffect(() => this.actions$.pipe(
    ofType(mProductListStore_Backup.actions.remove),
    exhaustMap(({deletingId}) => this.merchantSvc.deleteProduct(deletingId)
      .pipe(
        map(() => mProductListStore_Backup.actions.removeOK({ deletedId: deletingId})),
        catchError((error) => of(mProductListStore_Backup.actions.removeKO({ error }))))
      )
  ))
  /** Form */
  create$ = createEffect(() => this.actions$.pipe(
    ofType(mProductFormStore.actions.create),
    exhaustMap(({ payload }) => this.merchantSvc.createProduct(payload)
      .pipe(
        map(data => mProductFormStore.actions.createOK({data})),
        catchError((error) => of(mProductFormStore.actions.createKO({ error }))))
      )
  ))

  update$ = createEffect(() => this.actions$.pipe(
    ofType(mProductFormStore.actions.update),
    exhaustMap(({ id, payload }) => this.merchantSvc.updateProduct(id, payload)
      .pipe(
        map(data => mProductFormStore.actions.updateOK({data})),
        catchError((error) => of(mProductFormStore.actions.updateKO({ error }))))
      )
  ))

  loadForm$ = createEffect(() => this.actions$.pipe(
    ofType(mProductFormStore.actions.load),
    exhaustMap(({ id }) => this.merchantSvc.getProduct(id)
      .pipe(
        map(res => mProductFormStore.actions.loadOK(res)),
        catchError((error) => of(mProductFormStore.actions.loadKO({ error }))))
      )
  ))

  constructor(
    private actions$: Actions,
    private merchantSvc: MerchantService
  ) { }
}
