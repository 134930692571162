<app-isolated-layout>
  <section>
    <div class="d-flex justify-content-center">

      <div style="max-width: 450px; width: 100%">
        <div>
          <div class="mb-3">
            <img class="full" src="/assets/logo/net_purple_logo_small.png" height="40" />
          </div>
          <div>
            <div class="my-3">
              <h5 class="card-title">Recupera tu acceso</h5>
              <p>Introduce tu email</p>
            </div>
          </div>
        </div>
        <div class="mt-2 border border-2 p-2 rounded">
          <p>
            Si tu email existe en nuestra base de datos, recibirás instrucciones en tu email.
          </p>
          <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
            <app-form-input placeholder="johndoe@twitchmaster.x" label="Email" controlName="email" [form]="form"
              marginBottom="3" />


            <div *ngIf="apiError" class="mb-4">
              <div class="alert alert-danger" role="alert">
                {{apiError.error.message}}
              </div>
            </div>

            <div *ngIf="success" class="mb-4">
              <div class="alert alert-success" role="alert">
                <strong>Cuenta creada con éxito!</strong>
                Hemos enviado un email para verificar tu cuenta, dirígete a
                <ng-container *ngIf="!suggestedMailboxUrl">tu buzón de correo</ng-container>
                <ng-container *ngIf="suggestedMailboxUrl">
                  <a [href]="suggestedMailboxUrl">buzón de correo</a>
                </ng-container>
                y pincha en el enlace.
              </div>
            </div>


            <div class="d-grid mb-3">
              <button class="btn btn-block btn-primary" [disabled]="isCtaDisabled" type="submit"
                [ngClass]="{loading: isLoading}">
                <ng-container *ngIf="isIdle">Cambiar contraseña</ng-container>
                <ng-container *ngIf="isLoading">
                  <div class="spinner-border  spinner-border-sm" role="status"></div>
                  Restaurando clave
                </ng-container>
                <ng-container *ngIf="success"><fa-icon [icon]="btnSuccessIcon"></fa-icon> Listo!</ng-container>
              </button>
            </div>

          </form>
        </div>
      </div>


    </div>
  </section>

</app-isolated-layout>
