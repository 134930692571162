import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../../auth/AuthService';
import { faTwitch } from '@fortawesome/free-brands-svg-icons'


@Component({
  selector: 'app-twitch-auth-btn',
  templateUrl: './twitch-auth-btn.component.html',
  styleUrls: ['./twitch-auth-btn.component.scss']
})
export class TwitchAuthBtnComponent implements OnInit {
  @Input() returnAfterLogin?:string|null
  @Input({ required: true }) text!: string
  @Input() disabled = false

  constructor(private authService: AuthService) { }

  processingCallback: boolean = false
  twitchIcon = faTwitch
  twitchOauthUrl = ''

  ngOnInit(): void {
    this.twitchOauthUrl = this.authService.getTwitchOauthUrl({ returnAfterLogin: this.returnAfterLogin });
  }



}
