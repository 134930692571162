import { Component } from '@angular/core';
import { userFeatActions } from '../../../store/user.store';
import { AppStore } from '../../../store';

@Component({
  selector: 'app-update-banner-form',
  templateUrl: './update-banner-form.component.html',
  styleUrls: ['./update-banner-form.component.scss']
})
export class UpdateBannerFormComponent {
    constructor(
      private store: AppStore
    ) {}

    refreshUser(){
      this.store.dispatch(userFeatActions.fetchMe())
    }
  }
