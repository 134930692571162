import { Injectable, TemplateRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AppStore } from '../../store';
import { UiState, uiFeat, uiFeatActions } from '../../store/ui.store';
import { take } from 'rxjs';
import { getApiErrorMessage, isApiErrorResponse } from '../../utils/api.utils';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/AuthService';
import { AccountMode } from '../../enums';

export interface Toast {
	template?: TemplateRef<any>;
  text?: string;
	type: "success"|"danger"|"warning"|"info";
	delay?: number;
}

@Injectable({
  providedIn: 'root'
})
export class UiService {
  public isMenuCollapsed = true;
  constructor(private titleSvc: Title, private store: AppStore, private authSvc: AuthService) {

  }

  public closeMenu(){
    this.isMenuCollapsed = true
  }
  public openMenu(){
    this.isMenuCollapsed = true
  }
  public toggleMenu(){
    this.isMenuCollapsed = !this.isMenuCollapsed
  }
  /** Title */
  setTitle(...title: Array<string|null|undefined>){
    const flags = []
    if(!environment.production){
      flags.push('D')
      if(this.authSvc.accountMode === AccountMode.Merchant){
        flags.push('S')
      }
    }
    const flagsPrefix = flags.length === 0 ? '' : `[${flags.join('/')}] `
    this.titleSvc.setTitle(`${flagsPrefix}suass - ${title.filter(v=>!!v).join(' > ')}`)
  }
  setDefaultTitle(){
    this.setTitle('Vende en Twitch easy peasy')
  }

  /** Cookie toast */
  showCookieConsent = false

  /** Toasts */
  toasts: Toast[] = [];

	showToast(toast: Toast) {
		this.toasts.push(toast);
	}
  showErrorToast(toast: Omit<Toast, 'type'> | Error){
    if(toast instanceof HttpErrorResponse){

      this.showToast({
        text: getApiErrorMessage(toast as HttpErrorResponse),
        type: 'danger'
      })
      return
    }
    if(toast instanceof Error){
      this.showToast({
        text: toast.message,
        type: 'danger'
      })
      return
    }
    this.showToast({
      ...toast,
      type: 'danger'
    })
  }

	removeToast(toast: Toast) {
		this.toasts = this.toasts.filter((t) => t !== toast);
	}

	clearToasts() {
		this.toasts.splice(0, this.toasts.length);
	}

  // theme

  get theme$(){
    return this.store.select(uiFeat.selectTheme)
  }

  toggleTheme(): void {
    this.theme$.pipe(take(1)).subscribe(theme=>{
      const newTheme = theme === 'dark' ? 'light' : 'dark'
      this.store.dispatch(uiFeatActions.setTheme({theme: newTheme}))
    })
  }


}
