import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { IUser, IUserPaymentMethod } from '../../models/User';
import { ApiService, IApiRes } from './ApiService';
import { CustomerOrderDto } from '../../models/Order';
import { IAuction } from '../../models/Auction';


export interface BuyConfirmDetailsDto {
  existingOrder?: CustomerOrderDto | null
  deliveryPriceWithTax: string
}

export interface DirectBuyPayloadDto {
  paymentMethodId: string
  productId: string
}

export interface CreateOrderReviewDto {
  orderId: string
  rating: number
  message?:string|null
}

export interface OrderReviewDto {
  id: string
  orderId: string
  customerId: string
  customer:{
    avatar: string
    alias: string
  }
  rating: number
  message?:string|null
  createdAt: number
}

@Injectable({
  providedIn: 'root'
})
export class CustomerService extends ApiService {

  // Payment methods
  getPaymentMethodSetup() {
    return this.post<IApiRes<{ clientSecret: string }>>(
      'customer/payments/payment-method/setup',
      {}
    ).pipe(
      map((res) => res.data)
    );
  }

  getPaymentRequiredAction(paymentId: string) {
    return this.post<IApiRes<{ clientSecret: string }>>(
      `customer/payments/${paymentId}/required-action`,
      {}
    ).pipe(
      map((res) => res.data)
    );
  }

  deletePaymentMethod(paymentMethodId: string): Observable<IUser> {
    return this.delete<IApiRes<IUser>>(`customer/payments/payment-method/${paymentMethodId}`).pipe(
      map((res) => res.data)
    );
  }

  setDefaultPaymentMethod(paymentMethodId: string): Observable<IUser> {
    return this.post<IApiRes<IUser>>(`customer/payments/payment-method/${paymentMethodId}/set-default`, {}).pipe(
      map((res) => res.data)
    );
  }

  confirmPaymentMethod({ setupId }: { setupId: string }): Observable<IUserPaymentMethod> {
    return this.post<IApiRes<IUserPaymentMethod>>('customer/payments/payment-method/confirm', {
      setupId,
    }).pipe(
      map((res) => res.data)
    );
  }

  // Orders
  getOrders(): Observable<CustomerOrderDto[]> {
    return this.get<IApiRes<CustomerOrderDto[]>>('customer/orders',).pipe(
      map((res) => res.data)
    );
  }
  getOrder(id: string): Observable<CustomerOrderDto> {
    return this.get<IApiRes<CustomerOrderDto>>(`customer/orders/${id}`,).pipe(
      map((res) => res.data)
    );
  }

  retryOrderPayment(orderId: string, paymentId: string, paymentMethodId?:string): Observable<any> {
    return this.post<IApiRes<CustomerOrderDto>>(`customer/orders/${orderId}/retry-payment`,{
      paymentId,
      paymentMethodId
    }).pipe(
      map((res) => res.data)
    );
  }
  answerSendLater(orderId:string, answer: 'approve'|'reject'): Observable<CustomerOrderDto>{
    return this.post<IApiRes<CustomerOrderDto>>(`customer/orders/${orderId}/send-later/${answer}`,{
      answer,
    }).pipe(map((res) => res.data));
  }
  markOrderAsDelivered(orderId: string){
    return this.post<IApiRes<CustomerOrderDto>>(`customer/orders/${orderId}/mark-as-delivered`,{})
      .pipe(map((res) => res.data));
  }
  // Auction
  bid(auctionId: string, { amount }: {amount: string}): Observable<IAuction>{
    const idempotencyKey = `${auctionId}:${amount}`
    return this.post<IApiRes<CustomerOrderDto>>(`customer/auctions/${auctionId}/bid`,{
      amount,
      idempotencyKey
    }).pipe(
      map((res) => res.data)
    );
  }

  getAllowedCountries(): Observable<string[]>{
    return this.get<IApiRes<{countries: string[]}>>(`users/onboarding/countries`,).pipe(
      map((res) => res.data.countries)
    );
  }

  getBuyConfirmDetails(productId: string): Observable<BuyConfirmDetailsDto>{
    return this.get<IApiRes<BuyConfirmDetailsDto>>(`customer/shop/confirm/details/${productId}`,).pipe(
      map((res) => res.data)
    );
  }

  directBuy(payload: { productId: string}){
    return this.post<IApiRes<{orderId: string}>>(`customer/shop/confirm`, payload).pipe(
      map((res) => res.data)
    );
  }

  createReview({orderId, ...payload}: CreateOrderReviewDto){
    return this.post<IApiRes<OrderReviewDto>>(`customer/orders/${orderId}/review`, payload).pipe(
      map((res) => res.data)
    );
  }
  getOrderReview(orderId: string): Observable<OrderReviewDto>{
    return this.get<IApiRes<OrderReviewDto>>(`customer/orders/${orderId}/review`).pipe(
      map((res) => res.data)
    );
  }
}
