import { Injectable } from "@angular/core";
import { ListBaseEffects } from "../utils/list.base.effects";
import { pMerchantReviewList } from "./merchant.profile.store";
import { HttpClient } from "@angular/common/http";
import { Actions } from "@ngrx/effects";
import { AppStore } from "../app.store";
import { IAuctionExpanded } from "../../models/Auction";

@Injectable()
export class MerchantReviewListEffects extends ListBaseEffects<IAuctionExpanded> {
  constructor(
    actions$: Actions,
    store: AppStore,
    http: HttpClient
  ) {
    super(pMerchantReviewList, `/public/merchants/:merchantId/reviews`, actions$, store, http)
  }
}
