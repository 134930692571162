<app-page-title title="Mi cuenta" />
<div class="row">
  <div class="col-12 col-md-6 col-lg-6 col-xl-6">
    <div class="mb-3">
      <app-merchant-profile-form />
    </div>
  </div>
  <div class="col-12 col-md-6 col-lg-6 col-xl-6">
    <div class="mb-3">
      <div class="card">
        <div class="card-header">
          Avatar & Banner
        </div>
        <div class="card-body">
          <app-merchant-profile-header [user]="(user$|async)!"/>
          <div class="row align-items-center mt-5">
            <div class="col-6 col-sm-6 col-md-12 col-lg-6">
              <app-update-avatar-form />
            </div>
            <div class="col-6 col-sm-6 col-md-12 col-lg-6">
              <app-update-banner-form />
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="col-9">

  </div>
</div>
