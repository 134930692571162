import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../../auth/AuthService';
import { forkJoin } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { OnboardingStatusDto, UserService } from '../../../api/services/UserService';
import { IUser } from '../../../models/User';
import { UiService } from '../../../ui/services/ui.service';
import { AppStore } from '../../../store';
import { userFeat } from '../../../store/user.store';
import { Subs, createSubs, unsubAll } from '../../../utils/rxjs.utils';
import { AccountMode } from '../../../enums';

@Component({
  selector: 'app-account-page',
  templateUrl: './account-page.component.html',
  styleUrls: ['./account-page.component.scss']
})
export class AccountPageComponent implements OnInit, OnDestroy {

  user: IUser | null = null
  status: OnboardingStatusDto | null = null
  subs$ = createSubs()

  constructor(
    private router: Router,
    private uiCommonSvc: UiService,
    public authSvc: AuthService,
    private route: ActivatedRoute,
    private store: AppStore
  ) { }

  ngOnInit(): void {
    this.uiCommonSvc.setTitle('Mi cuenta')
    this.subs$.user = this.store.select(userFeat.selectUserState).subscribe(({ status, user }) => {
      this.status = status
      this.user = user
      this.setup()
    })
  }

  ngOnDestroy(): void {
    unsubAll(this.subs$)
  }

  private setup() {
    if (!this.user) {
      return
    }

    if (this.accountModeFromPath && this.authSvc.accountMode !== this.accountModeFromPath) {
      this.authSvc.accountMode = this.accountModeFromPath
      return
    }

  }

  get isMerchantMode (){
    return this.authSvc.accountMode === AccountMode.Merchant
  }

  get accountModeFromPath (){

    if(this.router.url.startsWith('/viewer')){
      return AccountMode.Customer
    } else if(this.router.url.startsWith('/streamer')){
      return AccountMode.Merchant
    }

    return null
  }

  get isAccountBasePath() {
    return this.route.snapshot.routeConfig?.path === 'panel' && this.route.snapshot.children.length === 0
  }


}
