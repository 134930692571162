import { createAction, createFeature, createReducer, on, props } from '@ngrx/store';
import { IAuctionExpanded } from '../../models/Auction';


const pAuctionFeatName = 'Auction'

export interface UserState {
  processingBid: string|null
  bidError: any|null
}
const initialState: UserState = {
  processingBid: null,
  bidError: null,
};

// Actions
const prefix = `[${pAuctionFeatName}]`

const bid = createAction(`${prefix} Bid`, props<{ auctionId: string, amount: string }>());
const bidOK = createAction(`${prefix} Bid OK`, props<{ bidderId: string, auctionId: string, amount: string }>());
const bidKO = createAction(`${prefix} Bid KO`, props<{ error: any }>());

// Reducer
const reducer = createReducer<UserState>(
  initialState,
  // bidding
  on(bid, (state, { auctionId})=>({...state, processingBid: auctionId, error: null})),
  on(bidOK, (state)=>({...state, processingBid: null, bidError: null})),
  on(bidKO, (state, error)=>({...state, processingBid: null, bidError: error }))

);

// Feature
export const pAuctionFeat = createFeature({
  name: pAuctionFeatName,
  reducer
})

export const pAuctionFeatActions = {
  bid,
  bidOK,
  bidKO
}
