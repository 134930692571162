import { Component } from '@angular/core';
import { UiService } from '../../services/ui.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  constructor(public uiSvc: UiService){

  }
  socialNetworks = [
    {name:'suass.live', icon: 'instagram.png', url:'https://www.instagram.com/suass.live'},
    {name:'SuassLive', icon: 'x.png', url:'https://x.com/SuassLive'},
    {name:'suass', icon: 'linkedin.png', url:'https://linkedin.com/company/suass'},
    {name: 'suass', icon: 'discord.png', url: 'https://discord.gg/cvM6hmev6z'}
  ]

  payments = [
    {name: 'Visa', id: 'visa'},
    {name: 'MasterCard', id: 'mastercard'},
    {name: 'American Express', id: 'amex'}
  ]
}
