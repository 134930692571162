<div class="sidebar h-100 d-flex flex-column justify-content-between" [ngClass]="sideBarClasses" *ngIf="user" (mouseover)="sideBarClasses.hover=true" (mouseleave)="sideBarClasses.hover=false">
  <a routerLink="/" class="logo">
    <img class="full" src="/assets/logo/net_purple_logo_small.png" height="40" />
    <img class="icon" src="/assets/logo/net_purple_logo_icon.png" height="40" />
  </a>
  <div class="profile d-flex gap-2 py-3 p-lg-2 py-lg-3">
    <div class="photo">
      <img class="rounded img-fluid d-block" [src]="user.avatar" />
    </div>
    <div>
      <div class="welcome-text">Hola, <strong>{{user.alias}}</strong>!</div>
      <a [routerLink]="isStreamerMode ? '/viewer' : '/streamer'" class="form-check form-switch">
        <input class="form-check-input" type="checkbox" role="switch" id="accMode" [checked]="isStreamerMode">
        <label class="form-check-label" for="accMode">Modo streamer</label>
      </a>
    </div>
  </div>

  <nav class="main-nav mt-3 flex-grow-1">

    <a *ngFor="let item of navItems"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{exact: !!item.exact}"
      [routerLink]="item.disabled ? '/' : item.link"
      [class]="item.class || ''"
      [ngClass]="{disabled : item.disabled, 'mt-auto': item.pullEnd }"
      [ngbTooltip]="item.disabled || null" placement="bottom">
      <div class="label">
        <span class="icon">
          <fa-icon [icon]="item.icon" />
        </span>
        <span class="text">{{item.label}}</span>
      </div>
    </a>

  </nav>
</div>


<div class="bottombar" >
  <div class="main-menu d-flex justify-content-center gap-2">


    <nav class="main-nav d-flex justify-content-center">
      <ng-container *ngFor="let item of navItems">

          <a *ngIf="!item.hideSmall" routerLinkActive="active" [routerLinkActiveOptions]="{exact: !!item.exact}"
            [routerLink]="item.disabled ? '/' : item.link" [class]="item.class || ''"
            [ngClass]="{disabled : item.disabled, 'ms-auto': item.pullEnd }"
            [ngbTooltip]="item.disabled || null" placement="bottom">
            <div class="label">
              <span class="icon">
                <fa-icon [icon]="item.icon" />
              </span>
              <span class="text">{{item.label}}</span>
            </div>
          </a>
        </ng-container>

    </nav>

  </div>
</div>
