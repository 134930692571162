import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, switchMap } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/AuthService';
import { IAuctionExpanded } from '../../models/Auction';
import { MerchantWidgetSettings } from '../../models/Order';

export interface IAuctionEvent {
  event: string
  data: IAuctionExpanded
}

@Injectable({
  providedIn: 'root',
})
export class RealTimeService {

  private socket: Socket;
  private connected$ = new BehaviorSubject(true)
  private reconnectInterval: any = null

  constructor(private authSvc: AuthService) {
    this.socket = io(environment.wsApiBaseUrl, {
      auth: this.auth.bind(this),
      transports: ['websocket', 'polling', 'webtransport'],
      withCredentials: true,
      autoConnect: true,
    });

    this.socket.on('connect', () => {
      this.connected$.next(true)
      clearInterval(this.reconnectInterval)
    })

    this.socket.on('disconnect', () => {
      this.reconnectInterval = setInterval(() => {
        this.socket.connect()
      }, 500)
    })
  }

  auth(cb: any) {
    cb({ token: this.authSvc.getToken() });
  }

  get isAuthenticated() {
    return this.authSvc.getToken() !== null;
  }

  subToAuctions(): Observable<IAuctionEvent> {
    return this.buildReconnected$(() => {
      return new Observable<IAuctionEvent>((observer) => {
        const events: any = {
          'auction.started': null,
          'auction.created': null
        }

        this.socket.emit('join.auctions', () => {
          Object.keys(events).forEach(event => {
            events[event] = (data: IAuctionExpanded) => {
              observer.next({ event, data })
            }
            this.socket.on(event, events[event]);
          })
        })

        return () => {
          Object.entries(events).forEach(([event, listener]) => {
            this.socket.off(event, listener as any)
          })
          this.socket.emit('leave.auctions')
        }
      });
    })
  }

  subToAuction(auctionId: string, emitOnConnect = false): Observable<IAuctionEvent> {
    return this.buildReconnected$(() => {
      return new Observable<IAuctionEvent>((observer) => {
        const events = [
          'auction.recreated',
          'auction.started',
          'auction.closed',
          'auction.bid.placed',
          'auction.deleted'
        ]
        this.socket.emit('join.auction', auctionId, emitOnConnect, (data: IAuctionExpanded) => {
          if (emitOnConnect && data) {
            observer.next({ event: 'initial', data })
          }
          events.forEach(event => {
            this.socket.on(`${event}.${auctionId}`, (data: IAuctionExpanded) => {
              observer.next({ event, data })
            });
          })
        })

        return () => {
          events.forEach(event => {
            this.socket.off(`${event}.${auctionId}`)
          })
          this.socket.emit('leave.auction', auctionId)
        }
      })
    });
  }

  subToMerchantAuctions(merchantId: string, emitOnConnect = false): Observable<IAuctionEvent> {
    return this.buildReconnected$(() => {
      return new Observable<IAuctionEvent>((observer) => {
        const events = [
          'auction.created',
          'auction.recreated',
          'auction.started',
          'auction.closed',
          'auction.bid.placed',
          'auction.deleted'
        ]
        this.socket.emit('join.merchant.auction.live', merchantId, emitOnConnect, (data: IAuctionExpanded) => {
          if (emitOnConnect && data) {
            observer.next({ event: 'initial', data });
          }

          events.forEach(event => {
            this.socket.on(`${event}.${merchantId}`, (data: IAuctionExpanded) => {
              observer.next({ event, data });
            });
          })


        })

        return () => {
          events.forEach(event => {
            this.socket.off(`${event}.${merchantId}`)
          })
          this.socket.emit('leave.merchant.auction.live', merchantId)
        }
      });
    });
  }

  subToWidgetSettings(merchantId: string, emitOnConnect: boolean = false): Observable<MerchantWidgetSettings> {
    return this.buildReconnected$(() => {
      return new Observable<MerchantWidgetSettings>((observer) => {
        this.socket.emit('join.auction.widget.settings', merchantId, emitOnConnect, (data: any)=>{
          observer.next(data)
        })
        this.socket.on(`${merchantId}.updated'`, (data) => {
          observer.next(data);
        });

        return () => {
          this.socket.emit('leave.auction.widget.settings', merchantId)
        }
      });
    })
  }

  private buildReconnected$<T>(observableBuilder: () => Observable<T>) {
    return this.connected$.pipe(switchMap(observableBuilder))
  }
}
