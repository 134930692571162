import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IAuctionEvent, RealTimeService } from '../../../api/services/RealTimeService';
import {  IAuctionExpanded } from '../../../models/Auction';
import { MerchantWidgetSettings, WidgetOrientation } from '../../../models/Order';
import { createSubs, unsubAll } from '../../../utils/rxjs.utils';
import { merge, pickBy } from 'lodash';

@Component({
  selector: 'app-auction-widget-page',
  templateUrl: './auction-widget-page.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./auction-widget-page.component.scss']
})
export class AuctionWidgetPageComponent implements OnInit, OnDestroy {

  auction?: IAuctionExpanded
  forcedSettings: Partial<MerchantWidgetSettings> = {}
  settings?: MerchantWidgetSettings
  subs$ = createSubs()


  constructor(
    private route: ActivatedRoute,
    private rtService: RealTimeService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(({ auction, orientation, displayProductPicture, displaySecondToLastBid }) => {

      this.forcedSettings = {
        orientation: Object.values(WidgetOrientation).includes(orientation) ? orientation : undefined,
        displayProductPicture: typeof displayProductPicture === 'string' ? displayProductPicture === '1' : undefined,
        displaySecondToLastBid: typeof displaySecondToLastBid === 'string' ? displaySecondToLastBid === '1' : undefined
      }
      this.loadAuction(auction)
    })
  }

  ngOnDestroy(): void {
    unsubAll(this.subs$)
  }

  loadAuction(auctionId: string) {
    this.subs$.auction =this.rtService.subToAuction(auctionId, true).subscribe(this.onAuctionEvent.bind(this))
  }

  onAuctionEvent({data: auction, event}: IAuctionEvent) {
    this.auction = auction

    if (this.subs$.widget) {
      return
    }

    this.subs$.widget =this.rtService.subToWidgetSettings(auction.merchantId, true).subscribe((settings: MerchantWidgetSettings) => {
      const forcedSettings = pickBy(this.forcedSettings, (v)=>typeof v !== 'undefined')
      this.settings =merge(settings, forcedSettings)
    })
  }

}
