import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IUserAddress } from '../../../models/User';
import { stateCodeToProvince } from '../../../utils/address.utils';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-address-block',
  templateUrl: './address-block.component.html',
  styleUrls: ['./address-block.component.scss']
})
export class AddressBlockComponent {
  @Input({required: true}) address!: IUserAddress & {fullName?:string}
  @Input() title: string  = 'Dirección'
  @Input() editButton: string|null = null
  @Output() onEditRequest = new EventEmitter<boolean>();
  copyIcon = faCopy
  get copy(){
    const { fullName, line1, line2, postalCode, city } = this.address
    return `${fullName ? `${fullName}<br/>` :''}${line1}, ${line2 || ''}<br/>${postalCode}, ${city}, ${this.state}, España`
  }

  get state(){
    return stateCodeToProvince(this.address.state)
  }
}
