import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ApiService, IApiRes } from './ApiService';
import { IUser } from '../../models/User';
import { FullPartial, UndefinedPartial } from '../../../types.util';

export enum MerchantOnboardingTask {
  RequestMerchantApproval = 'request_merchant_approval',
  CompleteMerchantDetails = 'complete_merchant_details',
  ConnectStreamingChannel = 'connect_streaming_channel',
}

export enum CustomerOnboardingTask {
  CompletePersonalDetails = 'complete_personal_details',
  AddPaymentMethod = 'add_payment_method',
  ConnectPlatformAccount = 'connect_platform_account',
}

export interface OnboardingStatusDto {
  customer:{
    tasks: Array<{name: CustomerOnboardingTask, completed: boolean}>,
    completed: boolean
    optional: boolean
  }
  merchant: {
    tasks: Array<{name: MerchantOnboardingTask, completed: boolean}>,
    completed:boolean
    frozen: boolean
    optional: boolean
  }
}
export interface IUpdateUserDto {
  address?: IUser['address'] |null
  lastName?: IUser['lastName' ]|null
  firstName?: IUser['firstName' ]|null
  bio?: IUser['bio' ]|null
  title?: IUser['title' ]|null
  socials?: Partial<IUser['socials']>|null
}

@Injectable({
  providedIn: 'root'
})
export class UserService extends ApiService {
  getOnboardingStatus(){
    return this.get<IApiRes<OnboardingStatusDto>>('users/onboarding/status').pipe(
      map((res) => res.data)
    )
  }

  getConnectPlatformUrl(platform: string) {
    return this.get<IApiRes<{ url: string }>>(`users/connect-platform/${platform}`).pipe(map(res => res.data))
  }

  disconnectPlatform(platform: string) {
    return this.post<IApiRes<IUser>>(`users/disconnect-platform/${platform}`,{}).pipe(map(res => res.data))
  }
  updateUser(user: IUpdateUserDto): Observable<IUser> {
    return this.put<IApiRes<IUser>>(`users`, user).pipe(map((res) => res.data));
  }
}
