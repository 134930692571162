import { Component, Input } from '@angular/core';
import * as merge from 'deepmerge';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexGrid, ApexNonAxisChartSeries, ApexPlotOptions, ApexStroke, ApexTheme, ApexXAxis, ApexYAxis } from 'ng-apexcharts';

export interface Chart {
  series: ApexAxisChartSeries|ApexNonAxisChartSeries,
  grid?: ApexGrid,
  chart: ApexChart
  xaxis: ApexXAxis
  yaxis?: ApexYAxis
  dataLabels?: ApexDataLabels
}

const defChart: Partial<ApexChart>={
  toolbar:{
    show: false
  }
}
const defYaxis: ApexYAxis ={
  labels:{
    show: true
  },
  axisTicks: {
    show: false
  }
}
const defXAxis: ApexXAxis = {axisTicks: {
  show: false
},}
const defDataLabels: ApexDataLabels = {
  'enabled': false
}
@Component({
  selector: 'app-dashboard-chart-box',
  templateUrl: './dashboard-chart-box.component.html',
  styleUrls: ['./dashboard-chart-box.component.scss']
})
export class DashboardChartBoxComponent {

  private _chartOptions!: Chart
  @Input({required: false}) title?: string
  @Input({alias:'chartOptions'}) set chartOptions(value: Chart){
    const def ={
      chart:defChart,
      dataLabels: defDataLabels,
      yaxis: defYaxis,
      xaxis: defXAxis,
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: false
          }
        }
      }
    }
    this._chartOptions = merge(def, value)
  }
  get chartOptions(){
    return this._chartOptions
  }

  plotOptions: ApexPlotOptions = {

  }

  stroke: ApexStroke = {
    curve: 'smooth',
    width: 2
  }

  get dataLabels() {
    return this.chartOptions.dataLabels
  }

  get chart() {
    return this.chartOptions.chart
  }
  get series() {
    return this.chartOptions.series
  }
  get grid() {
    return this.chartOptions.grid
  }

  get xaxis() {
    return this.chartOptions.xaxis
  }


  get yaxis() {
    return this.chartOptions.yaxis
  }
}
