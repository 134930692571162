<tr [ngClass]="{opened}" class="order-header">
  <th class="pointer" (click)="toggle()">
    <div class="d-flex gap-3 align-items-center">
      <fa-icon [icon]="toggleIcon" />
      <div>{{order.createdAt | date: 'dd/M/yyyy'}}</div>
    </div>
  </th>
  <th class="pointer align-middle" (click)="toggle()">
    <app-customer-tag [customer]="order.customer" />
  </th>
  <th class="pointer align-middle" (click)="toggle()">
    <div class="address flex-grow-1" [innerHTML]="customerAddressText"></div>
  </th>
  <th class="align-middle"><app-order-status-label [status]="order.status" /></th>
  <th class="pointer align-middle" [width]="100" (click)="toggle()">
    <div class="align-middle">
      <div class="total justify-content-end d-flex gap-1">
        <div>Artículos</div>
        <b>{{order.items.length}}</b>
      </div>
      <div class="total align-middle justify-content-end d-flex gap-1">
        <div>Total</div> <b>{{prettyAmount}}</b>
      </div>
    </div>
  </th>
  <th [width]="200" class="text-end">

    <!-- <button class="btn btn-outline-secondary" *ngIf="(isBeingCanceled$|async); else actions">
            <app-loading-spinner />
          </button> -->

    <div class="d-flex gap-1 justify-content-end">

      <div ngbDropdown class="btn-group btn-group-sm">

        <button [disabled]="!!(actionsDisabled$|async)" type="button" class="btn btn-sm btn-secondary" (click)="send()"
          *ngIf="canSend()">
          Enviar
        </button>
        <button type="button" class="btn  btn-secondary" ngbDropdownToggle *ngIf="canSendLater()"></button>
        <div ngbDropdownMenu *ngIf="canSendLater()">
          <button [disabled]="!!(actionsDisabled$|async)" ngbDropdownItem (click)="sendLater()">
            Enviar después
          </button>
          <button [disabled]="!!(actionsDisabled$|async)" ngbDropdownItem (click)="getOrderDeliveryLabel()">
            Descargar etiqueta
          </button>
          <button [disabled]="!!(actionsDisabled$|async)" *ngIf="canCancel()" (click)="cancel()" ngbDropdownItem
            class="text-danger">
            Cancelar
          </button>
        </div>
      </div>
      <a class="btn btn-sm btn-secondary" [ngClass]="{'disabled':!!(actionsDisabled$|async)}"
        [routerLink]="!(actionsDisabled$|async) ? '/streamer/pedidos/'+order.id:''">Ver</a>
    </div>
  </th>
</tr>
<tr *ngIf="opened" [ngClass]="{opened}" class="items-row">
  <td colspan="6">
    <table class="table items-table table-hover">
      <thead>
        <tr>
          <th [width]="100">ID</th>
          <th>Articulo</th>
          <th class="text-end">Cantidad</th>
          <th></th>
        </tr>
      </thead>
      <tbody *ngIf="opened">
        <tr *ngFor="let item of order.items" [ngClass]="{'table-warning': itemPaymentIsNotSuccessful(item)}">
          <td class="align-middle">
            <span class="badge text-bg-secondary order-id">{{prettyOrderId}}</span>
          </td>
          <td class="align-middle">
            <div class="d-flex align-items-center gap-2">
              <div class="product-image" [ngStyle]="productImageStyle(item)"></div>
              <div>{{item.description}}</div>
            </div>
          </td>
          <td [width]="100" class="text-end align-middle">
            <div class="d-flex justify-content-end gap-2">
              <s ngbTooltip="Cantidad reembolsada"
                *ngIf="item.refundedAmount && item.refundedAmount !== '0'"><small>{{item.refundedAmount}}{{order.currencySymbol
                  }}</small></s>
              <span>{{itemPrettyAmount(item)}}</span>
            </div>
          </td>
          <td class="text-end align-middle">
            <button [disabled]="!!(actionsDisabled$|async)" *ngIf="canCancelItem(item)" (click)="cancelItem(item)"
              type="button" class="btn btn-link text-danger">
              <ng-container *ngIf="isCancelingOrderItem$(item)|async; else cancelIconTpl">
                <app-loading-spinner />
              </ng-container>
              <ng-template #cancelIconTpl>
                <fa-icon [icon]="cancelIcon" />
              </ng-template>
            </button>
          </td>

        </tr>
      </tbody>

    </table>
  </td>
</tr>
