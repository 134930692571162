<ul class="list-group method-list" [ngClass]="{'list-group-flush': cardEmbedList}">
  <li class="list-group-item" *ngFor="let paymentMethod of paymentMethodList">
    <div class="d-flex gap-2 align-items-center">
      <div class="flex-grow-1">
        <app-payment-method-label [paymentMethod]="paymentMethod" />
      </div>
      <div class="d-flex align-items-center gap-2">
      <span placement="start" class="badge bg-success" *ngIf="paymentMethod.default;else makeDefault">
        <fa-icon [icon]="defaultIcon" />
        <span class="d-none d-sm-inline"> Principal</span>
      </span>
      <ng-template #makeDefault>
        <button class="btn btn-sm btn-link" (click)="setDefaultMethod(paymentMethod)">Hacer principal</button>
      </ng-template>
      <button class="btn btn-sm btn-outline-danger" (click)="deleteMethod(paymentMethod)"><fa-icon
          [icon]="deleteMethodIcon" /></button>
        </div>
    </div>
  </li>
</ul>
<div class="px-3" *ngIf="paymentMethods.length === 0">

  <div  class="alert alert-warning">
    No tienes métodos de pago guardados
  </div>
</div>
