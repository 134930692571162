import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, tap } from 'rxjs';
import { AuthService } from './AuthService';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService){
  }
  get token(){
    return this.authService.getToken()
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let finalReq = request
    if (this.token) {
      finalReq = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${this.token}`)
      });
    }

    return next.handle(finalReq).pipe(
      catchError((res) => {
        if (res instanceof HttpErrorResponse) {
          if(res.error.error?.code === 'INVALID_AUTH_TOKEN'){
              this.authService.setToken('');
          }
        }
        throw res
      })

    );
  }
}

