import { IUserPaymentMethod } from "./User";

export enum OrderStatus {
  Created = 'created',
  PendingPayment = 'pending_payment',
  Paid = 'paid',
  Sent = 'sent',
  AwaitingCustomerDeliveryApproval = 'awaiting_customer_delivery_approval',
  // Pivotal
  PendingDelivery = 'pending_delivery',
  // Final states
  Canceled = 'cancelled',
  Completed = 'completed',
  Refunded = 'refunded' // 100% refunded only
}

export const isCancelable = (status: OrderStatus)=>{
  return [
    OrderStatus.Created,
    OrderStatus.Paid,
    OrderStatus.PendingDelivery,
    OrderStatus.PendingPayment,
    OrderStatus.AwaitingCustomerDeliveryApproval,
  ].includes(status)
}

export const orderAcceptNewItems = (status: OrderStatus) => [OrderStatus.Paid, OrderStatus.PendingPayment].includes(status)

export enum PaymentStatus {
  Pending = 'pending',
  Processing = 'processing',
  RequiresAction = 'requires_action',
  Succeeded = 'succeeded',
  Failed = 'failed'
}
export type CustomerOrderDto = any


export interface IOrderDeliveryDto {
    provider: string
    trackingNumber: string
}

export enum RefundReason {
    RequestedByCustomer = 'requested_by_customer'
}
export interface IRefundDto {
    amount: string
    createdAt: number
    reason: RefundReason
}

export interface IMerchantOrderPaymentDto {
    id:string;
    amount:string;
    currency:string;
    createdAt: number,
    currencySymbol: string
    delivery: IOrderDeliveryDto | null
    paymentMethod: IUserPaymentMethod,
    refunds: IRefundDto[]
}
export type OrderPaymentDto = any
export interface MerchantOrderItemDto {
  id: string
  description: string
  [key: string]: any
}
export interface MerchantOrderDto {
  id: string
  items: MerchantOrderItemDto[]
  [key: string]: any
}


export enum WidgetOrientation {
  Horizontal = 'horizontal',
  Vertical = 'vertical'
}
export interface MerchantWidgetSettings {
  orientation: WidgetOrientation
  displayProductPicture: boolean
  displaySecondToLastBid: boolean
}

export interface MerchantSettingsDto {
  widget: MerchantWidgetSettings
}
