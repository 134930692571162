import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { IUserPaymentMethod } from '../../models/User';
import { MerchantAddOrderDeliveryModalComponent } from '../components/merchant-add-order-delivery-modal/merchant-add-order-delivery-modal.component';
import { MerchantOrderDto } from '../../models/Order';
import { environment } from '../../../environments/environment';
import { BuyConfirmationModalComponent } from '../components/buy-confirmation-modal/buy-confirmation-modal.component';
import { IProduct } from '../../models/Product';

@Injectable({
  providedIn: 'root'
})
export class UiShopService {



  constructor(private modalSvc: NgbModal) {

  }

  openBuyConfirmationModal(product: IProduct): Observable<MerchantOrderDto> {
    const modalRef = this.modalSvc.open(BuyConfirmationModalComponent, {
      centered:true,
      backdrop:'static',
      modalDialogClass:'direct-buy-modal'
    });
    modalRef.componentInstance.product = product
    return modalRef.componentInstance.onSuccess
  }
}
