<div class="d-flex justify-content-center mb-3" *ngIf="hasProducts$|async">
  <div class="flex-grow-1">
    <app-form-input placeholder="¿Qué estás buscando?" [form]="filterForm" controlName="search" />
  </div>
</div>
<app-loading-indicator *ngIf="loading$|async" />
<div *ngIf="!(hasProducts$|async)" class="alert alert-secondary">No hay productos para mostrar</div>
<div class="row">

  <ng-container *ngFor="let product of (products$|async)">
    <div class="col-12 col-sm-6 col-md-4 mb-4">
      <app-shop-product-item [product]="product" />
    </div>
  </ng-container>
</div>
<div class="d-flex justify-content-center mb-5">
  <app-pagination [listStore]="listStore" [autoHideOnePager]="true" />
</div>
