<section>
  <div class="d-flex justify-content-center">

    <div style="max-width: 450px; width: 100%">
      <div>
        <div class="mb-3">
          <img class="full" src="/assets/logo/net_purple_logo_small.png" height="40" />
        </div>
        <div>
          <div class="my-3">
            <h5 class="card-title">Crea tu cuenta</h5>
            <p>Y unéte a nuestra comunidad</p>
          </div>
          <app-twitch-auth-btn text="Crear cuenta con Twitch" />
          <p class="mt-3 oauth-terms-and-condition">Al usar Twitch se creará una cuenta si no la tenías ya y aceptas
            nuestros <a [routerLink]="['/terminos-y-condiciones']" class="text-indigo-600">términos y
              condiciones</a> y <a [routerLink]="['/politica-de-privacidad']" class="text-indigo-600">política de
              privacidad</a> </p>
        </div>
      </div>
      <div class="mt-2 border border-2 p-2 rounded">
        <div><small>Puedes registrarte con tu email</small></div>
        <form [formGroup]="form" (ngSubmit)="onSubmit()" class="mt-3" novalidate>
          <app-form-input placeholder="johndoe@twitchmaster.x" label="Email" controlName="email" [form]="form"
            marginBottom="3" />
          <app-form-input type="password" placeholder="*******" label="Contraseña" controlName="password" [form]="form"
            marginBottom="3" />
          <app-form-input type="password" placeholder="*******" label="Confirmar contraseña"
            controlName="repeatPassword" [form]="form" marginBottom="3" />

          <div class="mb-3">
            <app-form-input type="checkbox" controlName="acceptTerms" [form]="form">
              <small class="text-muted">Acepto <a [routerLink]="['/terminos-y-condiciones']" class="text-indigo-600">los
                  términos y condiciones</a> y <a [routerLink]="['/politica-de-privacidad']" class="text-indigo-600">la
                  política de privacidad</a></small>
            </app-form-input>
          </div>

          <app-form-errors [form]="form" />
          <div *ngIf="apiError" class="mb-4">
            <div class="alert alert-danger" role="alert">
              {{apiError.error.message}}
            </div>
          </div>

          <div *ngIf="success" class="mb-4">
            <div class="alert alert-success" role="alert">
              <strong>Cuenta creada con éxito!</strong>
              Hemos enviado un email para verificar tu cuenta, dirígete a
              <ng-container *ngIf="!suggestedMailboxUrl">tu buzón de correo</ng-container>
              <ng-container *ngIf="suggestedMailboxUrl">
                <a [href]="suggestedMailboxUrl">buzón de correo</a>
              </ng-container>
              y pincha en el enlace.
            </div>
          </div>

          <div class="d-grid mb-3">
            <button class="btn btn-block btn-primary" [disabled]="isCtaDisabled" type="submit"
              [ngClass]="{loading: isLoading}">
              <ng-container *ngIf="isIdle">Crear cuenta</ng-container>
              <ng-container *ngIf="isLoading">
                <div class="spinner-border  spinner-border-sm" role="status"></div>
                Creando cuenta
              </ng-container>
              <ng-container *ngIf="success"><fa-icon [icon]="btnSuccessIcon"></fa-icon> Estamos
                ready!</ng-container>
            </button>
          </div>
          <div>
            <a routerLink="/login" class="btn w-100 btn-outline-secondary">Quiero iniciar sesión</a>
          </div>


        </form>
      </div>
    </div>


  </div>
</section>
