<div class="auction-page">
  <ng-container *ngIf="merchantWithAuctions; else loadingAuction">
    <div class="auction-header d-flex justify-content-between align-items-center">
      <div class="title" [routerLink]="merchantProfileLink">
        <img [src]="merchantWithAuctions.avatar" height="30" class="rounded"/>
        {{ merchantWithAuctions.alias }}
      </div>
      <div class="buttons d-flex gap-2">
        <a class="btn btn-sm btn-outline-secondary" [routerLink]="merchantProfileLink">
          Ver perfil
        </a>
        <button [disabled]="showingChatDisabled" class="btn btn-sm btn-outline-secondary"
          (click)="showChat=!showChat"><fa-icon [icon]="toggleChatIcon"/> {{showChat ? 'Ocultar chat' : 'Ver chat'}}</button>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mb-2" [ngClass]="{'col-xl-9': !this.showChat}">
        <div class="stream-player">
          <app-twitch-embed *ngIf="twitchChannel" [channel]="twitchChannel" [showChat]="showChat" />
        </div>

      </div>

      <!-- Right column -->
      <div class="col-12" [ngClass]="{'col-xl-3': !this.showChat}">
        <div class="row">


          <div class="col-10 col-md-6 mb-3" [ngClass]="{'col-xl-6': this.showChat, 'col-xl-12': !this.showChat}">
            <app-bid-form [bid]="lastBid" [auction]="currentAuction" [forceHorizontal]="this.showChat"/>
          </div>

          <div class="col-2 d-md-none mb-3">
            <button class="btn btn-outline-secondary w-100 h-100" (click)="toggleChatMobile()"><fa-icon [icon]="toggleChatIcon"/></button>
          </div>

          <div class="col-12 col-md-6 mb-3" [ngClass]="{'col-xl-6': this.showChat, 'col-xl-12': !this.showChat}">
            <!-- Time -->
            <div class="mb-1" *ngIf="isAuctionStarted && currentAuction">
              <app-remaining-time-blocks [date]="currentAuction.endsAt" />
            </div>
            <div class="alert mb-0 gap-1 gap-sm-3 h-100 align-items-center d-flex alert-secondary p-1" *ngIf="!isAuctionStarted">
              <span>
                ⏰
              </span>
              <span> <b>{{merchantWithAuctions.alias}}</b> está en directo. La subasta empezará pronto</span>
            </div>
            <!--/ Time -->
          </div>
          <!-- Direct buy -->
          <div class="col-12 col-sm-6 mb-3" [ngClass]="{'col-xl-6': this.showChat, 'col-xl-12': !this.showChat}" *ngIf="(hasProducts$|async)">
            <div class="auctions">
              <div class="d-flex justify-content-between align-items-center">
                <div class="detail-title">
                  Compra directa
                </div>
              </div>
              <div class="auction-list">
                <div class="auction-list-item" *ngFor="let product of (products$|async)">
                  <div class="picture">
                    <img [src]="product.picture.url" class="rounded" />
                  </div>
                  <div class="details">
                    <div class="title">
                      {{product.name}}
                    </div>
                  </div>
                  <div class="ms-auto">
                    <button class="btn btn-outline-primary btn-sm" (click)="buyDirectModal(product)">
                        <fa-icon [icon]="buyIcon"/>
                        {{ product.price | price }}€
                    </button>
                  </div>
                </div>

              </div>


            </div>
          </div>
          <!--/ Direct buy -->
          <!-- Auctions -->
          <div class="col-12 col-sm-6 mb-3" [ngClass]="{'col-xl-6': this.showChat, 'col-xl-12': !this.showChat}">
            <div class="auctions mb-3">
              <div class="d-flex justify-content-between align-items-center">
                <div class="detail-title">
                  Subastando
                </div>
              </div>
              <div class="auction-list">
                <div class="auction-list-item active" *ngIf="currentAuction">
                  <div class="picture">
                    <img [src]="currentAuction.pictureUrl" class="rounded" />
                  </div>
                  <div class="details">
                    <div class="title">{{ currentAuction.title }}</div>
                    <div class="prices">{{ lastBid ? lastBid.amount : currentAuction.startingPrice |price
                      }}{{currentAuction.currencySymbol}}</div>
                  </div>
                  <span class="ms-auto badge text-bg-danger" *ngIf="isAuctionStarted"><small>En directo</small></span>
                  <span class="ms-auto badge text-bg-secondary" *ngIf="isAuctionClosed"><small>Terminada</small></span>
                </div>
                <div class="upcoming mt-2" *ngIf="nextLiveAuctions.length >0">

                  <div class="scroller">
                    <ng-container *ngFor="let nextAuction of nextLiveAuctions">
                      <div class="auction-list-item">
                        <div class="picture">
                          <img [src]="nextAuction.pictureUrl" class="rounded" />
                        </div>
                        <div class="details">
                          <div class="title">{{ nextAuction.title }}</div>
                        </div>
                        <div class="ms-auto">
                          <span
                            class="amount">{{nextAuction.startingPrice}}</span><small>{{nextAuction.currencySymbol}}</small>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>


            </div>

          <!-- Bids -->

            <div class="auctions mb-3">
              <div class="d-flex justify-content-between align-items-center">
                <div class="detail-title">
                  Pujas
                </div>
              </div>
              <div class="alert alert-secondary mb-0" *ngIf="!lastBid">
                {{ isAuctionStarted ? 'Nadie ha pujado' : 'No ha empezado' }}
              </div>
              <div class="auction-list" *ngIf="lastBid">
                <div class="auction-list-item active">
                  <div class="picture">
                    <img [src]="lastBid.avatar" class="rounded" />
                  </div>
                  <div class="details">
                    <div class="title">
                      {{lastBid.alias}}
                    </div>
                    <div class="prices text-primary">
                      <b>{{ lastBid.amount }}<small>{{currentAuction?.currencySymbol}}</small></b>
                    </div>
                  </div>
                  <span class="ms-auto">🏆</span>
                </div>
                <div class="upcoming mt-2" *ngIf="nextLiveAuctions.length >0">

                  <div class="scroller">
                    <ng-container *ngFor="let bid of orderedBids">
                      <div class="auction-list-item">
                        <div class="picture">
                          <img [src]="bid.avatar" class="rounded" />
                        </div>
                        <div class="details">
                          <div class="title">{{ bid.alias }}</div>
                        </div>
                        <div class="ms-auto">
                          <span class="amount">{{bid.amount}}</span><small>{{currentAuction?.currencySymbol}}</small>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>


            </div>

          <!--/ Bids -->
          </div>
          <!--/ Auctions -->





        </div>
        <!-- End Right column -->
      </div>
    </div>
  </ng-container>


  <ng-template #loadingAuction>
    <app-loading-indicator />
  </ng-template>
</div>
