<div class="remaining-time" *ngIf="!compact;else compactTpl" [ngClass]="{highlightText}">
  <div *ngFor="let c of timeComponents" class="flex-grow-1">
    <div class="num">{{c[0]}}</div>
    <div class="label">{{timeComponentLabel(c[1])}}</div>
  </div>
</div>

<ng-template #compactTpl>
  <div class="compact-time">
    <span *ngFor="let c of timeComponents" class="flex-grow-1">
      {{c[0]}}{{timeComponentLabel(c[1], true)}}
    </span>
  </div>
</ng-template>
