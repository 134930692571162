<button *ngIf="options.length === 0; else dropdown" class="btn btn-outline-secondary w-100">
  <app-payment-method-label *ngIf="paymentMethods.length>0 && selectedPaymentMethod"
    [paymentMethod]="selectedPaymentMethod" />
</button>

<ng-template #dropdown>
  <div ngbDropdown class="w-100">
    <button type="button" class="btn w-100 btn-outline-secondary gap-1 d-flex align-items-center" id="dropdownBasic1"
      [ngClass]="{'border-warning border-2': !selectedPaymentMethod}"
      ngbDropdownToggle>
      <ng-container *ngIf="selectedPaymentMethod">
        <app-payment-method-label [paymentMethod]="selectedPaymentMethod" />
        <div class="ms-auto">Cambiar</div>
      </ng-container>
      <div *ngIf="!selectedPaymentMethod" class="flex-grow-1">
        Elige un método de pago
      </div>
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="w-100">
      <ng-container *ngFor="let paymentMethod of options">
        <button ngbDropdownItem (click)="select(paymentMethod)">
          <app-payment-method-label [paymentMethod]="paymentMethod" />
        </button>
      </ng-container>
      <button *ngIf="addEnabled" ngbDropdownItem [disabled]="lock" (click)="openAddMethodModal()">Añadir método</button>
    </div>
  </div>
</ng-template>
<!--
<div class="d-flex justify-content-between align-items-center mb-1">
  <div>{{label}}</div>
  <button *ngIf="addEnabled" class="btn btn-sm btn-outline-primary" [disabled]="lock" (click)="openAddMethodModal()">Añadir método</button>
</div>
<ul class="list-group method-list">
  <li class="list-group-item" *ngFor="let paymentMethod of paymentMethods">
    <div class="top d-flex gap-2 align-items-center">
      <div class="flex-grow-1">
        <app-payment-method-label [paymentMethod]="paymentMethod" />
      </div>
      <button class="btn btn-sm btn-link text-secondary" [disabled]="lock" (click)="select(paymentMethod)">Elegir</button>
    </div>
  </li>
</ul>
 -->
