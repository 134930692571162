import { AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * Creates a validator function that checks if the control's value matches the given regex.
 * @param regex The regex to test the control's value against.
 * @param errorDescription Description for the error message if the control's value does not match the regex.
 * @returns A validator function that returns an error object if the control's value does not match the regex.
 */
export function regexValidator(regex: RegExp, errorDescription: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const isValid = regex.test(control.value);
    return isValid ? null : { 'regexInvalid': { value: control.value, message: errorDescription } };
  };
}
