import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../auth/AuthService';
import { Router } from '@angular/router';
import { AppStore } from '../../../store';
import { authActions } from '../../../store/auth.store';

@Component({
  selector: 'app-logout-page',
  templateUrl: './logout-page.component.html',
  styleUrls: ['./logout-page.component.scss']
})
export class LogoutPageComponent implements OnInit {
  constructor(private router: Router, private appStore:AppStore){

  }
  ngOnInit(){
    this.appStore.dispatch(authActions.logout())
    setTimeout(()=>{
      this.router.navigate(['/login'], {
        onSameUrlNavigation: 'reload'
      })
    }, 1500)
  }
}
