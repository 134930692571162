<app-page-title title="Mercado">
  <div>
    <a class="btn btn-sm btn-secondary" routerLink="/viewer/mercado/vendedores">Ver vendedores</a>
  </div>
</app-page-title>
<div class="mb-3">
  <div class="d-flex gap-3 justify-content-between">
    <div class="flex-grow-1">
      <app-form-input [form]="filterForm" placeholder="¿Qué estás buscando?" controlName="search" />
    </div>
    <app-form-input [form]="filterForm" type="select" controlName="sortBy" [options]="sortByOptions"/>
  </div>
</div>

<div class="mb-3">
  <app-loading-indicator *ngIf="(loading$|async)" />
</div>

<div class="row">
  <ng-container *ngFor="let product of (products$|async)">
    <div class="col-12 col-sm-6 col-md-4 col-xl-3 mb-4">
      <app-shop-product-item [product]="product" [showMerchantHeader]="true" />
    </div>
  </ng-container>
  <div class="d-flex justify-content-center">
    <app-pagination [listStore]="listStore" [autoHideOnePager]="true"/>
  </div>
</div>
