import { CdkDragEnd, CdkDragMove } from '@angular/cdk/drag-drop';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { faAngleDoubleRight, faCheck } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { createSubs, unsubAll } from '../../../utils/rxjs.utils';

enum State {
  Idle = 'idle',
  Processing = 'processing'
}

@Component({
  selector: 'app-slide-button',
  templateUrl: './slide-button.component.html',
  styleUrls: ['./slide-button.component.scss']
})
export class SlideButtonComponent implements OnInit , OnDestroy {
  @Input({required: true}) processingText!: string
  @Input({required: true}) idleText!: string
  @Input({ required: true}) confirmAbovePercent!: number
  @Input({ required: true}) success$!: Observable<boolean>
  @Input({ required: true}) action!:  () => void
  @ViewChild('boundary') boundary!: ElementRef;
  @ViewChild('draggable') draggable!: ElementRef;

  dragPosition = {x: 0, y: 0}
  percentage = 0
  state = State.Idle
  subs$ = createSubs()

  ngOnInit(): void {
    this.subs$.success = this.success$.subscribe(()=>{
      this.reset()
    })
  }

  get isReady(){
    return this.percentage >= this.confirmAbovePercent
  }

  reset(){
    this.state = State.Idle
    this.dragPosition= {x: 0, y: this.dragPosition.y}
    this.percentage = 0
  }
  calcPercentageSlided(pxMoved: number){
    const boundaryWidth = this.boundary.nativeElement.offsetWidth
    const draggableWidth = this.draggable.nativeElement.offsetWidth
    const pxMovementRange = boundaryWidth - draggableWidth
    const percentage = pxMoved / pxMovementRange
    return Math.min(1, percentage)
  }


  onDragEnded(event: CdkDragEnd<any>){
    this.percentage = this.calcPercentageSlided(event.distance.x)
    if(this.isReady){
      this.triggerAction()
    }

  }

  onDragMoved(event: CdkDragMove<any>){
    this.percentage = this.calcPercentageSlided(event.distance.x)
  }
  get helperText(){
    if(!this.isReady){
      return 'Desliza para confirmar'
    }
    if(this.isProcessing){
      return 'Espera un momento'
    }
    return 'Suelta para confirmar'
  }
  private triggerAction(){
    this.state = State.Processing
    this.action()
  }

  get wrapperClasses () {
    return {
      'awaiting': !this.isReady,
      'ready': this.isReady
    }
  }

  get slideBtnStyle () {

    const successRgba = `32, 201, 151, ${this.percentage}`
    const idleRgba = `79, 70, 229, ${this.percentage}`
    const color = this.percentage > 0.5 ? 'white' : 'black'
    return {
      color,
      background: `rgba(${idleRgba})`
    }
  }

  get isProcessing(){
    return this.state === State.Processing
  }

  slideRightIcon = faAngleDoubleRight
  checkIcon = faCheck

  ngOnDestroy(): void {
    unsubAll(this.subs$)
  }
}

