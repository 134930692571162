import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TwitchOauthCb } from '../../../auth/auth.types';
import { UiService } from '../../../ui/services/ui.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  twitchCb: TwitchOauthCb | undefined
  public constructor(private route: ActivatedRoute, private router: Router, private uiCommonSvc: UiService) {
  }

  ngOnInit(): void {
    this.uiCommonSvc.setTitle('Iniciar sesión')
    this.route.data.subscribe(data => {
      if (data.userResolver) {
        this.router.navigate(['/viewer'])
      }
    })
  }
}
