import { Observable, Subscription, concatMap, delay, of, skip } from "rxjs"


export type Subs = Record<string, Subscription>

export const createSubs = (): Subs => ({})

export const unsubAll = (subs: Subs) => {
  Object.entries(subs).forEach(([name, sub]) => {
    sub.unsubscribe()
  })
}

export const minDelay = (minDelay:number)=> {
  return <T=any>(source: Observable<T>) => source.pipe(
    concatMap(value =>
      of(value).pipe(
        delay(minDelay)
      )
    )
  );
}

export const loadingSuccessDelay = (minDelay:number)=> {
  return <T=any>(source: Observable<T>) => source.pipe(
    skip(1),
    concatMap(loading =>{
      if(loading){
        return of(loading)
      }
      return of(loading).pipe(delay(minDelay))
    })
  );
}
