import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../../../api/services/CustomerService';
import { CustomerOrderDto } from '../../../models/Order';

@Component({
  selector: 'app-customer-order-list',
  templateUrl: './customer-order-list.component.html',
  styleUrls: ['./customer-order-list.component.scss']
})
export class CustomerOrderListComponent implements OnInit{
    orders: CustomerOrderDto[] = [];
    constructor(private customerSvc: CustomerService) { }
  
    ngOnInit(): void {
      this.customerSvc.getOrders().subscribe((orders) => {
        this.orders = orders;
      })
    }
}
