import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IAuctionExpanded } from '../../../models/Auction';

@Component({
  selector: 'app-bid-with-action',
  templateUrl: './bid-with-action.component.html',
  styleUrls: ['./bid-with-action.component.scss'],

})
export class BidWithActionComponent  {
  @Input() auction!: IAuctionExpanded;
  @Input() bidTemplate: string|null = null
  @Input() bid!: IAuctionExpanded['bids'][0]




}
