import { Component } from '@angular/core';
import { playgroundStore } from './playground.store';
import { AppStore } from '../../store';
import { Actions, ofType } from '@ngrx/effects';
import { map } from 'rxjs';

@Component({
  selector: 'app-playground',
  templateUrl: './playground.component.html',
  styleUrls: ['./playground.component.scss']
})
export class PlaygroundComponent {
  constructor(private store: AppStore, private actions$: Actions){}
  action(){
    this.store.dispatch(playgroundStore.actions.doIt())
  }
  success$ = this.actions$.pipe(ofType(playgroundStore.actions.ok), map(()=>true))

}
