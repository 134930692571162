import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AppStore } from '../../../../store';
import { userFeat, userFeatActions } from '../../../../store/user.store';
import { map } from 'rxjs';

@Component({
  selector: 'app-merchant-edit-profile',
  templateUrl: './merchant-edit-profile.component.html',
  styleUrls: ['./merchant-edit-profile.component.scss']
})
export class MerchantEditProfileComponent {
  constructor(
    private store: AppStore
  ) {}


  user$ = this.store.select(userFeat.selectUser)
  avatarUrl$ = this.user$.pipe(map(user=>user?.avatar))
  banner$ = this.user$.pipe(map(user=>user?.banner))

  refreshUser(){
    this.store.dispatch(userFeatActions.fetchMe())
  }
}
