<footer>
  <div class="container d-flex flex-column gap-3 align-items-center text-center">
    <div><b>SUASS</b><br/>Solución para ventas en Streaming.</div>
    <div class="d-flex gap-5 justify-content-center">
      <div>
        <h6>Redes sociales</h6>
        <ul id="social-links" class="list-unstyled d-flex gap-3 align-items-center justify-content-center">
          <li *ngFor="let net of socialNetworks">
            <a [href]="net.url" target="_blank">
              <img class="icon" src="/assets/img/social/{{net.icon}}"/>
            </a>
        </ul>
      </div>
      <div>
        <h6>Aceptamos</h6>
        <ul class="list-unstyled d-flex gap-3 align-items-center justify-content-center">
          <li *ngFor="let pay of payments">
            <img class="icon" src="/assets/img/payment-methods/{{pay.id}}.svg" [alt]="pay.name">
        </ul>
      </div>
    </div>
  </div>

  <div class="text-center mb-3">
    <ul class="list-unstyled d-flex justify-content-center gap-3 mb-0">
      <li><a routerLink="/politica-de-cookies">Política de cookies</a></li>
      <li><a routerLink="/politica-de-privacidad">Política de privacidad</a></li>
      <li><a routerLink="/terminos-y-condiciones">Términos y condiciones</a></li>
      <li (click)="uiSvc.toggleTheme()"> {{(uiSvc.theme$|async)==='dark' ? '☀️' :'🌚'}}</li>
    </ul>
  </div>
  <div class="text-center">
    © 2023 suass. Todos los derechos reservados. Contáctanos en sergio@suass.live
  </div>
  <div class="text-center">
    <small>Hecho con ❤️ desde 🇪🇸</small>
  </div>
</footer>
