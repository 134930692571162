import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ICreateAuctionDto } from '../../../api/services/MerchantService';
import { FormControl, FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { IFormInputOption } from '../form-input/form-input.component';
import { AuctionChannel, AuctionTemplate, IAuction, TimeUnit } from '../../../models/Auction';
import { faImage, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { UiService } from '../../services/ui.service';
import { AppStore } from '../../../store';
import { ViewStatus, mAuctionFormFeat, mAuctionFormActions } from '../../../store/merchant.store';
import { map } from 'rxjs';
import { bytesToMegaBytes } from '../../../utils/common.utils';
import { createSubs, unsubAll } from '../../../utils/rxjs.utils';
import { Actions, ofType } from '@ngrx/effects';


const getDateString = (timestamp: number | null = null) => {
  const currentDate = timestamp ? new Date(timestamp) : new Date();
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const day = currentDate.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}

const getTimeString = (timestamp: number | null = null) => {
  const currentDate = timestamp ? new Date(timestamp) : new Date();
  return `${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}`;
}


@Component({
  selector: 'app-auction-form',
  templateUrl: './auction-form.component.html',
  styleUrls: ['./auction-form.component.scss']
})
export class AuctionFormComponent implements OnInit , OnDestroy {
  @Input() auction: IAuction | null = null
  @Input() auctionTemplate: AuctionTemplate | null = null
  @Output() onSuccess = new EventEmitter<IAuction>()
  subs$ = createSubs()
  auxForm = new FormGroup({
    live: new FormControl(false),
    isScheduling:new FormControl(false)
  })
  // products: IProduct[] = []
  form: FormGroup = new FormGroup({
    id: new FormControl(undefined),
    title: new FormControl('', [Validators.required]),
    description: new FormControl('', []),
    startingPrice: new FormControl('', [Validators.required]),
    deliveryPrice: new FormControl(null, [Validators.required]),
    minBidIncrement: new FormControl('1', [Validators.required]),

    durationUnit: new FormControl('s', [Validators.required]),
    duration: new FormControl(30, [Validators.required, Validators.min(1)]),
    extPeriodUnit: new FormControl('s', [Validators.required]),
    extPeriod: new FormControl(10, [Validators.required, Validators.min(1)]),

    startDate: new FormControl(getDateString(), [Validators.required]),
    startTime: new FormControl(getTimeString(), [Validators.required]),

    pictureData: new FormControl('', [Validators.required]),
    pictureDataFrom: new FormControl('', []),


    quantity: new FormControl(1, [Validators.required, Validators.min(1), Validators.max(30)]),
  })
  showTips = false
  addImageIcon = faImage
  helpIcon = faQuestionCircle

  get error$() {
    return this.store.select(mAuctionFormFeat.selectError)
  }

  get loading$() {
    return this.store.select(mAuctionFormFeat.selectStatus).pipe(map(status => status === ViewStatus.Processing))
  }

  constructor(private uiCommonSvc: UiService, private store: AppStore, private actions$: Actions) {
  }

  get isScheduling(){
    return !!this.auxForm.get('isScheduling')?.value
  }

  get isSaveDisabled() {
    return this.form.invalid || this.form.pristine
  }


  get ctaText() {
    return this.loading$.pipe(map(loading => {
      if (loading) {
        return 'Cargando...'
      }
      if (this.isScheduling) {
        return 'Programar subasta'
      }
      return 'Guardar subasta'
    }))
  }

  get fcStartTime(): FormControl {
    return this.form.get('startTime') as any
  }
  set startTime(value: any) {
    this.form.get('startTime')?.setValue(value)
  }


  get pictureSrc() {
    return this.form.get('pictureData')?.value || this.auction?.pictureUrl || this.auctionTemplate?.pictureUrl
  }

  ngOnDestroy(): void {
    unsubAll(this.subs$)
  }

  ngOnInit(): void {
    this.subs$.title = this.form.get('title')!.valueChanges.subscribe(value => {
      this.uiCommonSvc.setTitle(value || 'Subasta')
    })

    this.subs$.isScheduling = this.auxForm.get('isScheduling')!.valueChanges.subscribe(this.onScheduleChange.bind(this))
    this.subs$.isScheduling = this.auxForm.get('live')!.valueChanges.subscribe(this.onLiveChange.bind(this))
    this.subs$.success = this.actions$.pipe(ofType(mAuctionFormActions.auctionFormOk)).subscribe(()=>{
      this.onSuccess.emit()
    })

    this.setupForm()
  }

  private setupForm() {
    const auction = this.auction || this.auctionTemplate
    if (!auction) {
      return
    }
    const id = this.auction?.id
    const pictureDataFrom = this.auctionTemplate?.id
    const val = {
      id,
      title: auction.title,
      description: auction.description,
      startingPrice: auction.startingPrice,
      deliveryPrice: auction.deliveryPrice || null,
      minBidIncrement: auction.minBidIncrement,
      startDate: getDateString(auction.startsAt),
      startTime: getTimeString(auction.startsAt),
      durationUnit: auction.durationUnit,
      duration: auction.duration,
      extPeriodUnit: auction.extPeriodUnit,
      extPeriod: auction.extPeriod,
      pictureData: null,
      pictureDataFrom
    }
    if(auction.channels.includes(AuctionChannel.Live)){
      this.auxForm.get('live')?.setValue(true)
    }
    this.form.patchValue(val)
    this.form.setControl('pictureData', new FormControl('', []))
    if(this.auctionTemplate){
      this.form.markAsDirty()
    }
  }

  onScheduleChange(isScheduling: boolean| null) {
    const cs = ['startTime', 'startDate']
    cs.forEach(name => {
      const control = this.form.get(name)
      isScheduling ? control?.enable() : control?.disable()
    })

    if(isScheduling){
      this.auxForm.get('live')?.patchValue(false)
    }
  }

  onLiveChange(isLive: boolean|null){
    if(isLive){
      this.auxForm.get('isScheduling')?.patchValue(false)
    }
  }
  get hasLiveChannel(){
    return !!this.auxForm.get('live')?.value
  }
  get quantity(){
    return this.form.get('quantity')?.value || 1
  }
  handleUpload(event: Event) {
    const target = event.target as HTMLInputElement;
    if (!target.files || target.files.length !== 1) {
      return
    }
    //for (let i = 0; i < target.files.length; i++) {
    const file = target.files[0]
    if(bytesToMegaBytes(file.size) > 10){
      return alert("Máximo 10MB")
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const data = reader.result?.toString()
      if (data) {
        this.form.get('pictureData')?.setValue(data)
        this.form.get('pictureDataFrom')?.setValue(undefined)
      }
    };

    //}

  }

  preparePayload(form: FormGroup) {
    const { pictureData: pictureDataRaw, startDate, startTime, duration, extPeriod, deliveryPrice, ...otherFields } = form.getRawValue()
    const startsAt = this.isScheduling ? new Date(`${startDate} ${startTime}`).getTime() : null
    const [_prefix, pictureData] = (pictureDataRaw||'').split(';base64,')
    const channels = [
      AuctionChannel.Offline
    ]
    if(this.hasLiveChannel){
      channels.push(AuctionChannel.Live)
    }
    return {
      ...otherFields as any,
      channels,
      pictureData,
      deliveryPrice: (!deliveryPrice || deliveryPrice === '0') ? null : deliveryPrice,
      quantity: otherFields.quantity ? Number(otherFields.quantity) : undefined,
      duration: Number(duration),
      extPeriod: Number(extPeriod),
      startsAt
    }
  }


  save() {
    if (!this.form.valid) {
      return;
    }

    // if (this.auction) {
    //   const auction: IUpdateAuctionDto = preparePayload(this.form)
    //   return this.store.dispatch(mAuctionFormActions.submitAuctionUpdateForm({ auction }))
    // }
    const auction: ICreateAuctionDto = this.preparePayload(this.form)
    return this.store.dispatch(mAuctionFormActions.submitAuctionCreateForm({ auction }))
  }

  timeUnitOptions: IFormInputOption[]= [
    { value: TimeUnit.Seconds, label: 'seg' },
    { value: TimeUnit.Minutes, label: 'min' },
    { value: TimeUnit.Hours, label: 'horas' },
    { value: TimeUnit.Days, label: 'días' }
  ]
}

