import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IProduct } from '../../../models/Product';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MerchantService } from '../../../api/services/MerchantService';
import { faImage, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Observable } from 'rxjs';
import { MerchantOrderDto, RefundReason } from '../../../models/Order';
import BigNumber from 'bignumber.js';

enum State {
  Loading = 'loading',
  Idle = 'idle',
}


@Component({
  selector: 'app-add-order-item-modal',
  templateUrl: './add-order-item-modal.component.html',
  styleUrls: ['./add-order-item-modal.component.scss']
})
export class AddOrderItemModalComponent implements OnInit {
  @Output() onSuccess = new EventEmitter<MerchantOrderDto>()
  @Input({ required: true }) order!: MerchantOrderDto
  @Input({ required: true }) amount: string | null = null
  modalRef!: NgbModalRef
  state = State.Idle
  form = new FormGroup({
    isDeliveryPayment: new FormControl(false),
    netAmount: new FormControl('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    totalAmount: new FormControl('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    description: new FormControl('', {
      validators: [Validators.required],
      nonNullable: true,
    })
  })


  constructor(private merchantSvc: MerchantService) { }

  ngOnInit(): void {
    this.form.get('netAmount')?.valueChanges.subscribe(net => {
      this.form.get('totalAmount')?.setValue(new BigNumber(net).multipliedBy(1.21).toFixed(2), {
        emitEvent: false
      })
    })

    this.form.get('totalAmount')?.valueChanges.subscribe(net => {
      this.form.get('netAmount')?.setValue(new BigNumber(net).dividedBy(1.21).toFixed(2), {
        emitEvent: false
      })
    })
  }

  get isSaveDisabled() {
    return this.state !== State.Idle || this.form.invalid
  }

  get isLoading() {
    return this.state === State.Loading
  }

  get total() {
    const net = this.form.get('netAmount')?.value
    if (!net) {
      return '-'
    }
    return new BigNumber(net).multipliedBy(1.21).toFixed(2)
  }

  onCallSuccess(order: MerchantOrderDto) {
    this.onSuccess.emit(order)
    this.modalRef?.close()
    this.form.reset()
  }
  onCallError(error: any) {
    this.state = State.Idle
  }

  onSubmit() {
    if (!this.form.valid || !this.modalRef || this.state !== State.Idle) {
      return;
    }

    this.state = State.Loading;
    const { netAmount, description, isDeliveryPayment } = this.form.getRawValue()

    const tags = []
    if (isDeliveryPayment) {
      tags.push('delivery')
    }
    this.merchantSvc.addOrderItem(this.order.id, { netAmount, description, tags }).subscribe({
      next: this.onCallSuccess.bind(this),
      error: this.onCallError.bind(this),
    })

  }
}
