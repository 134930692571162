import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IApiPaginationData } from '../api/services/ApiService';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { AppStore } from '../store';
import { createSubs, unsubAll } from '../utils/rxjs.utils';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent  implements OnInit, OnDestroy {
  constructor(private store: AppStore){}

  @Input() autoHideOnePager: boolean = false
  get shouldHide(){
    return this.autoHideOnePager &&  (!this.pagination?.totalPages || this.pagination.totalPages <= 1)
  }
  paginationForm = new FormGroup({
    pageSize: new FormControl(10)
  })
  get pageSizeControl(): FormControl {
    return this.paginationForm.get('pageSize') as any
  }
  @Input() listStore!: any
  pagination?: IApiPaginationData
  paginationList: number[] = []
  get isFirstPage() {
    return this.pagination?.page === 1
  }
  get isLastPage() {
    return this.pagination?.page === this.pagination?.totalPages
  }

  subs$ = createSubs()

  ngOnInit(): void {
    this.subs$.pagination = this.store.select(this.listStore.feat.selectPagination).subscribe((pagination) => {
      if(!pagination){
        return
      }
      this.pagination = pagination as IApiPaginationData
      this.pageSizeControl.setValue(this.pagination.pageSize, {emitEvent: false})
      const pages = []
      const start = Math.max(1, this.pagination.page-3)
      const end = Math.min(this.pagination.totalPages, this.pagination.page+3)
      for (let i = start; i <= end; i++) {
        pages.push(i)
      }
      this.paginationList = pages
    })

    this.pageSizeControl.valueChanges.subscribe(pageSize=>{
      this.store.dispatch(this.listStore.actions.setPagination({pagination: {pageSize}}))
    })
  }

  ngOnDestroy(){
    unsubAll(this.subs$)
  }

  goPage(page:number|null|undefined){
    if(!page){
      return
    }
    this.store.dispatch(this.listStore.actions.setPagination({pagination: {page}}))
  }

  prevIcon = faCaretLeft
  nextIcon = faCaretRight

}
