import { createAction, createFeature, createReducer, createSelector, on, props } from '@ngrx/store';

import { MerchantOrderDto } from '../models/Order';


const merchantFeatName = 'merchant'
const mOrderListFeatName = `${merchantFeatName}OrderList`

export interface MerchantOrderListState {
  filters: any,
  processing: boolean
  cancelingOrderId: string | null;
  refreshingPaymentsOrderId: string | null;
  refundingOrderItemId: { orderId: string, orderItemId: string, amount: string } | null;
  orders: MerchantOrderDto[]
  error: Error | null
  loading: boolean
}

export const orderListInitialState: MerchantOrderListState = {
  filters: {},
  orders: [],
  cancelingOrderId: null,
  refreshingPaymentsOrderId: null,
  refundingOrderItemId: null,
  error: null,
  processing: false,
  loading: false,
}

// Actions
const prefix = `[${merchantFeatName}]`

const loadOrders = createAction(`${prefix} Load merchant orders`, props<{ filters: any }>());
const loadOrdersOK = createAction(`${prefix} Load merchant orders OK`, props<{ orders: MerchantOrderDto[] }>());
const loadOrdersKO = createAction(`${prefix} Load merchant orders KO`, props<{ error: Error }>());

const cancelOrder = createAction(`${prefix} Cancel order`, props<{ orderId: string }>());
const cancelOrderOK = createAction(`${prefix} Cancel order OK`, props<{ order: MerchantOrderDto }>());
const cancelOrderKO = createAction(`${prefix} Cancel order KO`, props<{ error: Error }>());

const refreshOrderPayments = createAction(`${prefix} Refresh order payments`, props<{ orderId: string }>());
const refreshOrderPaymentsOK = createAction(`${prefix} Refresh order payments OK`, props<{ order: MerchantOrderDto }>());
const refreshOrderPaymentsKO = createAction(`${prefix} Refresh order payments KO`, props<{ error: Error }>());

const refundOrderItem = createAction(`${prefix} Refund Order Item`, props<{ orderId: string, orderItemId: string, amount: string }>());
const refundOrderItemOK = createAction(`${prefix} Refund Order Item OK`, props<{ order: MerchantOrderDto }>());
const refundOrderItemKO = createAction(`${prefix} Refund Order Item KO`, props<{ error: Error }>());


// Reducer
const orderListReducer = createReducer(
  orderListInitialState,
  // List Orders
  on(loadOrders, (state, { filters }) => ({ ...state, orders: [], loading: true, cancelingOrderId: null, error: null, filters })),
  on(loadOrdersOK, (state, { orders }) => ({ ...state, orders, cancelingOrderId: null, loading: false })),
  on(loadOrdersKO, (state, { error }) => ({ ...state, error, loading: false })),
  // Cancel Order
  on(cancelOrder, (state, { orderId }) => ({ ...state, cancelingOrderId: orderId, processing: true })),
  on(cancelOrderOK, (state, { order }) => ({
    ...state,
    processing: false,
    cancelingOrderId: null,
    error: null,
    orders: state.orders.map(o => o.id === order.id ? order : o)
  })),
  on(cancelOrderKO, (state, { error }) => ({ ...state, cancelingOrderId: null, processing: false, error })),
  // Refresh Order Payments
  on(refreshOrderPayments, (state, { orderId }) => ({ ...state, refreshingPaymentsOrderId: orderId, processing: true })),
  on(refreshOrderPaymentsOK, (state, { order }) => ({
    ...state,
    processing: false,
    refreshingPaymentsOrderId: null,
    error: null,
    orders: state.orders.map(o => o.id === order.id ? order : o)
  })),
  on(refreshOrderPaymentsKO, (state, { error }) => ({ ...state, refreshingPaymentsOrderId: null, processing: false, error })),
  // Refund Order Item
  on(refundOrderItem, (state, refundingOrderItemId) => ({ ...state, refundingOrderItemId, processing: true })),
  on(refundOrderItemOK, (state, { order }) => ({
    ...state,
    processing: false,
    cancelingOrderId: null,
    refundingOrderItemId: null,
    error: null,
    orders: state.orders.map(o => o.id === order.id ? order : o)
  })),
  on(refundOrderItemKO, (state, { error }) => ({ ...state, refundingOrderItemId: null, processing: false, error })),
);

export const mOrderListFeat = createFeature({
  name: mOrderListFeatName,
  reducer: orderListReducer,
  extraSelectors: ({ selectMerchantOrderListState }) => ({
    processingIds: createSelector(
      selectMerchantOrderListState,
      (state) => {
        const list = []
        if (state.cancelingOrderId) {
          list.push(state.cancelingOrderId)
        }
        if (state.refundingOrderItemId) {
          const { orderId, orderItemId } = state.refundingOrderItemId
          list.push(orderId, orderItemId)
        }
        return list
      }
    )
  })
})

export const mOrderListActions = {
  loadOrders,
  loadOrdersOK,
  loadOrdersKO,

  cancelOrder,
  cancelOrderOK,
  cancelOrderKO,

  refreshOrderPayments,
  refreshOrderPaymentsOK,
  refreshOrderPaymentsKO,

  refundOrderItem,
  refundOrderItemOK,
  refundOrderItemKO
}
