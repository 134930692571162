import { Component } from '@angular/core';
import { AppStore } from '../../../../store';
import { pMerchantAuctionList, pMerchantProfile } from '../../../../store/public/merchant.profile.store';
import { map } from 'rxjs';

@Component({
  selector: 'app-merchant-profile-auctions-page',
  templateUrl: './merchant-profile-auctions-page.component.html',
  styleUrls: ['./merchant-profile-auctions-page.component.scss']
})
export class MerchantProfileAuctionsPageComponent {
  constructor(
    private store: AppStore
  ) {}

  profile$ = this.store.select(pMerchantProfile.feat.selectData)
  auctions$ = this.store.select(pMerchantAuctionList.feat.selectData)
  loading$ = this.store.select(pMerchantAuctionList.feat.selectLoading)
  hasAuctions$ = this.auctions$.pipe(map(auctions=>auctions.length >0 ))


  listStore = pMerchantAuctionList

  ngOnInit(): void {
    this.profile$.subscribe((profile)=>{
      if(!profile){
        return
      }
      this.store.dispatch(pMerchantAuctionList.actions.load({
        params:{
          merchantId: profile.user.id
        }
      }))
    })
  }

}
