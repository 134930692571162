import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuctionListingDto, PublicService } from '../../../api/services/PublicService';
import { UiService } from '../../../ui/services/ui.service';
import { FormControl, FormGroup } from '@angular/forms';
import { createSubs, unsubAll } from '../../../utils/rxjs.utils';
import { RealTimeService } from '../../../api/services/RealTimeService';
import { AuctionChannel, AuctionState } from '../../../models/Auction';

@Component({
  selector: 'app-auction-list-page',
  templateUrl: './auction-list-page.component.html',
  styleUrls: ['./auction-list-page.component.scss']
})
export class AuctionListPageComponent implements OnInit, OnDestroy {
  data: AuctionListingDto | null = null
  listForm = new FormGroup({
    isFastBiddingEnabled: new FormControl(false)
  })
  subs$ = createSubs()
  constructor(
    private publicSvc: PublicService,
    private rtSvc: RealTimeService,
    private uiSvc: UiService
  ) { }
  get noData() {
    return this.data?.liveMerchants.length === 0
      && this.data?.upcomingAuctions.length === 0
      && this.data?.offlineStartedAuctions.length === 0
  }
  get fcIsFastBiddingEnabled(){
    return this.listForm.get('isFastBiddingEnabled')!
  }
  get isFastBiddingEnabled() {
    return !!this.fcIsFastBiddingEnabled.value
  }

  ngOnInit(): void {
    this.uiSvc.setTitle(`Subastas`)
    this.fcIsFastBiddingEnabled.valueChanges.subscribe((enabled)=>{
      localStorage.setItem('isFastBiddingEnabled', enabled ? '1': '0')
    })
    const stored = localStorage.getItem('isFastBiddingEnabled') === '1'
    this.fcIsFastBiddingEnabled.setValue(stored)
    this.publicSvc.getAuctionListing().subscribe((data) => {
      this.data = data

      this.subs$.auctionUpdates = this.rtSvc.subToAuctions().subscribe(({event, data: auction})=>{
        if(auction.state === AuctionState.Started){
          if(!auction.channels.includes(AuctionChannel.Live)){
            this.data?.offlineStartedAuctions.push(auction)
          }
        }
      })
    })
  }

  ngOnDestroy(): void {
    unsubAll(this.subs$)
  }

}
