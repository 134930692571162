import { HttpErrorResponse } from "@angular/common/http";
import { pickBy } from "lodash";
import { Observable, debounceTime, merge, of, take, timer } from "rxjs";

export const prettyId = (richUuid: string) => {
  const [prefix, uuid] = richUuid.split('_')

  return (uuid || prefix).slice(0, 8)
}

export const bytesToMegaBytes = (bytes: number) => bytes / (1024 * 1024)

export function remainingSeconds(targetDate: number) {
  const now = new Date().getTime();
  const timeRemaining = targetDate - now;
  return timeRemaining / 1000
}

export function remainingTimeStr(targetDate: number) {
  const now = new Date().getTime();
  const timeRemaining = targetDate - now;

  if (timeRemaining <= 0) {
    return 'done'
  }
  let hours = Math.floor(timeRemaining / (1000 * 60 * 60));
  const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);
  let days = hours > 23 ? Math.floor(hours / 24) : 0
  if (days > 0) {
    hours = hours % 24
  }

  const data: Array<[number, string]> = [[hours, 'h'], [minutes, 'm'], [seconds, 's']]
  let nums: Array<string> = data.map(([n, label]) => `${n} ${label}`)
  if (days > 0) {
    nums = [`${days} dias`, ...nums]
  }
  return nums.join(' ')

}

interface RemainingTimePartsOpts {
  inverse?: boolean
  skipLeadingZeros?: boolean,
  maxComponents?: number
}
export type TimeComponents = Array<[number, string]>
export function remainingTimeParts(targetDate: number | Date, { inverse, skipLeadingZeros, maxComponents }: RemainingTimePartsOpts = {}): Array<[number, string]> {
  const now = new Date().getTime();
  const targetDateParsed = (targetDate instanceof Date ? targetDate.getTime() : targetDate)
  const timeRemaining = inverse ? now - targetDateParsed : targetDateParsed - now;

  if (timeRemaining <= 0) {
    return []
  }
  let hours = Math.floor(timeRemaining / (1000 * 60 * 60));
  const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);
  let days = hours > 23 ? Math.floor(hours / 24) : 0
  if (days > 0) {
    hours = hours % 24
  }

  let data: Array<[number, string]> = [[hours, 'h'], [minutes, 'm'], [seconds, 's']]
  if (days > 0) {
    data = [[days, 'd'], ...data]
  }
  if (skipLeadingZeros) {
    data = data.filter((c, index) => (index === data.length - 1) || c[0] !== 0)
  }
  if (maxComponents) {
    data = data.slice(0, maxComponents)
  }
  return data

}
export function padNum(n: number) {
  return n.toString().padStart(2, '0')
}


export const delayedObservable = <T = any>(observable: Observable<T>, minDelay: number) => {
  const timerObservable = timer(minDelay);
  return merge(observable, timerObservable).pipe(
    debounceTime(minDelay)
  );
};

export const apiErrorToErrorParams = (error: HttpErrorResponse) => {
  const res = error.error
  if (res.error) {
    const { code, message } = res.error.error
    return message || code
  }
  return 'Error desconocido'
}


export const extractApiErrorCodeAndArgs = (error: HttpErrorResponse) => {

  const res = error.error

  if (res.error) {
    const { code, args } = res.error
    return { code, args }
  }

  return {
    code: 'UNKNOWN',
    args: {}
  }

}



export const objectToQueryParam = (obj: any, prefix = ''): string => {
  obj = pickBy(obj, (v)=>typeof v !== 'undefined')
  let queryParts = [];

  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      let value = obj[key];
      let paramKey = prefix ? `${prefix}[${key}]` : key;

      if (Array.isArray(value)) {
        value.forEach(item => {
          queryParts.push(`${encodeURIComponent(paramKey)}[]=${encodeURIComponent(item)}`);
        });
      }
      else if (typeof value === 'object' && value !== null) {
        queryParts.push(objectToQueryParam(value, paramKey));
      } else {
        queryParts.push(`${encodeURIComponent(paramKey)}=${encodeURIComponent(value)}`);
      }
    }
  }

  return queryParts.join('&');
}

export enum SortDir {
  Desc = 'desc',
  Asc = 'asc'
}
export const sortArrayBy = (fieldName: string, direction: SortDir = SortDir.Desc) => {
  const factor = direction === SortDir.Asc ? 1 : -1
  return (a: any, b: any) => {
    const valueA = a[fieldName], valueB = b[fieldName];
    if (valueA < valueB) return -factor;
    if (valueA > valueB) return factor;
    return 0;
  }
}

export function nearestDate(theDate: Date): Date {
  const day = new Date(theDate)
  day.setHours(0);
  day.setMinutes(0);
  day.setSeconds(0);
  day.setMilliseconds(0);

  return day

}


export const launchIntoFullscreen = (element: any, callback?: () => void) => {
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen();
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen();
  }
  if (callback) {
    if (document.fullscreenElement) {
      callback()
    } else {
      document.addEventListener('fullscreenchange', () => {
        callback()
      }, { once: true })
    }
  }
}

export const exitFullscreen = (callback?: () => void) => {
  const d: any = document
  if (d.exitFullscreen) {
    d.exitFullscreen();
  } else if (d.mozCancelFullScreen) {
    d.mozCancelFullScreen();
  } else if (d.webkitExitFullscreen) {
    d.webkitExitFullscreen();
  }
  if (callback) {
    if (!document.fullscreenElement) {
      callback()
    } else {
      document.addEventListener('fullscreenchange', () => {
        callback()
      }, { once: true })
    }
  }
}

export const capitalizeFirstLetter = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1);
