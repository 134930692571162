import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, of } from 'rxjs';
import { map, exhaustMap, catchError, delay } from 'rxjs/operators';
import { AuthService } from '../auth/AuthService';
import { authActions } from './auth.store';
import { userFeatActions } from './user.store';
import { UserService } from '../api/services/UserService';
import { setUserTracked } from '../../tracker';

@Injectable()
export class UserEffects {

  setUserAfterAuthenticate$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.authenticateOK),
    map(()=>userFeatActions.fetchMe())
  ))

  onLogout$  = createEffect(() => this.actions$.pipe(
    ofType(authActions.logout),
    map(()=>userFeatActions.reset())
  ))

  onUserFetchMe$ = createEffect(() => this.actions$.pipe(
    ofType(userFeatActions.fetchMe),
    exhaustMap(() => forkJoin({ user: this.authSvc.me(), status: this.userSvc.getOnboardingStatus() })
      .pipe(
        map(({user, status}) => {
          setUserTracked(user?.id)
          return userFeatActions.fetchMeOK({ user, status })
        }),
        catchError((error) => {
          setUserTracked(undefined)
          return of(userFeatActions.fetchMeKO({ error }))
        }))
      )
  ))

  userUpdate$ = createEffect(() => this.actions$.pipe(
    ofType(userFeatActions.update),
    exhaustMap(({ data }) => this.userSvc.updateUser(data)
      .pipe(
        map((user) => {
          return userFeatActions.updateOK({ user })
        }),
        catchError((error) => {
          return of(userFeatActions.updateKO({ error }))
        }))
      )
  ))

  constructor(
    private actions$: Actions,
    private authSvc: AuthService,
    private userSvc: UserService
  ) { }
}
