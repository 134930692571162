import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MerchantService } from '../../../api/services/MerchantService';
import { MerchantOrderDto } from '../../../models/Order';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { prettyId } from '../../../utils/common.utils';

@Component({
  selector: 'app-merchant-add-order-delivery-modal',
  templateUrl: './merchant-add-order-delivery-modal.component.html',
  styleUrls: ['./merchant-add-order-delivery-modal.component.scss']
})
export class MerchantAddOrderDeliveryModalComponent implements OnInit {
  @Output() onSuccess = new EventEmitter<MerchantOrderDto>()
  @Input({ required: true }) orderId!: string
  @Input() sendLater = false
  order: MerchantOrderDto | null = null
  review = false
  error: any
  constructor(public activeModal: NgbActiveModal, private merchantSvc: MerchantService) { }

  get reviewLabel() {
    const n = this.order?.items.length || 0
    return this.review ? 'Cerrar' : `Revisar ${n} artículo ${n > 1 ? 's' : ''}`
  }

  onDeliveryInfoSaved(order: MerchantOrderDto) {
    this.onSuccess.emit(order)
    this.activeModal.close()
  }

  submitSendLater() {
    if(!this.order){
      return
    }
    return this.merchantSvc.setOrderSendLater(this.order.id).subscribe({
      next: order => {
        this.onSuccess.emit(order)
        this.activeModal.close()
      },
      error: (error) => {
        this.error = error
      }

    })
  }

  ngOnInit(): void {
    this.merchantSvc.getOrder(this.orderId).subscribe(order => {
      this.order = order
    })
  }


  prettyOrderId(id: string) {
    return prettyId(id)
  }
}
