import { Component, Input } from '@angular/core';
import {faStripe} from '@fortawesome/free-brands-svg-icons'
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-payment-vendor-link',
  templateUrl: './payment-vendor-link.component.html',
  styleUrls: ['./payment-vendor-link.component.scss']
})
export class PaymentVendorLinkComponent {
  @Input() payment: any

  stripeIcon = faStripe

  get link(){
    const parts = ['https://dashboard.stripe.com/']
    if(!environment.production){
      parts.push('test/')
    }
    parts.push('payments/')
    return [...parts, this.payment.providerData.id].join('')
  }
}
