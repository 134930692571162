<div class="alert alert-info" *ngIf="orders.length === 0;else orderTable">No tienes pedidos aún</div>
<ng-template #orderTable>
  <div class="table-responsive table-bottom-spacing">

    <table class="table table-striped table-sm table-hover">
      <thead>
        <tr>
          <th>Vendedor/ID</th>
          <th>Estado</th>
          <th>Fecha</th>
          <th>Total</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr app-customer-order-list-item *ngFor="let order of orders"
          [order]="order"></tr>
      </tbody>
    </table>
  </div>
</ng-template>
