import { Component, Input } from '@angular/core';
import { IUser } from '../../../models/User';
import { UserService } from '../../../api/services/UserService';
import { faPlug, faPlugCircleXmark, faQuestionCircle, faRefresh } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-customer-account-platforms',
  templateUrl: './customer-account-platforms.component.html',
  styleUrls: ['./customer-account-platforms.component.scss']
})
export class CustomerAccountPlatformsComponent {

  constructor(private userSvc: UserService){}

  @Input({required: true}) user!: IUser
  iconHelp = faQuestionCircle
  iconUpdate = faRefresh
  iconDisconnect = faPlugCircleXmark
  loading = false;

  get noPlatforms(){
    return this.user.platforms.length === 0
  }

  disconnectTwitch(){
    this.loading = true
    this.userSvc.disconnectPlatform('twitch').subscribe(user=>{
      this.user = user
      this.loading = false
    })
  }
  connectTwitch(){
    this.loading = true
    this.userSvc.getConnectPlatformUrl('twitch').subscribe({
      next:({url})=> window.location.href = url,
      error:(err)=> this.loading = false,
    })
  }
}
