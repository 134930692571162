<div class="table-responsive table-bottom-spacing">

  <table class="table w-100">
    <thead>
      <tr>
        <th>Fecha</th>
        <th>Cliente</th>
        <th>Dirección</th>
        <th>Estado</th>
        <th class="text-end">Resumen</th>
        <th class="text-end">Acciones</th>
      </tr>
    </thead>
    <tbody app-merchant-order-list-item class="w-100" *ngFor="let order of orders" [order]="order">
    </tbody>

  </table>
</div>
