<app-page-title title="Vendedores">
  <div>
    <a class="btn btn-sm btn-secondary" routerLink="/viewer/mercado">Ver todo</a>
  </div>
</app-page-title>
<app-loading-indicator *ngIf="loading$|async" />
<div class="row">
  <ng-container *ngFor="let profile of (data$|async)">
    <div class="col-12 col-md-6 col-lg-4 mb-3">
      <app-merchant-profile-card [profile]="profile" />
    </div>
  </ng-container>
</div>
