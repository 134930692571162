import { Component, OnInit } from '@angular/core';
import { loadTracker } from '../tracker';
import { UiService } from './ui/services/ui.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(public uiSvc: UiService, private router: Router) {

  }
  ngOnInit(): void {
    loadTracker()
    this.uiSvc.theme$.subscribe(theme => {
      document.querySelector('html')?.setAttribute('data-bs-theme', theme)
    })
    //this.uiSvc.toggleTheme()
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.uiSvc.closeMenu()
      }
    })

    this.uiSvc.showCookieConsent = true

  }

}
