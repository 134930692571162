<!-- Social -->
<div class="card">
  <div class="card-header">Redes sociales</div>
  <div class="card-body">
    <div class="alert alert-secondary p-2">
      Nombre de usuario, sin url ni el @
    </div>

      <div class="d-flex flex-column gap-2">
        <ng-container *ngFor="let platform of socialPlatforms">
          <app-form-input [iconPrefix]="platform.icon" [controlName]="platform.name" [form]="form" />
        </ng-container>
      </div>
  </div>
  <div class="card-footer text-end">
    <button class="btn btn-outline-primary">Guardar</button>
  </div>
</div>
<!-- / Social -->
