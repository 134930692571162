<td class="align-middle" [routerLink]="['/viewer/cuenta/pedidos/',order.id]" >
  <div class="merchant">
    <img class="image" [src]="order.merchant.logoUrl" alt="Logo de {{order.merchant.name}}">
    <div class="info">
      <div class="title">{{order.merchant.name}}</div>
      <div class="platform">{{prettyOrderId}}</div>
    </div>
  </div>
</td>
<td class="align-middle"[routerLink]="['/viewer/cuenta/pedidos/',order.id]" ><app-order-status-label [status]="order.status" /></td>
<td class="align-middle"[routerLink]="['/viewer/cuenta/pedidos/',order.id]" >
  <div>
    <span>{{order.createdAt | date: 'dd/M/yyyy'}}</span>
  </div>
  <div>
    <span>{{order.createdAt | date: 'HH:mm'}}</span>
  </div>
</td>
<td class="align-middle" [routerLink]="['/viewer/cuenta/pedidos/',order.id]" ><span>{{prettyAmount}}</span></td>

<td class="align-middle">
  <div class="d-flex gap-1">
    <button class="btn btn-outline-primary" (click)="markAsCompleted()" *ngIf="order.status === 'sent'">Lo he recibido</button>
    <button class="btn btn-outline-primary" (click)="review()" *ngIf="order.status === 'completed'">Valoración</button>
    <a class="btn btn-outline-primary" routerLink="/viewer/cuenta/pedidos/{{order.id}}"> <fa-icon [icon]="viewMoreIcon" /></a>
  </div>
</td>
