<app-page-title title="Productos" />
<app-datatable
  [columns]="columns"
  [rowComponent]="rowComponent"
  [listStore]="store"
  [dataSelector]="store.feat.selectData"
  [loadingSelector]="store.feat.selectLoading"
  [loadData]="store.actions.load"
  [paginationSelector]="store.feat.selectPagination">
  <a routerLink="crear" class="btn btn-primary">Crear producto</a>
</app-datatable>
