<div class="modal-header">
  <h5 class="modal-title" id="modal-basic-title">
    <ng-container *ngIf="!orderId">Detalles compra</ng-container>
  </h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>

</div>
<div *ngIf="orderId" class="modal-body pt-0">
  <div class="px-5">
    <app-fancy-icon-buy-success />
  </div>
  <div class="text-center">
    <h5 class="text-primary">Compra completada!</h5>
    <p>Muchas gracias por tu pedido!</p>
    <a class="w-100 btn btn-lg btn-primary mb-2" (click)="activeModal.close()" routerLink="/viewer/cuenta/pedidos/{{orderId}}">Ver
      pedido</a>
    <button class="btn btn-lg w-100 btn-secondary" (click)="activeModal.dismiss('Cross click')">Cerrar ventana</button>
  </div>
</div>
<div *ngIf="!confirmDetails" class="modal-body text-center">
  <app-loading-indicator/>
</div>
<ng-container *ngIf="confirmDetails">
  <div (ngSubmit)="save()" *ngIf="!orderId">
    <div *ngIf="order" class="modal-body">
      <div class="alert alert-secondary p-1">
        Este producto se añadirá en el pedido que tienes pendiente con
        <img [src]="order.merchant.logoUrl" height="20" class="rounded" /> {{order.merchant.name}}.
      </div>
    </div>
    <table class="table table-sm">
      <tbody>
        <tr>
          <td class="text-center"><img [src]="product.picture.url" height="50" /></td>
          <td class="align-middle">{{product.name}}</td>
          <td class="align-middle text-end">{{product.price}}€</td>
        </tr>
        <tr *ngIf="deliveryPrice">
          <td class="align-middle"></td>
          <td class="align-middle">Gastos de envío</td>
          <td class="align-middle text-end">
              {{deliveryPrice}}€
          </td>
        </tr>
        <tr *ngIf="deliveryPrice">
          <td class="align-middle"></td>
          <td class="align-middle text-end"><b>Total</b></td>
          <td class="align-middle text-end">
              <b>{{total}}€</b>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="modal-body">
      <app-api-error-block [error]="error$|async" />
      <h6>Método de pago</h6>
      <app-select-payment-method  [control]="paymentMethodControl" [addEnabled]="true" [autoSelectDefault]="true" [paymentMethods]="(paymentMethods$|async)||[]"/>
    </div>
    <div class="modal-footer">
      <button (click)="save()" [disabled]="isSaveDisabled" type="submit" class="btn w-100 btn-lg btn-primary">
        <ng-container *ngIf="(loading$|async)">
          <app-loading-spinner />
          Procesando
        </ng-container>
        <ng-container *ngIf="!(loading$|async)">
          Realizar compra
        </ng-container>
      </button>
    </div>
  </div>
</ng-container>
