import { Component, EventEmitter, Input, Output } from '@angular/core';
import { delayedObservable } from '../../../utils/common.utils';


export enum BtnAsyncState {
  Idle = 'idle',
  Loading = 'loading'
}


type BtnAsyncAction = (() => any)

@Component({
  selector: 'app-btn-async',
  template: `
    <button class="btn {{classes}}" type="button"
      [disabled]="isDisabled"
      (click)="onClick()">
        <ng-container *ngIf="!isLoading; else notLoadingText">
          {{ submitText }}
        </ng-container>
        <ng-template #notLoadingText>
          <app-loading-spinner/> {{ loadingText }}
        </ng-template>
    </button>

  `,
  styleUrls: ['./btn-async.component.scss']
})
export class BtnAsyncComponent {
  _state: BtnAsyncState = BtnAsyncState.Idle
  @Input() classes = 'btn-primary'
  @Input() disabled = false
  @Input({ required: true }) submitText!: string
  @Input({ required: true }) loadingText!: string
  @Input() action!: BtnAsyncAction
  @Output() onSuccess = new EventEmitter<any>
  @Output() onError = new EventEmitter<any>
  @Output() onStateChange = new EventEmitter<any>

  constructor() { }

  get state() {
    return this._state
  }

  set state(value: BtnAsyncState) {
    this._state = value
    this.onStateChange.emit(value)
  }

  get isLoading() {
    return this.state === BtnAsyncState.Loading
  }

  get isDisabled() {
    return this.disabled || this.isLoading
  }

  onSuccessHandler(res: any) {
    this.state = BtnAsyncState.Idle
    if (this.onSuccess) {
      this.onSuccess.emit(res)
    }
  }

  onErrorHandler(res: any) {
    this.state = BtnAsyncState.Idle

    if (this.onError) {
      this.onError.emit(res)
    }
  }

  async onClick() {
    let observable = this.action()

    if (!observable) {
      this.state = BtnAsyncState.Idle
      return
    }

    this.state = BtnAsyncState.Loading
    if (observable instanceof Promise) {
      try {
        observable = await observable
      } catch (error) {
        return this.onErrorHandler(error)
      }
    }

    return observable.subscribe({
      next: this.onSuccessHandler.bind(this),
      error: this.onErrorHandler.bind(this),
    })
  }
}
