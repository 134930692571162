<app-page-title title="Subastas">
  <form class="form-check form-switch pro-mode-switch" [formGroup]="listForm">
    <input class="form-check-input" type="checkbox" formControlName="isFastBiddingEnabled" id="FastBidding">
    <label class="form-check-label" [ngClass]="{enabled: isFastBiddingEnabled}" for="FastBidding">Puja rapida</label>
  </form>
</app-page-title>

<div *ngIf="!data;">
  <app-loading-indicator />
</div>

<ng-container *ngIf="data">
  <div class="row">

    <ng-container *ngFor="let merchant of data.liveMerchants">
      <div class="mb-4 col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12" *ngIf="merchant.auctions.length > 0">
        <app-auction-wm-list-item [merchant]="merchant" [enableBiding]="isFastBiddingEnabled" />
      </div>

    </ng-container>
    <ng-container *ngFor="let auction of data.offlineStartedAuctions">
      <div class="mb-4 col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12">
        <app-auction-wm-list-item [compact]="true" [auction]="auction" [enableBiding]="isFastBiddingEnabled" />
      </div>

    </ng-container>
  </div>

  <ng-container *ngIf="data.upcomingAuctions && data.upcomingAuctions.length > 0">
    <h6 class="my-3">Próximamente</h6>
    <div class="row">
      <ng-container *ngFor="let auction of data.upcomingAuctions">
        <div class="mb-4 col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12">
          <app-auction-wm-list-item [compact]="true" [auction]="auction" [enableBiding]="isFastBiddingEnabled" />
        </div>

      </ng-container>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="noData">
  <div class="row align-items-center justify-content-center">
    <div class="col-12 col-sm-4 col-lg-5 col-xl-3">
      <app-fancy-icon-desert />
    </div>
    <div class="col-12 col-sm-8 col-lg-7 col-xl-4">
      <h5>No encontramos nada 😔</h5>
      <p>No hay ninguna subasta activa por el momento, vuelve más tarde!</p>
    </div>
  </div>
</ng-container>
