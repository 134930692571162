<ng-container *ngFor="let toast of toastService.toasts;">
  <ngb-toast [autohide]="true" [delay]="toast.delay || 8000" (hidden)="toastService.removeToast(toast)" >
    <div [class]="'shadow-sm alert alert-'+toast.type">
    <ng-container *ngIf="toast.template; else textToast">
      <ng-template [ngTemplateOutlet]="toast.template"></ng-template>
    </ng-container>
    <ng-template #textToast >
      <div class="d-flex">
        <div class="flex-grow-1" [innerHTML]="toast.text"></div>
        <button type="button" class="btn-close pointer" (click)="toastService.removeToast(toast)"></button>
      </div>
    </ng-template>
  </div>
  </ngb-toast>
</ng-container>

<!-- <ngb-toast [autohide]="false" *ngIf="toastService.showCookieConsent" (hidden)="toastService.showCookieConsent = false" >
  <div class="shadow-sm alert">
  <div><b>Cookies</b></div>
  <div class="mb-2 mt-1">
  En nuestra web usamos cookies unicamente necesarias para el correcto funcionamiento de la sesión de usuario y procesamiento de pagos.
</div>
  <div class="d-flex gap-3">
    <button class="btn btn-outline-secondary btn-sm" (click)="toastService.showCookieConsent = false">Rechazar</button>
    <button class="btn btn-outline-primary btn-sm" (click)="toastService.showCookieConsent = false">Aceptar todas</button>
  </div>
</div>
</ngb-toast> -->
