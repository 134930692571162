import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MerchantService } from '../../../api/services/MerchantService';
import { MerchantOrderDto, OrderStatus } from '../../../models/Order';

const providerOptions = [{
  value: 'correos',
  label: 'Correos',
}, {
  value: 'correos_express',
  label: 'Correos Express'
}, {
  value: 'dhl',
  label: 'DHL'
}, {
  value: 'mrw',
  label: 'MRW'
}, {
  value: 'other',
  label: 'Otro'
}
]
@Component({
  selector: 'app-delivery-form',
  templateUrl: './delivery-form.component.html',
  styleUrls: ['./delivery-form.component.scss']
})
export class DeliveryFormComponent implements OnInit {
  @Input() order!: MerchantOrderDto;
  @Output() onSuccess = new EventEmitter<MerchantOrderDto>()
  error: any;
  form = new FormGroup({
    provider: new FormControl('', [Validators.required]),
    trackingNumber: new FormControl('', [Validators.required]),
    fixedProvider: new FormControl<string|null>(null, [])
  })

  providerOptions = providerOptions
  constructor(private merchantSvc: MerchantService) {

  }

  ngOnInit(): void {
    if (this.order?.delivery) {
      const { provider, trackingNumber } = this.order.delivery
      const fixedProvider = this.providerOptions.find(option=>option.value === provider)?.value || 'other'
      this.form.setValue({ provider, trackingNumber, fixedProvider})
    }
    this.fixedProviderFc.valueChanges.subscribe(v=>{
      this.form.get('provider')?.patchValue(v === 'other' ? '':v)
    })
    if([OrderStatus.Completed, OrderStatus.Canceled].includes(this.order?.status)){
      this.form.disable()
    }
  }
  get fixedProviderFc(){
    return this.form.get('fixedProvider')!
  }
  get otherProviderSelected(){
    return this.fixedProviderFc.value === 'other'
  }

  get providerSelected(){
    const provider = this.form.get('provider')?.value
    const fixedProvider = this.fixedProviderFc.value
    const val = fixedProvider || provider
    if(! val || val === 'other' ){
      return null
    }
    return fixedProvider
  }
  get submitDisabled() {
    return !this.form.valid || this.form.pristine
  }
  setProvider(name: string) {
    this.form.get('provider')?.setValue(name)
  }

  submit(): void {
    const { provider, trackingNumber } = this.form.value
    this.merchantSvc.updateOrderDelivery(this.order.id, { provider, trackingNumber}).subscribe({
      next: (order) => {
        this.form.markAsPristine()
        this.onSuccess.emit(order)
      },
      error: (error) => {
        this.form.markAsPristine()
        this.error = error
        throw error
      }
    })
  }
}
