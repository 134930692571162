import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AddressFormValue } from '../address-form/address-form.component';
import { CustomerService } from '../../../api/services/CustomerService';
import { IUser } from '../../../models/User';
import { CustomerOnboardingTask, OnboardingStatusDto, UserService } from '../../../api/services/UserService';
import { AppStore } from '../../../store';
import { userFeat } from '../../../store/user.store';
import { map } from 'rxjs';

@Component({
  selector: 'app-user-address-form-card',
  templateUrl: './user-address-form-card.component.html',
  styleUrls: ['./user-address-form-card.component.scss']
})
export class UserAddressFormCardComponent  {
  @Input({required: true}) user!: IUser
  @Output() onAddressUpdated = new EventEmitter<IUser>()
  value: AddressFormValue | null = null;
  isLoading = false


  constructor(private userSvc: UserService, private store: AppStore) {}

  get missingPersonalDetails$(){
    return this.store.select(userFeat.selectStatus).pipe(map(status=>{
      return !status?.customer.tasks.find(t=>t.name === CustomerOnboardingTask.CompletePersonalDetails && t.completed)
    }))
  }
  onPersonalDataChange(value:AddressFormValue){
    this.value = value
  }

  saveAddress(){
    if(!this.value){
      return
    }
    return this.userSvc.updateUser(this.value);
  }

  onSuccess(user: IUser){
    this.user = user
    this.onAddressUpdated.emit(user)
  }

  onError(err:any){

  }


}
