import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { FileUploadService } from '../../../api/services/FileUploadService';
import { faCheck, faFile, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FileType } from '../../../models/Enum';

const imgs = ['JPG','PNG','WEBP']

const fileTypeToExt = {
  [FileType.AuctionPic]: imgs,
  [FileType.ProductPic]: imgs,
  [FileType.Avatar]: imgs,
  [FileType.Banner]: imgs
}

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  private _fileType!: FileType
  @Input({ required: true }) set fileType (fileType: string){
    this._fileType = fileType as FileType
    this.extensions = fileTypeToExt[fileType as FileType]
  }
  get fileType(): FileType{
    return this._fileType
  }
  @Input() onlyButton = false
  @Input() chooseButtonText = 'Elegir'
  @Input() autoUpload = false
  @Input() extensions: string[] = [] // empty any
  @Input() fileSize: string = '10MB'
  @Input() imgDimension?: string
  @Output() onUpload = new EventEmitter()
  @Output() onProgress = new EventEmitter<number>()
  selectedFiles?: FileList;
  currentFile?: File;
  progress = 0;
  message = '';

  fileData: any
  get isUploading(){
    return this.progress > 0 && this.progress < 100
  }
  get fileName(){
    return this.selectedFiles?.item(0)?.name
  }

  get extensionText(){
    return this.extensions.join(', ')
  }

  constructor(private uploadService: FileUploadService) { }

  ngOnInit(): void { }

  prepareFiles(files: FileList){
    this.progress = 0
    this.selectedFiles = files
    const file = files.item(0)
    if(!file){
      return
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const data = reader.result?.toString()
      if (data) {
        this.fileData = data
      }
    };
  }

  onFileDropped($event:any) {
    this.prepareFiles($event)
    if(this.autoUpload){
      this.upload()
    }
  }


  selectFile(event: any): void {
    this.prepareFiles(event.target.files);
    if(this.autoUpload){
      this.upload()
    }
  }

  upload(): void {
    this.progress = 0;

    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);

      if (file) {
        this.currentFile = file;

        this.uploadService.upload(this.currentFile, {
          fileType: this.fileType
        }).subscribe({
          next: (event: any) => {
            if (event.type === HttpEventType.UploadProgress) {
              this.progress = Math.round((100 * event.loaded) / event.total);
              this.onProgress.emit(this.progress)
            } else if (event instanceof HttpResponse) {
              this.onUpload.emit(event.body.data)
              this.message = ''
            }
          },
          error: (err: any) => {
            this.progress = 0;

            if (err.error && err.error.message) {
              this.message = err.error.message;
            } else {
              this.message = 'Could not upload the file!';
            }

            this.currentFile = undefined;
          },
        });
      }

      this.selectedFiles = undefined;
    }
  }


  uploadIcon = faUpload
  successIcon = faCheck
  fileIcon = faFile
}
