<div class="card">
  <div class="card-body">
    <h5 class="card-title">Envío</h5>
    <div class="alert alert-info" *ngIf="!showDeliveryForm && !order.sendLater">
      No hay envío asociado a este pedido todavía.
      <div class="mt-2 d-flex gap-3" *ngIf="!isOrderCanceled">
        <button [disabled]="this.disableActions" (click)="displayForm()" class="btn btn-sm btn-outline-primary">Añadir
          envío</button> o
        <button [disabled]="this.disableActions" (click)="sendLaterRequest()"
          class="btn btn-sm btn-outline-primary">Enviar más tarde</button>
      </div>
    </div>

    <ng-container *ngIf="order.sendLater" >
      <div class="alert alert-success" *ngIf="order.sendLater.approvedByCustomerAt">
        El cliente ha aprobado la solicitud de enviarlo más adelante.
        <div><b>Fecha aprobación:</b> {{ order.sendLater.approvedByCustomerAt | date: 'short' }}</div>
      </div>
      <div class="alert alert-danger" *ngIf="order.sendLater.rejectedByCustomerAt">
        El cliente ha rechazado la solicitud de enviarlo más adelante.
        <div><b>Fecha rechazo:</b> {{ order.sendLater.rejectedByCustomerAt | date: 'short' }}</div>
      </div>
      <div class="alert alert-warning" *ngIf="!order.sendLater.approvedByCustomerAt && !order.sendLater.rejectedByCustomerAt">
        Estamos esperando la aprobación de {{order.customer.firstName}} para que se envíe su pedido más adelante.
        <div class="text-center">
          Si habéis acordado hacer un envío, <a href="#" (click)="displayForm(); $event.preventDefault()">añade los
            detalles</a>.
          </div>
        </div>
        <button *ngIf="!showDeliveryForm" [disabled]="this.disableActions" (click)="displayForm()" class="btn btn-sm btn-outline-primary">Añadir envío</button>
    </ng-container>

    <app-delivery-form [order]="order" *ngIf="showDeliveryForm && !isOrderCanceled" (onSuccess)="onDeliveryInfoSaved($event)">
      <button type="button" class="btn btn-link" *ngIf="order.delivery" (click)="sendLaterRequest()">Enviar más adelante</button>
      <button type="button" class="btn btn-outline-secondary" (click)="hideForm()" *ngIf="!order.delivery" >Cancelar</button>
    </app-delivery-form>

  </div>

</div>
