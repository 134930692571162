<app-isolated-layout>
    <div class="d-flex justify-content-center z-4">

        <div class="mt-5" style="max-width: 400px; width: 100%;">

                <h5 class="card-title text-center">Confirmación de cuenta</h5>
                <ng-container *ngIf="isLoading">
                    <h6 class="card-subtitle mb-3 text-center"><strong>Un segundito...</strong>, estamos comprobando el enlace.</h6>
                    <img src="assets/img/process.svg" class="img-fluid mb-3" alt="Cargando" />
                </ng-container>
                <ng-container *ngIf="isSuccessful">
                    <h6 class="card-subtitle mb-3 text-center"><strong>Enhorabuena!</strong> Ya tienes tu cuenta lista.</h6>
                    <img src="assets/img/verified-account.svg" class="img-fluid mb-3" alt="Cuenta confirmada" />
                </ng-container>
                <ng-container *ngIf="hasError">
                    <h6 class="card-subtitle mb-3 text-center"><strong>Vaya!</strong> Parece que el enlace es incorrecto.</h6>
                    <img src="assets/img/uknown-error.svg" class="img-fluid mb-3" alt="Error" />
                </ng-container>


        </div>

    </div>
</app-isolated-layout>
