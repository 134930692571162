import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { faInstagram, faTelegram, faTwitch } from '@fortawesome/free-brands-svg-icons';
import { faX } from '@fortawesome/free-solid-svg-icons';

const socialPlatforms = [
  {icon: faX, name:'x', label: 'X/Twitter'},
  {icon: faTelegram, name:'telegram', label: 'Telegram'},
  {icon: faInstagram, name:'instagram', label: 'Instagram'},
  {icon: faTwitch, name:'twitch', label: 'Twitch'}
]

@Component({
  selector: 'app-edit-social-platforms',
  templateUrl: './edit-social-platforms.component.html',
  styleUrls: ['./edit-social-platforms.component.scss']
})
export class EditSocialPlatformsComponent {
  socialPlatforms = socialPlatforms

  form = new FormGroup(
    socialPlatforms.reduce((controls, platform)=>{
      return {
        ...controls,
        [platform.name]: new FormControl()
      }
    }, {})
  )
}
