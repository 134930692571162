<div class="card">
  <div class="card-header d-flex align-items-center gap-1">
    <img [src]="review.customer.avatar" class="rounded" height="30"/> {{review.customer.alias}}
    <div class="stars">
      <span *ngFor="let star of stars"><fa-icon [icon]="starIcon"/></span>
    </div>
    {{ review.rating }}

    <div class="ms-auto">{{ review.createdAt | date: 'short' }}</div>
  </div>
  <div class="card-body" *ngIf="review.message">
    {{ review.message}}
  </div>
</div>
