import { Component, Input, OnInit } from '@angular/core';
import { AppStore } from '../../../../store';
import { pMerchantProfile, pMerchantReviewList } from '../../../../store/public/merchant.profile.store';


@Component({
  selector: 'app-merchant-profile-reviews-page',
  templateUrl: './merchant-profile-reviews-page.component.html',
  styleUrls: ['./merchant-profile-reviews-page.component.scss']
})
export class MerchantProfileReviewsPageComponent implements OnInit {

  constructor(
    private store: AppStore
  ) {}
  ngOnInit(): void {
    this.store.select(pMerchantProfile.feat.selectData).subscribe(profile=>{
      if(!profile){
        return
      }
      this.store.dispatch(pMerchantReviewList.actions.load({params:{
        merchantId: profile.user.id
      }}))
    })

  }
  reviews$ = this.store.select(pMerchantReviewList.feat.selectData)
}
