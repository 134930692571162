import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AuctionState, IAuctionExpanded } from '../../../models/Auction';
import { faGavel } from '@fortawesome/free-solid-svg-icons';
import BigNumber from 'bignumber.js';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UiService } from '../../services/ui.service';
import { OnboardingStatusDto } from '../../../api/services/UserService';
import { IUser } from '../../../models/User';
import { apiErrorToErrorParams } from '../../../utils/common.utils';
import { AppStore } from '../../../store';
import { pAuctionFeat, pAuctionFeatActions } from '../../../store/public/auction.store';
import { Actions, ofType } from '@ngrx/effects';
import { createSubs, unsubAll } from '../../../utils/rxjs.utils';
import { userFeat } from '../../../store/user.store';

@Component({
  selector: 'app-bid-form',
  templateUrl: './bid-form.component.html',
  styleUrls: ['./bid-form.component.scss']
})
export class BidFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() auction?: IAuctionExpanded;
  @Input() user: IUser | null = null;
  @Input() onboardingStatus: OnboardingStatusDto | null = null
  @Input() bid?: IAuctionExpanded['bids'][0]
  @Input() forceHorizontal = false
  amountFocused = false
  bidIcon = faGavel
  bidForm = new FormGroup({
    amount: new FormControl(0, [Validators.required])
  })

  subs$ = createSubs()

  constructor(private store: AppStore, private uiSvc: UiService, private actions$: Actions) { }

  ngOnInit(): void {
    this.setAmountToMin()
    // this.subs$.bidOK = this.actions$.pipe(ofType(pAuctionFeatActions.bidOK)).subscribe(({ amount }) => {
    //   trackEvent('bid.form')
    //   this.uiSvc.showToast({ type: 'success', text: `Pujaste ${amount}${this.auction.currencySymbol}` })
    // })
    this.subs$.bidError = this.store.select(pAuctionFeat.selectBidError).subscribe(error => {
      error && this.uiSvc.showToast({ type: 'danger', text: apiErrorToErrorParams(error) })
    })
    this.store.select(userFeat.selectUserState).subscribe(({ status, user }) => {
      this.user = user
      this.onboardingStatus = status
    })
  }

  ngOnDestroy(): void {
    unsubAll(this.subs$)
  }

  get processing$() {
    return this.store.select(pAuctionFeat.selectProcessingBid)
  }
  get pendingTasks() {
    return this.onboardingStatus?.customer?.tasks.filter(t => !t.completed) || []
  }
  get bidDisabled() {
    return !this.auction || !this.user || this.userIsLastBidder || this.bidForm.invalid || !this.onboardingStatus?.customer?.completed || this.auction.state !== AuctionState.Started
  }
  get bidBtnText() {
    if (this.auction?.state === AuctionState.Closed && this.userIsLastBidder) {
      return 'Ganaste!'
    }
    if (this.auction?.state === AuctionState.Started && this.userIsLastBidder) {
      return 'Vas ganando'
    }
    return 'Pujar'
  }
  get userIsLastBidder() {
    return this.user?.id === this.auction?.bids[0]?.bidderId
  }

  placeBid(amount: string) {
    if (!this.auction) {
      return
    }
    this.store.dispatch(pAuctionFeatActions.bid({ auctionId: this.auction.id, amount }))
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes['auction'].currentValue) {
      return this.fcAmount.setValue(0)
    }
    if (changes['auction']) {
      if (this.nextBidAmount.isGreaterThan(this.amount)) {
        this.setAmountToMin()
      }
      if (changes['auction'].currentValue.state === AuctionState.Closed) {
        this.fcAmount.setValue(0)
      }

    }
  }

  get nextBidAmount() {
    return new BigNumber(this.auction?.nextBidAmount || 0)
  }

  get fcAmount() {
    return this.bidForm.get('amount')!
  }

  get amount() {
    return this.fcAmount.value!
  }

  get minBidIncrement() {
    if (!this.auction) {
      return null
    }
    return new BigNumber(this.auction.minBidIncrement)
  }

  setAmountToMin() {
    this.fcAmount.setValue(this.nextBidAmount.toNumber())
  }

  incAmountBy(amount: number) {
    const value = this.amount || this.nextBidAmount.toNumber()
    this.fcAmount.setValue(value + amount)
  }

  cantDecrease(amount: number) {
    return this.nextBidAmount.isGreaterThanOrEqualTo(amount)
  }

  get isOnboardingCompleted() {
    return !!this.onboardingStatus?.customer?.completed
  }

  private updateAmountControl() {
    this.bidForm.get('amount')?.setValidators([Validators.required, Validators.min(this.nextBidAmount.toNumber())])
  }
}
