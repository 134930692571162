export enum StreamingPlatform{
  Youtube = 'youtube',
  Twitch = 'twitch'
}

export enum FileType {
  Avatar = 'avatar',
  Banner = 'banner',
  AuctionPic = "auction_pic",
  ProductPic = 'product_pic'
}
