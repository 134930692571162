import { createAction, createFeature, createReducer, on, props } from '@ngrx/store';
import { IApiPaginationData } from '../../api/services/ApiService';
import { merge } from 'lodash';
import { FullPartial } from '../../../types.util';

export interface GenericListStore<DataElement> {
  filters: any
  pagination: IApiPaginationData
  deletingId: string|null
  data: DataElement[]
  error:Error | null
  loading: boolean
}

const defInitialState: GenericListStore<any> = {
  filters: null,
  pagination:{
    page: 1,
    pageSize: 10,
    totalPages: 0,
    total: 0,
    prevPage: null,
    nextPage: null,
    sortBy: {}
  },
  deletingId: null,
  data: [],
  error: null,
  loading: false
}

type PaginationParams = { page?: number, pageSize?:number, sortBy?:Record<string, 'asc'|'desc'> }

export const buildCrudStore = <DataElement extends {id: string }, ResourceParam = any >(
  resourceName: string,
  partialInitialState: FullPartial<GenericListStore<DataElement>> = {}
) => {

  const featureName = `${resourceName}List`

  // Actions
  const prefix = `[${featureName}]`

  const load = createAction(`${prefix} Load ${resourceName}`, props<PaginationParams & { params: ResourceParam, filters?: any }>());
  const loadOK = createAction(`${prefix} Load ${resourceName} OK`, props<{ data: DataElement[], pagination: IApiPaginationData }>());
  const loadKO = createAction(`${prefix} Load ${resourceName} KO`, props<{ error: Error }>());

  const remove = createAction(`${prefix} Remove ${resourceName}`, props<{ deletingId: string }>());
  const removeOK = createAction(`${prefix} Remove ${resourceName} OK`, props<{ deletedId: string }>());
  const removeKO = createAction(`${prefix} Remove ${resourceName} KO`, props<{ error: Error }>());

  const updateOK = createAction(`${prefix} Update ${resourceName}  OK`, props<{ data: DataElement }>());


  // Reducer
  const initialState = merge(defInitialState, partialInitialState)
  const reducer = createReducer(
    initialState,
    on(load, (state, { filters, page, pageSize, sortBy }) => ({ ...state, page, sortBy, pageSize, filters, loading: true, deletingId: null, error: null })),
    on(loadOK, (state, { data, pagination }) => ({ ...state, data, pagination, deletingId: null, loading: false })),
    on(loadKO, (state, { error }) => ({ ...state, error, loading: false })),
    // delete
    on(remove, (state, { deletingId }) => ({ ...state, deletingId })),
    on(removeOK, (state, { deletedId }) => ({
      ...state,
      deletingId: null,
      error: null,
      data: state.data.filter(a => a.id !== deletedId)
    })),
    on(removeKO, (state, { error }) => ({ ...state, deletingId: null, error })),
    // update
    on(updateOK, (state, { data }) => ({
      ...state,
      error: null,
      data: state.data.map(item => data.id === data.id ? data : item)
    })),

  );

  // Feature
  return {
    feat: createFeature({
      name: featureName,
      reducer,
    }),
    actions: {
      load,
      loadOK,
      loadKO,

      updateOK,

      remove,
      removeOK,
      removeKO
    }
  }
}
