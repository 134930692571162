<div class="h-100 product text-center d-flex flex-column justify-content-center align-items-center" [ngClass]="{ ready }">
  <div class="picture mb-3">
    <img [src]="product.picture.url" (load)="ready = true" />
  </div>
  <div class="w-100 name flex-grow-1 mb-2">
    {{ product.name }}
  </div>
  <div class="w-100 stock mb-2" [ngClass]="{lowStock: product.stock.amount < 2}">
    {{ product.stock.amount }} unidades
  </div>
  <div class="w-100">
    <button (click)="openBuyModal()" class="btn btn-primary w-100">Comprar por {{product.price| price }}€</button>
  </div>
  <div class="mt-1 merchant d-flex gap-1" *ngIf="showMerchantHeader">
    <div>Vendido por</div>
    <a [routerLink]="['/viewer','mercado', product.merchant.alias]" class="merchant-tag">
      <img [src]="product.merchant.avatar" [height]="20" class="rounded"/>
      {{ product.merchant.alias}}
      </a>
  </div>
</div>
