import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MerchantWithAuctionsDto, StreamingStatus } from '../../../models/Merchant';
import { AppStore } from '../../../store';
import { userFeat } from '../../../store/user.store';
import { faTwitch, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { AuctionState, IAuctionExpanded } from '../../../models/Auction';
import { RealTimeService } from '../../../api/services/RealTimeService';
import { createSubs, unsubAll } from '../../../utils/rxjs.utils';

@Component({
  selector: 'app-auction-wm-list-item',
  templateUrl: './auction-wm-list-item.component.html',
  styleUrls: ['./auction-wm-list-item.component.scss']
})
export class AuctionWmListItemComponent implements OnInit, OnDestroy {
  @Input() merchant: MerchantWithAuctionsDto | null = null
  @Input() showMerchantHedaer = true
  @Input() enableBiding = false
  @Input() compact = false
  @Input() auction!: IAuctionExpanded
  constructor(
    private store: AppStore,
    private rtService: RealTimeService
  ) {}

  subs$ = createSubs()
  viewerIcon = faUser
  // State ops
  user$ = this.store.select(userFeat.selectUser)


  ngOnInit(): void {
    if(this.auction){
      this.subs$.toAuction = this.rtService.subToAuction(this.auction.id, true).subscribe(({data: auction, event})=>{
        // if(event === 'auction.deleted'){
        //   alert("DELETED")
        // }
        this.loadAuction(auction)
      })
    }
    else if(this.merchant){
      const startedAuction = this.merchant.auctions.find(auction=>auction.state === AuctionState.Started)
      this.loadAuction(startedAuction || this.merchant.auctions[0])
      this.subs$.toMerchant = this.rtService.subToMerchantAuctions(this.merchant.id, true).subscribe(({ data: auction, event })=>{
        this.loadAuction(auction)
      })
    }
  }

  ngOnDestroy(): void {
    unsubAll(this.subs$)
  }

  private loadAuction(auction: IAuctionExpanded){
    this.auction = auction
  }

  get merchantAvatar(){
    if(this.merchant){
      return this.merchant.avatar
    }
    return this.auction.merchant.avatar
  }

  get merchantAlias (){
    if(this.merchant){
      return this.merchant.alias
    }
    return this.auction.merchant.alias
  }

  get onLiveChannels(){
    return this.merchant?.channels.filter(channel=>channel.status === StreamingStatus.Live) || []
  }

  get hasLiveChannels(){
    return this.onLiveChannels.length > 0
  }
  get viewerCount(){
    if(!this.merchant){
      return 0
    }
    return this.merchant.channels.reduce((count, channel)=>count + (channel.details?.viewerCount || 0),0)
  }
  getChannelIcon(platform: string){
    return {
      twitch: faTwitch,
      youtube: faYoutube
    }[platform]!
  }

  getAuctionLink(auction: IAuctionExpanded){
    if(this.hasLiveChannels){
      return [`/viewer/subastas/live/${auction.merchant.alias}`]
    }
    return [`/viewer/subastas/${auction.id}`]
  }

  get latestBid(){
    return this.auction?.bids[0]
  }

  get isRunning(){
    return this.auction?.state === AuctionState.Started
  }
  get didNotStart(){
    return this.auction?.state === AuctionState.Created
  }

  get isClosed(){
    return this.auction?.state === AuctionState.Closed
  }


}
