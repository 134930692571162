import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'apiValidationError'
})
export class ApiValidationErrorPipe implements PipeTransform {

  transform({poperty, errors}: any, ...args: unknown[]): unknown {
    const [first]= errors
    return first.msg;
  }

}
