<app-page-title title="Pedidos">
  <div class="d-flex gap-2">
    <button dispatch-action class="btn btn-sm btn-secondary" [dispatcherBtn]="loadOrdersAction" [actionParams]="filters"
      [loadingSelector]="loadOrdersLoading" [icon]="refreshIcon" loadingText="Cargando">
    </button>

    <form [formGroup]="filterForm">
      <app-form-input placeholder="Buscar..." controlName="search" [form]="filterForm" />
    </form>
  </div>

  <div>
    <button class="btn btn-outline-secondary" (click)="downloadPendingOrdersDeliveryLabel()"><fa-icon [icon]="downloadIcon"/> Etiquetas</button>
  </div>
</app-page-title>

<ul ngbNav #nav="ngbNav" [activeId]="stage" class="nav-tabs " (activeIdChange)="onOrderTabChange($event)">
  <li ngbNavItem="pending"><button ngbNavLink>Enviar</button></li>
  <li ngbNavItem="sent"><button ngbNavLink>Enviados</button></li>
  <!-- <li ngbNavItem="completed"><button ngbNavLink>Entregados</button></li> -->
  <li ngbNavItem="pending_delivery"><button ngbNavLink>Enviar después</button></li>
  <li ngbNavItem="all"><button ngbNavLink>Todos</button></li>
</ul>

<div class="p-2" *ngIf="loading$|async;else orderTable">
  <app-loading-indicator />
</div>

<ng-template #orderTable>
  <ng-container *ngIf="(orderCount$|async) === 0">
    <div class="row align-items-center justify-content-center">
      <div class="col-4 col-lg-5 col-xl-3">
        <app-fancy-icon-empty-box />
      </div>
      <div class="col-8 col-lg-7 col-xl-4">
        <h5>No encontramos nada 😔</h5>
        <p>Prueba con otros términos de búsqueda!</p>
      </div>
    </div>
  </ng-container>
  <app-merchant-order-list [orders]="(orders$ | async) || []" *ngIf="orderCount$|async" />

</ng-template>
