<div class="wrapper" [ngClass]="wrapperClasses">
  <div class="btn w-100 btn-primary" #boundary>
    <div class="boundary">
      <div #draggable cdkDrag cdkDragBoundary=".boundary" [cdkDragStartDelay]=0 [cdkDragFreeDragPosition]="dragPosition"
        cdkDragLockAxis="x" (cdkDragEnded)="onDragEnded($event)" (cdkDragMoved)="onDragMoved($event)" class="draggable">
        <div [ngStyle]="slideBtnStyle" class="btn btn-primary icon awaiting">
          <fa-icon [icon]="slideRightIcon" />
        </div>
        <div [ngStyle]="slideBtnStyle" class="btn btn-primary icon ready">
            <fa-icon *ngIf="!isProcessing" [icon]="checkIcon" />
            <app-loading-spinner *ngIf="isProcessing"/>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="description">
        <b>
          {{isProcessing ? processingText : idleText }}
        </b>
      </div>
      <p class="helper">
        {{ helperText }}

      </p>
    </div>
  </div>
</div>
