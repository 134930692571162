<div class="d-flex justify-content-between align-items-center">


  <div class="d-flex gap-2 justify-content-start align-items-center">
    <form [formGroup]="filterForm" style="width: 100px">
      <app-form-input placeholder="Buscar..." controlName="search" [form]="filterForm" />
    </form>

    <button dispatch-action class="btn btn-sm btn-secondary" [dispatcherBtn]="loadData" [actionParams]="loadParams"
      [loadingSelector]="loadingSelector" [icon]="refreshIcon" loadingText="">
    </button>

  </div>

  <ng-content></ng-content>
</div>

<div class="datatable-wrapper">
  <div class="table-responsive">
    <div class="loading-overlay" *ngIf="loading$|async;"></div>
    <table class="table w-100">
      <thead>
        <tr>
          <ng-container *ngFor="let col of _columns">
            <th [class]="col.classes"><span [innerHTML]="col.title"></span></th>
          </ng-container>
        </tr>
      </thead>
      <tbody *ngIf="loading$|async;">
        <tr>
          <td colspan="6">
            <app-loading-indicator />
          </td>
        </tr>
      </tbody>
      <tr>
        <td colspan="6" *ngIf="noItems$|async">
          <div class="row align-items-center justify-content-center">
            <div class="col-4 col-lg-5 col-xl-3">
              <app-fancy-icon-empty-box />
            </div>
            <div class="col-8 col-lg-7 col-xl-4">
              <ng-template #notFoundMsg>
                <h5>No encontramos nada 😔</h5>
                <p>Prueba con otros términos de búsqueda!</p>
              </ng-template>
            </div>

          </div>
        </td>
      </tr>
      <ng-template #rowContainer></ng-template>

    </table>
  </div>
  <app-pagination [listStore]="listStore"/>

</div>
