<div class="address-block">
    <div class="d-flex justify-content-between align-items-center">
      <strong>{{title}}</strong>
      <button *ngIf="!editButton" class="btn btn-link btn-sm" ngxClipboard [cbContent]="copy"><fa-icon [icon]="copyIcon"/></button>

      <button *ngIf="editButton" (click)="onEditRequest.emit(true)" class="btn btn-sm btn-outline-primary">
          {{editButton}}
      </button>
    </div>
    <div>
      <ng-container *ngIf="address.fullName">{{address.fullName}}<br/></ng-container>
      {{address.line1}}, {{address.city}}, <br/>
      {{state}}, {{address.postalCode}}, {{address.country}}
    </div>
</div>
