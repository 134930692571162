import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, exhaustMap, catchError, delay } from 'rxjs/operators';
import { playgroundStore } from './playground.store';


@Injectable()
export class PlaygroundEffects {
  /** List  */
  load$ = createEffect(() => this.actions$.pipe(
    ofType(playgroundStore.actions.doIt),
    exhaustMap(() => of(true).pipe(delay(1000))
      .pipe(
        map(res => playgroundStore.actions.ok()),
        catchError((error) => of(playgroundStore.actions.ko())))
      )
  ))


  constructor(
    private actions$: Actions
  ) { }
}
