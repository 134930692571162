import { createAction, createFeature, createReducer, on, props } from '@ngrx/store';
import { merge } from 'lodash';

export interface GenericListStore<ElementDto> {
  data: ElementDto | null
  error:any
  loading: boolean
}

const defInitialState: GenericListStore<any> = {
  data: null,
  error: null,
  loading: false
}

export const buildFormStore = <
  ElementDto extends {id: string },
  CreateDto = any,
  UpdateDto = any
>(resourceName: string, partialInitialState: Partial<GenericListStore<ElementDto>> = {}) => {

  const featureName = `${resourceName}Form`

  // Actions
  const prefix = `[${featureName}]`

  const load = createAction(`${prefix} Load ${resourceName}`, props<{ id: string }>());
  const loadOK = createAction(`${prefix} Load ${resourceName} OK`, props<{ data: ElementDto }>());
  const loadKO = createAction(`${prefix} Load ${resourceName} KO`, props<{ error: Error }>());

  const create = createAction(`${prefix} Create ${resourceName}`, props<{ payload: CreateDto }>());
  const createOK = createAction(`${prefix} Create ${resourceName} OK`, props<{ data: ElementDto }>());
  const createKO = createAction(`${prefix} Create ${resourceName} KO`, props<{ error: Error }>());

  const update = createAction(`${prefix} Update ${resourceName}`, props<{ id: string, payload: UpdateDto }>());
  const updateOK = createAction(`${prefix} Update ${resourceName} OK`, props<{ data: ElementDto }>());
  const updateKO = createAction(`${prefix} Update ${resourceName} KO`, props<{ error: Error }>());

  // Reducer
  const initialState = merge(defInitialState, partialInitialState)
  const reducer = createReducer(
    initialState,
    on(create, (state) => ({ ...state, loading: true })),
    on(createOK, (state, {data}) => ({ ...state,data, loading: false })),
    on(createKO, (state, { error }) => ({ ...state, error, loading: false })),

    on(update, (state) => ({ ...state, loading: true })),
    on(updateOK, (state, { data }) => ({ ...state, data, loading: false })),
    on(updateKO, (state, { error }) => ({ ...state, error, loading: false })),

    on(load, (state) => ({ ...state, data: null, loading: true })),
    on(loadOK, (state, { data }) => ({ ...state, data, loading: false })),
    on(loadKO, (state, { error }) => ({ ...state, data: null, error, loading: false })),
  );


  return {
    feat: createFeature({
      name: featureName,
      reducer,
    }),
    actions: {
      create,
      createOK,
      createKO,

      update,
      updateOK,
      updateKO,

      load,
      loadOK,
      loadKO
    }
  }
}
