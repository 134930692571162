import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IUser, IUserPaymentMethod } from '../../../models/User';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UiPaymentService } from '../../services/ui-payment.service';
import { CustomerService } from '../../../api/services/CustomerService';
import { stripeElementsAppearance } from '../../../utils/stripe.utils';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { AppStore } from '../../../store';
import { userFeat, userFeatActions } from '../../../store/user.store';
import { UiService } from '../../services/ui.service';
import { UiState } from '../../../store/ui.store';

@Component({
  selector: 'app-add-payment-method-modal',
  templateUrl: './add-payment-method-modal.component.html',
  styleUrls: ['./add-payment-method-modal.component.scss']
})
export class AddPaymentMethodModalComponent implements OnInit {
  @ViewChild('sdkContainer', { static: true }) sdkContainerRef!: ElementRef;
  @Output() onPaymentSaved = new EventEmitter<IUserPaymentMethod>();
  error?: string
  elements: StripeElements | undefined;
  sdkContainer: any;
  readyToSubmit = false;
  stripe!: Stripe

  constructor(
    public activeModal: NgbActiveModal,
    private customerSvc: CustomerService,
    private uiPaymentSvc: UiPaymentService,
    private uiSvc: UiService,
    private store: AppStore
  ) { }


  ngOnInit() {

    this.readyToSubmit = false
    this.uiPaymentSvc.getStripe().then(stripe => {
      this.stripe = stripe

      this.customerSvc.getPaymentMethodSetup().subscribe((res) => {
        this.uiSvc.theme$.subscribe(theme => {
          this.loadStripe(res, theme);

        })
      });
    });

  }

  async loadStripe({ clientSecret }: { clientSecret: string }, theme: UiState['theme']) {

    this.elements = this.stripe.elements({
      clientSecret,
      appearance: stripeElementsAppearance[theme]
    });

    this.sdkContainer = this.elements.create('payment', {
      fields: {
        billingDetails: {
          name: 'auto',
          email: 'auto'
        },

      }
    });

    this.sdkContainer.mount(this.sdkContainerRef.nativeElement);
    this.sdkContainer.on('change', this.onPaymentChange.bind(this));
    this.sdkContainer.on('submit', () => {
      this.onSubmit();
    });
  }

  get submitEnabled() {
    return this.readyToSubmit;
  }


  private onPaymentChange(event: any) {
    this.readyToSubmit = event.complete;
  }

  onSuccess(paymentMethod: IUserPaymentMethod) {
    this.store.dispatch(userFeatActions.userPaymentMethodAdded({ paymentMethod }))
    this.onPaymentSaved.emit(paymentMethod)
    this.error = undefined
    this.activeModal.close()
  }
  onError(error: any) {
    this.readyToSubmit = true
    this.error = error.message

  }
  async onSubmit() {
    if (!this.submitEnabled || !this.elements) {
      return;
    }

    const { setupIntent, error } = await this.stripe.confirmSetup({
      elements: this.elements,
      redirect: 'if_required',
      // 'confirmParams':{
      //   'return_url': window.origin
      // }

    })

    if (error) {
      throw error
    }

    if (!setupIntent) {
      throw new Error('No setup intent')
    }

    return this.customerSvc
      .confirmPaymentMethod({ setupId: setupIntent.id })
  }
}
