const stateCodeToProvinceMap = {
  A: 'Alicante',
  AB: 'Albacete',
  AL: 'Almería',
  AV: 'Ávila',
  B: 'Barcelona',
  BA: 'Badajoz',
  BI: 'Biscay',
  BU: 'Burgos',
  CA: 'Cádiz',
  CC: 'Cáceres',
  CE: 'Ceuta',
  CO: 'Córdoba',
  CR: 'Ciudad Real',
  CS: 'Castellón',
  CU: 'Cuenca',
  GC: 'Las',
  GI: 'Girona',
  GR: 'Granada',
  GU: 'Guadalajara',
  H: 'Huelva',
  HU: 'Huesca',
  J: 'Jaén',
  L: 'Lleida',
  LE: 'León',
  LO: 'La Rioja',
  LU: 'Lugo',
  M: 'Madrid',
  MA: 'Málaga',
  ML: 'Melilla',
  MU: 'Murcia',
  NA: 'Navarra',
  O: 'Asturias',
  OR: 'Ourense',
  P: 'Palencia',
  PM: 'Balears',
  PO: 'Pontevedra',
  S: 'Cantabria',
  SA: 'Salamanca',
  SE: 'Sevilla',
  SG: 'Segovia',
  SO: 'Soria',
  SS: 'Gipuzkoa',
  T: 'Tarragona',
  TE: 'Teruel',
  TF: 'Santa',
  TO: 'Toledo',
  V: 'Valencia',
  VA: 'Valladolid',
  VI: 'Araba',
  Z: 'Zaragoza',
  ZA: 'Zamora'
}

export const stateCodeToProvince = (code: string) : string=>{
  return stateCodeToProvinceMap[code as keyof typeof stateCodeToProvinceMap] || code
}
