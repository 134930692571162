<form [formGroup]="reviewForm" (ngSubmit)="sendReview()" [ngClass]="{card: !activeModal, notReviewed: !review, 'border-primary': !review}">

  <div [ngClass]="{'card-header': !activeModal, 'modal-header': !!activeModal}" class="d-flex justify-content-between align-items-center">
    <ng-container *ngIf="!review"> Valora tu pedido</ng-container>
    <ng-container *ngIf="review"> Gracias por tu valoración</ng-container>
    <div *ngIf="activeModal">
      <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
    </div>
  </div>
  <div class="card-body"  [ngClass]="{'card-body': !activeModal, 'modal-body': !!activeModal}">
    <div class="d-flex gap-3 mb-3 align-items-center">
      <div class="stars d-flex align-items-center gap-1">
        <span class="star"
        [ngClass]="{ 'selected': rate <= tmpRating}"
        (mouseover)="!review && setRating(rate)"
        (click)="!review && setRating(rate)" *ngFor="let rate of ratings">
          <div>
            <fa-icon [icon]="rate <= tmpRating ? fullStarIcon : emptyStarIcon" />
          </div>
        </span>
      </div>
      <div class="label">
        {{getRateLabel(tmpRating)}}
      </div>
    </div>
    <app-form-input *ngIf="!review" [apiError]="error$|async" placeholder="Cuéntanos que te ha gustado, o lo que no!" [form]="reviewForm" controlName="message" type="textarea"/>
    <div *ngIf="review && review.message" class="p-1 border rounded">{{ review.message }}</div>
    <app-api-error-block [error]="error$|async" />
  </div>
  <div  [ngClass]="{'card-footer': !activeModal, 'modal-footer': !!activeModal}" class="text-end" *ngIf="!(review$|async)">
    <button class="btn btn-primary"  type="submit" [disabled]="isSubmitDisabled || (loading$|async)" >
      <ng-container *ngIf="(loading$|async)">
        <app-loading-spinner />
        Enviando
      </ng-container>
      <ng-container *ngIf="!(loading$|async)">
        Enviar valoración
      </ng-container>
    </button>
  </div>
</form>
