<div class="chart">
  <div class="chart-body">
    <h5 class="chart-title">{{title}}</h5>
    <app-loading-indicator *ngIf="!chartOptions"/>
    <apx-chart *ngIf="chartOptions"
    [series]="series"
    [chart]="chart"
    [xaxis]="xaxis"
    [stroke]="stroke"

    ></apx-chart>
    <!-- [dataLabels]="dataLabels || {}"
      [grid]="grid || {}" -->
    </div>
  </div>
