import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerOrderDto } from '../models/Order';

@Component({
  selector: 'app-review-order-modal',
  templateUrl: './review-order-modal.component.html',
  styleUrls: ['./review-order-modal.component.scss']
})
export class ReviewOrderModalComponent {
  @Input() order!: CustomerOrderDto
  constructor(
    public activeModal: NgbActiveModal,
  ){

  }
}
