import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../api/services/ApiService';
import { AuthService } from '../../../auth/AuthService';
import { trackEvent } from '../../../../tracker';
import { AppStore } from '../../../store';
import { authActions } from '../../../store/auth.store';

enum State {
  Loading,
  Success,
  Error
}

@Component({
  selector: 'app-confirm-account-page',
  templateUrl: './confirm-account-page.component.html',
  styleUrls: ['./confirm-account-page.component.scss']
})
export class ConfirmAccountPageComponent implements OnInit {
  private state = State.Loading;

  constructor(
    private store: AppStore,
    private authSvc: AuthService,
    private router: Router, private route: ActivatedRoute, private authService: AuthService) {

  }
  get isLoading() {
    return this.state === State.Loading
  }
  get isSuccessful() {
    return this.state === State.Success
  }
  get hasError() {
    return this.state === State.Error
  }
  ngOnInit() {
    // Access a single parameter
    const code = this.route.snapshot.paramMap.get('code');
    if (!code) {
      this.state = State.Error;
      return
    }

    this.authService.confirm(code).subscribe({
      next: ({ token }) => {
        this.state = State.Success;
        this.authSvc.setToken(token)
        this.store.dispatch(authActions.authenticateOK({ token }))
        trackEvent('account.confirmed')
        setTimeout(() => {
          this.router.navigate(['/viewer'])
        }, 1000)
      },
      error: (res) => {

        this.state = State.Error;
      }
    })
  }
}
