import * as amplitude from '@amplitude/analytics-browser';
import { environment } from './environments/environment';

export const loadTracker = ()=>{
  if(environment.amplitudeKey){
    amplitude.init(environment.amplitudeKey);
  }
}

export const setUserTracked = (userId?:string)=>{
  if(environment.amplitudeKey){
    amplitude.setUserId(userId)
  }
}

export const trackEvent = (event: string, data?: any) => {
  if(environment.amplitudeKey){
    amplitude.track(event, data)
  }
}
