import { AfterContentChecked, AfterContentInit, AfterViewChecked, AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TimeComponents, remainingTimeParts } from '../../../utils/common.utils';
import { createSubs, unsubAll } from '../../../utils/rxjs.utils';
import { interval } from 'rxjs';

@Component({
  selector: 'app-remaining-time-blocks',
  templateUrl: './remaining-time-blocks.component.html',
  styleUrls: ['./remaining-time-blocks.component.scss']
})
export class RemainingTimeBlocksComponent implements OnInit, OnDestroy {

  @Input({ required: true }) date: Date | number | null = null
  @Input() compact = false
  @Input() inverse = false
  @Input() highlightText = true
  interval = interval(1000)
  subs$ = createSubs()

  timeComponents: TimeComponents = []

  get skipLeadingZeros(){
    return this.compact
  }

  ngOnInit(): void {
    this.update()
    this.subs$.interval = this.interval.subscribe(()=>{
      this.update()
    })
  }

  update(){
    if(this.date){
      this.timeComponents = remainingTimeParts(this.date, {
        inverse: this.inverse,
        skipLeadingZeros: this.skipLeadingZeros,
        maxComponents: this.compact ?  3 : 4
      })

    }
  }

  ngOnDestroy(): void {
    unsubAll(this.subs$)
  }


  timeComponentLabel(key: string, compact = false) {
    return {
      h: compact ? 'h' : 'horas',
      m: compact ? 'm' : 'Mins',
      s: compact ? 's' : 'Segs',
      d: compact ? 'd' : 'Días',
    }[key]
  }
}
