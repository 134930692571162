import { Component, Input } from '@angular/core';
import { MerchantProfileDto } from '../../../models/Merchant';
import { faHeart, faShop, faStar } from '@fortawesome/free-solid-svg-icons';
import { socialListBuilder } from '../../../models/User';

@Component({
  selector: 'app-merchant-profile-card',
  templateUrl: './merchant-profile-card.component.html',
  styleUrls: ['./merchant-profile-card.component.scss']
})
export class MerchantProfileCardComponent {
  _profile!: MerchantProfileDto
  socials!: any
  @Input({ required: true}) set profile (profile: MerchantProfileDto) {
    this._profile = profile
    if(profile){
      this.socials = socialListBuilder(profile.user.socials)
    } else {
      this.socials = []
    }
  }
  get profile(){
    return this._profile
  }
  get routerLink () {
    return ['/viewer', 'mercado', this.profile?.user?.alias]
  }
  get user(){
    return this.profile.user
  }
  get bannerUrl(){
    return this.user.banner || 'assets/img/default_banner.png'
  }
  get rating(){
    return this.profile.reviewStats.avgRating
  }


  starIcon = faStar
  shopIcon = faShop
  followingIcon = faHeart
}
