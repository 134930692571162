import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignupPageComponent } from './pages/auth/signup-page/signup-page.component';
import { ConfirmAccountPageComponent } from './pages/account/confirm-account-page/confirm-account-page.component';
import { LoginPageComponent } from './pages/auth/login-page/login-page.component';
import { AccountPageComponent } from './pages/account/account-page/account-page.component';
import { AuthGuard } from './auth/AuthGuard';
import { OauthCallbackPageComponent } from './pages/auth/oauth-callback-page/oauth-callback-page.component';
import { LogoutPageComponent } from './pages/auth/logout-page/logout-page.component';
import { MerchantAuctionsPageComponent } from './pages/account/merchant/merchant-auctions-page/merchant-auctions-page.component';
import { CreateAuctionPageComponent } from './pages/account/merchant/create-auction-page/create-auction-page.component';
import { EditAuctionPageComponent } from './pages/account/merchant/edit-auction-page/edit-auction-page.component';
import { CustomerOrderViewPageComponent } from './pages/account/customer/customer-order-view-page/customer-order-view-page.component';
import { MerchantOrdersPageComponent } from './pages/account/merchant/merchant-orders-page/merchant-orders-page.component';
import { MerchantOrderViewPageComponent } from './pages/account/merchant/merchant-order-view-page/merchant-order-view-page.component';
import { LiveAuctionPageComponent } from './pages/customer/live-auction-page/live-auction-page.component';
import { CustomerProfilePageComponent } from './pages/account/customer/customer-profile-page/customer-profile-page.component';
import { MerchantOnboardingPageComponent } from './pages/account/merchant/merchant-onboarding-page/merchant-onboarding-page.component';
import { MerchantOnboardedGuard } from './auth/MerchantOnboardedGuard';
import { CustomerOrderListPageComponent } from './pages/account/customer/customer-order-list-page/customer-order-list-page.component';
import { AuctionWidgetPageComponent } from './pages/public/auction-widget-page/auction-widget-page.component';
import { userResolver } from './resolvers/user.resolver';
import { AuctionListPageComponent } from './pages/customer/auction-list-page/auction-list-page.component';
import { MerchantDashboardPageComponent } from './pages/account/merchant/merchant-dashboard-page/merchant-dashboard-page.component';
import { MerchantSettingsPageComponent } from './pages/account/merchant/merchant-settings-page/merchant-settings-page.component';
import { PrivacyPolicyPageComponent } from './pages/public/privacy-policy-page/privacy-policy-page.component';
import { TermsAndConditionsPageComponent } from './pages/public/terms-and-conditions-page/terms-and-conditions-page.component';

import { AccountPathGuard } from './auth/AccountPathGuard';
import { RegularAuctionPageComponent } from './pages/customer/regular-auction-page/regular-auction-page.component';
import { MerchantLiveAuctionsWidgetPageComponent } from './pages/public/merchant-live-auctions-widget-page/merchant-live-auctions-widget-page.component';
import { RecoverPasswordPageComponent } from './pages/auth/recover-password-page/recover-password-page.component';
import { RequestPasswordResetPageComponent } from './pages/auth/request-password-reset-page/request-password-reset-page.component';
import { MerchantProductsPageComponent } from './pages/account/merchant/merchant-products-page/merchant-products-page.component';
import { CreateProductPageComponent } from './pages/account/merchant/create-product-page/create-product-page.component';
import { EditProductPageComponent } from './pages/account/merchant/edit-product-page/edit-product-page.component';
import { MerchantEditProfileComponent } from './pages/account/merchant/merchant-edit-profile/merchant-edit-profile.component';
import { MerchantProfilePageComponent } from './pages/public/merchant-profile/merchant-profile-page/merchant-profile-page.component';
import { MerchantListPageComponent } from './pages/public/merchant-list-page/merchant-list-page.component';
import { CustomerAccountPageComponent } from './pages/account/customer/customer-account-page/customer-account-page.component';
import { MarketPageComponent } from './pages/public/market-page/market-page.component';
import { PlaygroundComponent } from './pages/playground/playground.component';


const infoPages = [
  { path: 'politica-de-privacidad', component: PrivacyPolicyPageComponent },
  { path: 'terminos-y-condiciones', component: TermsAndConditionsPageComponent },
]
const routes: Routes = [
  { path: '', component: AccountPageComponent, canActivate: [AuthGuard, AccountPathGuard] },
  { path: 'playground', component: PlaygroundComponent},
  { path: 'widgets/auction/live', component: MerchantLiveAuctionsWidgetPageComponent },
  { path: 'widgets/auction', component: AuctionWidgetPageComponent },
  { path: 'crear-cuenta', component: SignupPageComponent, resolve: { userResolver } },
  { path: 'restaurar-acceso/solicitar', component: RequestPasswordResetPageComponent },
  { path: 'restaurar-acceso/:code', component: RecoverPasswordPageComponent },
  { path: 'login', component: LoginPageComponent, resolve: { userResolver } },
  { path: 'logout', component: LogoutPageComponent },
  { path: 'confirmar-cuenta/:code', component: ConfirmAccountPageComponent },
  { path: 'oauth/callback/:platform', component: OauthCallbackPageComponent },
  { path: infoPages[0].path, pathMatch:'full', redirectTo: `/viewer/${infoPages[0].path}`},
  { path: infoPages[1].path, pathMatch:'full', redirectTo: `/viewer/${infoPages[1].path}`},
  {
    path: 'viewer',
    canActivate: [AuthGuard],
    resolve: { userResolver },
    component: AccountPageComponent,
    children: [
      ...infoPages,
      { path: '', redirectTo: 'cuenta', pathMatch: 'full' },
      { path: 'cuenta', component: CustomerAccountPageComponent, children:[
        {path: '', redirectTo: 'datos', pathMatch: 'full'},
        { path: 'datos', component: CustomerProfilePageComponent },
        { path: 'pedidos/:id', component: CustomerOrderViewPageComponent },
        { path: 'pedidos', component: CustomerOrderListPageComponent },

      ] },
      { path: 'subastas/live/:merchantAlias', component: LiveAuctionPageComponent },
      { path: 'subastas/:auctionId', component: RegularAuctionPageComponent },
      { path: 'subastas', component: AuctionListPageComponent },
      { path: 'mercado/vendedores', component: MerchantListPageComponent },
      { path: 'mercado/:merchantAlias', component: MerchantProfilePageComponent },
      { path: 'mercado/:merchantAlias/:tab', component: MerchantProfilePageComponent },
      { path: 'mercado', component: MarketPageComponent },

    ]
  },
  {
    path: 'streamer',
    canActivate: [AuthGuard],
    resolve: { userResolver },
    component: AccountPageComponent,
    children: [
      { path: '', component: MerchantDashboardPageComponent, canActivate: [MerchantOnboardedGuard] },
      { path: 'perfil', component: MerchantEditProfileComponent, canActivate: [MerchantOnboardedGuard] },
      { path: 'onboarding', component: MerchantOnboardingPageComponent },

      { path: 'subastas/crear', component: CreateAuctionPageComponent, canActivate: [MerchantOnboardedGuard] },
      { path: 'subastas/:id/copiar', component: CreateAuctionPageComponent, canActivate: [MerchantOnboardedGuard] },
      { path: 'subastas/:id', component: EditAuctionPageComponent, canActivate: [MerchantOnboardedGuard] },
      { path: 'subastas', component: MerchantAuctionsPageComponent, canActivate: [MerchantOnboardedGuard] },

      { path: 'pedidos/:id', component: MerchantOrderViewPageComponent, canActivate: [MerchantOnboardedGuard] },
      { path: 'pedidos', component: MerchantOrdersPageComponent, canActivate: [MerchantOnboardedGuard] },
      { path: 'pedidos/:stage', component: MerchantOrdersPageComponent, canActivate: [MerchantOnboardedGuard] },

      { path: 'productos/crear', component: CreateProductPageComponent, canActivate: [MerchantOnboardedGuard] },
      { path: 'productos/:id', component: EditProductPageComponent, canActivate: [MerchantOnboardedGuard] },
      { path: 'productos', component: MerchantProductsPageComponent, canActivate: [MerchantOnboardedGuard] },

      { path: 'configuracion', component: MerchantSettingsPageComponent, canActivate: [MerchantOnboardedGuard] },

      ...infoPages
    ]
  },
  {path: '**', redirectTo:'/'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
