import { Component } from '@angular/core';
import { TwitchOauthCb } from '../../../auth/auth.types';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, map, skip } from 'rxjs';
import { UiService } from '../../../ui/services/ui.service';
import { AppStore } from '../../../store';
import { AuthState, authActions, authFeat } from '../../../store/auth.store';

@Component({
  selector: 'app-oauth-callback-page',
  templateUrl: './oauth-callback-page.component.html',
  styleUrls: ['./oauth-callback-page.component.scss']
})
export class OauthCallbackPageComponent {
  twitchCb: TwitchOauthCb | undefined

  public constructor(
    private route: ActivatedRoute,
    private router: Router,
    private uiCommonSvc: UiService,
    private appStore: AppStore
  ) {

  }

  get isLoading$() {
    return this.appStore.select(authFeat.selectLoading)
  }
  get isSuccessful$() {
    return this.appStore.select(authFeat.selectToken).pipe(map(v => !!v))
  }
  get error$() {
    return this.appStore.select(authFeat.selectError).pipe(map(v => !!v))
  }

  ngOnInit() {
    this.uiCommonSvc.setTitle('Procesando')

    const { platform } = this.route.snapshot.params
    if (platform === 'twitch') {
      const { code, state } = this.route.snapshot.queryParams
      this.twitchCb = { code, state }


      this.appStore.select(authFeat.selectAuthState).pipe(skip(1)).subscribe((state) => {
        if(state.loading){
          return
        }
        if (!state.token || state.error) {
          this.router.navigate(['/']);
          return
        }

      })
      const retAfterLoginCacheKey = `oauth.callback.${state}.returnAfter`
      const returnAfterLogin = localStorage.getItem(retAfterLoginCacheKey)
      localStorage.removeItem(retAfterLoginCacheKey)
      this.appStore.dispatch(authActions.authenticateViaOauth({ platform: 'twitch', oauthToken: code, returnAfterLogin }))

      return
    }
    this.router.navigate(['/']);
  }
}
