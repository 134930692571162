<div *ngIf="auction" class="auction" [ngClass]="classes">

  <div class="picture" *ngIf="this.settings?.displayProductPicture">
    <img [src]="auction.pictureUrl" class="rounded" />
  </div>

  <div class="bids-and-others">
    <div class="title">{{auction.title}}</div>
    <div>
      <div class="no-bids" *ngIf="bidsToDisplay.length === 0">
        😍 Precio salida <b>{{auction.nextBidAmount}} {{auction.currencySymbol}}</b>

      </div>


      <div class="bids" *ngIf="bidsToDisplay.length > 0">
        <div class="bid" *ngFor="let bid of bidsToDisplay; index as i" [ngClass]="{last: isLastBid(i), winner: isFinished && isLastBid(i)}">

          <div class="avatar">
            <img [src]="bid.avatar" />
          </div>
          <div class="amount-and-bidder">
            <b>
              {{bid.amount}}<span class="currency">{{auction.currencySymbol}}</span>
            </b>
            &nbsp;por
            <span class="bidder"><b class="alias">{{bid.alias}}</b></span>
          </div>
          <div class="trophy" *ngIf="isFinished && isLastBid(i)">
            🏆
          </div>
        </div>
      </div>
      <div class="cmd-wrapper my-1" *ngIf="isStarted">
        Escribe <span class="cmd">!puja {{auction.nextBidAmount}}</span> en el chat
      </div>
      <div class="delivery-price" *ngIf="auction.deliveryPrice">
        +{{auction.deliveryPrice}}{{auction.currencySymbol}} gastos de envío
      </div>
    </div>
    <div *ngIf="!isFinished && isStarted" class="time-left mt-auto d-flex align-items-center justify-content-center gap-1" [ngClass]="{'kf-flash': isAboutToFinish}">
     Termina en <app-remaining-time-blocks [compact]="true" [date]="auction.endsAt"/>
    </div>
    <div *ngIf="isFinished" class="time-left">
      La subasta ha terminado
    </div>
    <div *ngIf="isCreated" class="time-left mt-auto d-flex align-items-center justify-content-center gap-1">
      <ng-container *ngIf="auction.startsAt">
      Empieza en <app-remaining-time-blocks [compact]="true" [date]="auction.startsAt"/>
      </ng-container>
      <ng-container *ngIf="!auction.startsAt">
        Empezará pronto
      </ng-container>
    </div>
    <div class="footer">

      <ng-containter [ngTemplateOutlet]="poweredBy"/>
    </div>
  </div>
</div>



<div *ngIf="!auction" class="auction" [ngClass]="classes">
    <div class="time-left mt-auto d-flex align-items-center justify-content-center gap-1">
      No hay ninguna subasta activa
    </div>
    <div class="footer">

      <ng-containter [ngTemplateOutlet]="poweredBy"/>
    </div>
  </div>


<ng-template #poweredBy>
  <div class="powered-by align-items-center d-flex justify-content-center gap-1">
    <img src="/assets/logo/net_white_icon.png" height="15" />
    <div>www.suass.live</div>
  </div>
</ng-template>

