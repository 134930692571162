<div class="row">
  <div class="col-12 col-lg-4">
    <div class="d-flex flex-column h-100 mb-4 mb-lg-0">

      <div class="flex-grow-1">
        <h6>Parámetros</h6>
        <form [formGroup]="form">
          <app-form-input type="checkbox" [form]="form" controlName="horizontal" marginBottom="3">
            Horizontal
          </app-form-input>
          <app-form-input type="checkbox" [form]="form" controlName="displaySecondToLastBid" marginBottom="3">
            Mostrar penúltima puja
          </app-form-input>
          <app-form-input type="checkbox" [form]="form" controlName="displayProductPicture" marginBottom="3">
            Mostrar foto producto
          </app-form-input>
        </form>
        <h6>Simulación</h6>
        <button class="btn btn-outline-secondary" (click)="simulateBid()">Simular puja</button>
      </div>

    </div>
  </div>
  <div class="col-12 col-lg-8">
    <div class="bg-secondary rounded d-flex justify-content-center align-items-center p-3 h-100">
      <app-loading-indicator *ngIf="!auction" />
      <app-auction-widget *ngIf="auction" [auction]="auction" [settings]="settings" />
    </div>
  </div>
</div>
