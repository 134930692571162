import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuctionChannel, AuctionState, IAuction, IAuctionExpanded } from '../../../models/Auction';
import { faCartShopping, faClone, faCopy, faEdit, faEye, faPlay, faRepeat, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { UiService } from '../../services/ui.service';
import { AppStore } from '../../../store';
import { mAuctionListActions, mAuctionListFeat } from '../../../store/merchant.store';
import { map } from 'rxjs';
import { createSubs, unsubAll } from '../../../utils/rxjs.utils';
import { getApiErrorMessage } from '../../../utils/api.utils';
import { Actions } from '@ngrx/effects';
import { RealTimeService } from '../../../api/services/RealTimeService';
import { DatatableRow } from '../datatable/datatable.interface';

@Component({
  selector: 'tbody[app-merchant-auction-list-item]',
  templateUrl: './merchant-auction-list-item.component.html',
  styleUrls: ['./merchant-auction-list-item.component.scss']
})
export class MerchantAuctionListItemComponent implements OnInit, OnDestroy, DatatableRow<IAuctionExpanded> {
  @Input({ required: true }) auction!: IAuctionExpanded
  @Input() set item(item: IAuctionExpanded){
    this.auction = item
  }
  copyIcon = faCopy
  playIcon = faPlay
  deleteIcon = faTrashAlt
  repeatIcon = faClone
  viewIcon = faEye
  orderIcon = faCartShopping
  editIcon = faEdit
  endsIn: Record<string, string | null> = {}
  subs$ = createSubs()

  constructor(
    private uiSvc: UiService,
    private store: AppStore,
    private rtSvc: RealTimeService
  ) { }

  ngOnInit(): void {
    if([AuctionState.Started].includes(this.auction.state)){
      this.subs$.waitingToClose = this.rtSvc.subToAuction(this.auction.id).subscribe(({event, data: auction})=>{
        this.auction = auction
      })
    }
  }

  notifyError(error: any) {
    const errMsg = getApiErrorMessage(error.error) || ''
    this.uiSvc.showErrorToast({ text: errMsg })
  }

  ngOnDestroy() {
    unsubAll(this.subs$)
  }


  showCopiedToClipboardNotification() {
    this.uiSvc.showToast({ text: 'URL copiada al portapapeles', type: 'success' })
  }

  /**
   * Actions
   */
  del() {
    if (confirm("Estás seguro?")) {
      this.store.dispatch(mAuctionListActions.deleteAuction({ auctionId: this.auction.id }))
    }
  }
  duplicateAuction() {
    this.store.dispatch(mAuctionListActions.duplicateAuction({ duplicateAuctionId: this.auction.id }))
  }

  start(){
    this.store.dispatch(mAuctionListActions.startAuction({ startAuctionId: this.auction.id }))
  }
  /**
   * Status helpers
   */
  get isCreated() {
    return this.auction.state === AuctionState.Created
  }

  get isStarted(){
    return this.auction.state === AuctionState.Started
  }

  get isLive(){
    return this.isStarted && this.auction.channels.includes(AuctionChannel.Live)
  }

  get isLiveNotStarted(){
    return !this.isStarted && this.auction.channels.includes(AuctionChannel.Live)
  }

  get isClosed() {
    return this.auction.state === AuctionState.Closed
  }
  get isDeleting$() {
    return this.store.select(mAuctionListFeat.selectDeletingAuctionId)
  }

  get isStarting$() {
    return this.store.select(mAuctionListFeat.selectStartAuctionId)
  }

  get isDuplicating$() {
    return this.store.select(mAuctionListFeat.selectDuplicateAuctionId)
  }

  get isAuctionBeingDeleted$() {
    return this.isDeleting$.pipe(map(id => id === this.auction.id))
  }


  get isAuctionBeingRepeated$() {
    return this.isDuplicating$.pipe(map(id => id === this.auction.id))
  }
  /**
   * Links
   */
  get widgetUrl() {
    return `${location.origin}/widgets/auction/?auction=${this.auction.id}`
  }
  get editLink() {
    return [`/streamer/subastas/${this.auction.id}`]
  }
  get orderLink() {
    return [`/streamer/pedidos/${this.auction.orderId}`]
  }
  get viewLink() {
    return [`/viewer/subastas/${this.auction.id}`]
  }

  /**
   * Bids
   */
  get winningBid() {
    return this.auction.bids[0]
  }
}
