import { Component, HostListener, OnInit } from "@angular/core"
import { IUser } from "../../../models/User";
import { UiService } from "../../services/ui.service";
import { AppStore } from "../../../store";
import { userFeat } from "../../../store/user.store";
import { environment } from "../../../../environments/environment";

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {
  bgStartPoint = 20
  background = 'transparent';

  user: IUser | null = null;
  get isMenuCollapsed() {
    return this.uiSvc.isMenuCollapsed
  };

  get isStaging() {
    return !environment.production
  }

  constructor(public uiSvc: UiService, public appStore: AppStore) { }

  ngOnInit(): void {
    this.appStore.select(userFeat.selectUser).subscribe(user => {
      this.user = user
    })
  }

  @HostListener("window:scroll", ['$event'])
  doSomethingOnInternalScroll($event: Event) {
    let scrollOffset = window.pageYOffset || window.scrollY;

    if (scrollOffset > this.bgStartPoint) {
      const a = (scrollOffset - this.bgStartPoint) / 100
      this.background = `rgba(255,255,255, ${a})`
    } else {
      this.background = 'transparent'

    }
  }
}
