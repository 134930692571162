import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { AuthService } from '../../../auth/AuthService';
import { faCheck, faSpinner, faLock, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faTwitch } from '@fortawesome/free-brands-svg-icons'
import { IApiErrorResponse } from '../../../api/services/ApiService';
import { HttpErrorResponse } from '@angular/common/http';
import { emailToMailboxUrl } from '../../../utils/mail';
import { ActivatedRoute } from '@angular/router';
import { trackEvent } from '../../../../tracker';

enum SignupFormState {
  Loading = 'loading',
  Idle = 'idle',
  Success = 'success',
}

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  apiError: IApiErrorResponse | null = null;

  state = SignupFormState.Idle;
  form = new FormGroup({
    email: new FormControl('', {
      validators: [Validators.required, Validators.email],
      nonNullable: true,
    }),
    password: new FormControl('', {
      validators: [Validators.required, Validators.minLength(6)],
      nonNullable: true,
    }),
    repeatPassword: new FormControl('', {
      validators: [Validators.required,],
      nonNullable: true,
    }),
    acceptTerms: new FormControl(false, {
      validators: [Validators.requiredTrue],
    }),
  }, {
    validators: [this.passwordMatchValidator]
  })

  btnSuccessIcon = faCheck
  btnLoadingIcon = faSpinner
  twitchIcon = faTwitch
  emailIcon = faEnvelope
  passIcon = faLock

  constructor(private authService: AuthService, private route: ActivatedRoute) {

  }

  get loginLink() {
    return `/login`
  }

  ngOnInit() {
    (window as any).t = this
    this.route.queryParams.subscribe(params => {
      if (params['twitch_callback'] === '1') {
        this.state = SignupFormState.Loading;
      }
    })
  }

  private passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
    const password = control.get('password');
    const repeatPassword = control.get('repeatPassword');
    if (!password?.value || !password.value || !repeatPassword?.value) {
      return null
    }
    if (password?.value !== repeatPassword?.value) {
      return { passwordMatch: true }
    }
    return null;
  }
  get isIdle() {
    return this.state === SignupFormState.Idle;
  }
  get isLoading() {
    return this.state === SignupFormState.Loading;
  }

  get formHasErrors() {
    return this.form.invalid && this.form.touched;
  }

  get success() {
    return this.state === SignupFormState.Success;
  }

  get isCtaDisabled() {
    return this.state !== SignupFormState.Idle || this.form.invalid
  }

  get suggestedMailboxUrl(): string | null {
    const email = this.form.get('email')?.value;
    if (!email) {
      return null;
    }
    return emailToMailboxUrl(email);
  }

  onSubmit() {
    if (!this.form.valid) {
      return;
    }

    this.state = SignupFormState.Loading;
    const { email, password } = this.form.getRawValue()
    this.authService.signup({
      email,
      password
    }).subscribe({
      next: this.onSuccess.bind(this),
      error: this.onError.bind(this),
    })
  }

  protected onSuccess(res: any) {
    this.apiError = null
    trackEvent('signup')
    this.state = SignupFormState.Success
  }

  protected onError(res: HttpErrorResponse) {
    this.apiError = res.error
    this.state = SignupFormState.Idle
  }
}
