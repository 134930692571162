<div class="payment-method-label d-flex gap-2 align-items-center">
  <img class="card-image" [src]="src" />
  <div *ngIf="isPaypal">{{paymentMethod.data.email}}</div>
  <div class="d-flex flex-column" *ngIf="paymentMethod.type === 'card' ">
    <div *ngIf="paymentMethod.alias" class="alias">{{paymentMethod.alias}}</div>
    <div class="digits d-flex align-items-center" *ngIf="paymentMethod.data.last4">
      <span>****</span>
      <span>****</span>
      <span>****</span>
      <span class="last-4">
        {{paymentMethod.data.last4}}
      </span>
    </div>
  </div>
</div>
