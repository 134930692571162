<ng-container *ngIf="error">
  <div class="alert alert-danger">
    <h6 class="alert-heading mb-1">Hubo un problemita</h6>
    <div *ngIf="errorList.length === 0">{{message}}</div>
    <ng-container *ngIf="errorList.length > 0" class="mb-0">
      <ng-container *ngFor="let error of errorList">
        <!-- {{error.property}}
        <ul *ngIf="error.errors">
          <li *ngFor="let propError of error.errors">
            {{propError.msg}}
            </li>
        </ul> -->
        <ul *ngIf="error.errors">
          <li *ngFor="let propError of error.errors">
            {{propError.msg}}
            </li>
        </ul>
      </ng-container>
    </ng-container>
  </div>
  </ng-container>
