<ng-container *ngIf="auction; else loadingAuction">

  <div class="card auction-card">
    <div class="card-body">
      <div class="row ">
        <div class="col-12 col-sm-5 col-md-6 col-lg-6">
          <div class="auction-picture d-flex justify-content-center rounded">
            <img [src]="auction.pictureUrl" class="rounded" />
          </div>
        </div>
        <div class="col-12 col-sm-7 col-md 6 col-lg-6">

          <h1>{{auction.title}}</h1>

          <div class="bg-body-secondary mb-3 p-2 rounded description" *ngIf="auction.description" [innerHTML]="auction.description">
          </div>

          <div class="row align-items-center">
            <div class="col-12 col-lg-6 mb-3">
              <div class="detail-title">Vendedor</div>
              <div class="merchant">
                <img [src]="auction.merchant.avatar" />
                <div class="alias">{{auction.merchant.alias}}</div>
                <div class="ms-auto">
                  <a [routerLink]="merchantProfileLink" class="btn btn-secondary">Ver perfil</a>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 mb-3">
              <ng-container [ngTemplateOutlet]="auctionCountdown" />
            </div>
          </div>

          <div class="detail-title">Haz tu puja</div>
          <app-bid-form [auction]="auction" [user]="user" [onboardingStatus]="onboardingStatus$|async" />
          <div class="mb-3 mt-3">
            <ng-container *ngIf="lastBid; else noBidMsg">
              <div class="d-flex justify-content-between align-items-center">
                <div class="detail-title">
                  Pujas
                </div>
                <div>
                  <button class="btn btn-link p-0" *ngIf="otherBids.length > 0" (click)="showAllBids = !showAllBids">{{allBidsTogglerText}}</button>
                </div>
              </div>
              <app-bid [highest]="true" [auction]="auction" [bid]="lastBid" />
              <div *ngIf="showAllBids" class="all-bids mt-3" >
                <div class="detail-title">
                  Pujas anteriores
                </div>
                <div class="list-group">
                  <ng-container *ngFor="let bid of otherBids">
                    <li class="list-group-item p-1">
                      <app-bid template="one-liner" [auction]="auction" [bid]="bid" />
                    </li>
                  </ng-container>
                </div>
              </div>
            </ng-container>
            <ng-template #noBidMsg>
              <div class="detail-title">Pujas</div>
              <div class="alert alert-secondary">Aún no ha pujado nadie!</div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>

</ng-container>


<ng-template #auctionCountdown>
  <ng-container *ngIf="isAuctionStarted">
    <div class="detail-title">Termina en</div>
    <app-remaining-time-blocks [date]="auction.endsAt" />
  </ng-container>
  <ng-container *ngIf="isAuctionCreated && auction.startsAt;">
    <div class="detail-title">Empieza en</div>
    <app-remaining-time-blocks [date]="auction.startsAt" [inverse]="true" />
  </ng-container>
  <ng-container *ngIf="isAuctionClosed">
    <div class="detail-title">Terminó hace</div>
    <app-remaining-time-blocks [inverse]="true" [date]="auction.endedAt" [highlightText]="false" />
  </ng-container>
</ng-template>
<ng-template #loadingAuction>
  <div class="card">
    <div class="card-body">
      <app-loading-indicator />
    </div>
  </div>
</ng-template>
