<div class="item h-100 d-flex flex-column rounded" [ngClass]="{compact}">
  <!-- Merchant header -->
  <div class="merchant d-flex gap-1 align-items-center" *ngIf="showMerchantHedaer">
    <img [src]="merchantAvatar" class="picture" />
    <div>{{merchantAlias}}</div>
    <div class="d-flex align-items-center  gap-1 ms-auto">
      <span class="live badge d-flex gap-1 text-danger" *ngIf="hasLiveChannels">
        LIVE
        <span class="platforms d-flex gap-1">
            <app-stream-platform-icon *ngFor="let channel of onLiveChannels" [class]="channel.platform" [platform]="channel.platform"/>
        </span>
      </span>
      <span class="live badge text-bg-secondary" *ngIf="hasLiveChannels && viewerCount > 0">
        <fa-icon [icon]="viewerIcon" /> {{viewerCount }}
      </span>
    </div>
  </div>
  <!-- Auction start-->
  <div class="auction flex-grow-1 p-2 text-center" *ngIf="auction">
    <a class="link" [routerLink]="getAuctionLink(auction)">
      <div class="picture">
        <img [src]="auction.pictureUrl" />
      </div>
      <div class="title">
        {{auction.title}}
      </div>
    </a>
  </div>
  <!-- Pro mode -->
  <div class="py-1 px-2" *ngIf="enableBiding && auction && isRunning">
    <app-fast-bid-btn [auction]="auction" />
  </div>
  <!-- Auction end -->
  <div class="row footer align-items-center">
    <div class="col-7 text-sm-center" *ngIf="latestBid">
      <ng-container [ngTemplateOutlet]="bid" />
    </div>
    <div [ngClass]="{'col-12': !latestBid, 'col-5': latestBid}" class="text-sm-center">
      <ng-container [ngTemplateOutlet]="endsIn"/>
    </div>
  </div>

</div>

<ng-template #endsIn>
  <div class="d-flex ends-at flex-column justify-content-center text-center" *ngIf="auction">
    <ng-container *ngIf="isRunning">
      <div class="title">Termina en</div>
      <app-remaining-time-blocks [date]="auction.endsAt" [compact]="true" />
    </ng-container>
    <ng-container *ngIf="didNotStart">
      <ng-container *ngIf="!auction.startsAt">
        <div class="title">A punto de empezar</div>
      </ng-container>
      <ng-container *ngIf="auction.startsAt">
        <div class="title">Empieza en</div>
        <app-remaining-time-blocks [date]="auction.startsAt" [compact]="true" />
      </ng-container>
    </ng-container>
    <ng-container *ngIf="isClosed">
      <div class="title">Terminó hace</div>
      <app-remaining-time-blocks [inverse]="true" [date]="auction.endsAt" [compact]="true" />
    </ng-container>
  </div>
</ng-template>

<ng-template #bid>
  <div class="bid" [ngClass]="{winner: isClosed}" *ngIf="auction && latestBid">

        <img class="picture" [src]="latestBid.avatar" />
        <div class="text-start" style="min-width: 0">
          <div class="alias">
            <div>
              <ng-container *ngIf="isClosed">🏆</ng-container>
              {{latestBid.alias}}
            </div>
          </div>
          <div><b>{{latestBid.amount}}{{auction.currencySymbol}}</b></div>
        </div>
    </div>
</ng-template>
