import { Component, Input } from '@angular/core';
import { IconDefinition, fa0 } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-dashboard-tile',
  templateUrl: './dashboard-tile.component.html',
  styleUrls: ['./dashboard-tile.component.scss']
})
export class DashboardTileComponent {

  @Input({required:true}) title!: string
  @Input({required:true}) icon!: IconDefinition
  @Input({required:true}) value!: string

  @Input() loading? = false
  @Input() subtitle?: string
  @Input() theme: string = 'secondary'
  @Input() unit!: string

  @Input() compact = false

  get bgClass(){
    return `bg-${this.theme}`
  }
}
