import { Component } from '@angular/core';
import { IApiErrorResponse } from '../../../api/services/ApiService';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { faCheck, faLock, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../../auth/AuthService';
import { ActivatedRoute, Router } from '@angular/router';
import { emailToMailboxUrl } from '../../../utils/mail';
import { HttpErrorResponse } from '@angular/common/http';
import { AppStore } from '../../../store';
import { authActions } from '../../../store/auth.store';

enum FormState {
  Loading = 'loading',
  Idle = 'idle',
  Success = 'success',
}

@Component({
  selector: 'app-recover-password-page',
  templateUrl: './recover-password-page.component.html',
  styleUrls: ['./recover-password-page.component.scss']
})
export class RecoverPasswordPageComponent {
  apiError: IApiErrorResponse | null = null;

  state = FormState.Idle;
  form = new FormGroup({
    code: new FormControl('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    newPassword: new FormControl('', {
      validators: [Validators.required, Validators.minLength(6)],
      nonNullable: true,
    }),
    repeatPassword: new FormControl('', {
      validators: [Validators.required,],
      nonNullable: true,
    }),

  }, {
    validators: [this.passwordMatchValidator]
  })

  btnSuccessIcon = faCheck
  btnLoadingIcon = faSpinner
  passIcon = faLock

  constructor(
    private store: AppStore,
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute) {

  }

  get loginLink() {
    return `/login`
  }

  ngOnInit() {
    this.route.params.subscribe(({ code }) => {
      if (!code) {
        this.router.navigate(['/recuperar-acceso'])
        return
      }

      this.form.get('code')?.setValue(code)

    })
  }

  private passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
    const password = control.get('newPassword');
    const repeatPassword = control.get('repeatPassword');
    if (password?.value !== repeatPassword?.value) {
      return { passwordMatch: true }
    }
    return null;
  }
  get isIdle() {
    return this.state === FormState.Idle;
  }
  get isLoading() {
    return this.state === FormState.Loading;
  }

  get formHasErrors() {
    return this.form.invalid && this.form.touched;
  }

  get success() {
    return this.state === FormState.Success;
  }

  get isCtaDisabled() {
    return this.state !== FormState.Idle || this.form.invalid
  }

  onSubmit() {
    if (!this.form.valid) {
      return;
    }

    this.state = FormState.Loading;
    const { code, newPassword } = this.form.getRawValue()
    this.authService.performPasswordReset({
      code,
      newPassword
    }).subscribe({
      next: this.onSuccess.bind(this),
      error: this.onError.bind(this),
    })
  }

  protected onSuccess({ token }: any) {
    this.apiError = null
    this.authService.setToken(token)
    this.store.dispatch(authActions.authenticateOK(token))
    setTimeout(() => {
      this.router.navigate(['/viewer'])
    }, 1000)
    this.state = FormState.Success
  }

  protected onError(res: HttpErrorResponse) {
    this.apiError = res.error
    this.state = FormState.Idle
  }
}
