import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MerchantOrderItemDto, MerchantOrderDto, OrderStatus, isCancelable, PaymentStatus } from '../../../models/Order';
import { prettyId } from '../../../utils/common.utils';
import { faCalendar, faCancel, faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { UiOrderService } from '../../services/ui-order.service';
import { AppStore } from '../../../store';
import { mOrderListActions, mOrderListFeat } from '../../../store/merchant.order-list.store';
import { map } from 'rxjs';
import { stateCodeToProvince } from '../../../utils/address.utils';
import BigNumber from 'bignumber.js';
import { createSubs, unsubAll } from '../../../utils/rxjs.utils';
import { Actions, ofType } from '@ngrx/effects';
import { UiService } from '../../services/ui.service';
import { getApiErrorMessage } from '../../../utils/api.utils';


@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'tbody[app-merchant-order-list-item]',
  templateUrl: './merchant-order-list-item.component.html',
  styleUrls: ['./merchant-order-list-item.component.scss']
})
export class MerchantOrderListItemComponent implements OnInit, OnDestroy {
  @Input({ required: true }) order!: MerchantOrderDto
  @Input() opened = false
  cancelIcon = faCancel
  sendLaterIcon = faCalendar

  subs$ = createSubs()

  get toggleIcon() {
    return this.opened ? faCaretUp : faCaretDown
  }
  constructor(
    private uiOrderSvc: UiOrderService,
    private uiSvc: UiService,
    private actions: Actions,
    private store: AppStore,
  ) { }
  ngOnInit(): void {

    this.subs$.refundError = this.actions.pipe(ofType(mOrderListActions.refundOrderItemKO)).subscribe(({ error }: any) => {
      this.notifyError(error)
    })
    this.subs$.cancelOrderError = this.actions.pipe(ofType(mOrderListActions.cancelOrderKO)).subscribe(({ error }: any) => {
      this.notifyError(error)
    })
    this.subs$.loadError = this.actions.pipe(ofType(mOrderListActions.loadOrdersKO)).subscribe(({ error }: any) => {
      this.notifyError(error)
    })
  }
  ngOnDestroy(): void {
    unsubAll(this.subs$)
  }

  itemPaymentIsNotSuccessful(item: MerchantOrderItemDto){
    return this.order.payments.find((p: any) => p.id === item.paymentId).status !== PaymentStatus.Succeeded
  }
  notifyError(error: any) {
    const errMsg = error.message ? getApiErrorMessage(error) : ''
    this.uiSvc.showErrorToast({ text: errMsg })
  }
  get customerAddressText() {
    const { line1, line2, postalCode, city, state } = this.order.deliveryAddress
    return `${line1}, ${line2 || ''}<br/>${postalCode}, ${city}, ${stateCodeToProvince(state)}, España`
  }
  get auctionLink() {
    return ['/panel', 'm', 'subastas', this.order.auctionId]
  }
  get prettyOrderId() {
    return prettyId(this.order.id);
  }

  get prettyAmount() {
    return `${this.order.total}${this.order.currencySymbol}`
  }
  itemPrettyAmount(item: MerchantOrderItemDto) {
    return `${item.total}${this.order.currencySymbol}`
  }

  productImageStyle(item: MerchantOrderItemDto) {
    return {
      'background-image': `url(${item.imageUrl})`
    }
  }

  actionsDisabled$ = this.store.select(mOrderListFeat.processingIds).pipe(
    map(ids => ids.includes(this.order.id))
  )

  toggle() {
    this.opened = !this.opened
  }



  canSend() {
    return [OrderStatus.Paid, OrderStatus.PendingDelivery].includes(this.order.status)
  }
  canSendLater() {
    return this.order.status === OrderStatus.Paid
  }
  send() {
    this.uiOrderSvc.openAddDeliveryModal(this.order.id, { later: false }).subscribe(order => this.order = order)
  }
  sendLater() {
    this.uiOrderSvc.openAddDeliveryModal(this.order.id, { later: true }).subscribe(order => this.order = order)
  }
  getOrderDeliveryLabel() {
    this.uiOrderSvc.downloadDeliveryLabel(this.order.id)
  }
  canCancel() {
    return isCancelable(this.order.status)
  }
  canCancelItem(item: MerchantOrderItemDto) {
    return isCancelable(this.order.status) && new BigNumber(item.total).gt(0)
  }
  cancelItem(item: MerchantOrderItemDto) {
    if (!confirm(`¿Seguro que quieres cancelar ${item.description} ? Esto hará un reembolso completo`)) {
      return
    }
    this.store.dispatch(mOrderListActions.refundOrderItem({ orderId: this.order.id, orderItemId: item.id, amount: item.total }))
  }
  cancel() {
    if (!confirm('¿Seguro que quieres cancelar? Esto hará un reembolso completo')) {
      return
    }
    this.store.dispatch(mOrderListActions.cancelOrder({ orderId: this.order.id }))
  }
  isCancelingOrderItem$(item: MerchantOrderItemDto) {
    return this.store.select(mOrderListFeat.processingIds).pipe(
      map(ids => ids.includes(item.id))
    )
  }

}
