import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppStore } from '../../../store';
import { IProduct } from '../../../models/Product';
import { CustomerOrderDto } from '../../../models/Order';
import { BuyConfirmDetailsDto, CustomerService } from '../../../api/services/CustomerService';
import { cDirectBuyFormStore } from '../../../store/customer/customer.shop.store';
import BigNumber from 'bignumber.js';
import { Actions, ofType } from '@ngrx/effects';
import { userFeat } from '../../../store/user.store';
import { delay, map } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { loadingSuccessDelay } from '../../../utils/rxjs.utils';

@Component({
  selector: 'app-buy-confirmation-modal',
  templateUrl: './buy-confirmation-modal.component.html',
  styleUrls: ['./buy-confirmation-modal.component.scss']
})
export class BuyConfirmationModalComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal,
    public actions$: Actions,
    public customerSvc: CustomerService,
    public store: AppStore
  ) { }

  @Input({ required: true }) product!: IProduct
  @Output() onSuccess = new EventEmitter<CustomerOrderDto>

  form = new FormGroup({
    productId: new FormControl('', [Validators.required]),
    paymentMethodId: new FormControl('', [Validators.required])
  })
  get paymentMethodControl(): FormControl {
    return this.form.get('paymentMethodId') as FormControl
  }
  orderId?: string

  error$ = this.store.select(cDirectBuyFormStore.feat.selectError)
  loading$ = this.store.select(cDirectBuyFormStore.feat.selectLoading).pipe(loadingSuccessDelay(1000))
  confirmDetails?: BuyConfirmDetailsDto

  paymentMethods$ = this.store.select(userFeat.selectUser).pipe(map(user => user?.paymentMethods))

  get order() {
    return this.confirmDetails?.existingOrder
  }
  get total(){
    if(!this.confirmDetails){
      return 0
    }
    return new BigNumber(this.confirmDetails.deliveryPriceWithTax).plus(this.product.price).toFixed(2)
  }
  ngOnInit(): void {
    this.customerSvc.getBuyConfirmDetails(this.product.id).subscribe(confirmDetails => {
      this.confirmDetails = confirmDetails
      this.form.get('productId')?.patchValue(this.product.id)
    })

    this.store.dispatch(cDirectBuyFormStore.actions.reset())
    this.actions$.pipe(ofType(cDirectBuyFormStore.actions.createOK)).subscribe(({ orderId }) => {
      this.orderId = orderId
    })
  }

  get deliveryPrice() {
    return this.confirmDetails?.deliveryPriceWithTax || this.product.deliveryPrice
  }

  get isSaveDisabled (){
    return !this.form.valid || !this.confirmDetails
  }

  save() {
    if (!this.form.valid || this.isSaveDisabled || !this.confirmDetails) {
      return;
    }
    const { productId, paymentMethodId } = this.form.value
    const payload = {
      productId: productId!,
      paymentMethodId: paymentMethodId!
    }
    return this.store.dispatch(cDirectBuyFormStore.actions.create({ payload }))
  }
}
