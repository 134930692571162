import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';

import { AppStore } from '../../../../store';
import { pMerchantProfile } from '../../../../store/public/merchant.profile.store';
import { ActivatedRoute } from '@angular/router';
import { MerchantProfileDto } from '../../../../models/Merchant';
import { UiShopService } from '../../../../ui/services/UiShopService';

const tabs = ['tienda', 'subastas', 'valoraciones']
@Component({
  selector: 'app-merchant-profile-page',
  templateUrl: './merchant-profile-page.component.html',
  styleUrls: ['./merchant-profile-page.component.scss']
})
export class MerchantProfilePageComponent implements OnInit{
  constructor(
    private store: AppStore,
    private route: ActivatedRoute,
    private location: Location

  ) {}
  tab = 'tienda'
  profile: MerchantProfileDto | null = null
  ngOnInit(): void {
    this.route.params.subscribe(({merchantAlias, tab})=>{
      if(tabs.includes(tab)){
        this.tab = tab
      } else {
        this.tab = 'tienda'
      }
      this.store.dispatch(pMerchantProfile.actions.load({ id: merchantAlias}))
    })
    this.store.select(pMerchantProfile.feat.selectData).subscribe(profile=>{
      this.profile = profile
    })
  }

  loading$ = this.store.select(pMerchantProfile.feat.selectLoading)

  onTabChange(tab: string){
    this.location.replaceState(`/viewer/mercado/${this.profile?.user.alias}/${tab}`);
  }
}
