<!-- <div class="d-flex justify-content-center mb-3" *ngIf="hasAuctions$|async">
  <app-form-input placeholder="Buscar" [form]="filterForm" controlName="search" />
</div> -->
<app-loading-indicator *ngIf="loading$|async" />
<div *ngIf="!(hasAuctions$|async)" class="alert alert-secondary">No hay subastas para mostrar</div>
<div class="row">

  <ng-container *ngFor="let auction of (auctions$|async)">
    <div class="col-12 col-sm-6 col-md-4 mb-4">
      <app-auction-wm-list-item [showMerchantHedaer]="false" [auction]="auction"></app-auction-wm-list-item>
    </div>
  </ng-container>
</div>
<div class="d-flex justify-content-center mb-5">
  <app-pagination [listStore]="listStore" [autoHideOnePager]="true" />
</div>
