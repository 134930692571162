import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AuctionState, IAuctionExpanded } from '../../../models/Auction';
import { remainingSeconds } from '../../../utils/common.utils';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { MerchantWidgetSettings, WidgetOrientation } from '../../../models/Order';

const defSettings: MerchantWidgetSettings = {
  orientation: WidgetOrientation.Vertical,
  displayProductPicture: true,
  displaySecondToLastBid: true
}
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-auction-widget',
  templateUrl: './auction-widget.component.html',
  styleUrls: ['./auction-widget.component.scss']
})
export class AuctionWidgetComponent implements OnInit, OnDestroy {
  @Input() auction?: IAuctionExpanded
  @Input() settings?: MerchantWidgetSettings = defSettings

  interval: any
  timeLeft = Infinity
  timeLeftIcon = faClock

  get isAboutToFinish(){
    return this.timeLeft < 10
  }
  get isCreated(){
    return this.auction?.state === AuctionState.Created
  }
  get isStarted(){
    return this.auction?.state === AuctionState.Started
  }
  get isFinished(){
    const isFinishedOutdated = this.auction?.state === AuctionState.Started && this.timeLeft <= 0
    return this.auction?.state === AuctionState.Closed || isFinishedOutdated;
  }

  get timeLeftTxt () {
    return Math.ceil(this.timeLeft) + 's'
  }

  get classes () {
    if(!this.auction){
      return { 'waiting': true }
    }
    return {
      'no-prod-pic': !this.settings?.displayProductPicture,
      'd-two-bids': this.settings?.displaySecondToLastBid,
      [this.settings?.orientation || defSettings.orientation]: true
    }
  }
  ngOnInit(): void {
    this.timeLeft = remainingSeconds(this.auction?.endsAt!)
    this.interval = setInterval(() => {
      if (this.auction) {
        this.timeLeft = remainingSeconds(this.auction.endsAt!)
      }
    }, 1000)
  }

  isLastBid(index: number){
    return this.bidsToDisplay.length === 1 || index === 1
  }
  get bidsToDisplay (){
    if(!this.auction || this.auction?.bids.length === 0){
      return []
    }
    const [lastBid, prevBid] = this.auction.bids
    if(this.settings?.displaySecondToLastBid && prevBid){
      return [prevBid, lastBid]
    }
    return [lastBid]
  }

  ngOnDestroy(): void {
    clearInterval(this.interval)
  }

  get pictureBgStyle(){
    return {
      'background-image':`url(${this.auction?.pictureUrl})`
    }
  }

}

