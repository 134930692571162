import { Component, Input } from '@angular/core';
import { OrderReviewDto } from '../../../api/services/CustomerService';
import { faStar } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-customer-review',
  templateUrl: './customer-review.component.html',
  styleUrls: ['./customer-review.component.scss']
})
export class CustomerReviewComponent {
  @Input({required: true}) review!: OrderReviewDto
  starIcon = faStar

  get stars (){
    return 'a'.repeat(this.review?.rating).split('')
  }
}
