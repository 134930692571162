import { Component, Input } from '@angular/core';
import { IProduct } from '../../../models/Product';
import { UiShopService } from '../../services/UiShopService';

@Component({
  selector: 'app-shop-product-item',
  templateUrl: './shop-product-item.component.html',
  styleUrls: ['./shop-product-item.component.scss']
})
export class ShopProductItemComponent {
  ready = false
  constructor(
    private uiShopSvc: UiShopService
  ) { }
  @Input({ required: true }) product!: IProduct
  @Input() showMerchantHeader? = false

  openBuyModal() {
    this.uiShopSvc.openBuyConfirmationModal(this.product)
  }
}
