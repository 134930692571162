<div class="modal-header">
  <h5 class="modal-title" id="modal-basic-title">{{ sendLater ? 'Enviar después': 'Añadir datos de envío'}}</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>

</div>
<div class="modal-body" *ngIf="!order">
  <app-loading-indicator />
</div>
<div class="modal-body" *ngIf="order">
  <div *ngIf="order">
    <div class="rounded bg-secondary p-2 mb-2" [ngClass]="{shadow: review}">
      <div class="d-flex justify-content-between gap-1 align-items-center">
          <app-customer-tag [customer]="order.customer" />
          <button class="btn h-100 btn-sm btn-outline-secondary" (click)="review = !review">{{reviewLabel}}</button>
      </div>
      <ul class="list-group mt-2" *ngIf="review">
        <li class="list-group-item p-1" *ngFor="let item of order.items">
          <div class="d-flex gap-2 align-items-center">
            <img *ngIf="item.imageUrl" [src]="item.imageUrl" height="30" />
            <div class="flex-grow-1">{{ item.description }}</div>
            <div class="badge text-bg-secondary">{{prettyOrderId(item.id)}}</div>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <ng-container *ngIf="sendLater">
    <div class="alert alert-info mb-2">
      Estás marcando estos artículos para mandarlos después.
      Se mandará un correo al cliente para esperar su aprobación.
    </div>
    <div class="text-end">
      <button (click)="submitSendLater()" class="btn btn-primary">Confirmar</button>
    </div>
    <app-message [message]="error" type="danger" />
  </ng-container>

  <app-delivery-form *ngIf="!sendLater" [order]="order" (onSuccess)="onDeliveryInfoSaved($event)"/>

</div>
