
<div class="alert alert-warning shadow" *ngIf="status && !status.customer.completed">
  Para poder empezar a usar nuestra plataforma debes completar tu perfil. Añadiendo una dirección de envío y un método de pago.
</div>

<div *ngIf="user" class="row">
  <div class="col-md-6 col-sm-12 mb-4">
    <app-user-address-form-card [user]="user" (onAddressUpdated)="onUserUpdated($event)" />
  </div>
  <div class="col-md-6 col-sm-12">
    <div class="mb-4">
      <app-customer-account-platforms [user]="user"  />
    </div>
    <div class="mb-4">
      <app-account-payment-methods-manager [user]="user" (onPaymentMethodAdded)="onPaymentMethodAdded($event)"  />
    </div>
  </div>
</div>

