<div style="margin: 100px auto; width: 300px;">
  <app-slide-button
  [action]="action.bind(this)"
  [success$]="success$"
  [confirmAbovePercent]="0.9"
  idleText="Pujar 13€"
  processingText="Procesando"
  >
  </app-slide-button>
</div>
