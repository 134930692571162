import { Component } from '@angular/core';

@Component({
  selector: 'app-terms-and-conditions-page',
  templateUrl: './terms-and-conditions-page.component.html',
  styleUrls: ['./terms-and-conditions-page.component.scss']
})
export class TermsAndConditionsPageComponent {

}
