import { Component, OnDestroy, OnInit } from '@angular/core';
import { IUser, IUserPaymentMethod } from '../../../../models/User';
import { OnboardingStatusDto } from '../../../../api/services/UserService';
import { UiService } from '../../../../ui/services/ui.service';
import { AppStore } from '../../../../store';

import { userFeat, userFeatActions } from '../../../../store/user.store';
import { createSubs, unsubAll } from '../../../../utils/rxjs.utils';
import { AuthService } from '../../../../auth/AuthService';
import { AccountMode } from '../../../../enums';

@Component({
  selector: 'app-customer-profile-page',
  templateUrl: './customer-profile-page.component.html',
  styleUrls: ['./customer-profile-page.component.scss']
})
export class CustomerProfilePageComponent implements OnInit, OnDestroy {
  user: IUser | null = null;
  status: OnboardingStatusDto | null = null;
  tab = 'datos'
  subs$ = createSubs()

  constructor(private uiSvc: UiService, private store: AppStore) {

  }
  onUserUpdated(user: IUser){

      this.store.dispatch(userFeatActions.userUpdated({ user }))
      this.refreshStatus()
  }
  onPaymentMethodAdded(paymentMethod: IUserPaymentMethod){

    if(!this.user){
      return
    }
    this.refreshStatus()
  }
  private refreshStatus(){
    this.store.dispatch(userFeatActions.fetchMe())
  }

  ngOnInit(): void {
    this.refreshStatus()

    this.subs$.userState = this.store.select(userFeat.selectUser).subscribe((user) => {
      this.user = user;
    })

    this.subs$.userStatus = this.store.select(userFeat.selectStatus).subscribe((status) => {
      if(!status){
        return
      }
      if(this.status && !this.status.customer.completed && status.customer.completed){
        this.uiSvc.showToast({
          type: 'success',
          text: 'Enhorabuena! Ya estás listo para empezar a comprar!',
        })
      }
      this.status = status
    })
  }

  ngOnDestroy(): void {
    unsubAll(this.subs$)
  }
}
