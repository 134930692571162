<section>
  <div class="d-flex justify-content-center">

    <div style="max-width: 450px; width: 100%">
      <div>
        <div class="mb-3">
          <img class="full" src="/assets/logo/net_purple_logo_small.png" height="40" />
        </div>
        <div>
          <div class="my-3">
            <h5 class="card-title">Bienvenido</h5>
            <p>Entra en tu cuenta</p>
          </div>
          <app-twitch-auth-btn text="Iniciar sesión con Twitch" [returnAfterLogin]="returnAfterLogin" />
          <p class="mt-3 oauth-terms-and-condition">Al entrar con Twitch se creará una cuenta y aceptas
            nuestros <a [routerLink]="['/terminos-y-condiciones']" class="text-indigo-600">términos y
              condiciones</a> y <a [routerLink]="['/politica-de-privacidad']" class="text-indigo-600">política de
              privacidad</a> </p>
        </div>
      </div>
      <div class="mt-2 border border-2 p-2 rounded">
        <div><small>Entra con tu email</small></div>
        <form [formGroup]="form" (ngSubmit)="onSubmit()" class="mt-3" novalidate>
          <app-form-input placeholder="johndoe@twitchmaster.x" label="Email" controlName="email" [form]="form"
            marginBottom="3" />
          <app-form-input type="password" placeholder="*******" label="Contraseña" controlName="password" [form]="form"
            marginBottom="3">
            <a tabindex="99" routerLink="/restaurar-acceso/solicitar">¿Has olvidado tu contraseña?</a>
          </app-form-input>
          <app-api-error-block [error]="error$|async" />

          <div class="mb-3">
            <button class="btn w-100 btn-block btn-primary" [disabled]="isCtaDisabled$|async" type="submit">
              <ng-container *ngIf="success$|async;else notSuccess"><fa-icon [icon]="btnSuccessIcon"></fa-icon> Let's
                go!</ng-container>
              <ng-template #notSuccess>
                <ng-container *ngIf="isIdle$|async">Iniciar sesión</ng-container>
                <ng-container *ngIf="isLoading$|async">
                  <div class="spinner-border spinner-border-sm" role="status"></div>
                  Comprobado
                </ng-container>
              </ng-template>
            </button>
          </div>


          <div>
            <a routerLink="/crear-cuenta" class="btn w-100 btn-outline-secondary">Quiero registrarme</a>
          </div>
        </form>
      </div>
    </div>

  </div>
</section>
