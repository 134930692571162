import { Component } from '@angular/core';
import { createSubs, unsubAll } from '../../../../utils/rxjs.utils';
import { ActivatedRoute, Router } from '@angular/router';
import { UiService } from '../../../../ui/services/ui.service';
import { MerchantService } from '../../../../api/services/MerchantService';
import { AppStore } from '../../../../store';
import { mProductFormStore } from '../../../../store/merchant/product/merchant.product.store';
import { Actions, ofType } from '@ngrx/effects';

@Component({
  selector: 'app-edit-product-page',
  templateUrl: './edit-product-page.component.html',
  styleUrls: ['./edit-product-page.component.scss']
})
export class EditProductPageComponent {
  subs = createSubs()
  public constructor(
    private route: ActivatedRoute,
    private store: AppStore,
    private actions: Actions,
    private router: Router,
    private uiCommonSvc: UiService
  ) { }


  ngOnInit(): void {
    this.uiCommonSvc.setTitle('Cargando producto')
    this.subs.routeParams = this.route.params.subscribe(({ id }) => {
        this.store.dispatch(mProductFormStore.actions.load({ id }))
    })
    this.actions.pipe(ofType(mProductFormStore.actions.loadOK)).subscribe(({data})=>{
      this.uiCommonSvc.setTitle(data.name)
    })
    this.actions.pipe(ofType(mProductFormStore.actions.loadKO)).subscribe(({error})=>{
      alert(error.message)
      this.router.navigate(['/streamer/productos'])
    })
  }

  ngOnDestroy() {
    unsubAll(this.subs)
  }
}
