import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Actions } from "@ngrx/effects";
import { ListBaseEffects } from "../../utils/list.base.effects";
import { mProductListStore } from "./merchant.product.store";
import { IProduct } from "../../../models/Product";
import { AppStore } from "../../app.store";

@Injectable()
export class MerchantPrivateProductListEffects extends ListBaseEffects<IProduct> {
  constructor(
    actions$: Actions,
    store: AppStore,
    http: HttpClient
  ) {
    super(mProductListStore, `/merchant/products`, actions$, store, http)
  }
}
