<div class="container">
  <h1>Términos y condiciones</h1>
  <div>
    <p><span>Este documento (al que se hace referencia como «Condiciones») establece cómo
        puede usted acceder y utilizar la Plataforma Suass. Para utilizar y acceder a la Plataforma Suass, debe usted
        aceptar estas Condiciones. Si no acepta estas Condiciones, no utilice la Plataforma.&nbsp;</span></p>
    <ul class="list-unstyled">
      <li><a href="#">Definiciones</a> </li>
      <li><a href="#">Estas Condiciones</a> </li>
      <li><a href="#">Creación de una cuenta</a> </li>
      <li><a href="#">Contribuciones.</a> </li>
      <li><a href="#">Cómo podemos poner fin a nuestra relación</a> </li>
      <li><a href="#">Honorarios</a> </li>
      <li><a href="#">Cómo pueden los Usuarios comprar un Artículo</a> </li>
      <li><a href="#">Cómo pueden los Vendedores incluir Artículos</a> </li>
      <li><a href="#">Responsabilidades del Vendedor</a> </li>
      <li><a href="#">Derechos de cancelación</a> </li>
      <li><a href="#">Entrega y devoluciones</a> </li>
      <li><a href="#">Política de Fondo de Comercio de Suass</a> </li>
      <li><a href="#">Nuestra responsabilidad por las pérdidas soportadas por usted</a></li>
      <li><a href="#">Contenido</a></li>
      <li><a href="#">Protección de Datos y Privacidad</a></li>
      <li><a href="#">Acceso a la Información Personal</a></li>
      <li><a href="#">Otras condiciones importantes</a></li>
      <li><a href="#">Apéndice 1 - Términos y Condiciones Generales</a></li>
    </ul>

    <a name="definiciones"></a>
    <h1><strong>Definiciones</strong></h1>
    <p><span>Los términos con mayúscula inicial utilizados en estas Condiciones tendrán el
        significado que se indica a continuación:</span></p>
    <p><strong>Suass</strong><span>: Es la marca de la plataforma, formada por Sergio López Rico, dado de alta como aútonomo.</span></p>
    <p><strong>Comprador</strong><span>: se refiere a cualquier persona que actúe a título
        personal, que tenga una Cuenta de Cliente y compre Artículos a través de la Plataforma.</span></p>
    <p><strong>Catálogo</strong><span>: significa el catálogo electrónico en el que figuran
        Artículos del mismo tipo.</span></p>
    <p><strong>Directrices de la Comunidad: </strong><span>significa las </span><span>Directrices de la
        Comunidad</span><span>.</span></p>
    <p><strong>Contribuciones</strong><span>: significa información, datos, textos, mensajes
        o cualquier otro contenido publicado en la Plataforma.&nbsp;</span></p>
    <p><strong>Cuenta de Cliente</strong><span>: significa la cuenta de Usuario creada a
        raíz del registro del Usuario en la Plataforma.</span></p>
    <p><strong>Términos y Condiciones Generales</strong><span>: significa los términos y
        condiciones generales de venta que rigen la relación entre Compradores y Vendedores, según se establece en el
        apéndice&nbsp;1 de estas Condiciones.</span></p>
    <p><strong>Vendedor Individual</strong><span>: significa todo Usuario que incluya uno o
        más Artículos en el catálogo de la Plataforma, que no actúe a título profesional.</span></p>
    <p><strong>Servicio de Pago Integrado</strong><span>: se refiere al servicio de pago en
        línea de los Artículos, disponible para el Comprador en la Plataforma, los servicios de pago que presta el
        socio de Suass, STRIPE SPAIN, SL, con domicilio social en
        Calle Zurbaran, 9 - LOC DR, Madrid, 28010, Madrid e inscrita en el Registro Mercantil de España con el número
        B88501820.</span></p>
    <p><strong>Artículos</strong><span>: significa los productos/artículos cuya venta o
        intercambio no es ilegal y que un Vendedor Individual o un Vendedor Profesional incluye en el catálogo de la
        Plataforma.</span></p>
    <p><strong>Listado de Artículos</strong><span>: significa la descripción de un Artículo,
        que también incluye los detalles del precio (IVA incluido) en euros y el estado del Artículo.</span></p>
    <p><strong>Venta en Directo</strong><span>: significa la celebración de una venta en
        directo, a través del servicio de difusión de vídeo ofrecido por la Plataforma, en el cual un Vendedor ofrece
        uno o varios artículos para la venta a los Usuarios.</span></p>
    <p><strong>Plataforma</strong><span>: significa el sitio web JoinSuass, así como las
        aplicaciones móviles de la plataforma Suass descargadas en smartphones o tabletas.</span></p>
    <p><strong>Vendedor Profesional</strong><span>: significa todo Usuario que incluya uno o
        varios artículos en el catálogo de la Plataforma a título profesional. Los Vendedores Profesionales se
        identifican en la Plataforma con el término «Profesional» que figura junto a su nombre.</span></p>
    <p><strong>Vendedor</strong><span>: significa un Vendedor Individual o un Vendedor
        Profesional.&nbsp;</span></p>
    <p><strong>Cuenta del Vendedor</strong><span>: significa la cuenta de un Vendedor
        Individual o de un Vendedor Profesional.</span></p>
    <p><strong>Operación</strong><span>: significa toda venta o intercambio celebrado en la
        Plataforma entre los Usuarios.</span></p>
    <p><strong>Usuario o usted</strong><span>: significa todo usuario de la Plataforma, ya
        sea un Comprador, un Vendedor Profesional o un Vendedor Individual.</span></p>
    <p><strong>Nosotros, nuestro/a(s), nos</strong><span>: significa Suass.&nbsp;</span></p>
    <ol>
      <li>
        <h1><strong>Estas Condiciones</strong></h1>
      </li>
      <ol>
        <li>
          <h2><span>Estas Condiciones (excepto el Apéndice&nbsp;1) se aplican entre usted y
              Suass.&nbsp;</span></h2>
        </li>
        <li>
          <h2><span>Suass es un servicio de alojamiento en línea que facilita el
              intercambio, la venta o la compra de Artículos, el acceso del Usuario para participar en subastas en
              directo del Vendedor y la comunicación del Usuario mediante el envío de mensajes a través del foro o de
              forma privada en la Plataforma.</span></h2>
        </li>
      </ol>
    </ol>
    <ul>
      <li>
        <h2><span>Suass se limita a facilitar Operaciones entre Usuarios. Suass no es parte
            de ninguna Operación entre Usuarios y no compra, vende ni comercializa ninguno de los artículos
            presentados en la Plataforma.</span></h2>
      </li>
    </ul>
    <ol>
      <li>
        <h2><span>Estas Condiciones surtirán efecto cuando registre usted una cuenta y se
            aplicarán hasta que usted o nosotros las demos por terminadas de conformidad con estas
            Condiciones.&nbsp;</span></h2>
      </li>
    </ol>
    <ul>
      <li>
        <h2><span>Si actúa usted como Vendedor:</span></h2>
      </li>
    </ul>
    <ol>
      <li>
        <h2><span>Podemos modificar estas Condiciones o la Plataforma, pero le informaremos,
            enviándole un resumen de las modificaciones al menos 15 días antes de que se apliquen (excepto en las
            circunstancias establecidas en la cláusula 2.5 a continuación). Podremos enviar una notificación con mayor
            anticipación cuando: (a) se añadan o eliminen características de la Plataforma que sean relevantes para
            usted; o (b) sea necesario adaptar sus servicios debido al cambio. Si no está satisfecho con los cambios,
            puede dar por terminada su relación con nosotros antes de que se introduzcan los cambios.&nbsp;</span>
        </h2>
      </li>
      <li>
        <h2><span>Podemos realizar cambios en las Condiciones o en la Plataforma con efecto
            inmediato en los siguientes casos: (a) si necesitamos realizar cambios para cumplir con las leyes o
            reglamentos; o (b) si estos son necesarios para hacer frente a un peligro imprevisto e inminente para la
            Plataforma, lo que podría incluir, por ejemplo, tener que hacer frente o defender la Plataforma frente a
            fraude, malware, spam, violaciones de datos u otros riesgos de ciberseguridad, así como otras situaciones.
            También podemos realizar cambios de inmediato si usted los acepta.</span></h2>
      </li>
    </ol>
    <ul>
      <li>
        <h2><span>Si actúa usted como Comprador:</span></h2>
      </li>
    </ul>
    <ol>
      <li>
        <h2><span>Estas Condiciones o la Plataforma podrán modificarse en cualquier momento.
            Se le notificará al menos 15 días antes de que las nuevas Condiciones surtan efecto, a menos que la ley
            exija un cambio o por motivos de emergencia, o si da usted su consentimiento al cambio, en cuyo caso
            podremos realizar cambios antes. Si no está satisfecho con las nuevas Condiciones, no siga utilizando la
            Plataforma.&nbsp;</span></h2>
      </li>
    </ol>
    <ul>
      <li>
        <h1><strong>Creación de una Cuenta</strong></h1>
      </li>
    </ul>
    <ol>
      <ol>
        <li>
          <h2><span>Para comprar o vender Artículos en la Plataforma, debe usted crear una
              cuenta. Para crear una cuenta, debe ser mayor de 18 años. Un adulto puede permitir el acceso a la cuenta
              del adulto a usuarios menores de 18 años, siempre que el adulto conceda un permiso previo. El adulto
              seguirá siendo responsable de la cuenta y estará obligado por estas Condiciones en todo
              momento.&nbsp;</span></h2>
        </li>
        <li>
          <h2><span>Debe usted mantener la confidencialidad de sus datos de inicio de sesión
              y contraseña, y no revelarlos a terceros ni permitir el acceso a terceros.&nbsp;</span></h2>
        </li>
        <li>
          <h2><span>Si es usted un Vendedor Profesional, debe usted facilitar su(s)
              número(s) de IVA y dirección</span><span>registrada(s). Si actúa usted en
              calidad profesional/operador comercial, debe inscribirse como Vendedor Profesional.</span></h2>
        </li>
        <li><span>Si se inscribe usted como
            Vendedor Individual, pero sus registros sugieren que es usted un Vendedor Profesional, podremos ponernos
            en contacto con usted para obtener información adicional, y podrá usted pasar a ser Vendedor
            Profesional.</span></li>
      </ol>
      <li>
        <h1><strong>Contribuciones.</strong></h1>
      </li>
      <ol>
        <li>
          <h2><span>Los Usuarios confirman que todas las Contribuciones son de su exclusiva
              responsabilidad.</span></h2>
        </li>
        <li>
          <h2><span>Los Usuarios deben comprometerse a abstenerse de:</span></h2>
        </li>
        <ol>
          <li>
            <h3><span>publicar Contribuciones ilícitas;</span></h3>
          </li>
          <li>
            <h3><span>compartir información contraria a los derechos y principios
                constitucionales, al orden público o a las normas de moralidad comúnmente aceptadas;</span></h3>
          </li>
          <li>
            <h3><span>utilizar la Plataforma o los Servicios para cualquier otro fin que no
                sea comprar o vender Artículos de conformidad con estas Condiciones, </span><span>las Directrices de
                la Comunidad</span><span> y
                otros requisitos que le comuniquemos en cualquier momento;</span></h3>
          </li>
          <li>
            <h3><span>publicar información de carácter comercial o publicitario sobre
                tabaco, alcohol o cualquier otra sustancia restringida, producto o servicio regulado, o en relación
                con los mismos;</span></h3>
          </li>
          <li>
            <h3><span>compartir Contribuciones que infrinjan los derechos de terceros o que
                sean difamatorias, insultantes, obscenas, pornográficas, ofensivas, violentas o inciten a la
                discriminación, la violencia política, el racismo, la xenofobia, el sexismo o la homofobia;</span>
            </h3>
          </li>
          <li>
            <h3><span>publicar información relativa a otros Usuarios;</span></h3>
          </li>
          <li>
            <h3><span>acceder de manera fraudulenta a la Plataforma;</span></h3>
          </li>
          <li>
            <h3><span>transmitir un mensaje que contenga virus informáticos o cualquier otro
                código, archivo o programa diseñado para interrumpir, destruir o limitar la funcionalidad de un
                software, ordenador o herramienta de telecomunicaciones;</span></h3>
          </li>
          <li>
            <h3><span>acosar de cualquier forma a otros Usuarios o a varios Usuarios;</span>
            </h3>
          </li>
          <li>
            <h3><span>enlazar o remitir a otros sitios web cuyo contenido pueda infringir
                cualquier ley o reglamento vigente y, en particular, pueda infringir los derechos de terceros.</span>
            </h3>
          </li>
        </ol>
        <li>
          <h2><span>No somos responsables de dichas Contribuciones, pero podemos rechazar,
              denegar o eliminar cualquier Contribución cuando consideremos que infringe estas Condiciones o las
              Directrices de la Comunidad.&nbsp;</span></h2>
        </li>
        <li>
          <h2><span>Los Usuarios no deben hacer comentarios amenazantes, insultantes,
              difamatorios o irrespetuosos hacia Suass, sus empleados o cualquier otro Usuario.</span></h2>
        </li>
        <li>
          <h2><span>Todos los Usuarios deben cumplir con: (a) la legislación; (b) las normas
              de catálogo establecidas por Suass; y (c) </span><span>las Directrices de la
              Comunidad</span></h2>
        </li>
        <li>
          <h2><span>La Plataforma puede incluir información o materiales cargados por otros
              Usuarios de la misma. Esta información y estos materiales no han sido verificados ni aprobados por
              nosotros. Las opiniones expresadas por otros Usuarios en la Plataforma no representan nuestras opiniones
              o valores.&nbsp;</span></h2>
        </li>
      </ol>
      <li>
        <h1><strong>Cómo podemos poner fin a nuestra relación</strong></h1>
      </li>
      <ol>
        <li>
          <h2><span>Podemos restringir temporalmente el acceso de los Usuarios a la
              Plataforma o deshabilitar su uso:</span></h2>
        </li>
        <ol>
          <li>
            <h3><span>para subsanar problemas técnicos;</span></h3>
          </li>
          <li>
            <h3><span>para actualizar la Plataforma a fin de reflejar cambios en las leyes y
                requisitos reglamentarios pertinentes;</span></h3>
          </li>
          <li>
            <h3><span>cuando los Usuarios no hayan observado las </span><span>Directrices de la
                Comunidad</span><span>, hayan
                actuado de manera ilícita o hayan cometido un acto delictivo al utilizar la Plataforma.</span></h3>
          </li>
        </ol>
        <li>
          <h2><span>Intentaremos informar al Usuario con antelación de cualquier restricción
              o suspensión del acceso a la Plataforma, salvo si el problema es urgente o se produce una emergencia,
              por ejemplo:</span></h2>
        </li>
        <ol>
          <li>
            <h3><span>en caso de fraude;</span></h3>
          </li>
          <li>
            <h3><span>cuando el Usuario haya recibido reiteradamente reseñas negativas o
                malas reseñas detalladas del Vendedor o del Comprador y la suspensión sea necesaria para proteger los
                intereses de otros Usuarios;</span></h3>
          </li>
          <li>
            <h3><span>cuando el Usuario cause perjuicios significativos a otros Usuarios o a
                Suass;</span></h3>
          </li>
          <li>
            <h3><span>cuando el Usuario haya facilitado información de contacto
                incorrecta;</span></h3>
          </li>
          <li>
            <h3><span>cuando el Usuario haya permitido a terceros acceder a su cuenta;
                o</span></h3>
          </li>
          <li>
            <h3><span>cuando se produzca un incumplimiento grave o reiterado de las
                Condiciones y, en el caso de los Vendedores, incluidas las normas mínimas de comportamiento detalladas
                en nuestro archivo de preguntas frecuentes del vendedor. Venta en Suass</span></h3>
          </li>
        </ol>
      </ol>
    </ol>
    <ul>
      <li>
        <h2><strong>Si es usted un Vendedor:</strong></h2>
      </li>
    </ul>
    <ol>
      <li>
        <h2><span>Podemos dar por terminada nuestra relación con usted y eliminar su acceso
            a la Plataforma notificándoselo con al menos 30 días de antelación si:</span></h2>
      </li>
      <ol>
        <li>
          <h3><span>incumple usted estas Condiciones;&nbsp;</span></h3>
        </li>
        <li>
          <h3><span>consideramos que es necesario para proteger nuestra reputación o la
              Plataforma;&nbsp;</span></h3>
        </li>
        <li>
          <h3><span>es usted un Vendedor Profesional y detectamos disparidades o
              discrepancias en el(los) número(s) de IVA facilitado(s);</span></h3>
        </li>
        <li>
          <h3><span>observamos que incumple usted sus obligaciones en materia de IVA.</span>
          </h3>
        </li>
      </ol>
      <li>
        <h2><span>Cuando le enviemos una notificación con arreglo al párrafo anterior, se le
            explicarán los motivos al menos 30 días antes de que surta efecto la resolución y tendrá la oportunidad de
            presentar una reclamación de conformidad con lo dispuesto en la cláusula 5.11. Si, tras la revisión, se
            considera que la resolución es errónea, se restablecerá el acceso a la Plataforma (lo que confirmaremos
            por escrito).&nbsp;</span></h2>
      </li>
      <li>
        <h2><span>No le notificaremos la resolución en las circunstancias que se indican a
            continuación:</span></h2>
      </li>
      <ol>
        <li>
          <h3><span>si entra en vigor una nueva ley que implique que tenemos que poner fin a
              nuestra prestación de la Plataforma inmediatamente, o con menos de 30 días de antelación;</span></h3>
        </li>
        <li>
          <h3><span>si ejercemos un derecho que tengamos en virtud de la legislación
              aplicable para dar por terminada la relación (por ejemplo, en caso de incumplimiento grave de estas
              Condiciones por su parte); o</span></h3>
        </li>
        <li>
          <h3><span>si damos por terminada nuestra relación como consecuencia de su
              incumplimiento continuado de estas Condiciones.&nbsp;</span></h3>
        </li>
      </ol>
    </ol>
    <ul>
      <li>
        <h2><strong>Si es usted un Comprador:</strong></h2>
      </li>
    </ul>
    <ol>
      <li>
        <h2><span>Podemos dar por terminada nuestra relación con usted mediante notificación
            cursada con una antelación razonable, a menos que haya incumplido usted gravemente estas Condiciones, en
            cuyo caso podremos dar por terminada nuestra relación con usted de inmediato.&nbsp;</span></h2>
      </li>
      <li>
        <h2><span>Los Usuarios pueden cerrar su cuenta y rescindir estas Condiciones en
            cualquier momento informándonos a través de la Plataforma.</span></h2>
      </li>
      <li>
        <h2><span>Si Suass rescinde estas Condiciones, ese Usuario no podrá seguir
            accediendo a la Plataforma (tampoco con otras Cuentas de Cliente) y no podrá volver a registrarse. En caso
            de suspensión, los Usuarios no podrán acceder a la Plataforma hasta que se haya levantado la
            suspensión.&nbsp;</span></h2>
      </li>
      <li>
        <h2><span>Suass podrá suspender la cuenta de un Usuario si detecta patrones de
            compra o de devolución inusuales que puedan ser indicio de fraude, ser contrarios a los principios de
            buena fe o competencia leal o perjudicar a otros usuarios o a la plataforma.&nbsp;</span></h2>
      </li>
      <li>
        <h2><span>La suspensión o rescisión no tendrá ningún efecto sobre la validez de los
            contratos ya celebrados en la Plataforma ni sobre sus obligaciones de efectuar los pagos que deba efectuar
            con arreglo a estas Condiciones.</span></h2>
      </li>
      <li>
        <h2><span>Si Suass lleva a cabo cualquiera de las acciones mencionadas en esta
            cláusula, incluidas la restricción, la suspensión o la rescisión, los Usuarios podrán solicitar el
            esclarecimiento de los hechos y circunstancias que den lugar a dichas acciones a través del procedimiento
            interno de reclamaciones de Suass escribiendo a sergio@suass.live.</span></h2>
      </li>
    </ol>
    <ul>
      <li>
        <h1><strong>Honorarios</strong></h1>
      </li>
    </ul>
    <ol>
      <ol>
        <ol>
          <li>
            <h2><span>No se aplica ningún cargo por registrar una cuenta en la Plataforma y
                pujar por artículos ofrecidos en la Plataforma.</span></h2>
          </li>
          <li>
            <h2><span>Los honorarios cobrados por la venta de Artículos en nuestra
                Plataforma,</span><span>que incluyen la prestación de servicios tecnológicos
                y automatizados, figuran en nuestra Política de Honorarios de Subasta. Suass podrá modificar los
                honorarios; no obstante, le notificaremos dichos cambios con al menos 15 días de antelación. Si no
                está satisfecho con dichos cambios, podrá cerrar su cuenta y dar por terminados estas Condiciones en
                cualquier momento.&nbsp;</span></h2>
          </li>
          <li>
            <h2><span>A menos que se indique lo contrario, todos los honorarios se expresan
                en EUROS (EUR / €). Es usted responsable de pagar todos los honorarios e impuestos aplicables que se
                deriven de la compra de Artículos en nuestra Plataforma de manera oportuna y mediante un método de
                pago válido. Si se presenta un problema con su método de pago o si su cuenta se encuentra en mora,
                intentaremos cobrar los importes adeudados por otros medios de cobro, efectuaremos cargos en los demás
                métodos de pago de su cuenta, podremos recurrir a una agencia de cobro o a un asesor jurídico
                autorizado para este fin. Podemos cobrar intereses de demora al tipo establecido por la Ley 3/2004
                española (Ley 3/2004, de 29 de diciembre, por la que se establecen medidas de lucha contra la
                morosidad en las operaciones comerciales) más un 3&nbsp;%, que se devengará diariamente desde la fecha
                de vencimiento del pago hasta la fecha de pago real del importe vencido. Por último, también podemos
                suspender o limitar su capacidad para usar nuestra Plataforma hasta que se haya recibido el pago
                completo.</span></h2>
          </li>
          <li>
            <h2><span>Podemos emitir facturas a los Vendedores en formato electrónico por
                correo electrónico, sin perjuicio del derecho legal de los consumidores a recibir las facturas
                impresas, incluso después de haber aceptado recibirlas por medios electrónicos.</span></h2>
          </li>
          <li><span>Cuando los artículos se expiden
              desde el extranjero, es posible que usted (comprador) deba pagar tasas de importación para recoger su
              artículo. Suass no actúa como Importador Registrado</span></li>
        </ol>
      </ol>
    </ol>
    <ul>
      <li>
        <h1><strong>Cómo pueden los Usuarios comprar un Artículo</strong></h1>
      </li>
    </ul>
    <ol>
      <ol>
        <ol>
          <li>
            <h2><span>Para comprar un Artículo, los Usuarios deben disponer de una Cuenta de
                Cliente activa. Los Vendedores son los únicos responsables de (i) la exactitud e integridad de la
                descripción del Artículo en el Listado de Artículos o durante la Venta en Directo; (ii) la exactitud e
                integridad de la localización de los artículos en el punto de venta, así como (iii) la conformidad del
                Artículo con la descripción que figura en el Listado de Artículos o durante la Venta en
                Directo.</span></h2>
          </li>
          <li>
            <h2><span>La Plataforma permite a los usuarios comprar artículos de dos formas
                diferentes:&nbsp;</span></h2>
          </li>
          <ol>
            <li><span>Mediante subasta. Para pujar,
                debe haber consignado previamente su dirección de entrega y elegido su método de pago. Usted puja al
                precio que desea pagar por la compra del Artículo presentado, hasta que haya transcurrido el plazo
                previsto por el Vendedor para la subasta. Si pierde la subasta, no se procede a ninguna solicitud de
                pago. Si se impone usted en la subasta, su pedido se realiza automáticamente y se comunica al
                Vendedor. A continuación, se realiza una solicitud de pago en su método de pago.</span></li>
            <li><span>Venta a precio fijo: Para
                comprar un artículo durante una Venta en Directo, debe haber consignado previamente su dirección de
                entrega y elegido su método de pago. Debe seleccionar el artículo que desea comprar. A continuación,
                haga clic en «Comprar». Se informa entonces al Usuario del importe total de su pedido. Por último, se
                le invita a hacer clic en «Confirmar Pago», lo que constituye una obligación de pago.&nbsp;</span>
            </li>
          </ol>
          <li>
            <h2><span>Cada pedido de un Producto constituye un contrato separado entre el
                Vendedor y el Comprador. El contrato se formalizará en el momento en que el Comprador reciba un correo
                electrónico de confirmación, y estará sujeto a los requisitos legales aplicables, a las condiciones
                establecidas en el Apéndice 1 y a las condiciones acordadas entre el Vendedor y el Comprador.</span>
            </h2>
          </li>
          <li>
            <h2><span>Los pagos en la Plataforma se procesan a través del sistema de pago
                seguro 3D gestionado por Stripe o mediante pago con códigos (pago, descuento o promocional).</span>
            </h2>
          </li>
          <li>
            <h2><span>Suass puede ofrecer descuentos y promociones ocasionalmente. Cuando
                esté disponible, se facilitará información adicional a través de la Plataforma y se aplicarán
                condiciones adicionales.&nbsp;</span></h2>
          </li>
          <li>
            <h2><span>Los Vendedores deben disponer de una cuenta en Stripe, siguiendo el
                proceso que les haya indicado Suass. Stripe cobra los pagos del Comprador, y los transfiere a los
                Vendedores, una vez deducidas las comisiones de Suass y de Stripe y el importe del IVA, cuando Suass
                tenga la obligación legal de recaudar el IVA. Los honorarios se explican en el apartado 6.2 anterior.
                La información de pago del Comprador no se comunica nunca a los Vendedores, a los que solo se facilita
                la información de contacto del Comprador necesaria para ponerse en contacto con el Vendedor y para
                tramitar su pedido, a saber, su apellido, nombre y dirección de entrega.</span></h2>
          </li>
        </ol>
        <li>
          <h1><strong>Cómo pueden los Vendedores incluir Artículos</strong></h1>
        </li>
        <ol>
          <li>
            <h2><span>Para poner un Artículo a la venta, los Vendedores deben tener una
                Cuenta de Vendedor activa. Para organizar una venta, el Vendedor debe crear una Venta en Directo en la
                Plataforma e introducir el Artículo que desea vender durante esa Venta en Directo.</span></h2>
          </li>
          <li>
            <h2><span>Para cada uno de los Artículos a la venta, el Vendedor determinará si
                desea llevar a cabo una subasta o proceder a una venta a precio fijo.&nbsp;</span></h2>
          </li>
          <li>
            <h2><span>En el caso de las ventas a precio fijo, el Vendedor fija un precio al
                que los Compradores podrán comprar el Artículo durante la Venta en Directo.&nbsp;</span></h2>
          </li>
          <li>
            <h2><span>En el caso de las ventas en subasta, el Vendedor fija un precio mínimo
                al que los Compradores pueden pujar durante la Venta en Directo. El Vendedor también establece el
                importe mínimo que debe añadirse para que un Comprador puje.</span></h2>
          </li>
          <li><span>Los Vendedores Profesionales
              deberán presentar una factura o recibo a través de la cuenta del vendedor.</span></li>
        </ol>
      </ol>
    </ol>
    <ul>
      <li>
        <h1><strong>Responsabilidades del Vendedor</strong></h1>
      </li>
    </ul>
    <ol>
      <ol>
        <li>
          <h2><span>Los Vendedores deberán:</span></h2>
        </li>
        <ol>
          <li>
            <h3><span>vender únicamente Artículos auténticos en la Plataforma o, de lo
                contrario, advertir de manera destacada sobre la naturaleza real o el origen de las obras si no fuera
                así);</span></h3>
          </li>
          <li>
            <h3><span>abstenerse de pujar en sus propias subastas;</span></h3>
          </li>
          <li>
            <h3><span>dar debido cumplimiento a los pedidos realizados por los Compradores
                en la Plataforma;</span></h3>
          </li>
          <li>
            <h3><span>enviar los Artículos de conformidad con las declaraciones realizadas
                durante las Ventas en Directo o en los anuncios;</span></h3>
          </li>
          <li>
            <h3><span>enviar los Artículos dentro del plazo máximo estipulado por Suass en
                las normas de Suass</span><span>;</span></h3>
          </li>
          <li>
            <h3><span>responder a las solicitudes de los Compradores o de Suass en relación
                con las ventas ya realizadas dentro del plazo máximo estipulado por Suass en las normas de
                Suass</span><span>;&nbsp;</span></h3>
          </li>
          <li>
            <h3><span>reembolsar al Comprador en caso de que se resuelva una disputa a favor
                del Comprador.&nbsp;</span></h3>
          </li>
          <li><span>cumplir las obligaciones en
              materia de IVA y otras normas fiscales cuando proceda</span></li>
        </ol>
      </ol>
    </ol>
    <p><strong>Entrega&nbsp;</strong></p>
    <ol>
      <li>
        <h2><span>Los costes de entrega se indican a los Compradores durante la Venta en
            Directo.&nbsp;</span></h2>
      </li>
    </ol>
    <ul>
      <li>
        <h2><span>Método de entrega:</span></h2>
      </li>
    </ul>
    <ol>
      <li>
        <h2><span>El Vendedor entrega los Artículos a la dirección indicada por el Comprador
            al hacer el pedido. Los Compradores deberán asegurarse de que los datos de entrega facilitados a los
            Vendedores sean correctos y completos; Suass no será responsable cuando se faciliten datos incorrectos o
            incompletos.&nbsp;</span></h2>
      </li>
      <li>
        <h2><span>Los Artículos solo pueden entregarse si:</span></h2>
      </li>
      <ol>
        <li>
          <h3><span>el Vendedor ofrece la entrega en el país de destino indicado en la
              dirección de entrega facilitada por el Comprador al hacer el pedido; y</span></h3>
        </li>
        <li>
          <h3><span>el Comprador tiene una dirección de entrega válida en el país de destino
              indicada en la dirección de entrega por el Comprador al hacer el pedido.</span></h3>
        </li>
      </ol>
    </ol>
    <ul>
      <li>
        <h2><span>Recepción de Artículos:</span></h2>
      </li>
    </ul>
    <ol>
      <li>
        <h2><span>Los Compradores deberán comprobar el estado de los Artículos en el momento
            de su recepción para confirmar que son conformes con el pedido, y deberán informar a Suass en un plazo de
            dos días (o lo antes posible) de cualquier problema, en cuyo caso podrán acogerse a la Política de Fondo
            de Comercio de Suass que se describe a continuación. .</span></h2>
      </li>
      <li>
        <h2><span>Entrega con retraso o no realizada: En caso de retraso en la entrega, o de
            no recibirse total o parcialmente los Artículos solicitados, se invita al Comprador a ponerse en contacto
            con Suass a partir de la fecha de entrega convenida para abordar el problema, y a informar a Suass en el
            plazo de dos meses desde la fecha del pedido de que no ha recibido total o parcialmente los artículos
            solicitados, en cuyo caso podrá acogerse la Política de Fondo de Comercio de Suass que se describe a
            continuación.&nbsp;</span></h2>
      </li>
      <li>
        <h2><span>Si el servicio de mensajería o de envío postal devuelve un Artículo al
            Vendedor por un motivo como «no reclamado» o «no reside en la dirección indicada», se reembolsará al
            Comprador el precio de su pedido (excepto el coste de devolución del Artículo)</span></h2>
      </li>
    </ol>
    <ul>
      <li>
        <h1><strong>Derechos de cancelación y devoluciones</strong></h1>
      </li>
    </ul>
    <ol>
      <ol>
        <ol>
          <li>
            <h2><span>Si es usted un Vendedor Profesional, debe poner a disposición de los
                Compradores el derecho legal de cancelación, y darlo a conocer. Existen algunas excepciones; consulte
                el Apéndice 1 para obtener más información. Esto se aplica tanto a los Artículos nuevos como a los de
                segunda mano. Los Vendedores Individuales no están legalmente obligados a hacerlo; no obstante, podrán
                hacerlo a su discreción.&nbsp;</span></h2>
          </li>
          <li>
            <h2><span>Se puede reconocer a los Vendedores Profesionales por la insignia
                «Profesional» que figura junto a su nombre de vendedor en la Plataforma.</span></h2>
          </li>
          <li>
            <h2><span>Política de devoluciones: El Vendedor es libre de establecer su propia
                política de devoluciones siempre que cumpla con los requisitos legales. Suass no será responsable en
                caso de que un Vendedor no disponga de una política de devoluciones.</span></h2>
          </li>
        </ol>
      </ol>
    </ol>
    <ul>
      <li>
        <h1><strong>Política de Fondo de Comercio de Suass</strong></h1>
      </li>
    </ul>
    <ol>
      <ol>
        <ol>
          <li>
            <h2><span>La mayoría de las ventas en Suass se realizan sin problemas. No
                obstante, si se produce un problema con un Artículo, la Política de Fondo de Comercio de Suass ayuda a
                Compradores y Vendedores a comunicarse y encontrar una solución al problema. En caso de conflicto, el
                Comprador podrá ponerse en contacto con Suass, que actuará como enlace con el Vendedor para encontrar
                una solución al problema planteado.</span></h2>
          </li>
          <li>
            <h2><span>Las normas que rigen la Política de Fondo de Comercio de Suass forman
                parte integrante de estas Condiciones. El Vendedor se compromete a cumplir las normas de la Política
                de Fondo de Comercio de Suass y nos autoriza a tomar la decisión final en cualquier disputa.</span>
            </h2>
          </li>
          <li>
            <h2><span>Si el Vendedor no propone una solución razonablemente aceptable al
                Comprador en un plazo de 10 días a partir del momento en el que el Comprador plantee la disputa con el
                Vendedor, la Política de Fondo de Comercio de Suass puede aplicarse a los siguientes tipos de
                disputas:</span></h2>
          </li>
          <ol>
            <li>
              <h3><span>En caso de que no se reciba un Artículo;</span></h3>
            </li>
            <li>
              <h3><span>En caso de recepción de un Artículo que no se ajuste al Artículo
                  vendido; o</span></h3>
            </li>
            <li>
              <h3><span>En caso de entrega de artículos falsificados</span></h3>
            </li>
          </ol>
          <li>
            <h2><span>En virtud de la Política de Fondo de Comercio de Suass, si se resuelve
                una disputa a favor del Comprador, Suass podrá exigir al Vendedor que reembolse al Comprador los
                importes adeudados al Comprador para que Suass pueda reembolsar al Comprador en nombre del Vendedor.
                El Vendedor también autoriza a Suass a facturar al Vendedor dichos importes en nombre de Suass.</span>
            </h2>
          </li>
          <li>
            <h2><span>Si se determina que el Vendedor ha incurrido en culpa o incumplimiento
                de estas Condiciones/los Términos y Condiciones Generales, se lo notificaremos al Vendedor y le
                facturaremos el importe del reembolso.</span></h2>
          </li>
          <li>
            <h2><span>El Vendedor reconoce y acepta que las autorizaciones anteriores se
                otorgarán de manera recurrente y en diversos momentos según lo exijamos para implementar las normas
                que rigen la Política de Fondo de Comercio de Suass. En el caso de reclamaciones futuras, cuando
                resolvamos una disputa a favor del Comprador, se lo notificaremos al Vendedor y seguiremos facturando
                al Vendedor.</span></h2>
          </li>
          <li>
            <h2><span>Podremos suspender la Política de Fondo de Comercio de Suass sin
                previo aviso si sospechamos que se han producido abusos o interferencias en el correcto funcionamiento
                de la Política de Fondo de Comercio de Suass.</span></h2>
          </li>
          <li>
            <h2><span>La Política de Fondo de Comercio de Suass no es una garantía de
                producto ni una garantía de servicio. Se trata de una característica adicional del fondo de comercio
                facilitada por Suass, y no sustituye a ningún derecho que los Compradores tengan frente a los
                Vendedores en virtud de la ley.</span></h2>
          </li>
        </ol>
      </ol>
    </ol>
    <ul>
      <li>
        <h1><strong>Nuestra responsabilidad por las pérdidas soportadas por usted</strong></h1>
      </li>
    </ul>
    <ol>
      <ol>
        <ol>
          <li>
            <h2><span>Siempre seremos responsables ante usted por lo siguiente:</span></h2>
          </li>
          <ol>
            <li>
              <h3><span>muerte o lesiones personales causadas por negligencia de
                  Suass;</span></h3>
            </li>
            <li>
              <h3><span>fraude por parte de Suass; y</span></h3>
            </li>
            <li>
              <h3><span>cualquier otra responsabilidad que no pueda excluirse o limitarse
                  con arreglo a la legislación aplicable, como conducta dolosa o negligencia grave.&nbsp;</span></h3>
            </li>
          </ol>
          <li>
            <h2><span>De lo contrario, solo seremos responsables frente a usted por daños,
                pérdidas o costes que se deriven del incumplimiento por nuestra parte de nuestras obligaciones
                derivadas de estas Condiciones y que usted y nosotros hubiéramos podido prever razonablemente al
                formalizar estas Condiciones.&nbsp;</span></h2>
          </li>
          <li>
            <h2><span>En la medida en que lo permita la legislación aplicable, no
                garantizamos que la Plataforma, su contenido y funcionalidad estén plenamente disponibles en todo
                momento. No seremos responsables de ninguna pérdida que sufra por usted: (a) como consecuencia de que
                la Plataforma no esté totalmente disponible en todo momento; o (b) en relación con cualquier Artículo
                comprado o vendido a través de la Plataforma. No asumimos el compromiso de que el acceso a la
                Plataforma no se vea interrumpido o esté exento de errores.&nbsp;</span></h2>
          </li>
          <li>
            <h2><span>No somos responsables por ningún Artículo perdido o dañado durante la
                entrega. Si se produce algún problema con su pedido, consulte la cláusula 11.</span></h2>
          </li>
        </ol>
      </ol>
    </ol>
    <ul>
      <li>
        <h1><strong>Contenido</strong></h1>
      </li>
    </ul>
    <ol>
      <ol>
        <ol>
          <li>
            <h2><span>Al cargar, transmitir o publicar contenido en la Plataforma, nos
                concede usted una licencia mundial, no exclusiva, libre de regalías y transferible para utilizar,
                reproducir, distribuir, elaborar obras derivadas, mostrar y ejecutar el contenido generado por el
                usuario a efectos de funcionamiento de la Plataforma. No reclamamos la propiedad de su contenido de
                usuario que seguirá correspondiéndole a usted, respetando siempre los límites establecidos por la ley
                aplicable, en particular en relación con los derechos de recuperación del Contenido de Usuario Final
                por parte del Usuario.&nbsp;</span></h2>
          </li>
          <li>
            <h2><span>Todos los derechos de propiedad intelectual sobre la Plataforma, su
                contenido y cualquier material relacionado pertenecen a Suass o a sus licenciantes y no deben
                copiarse, distribuirse, cargarse ni republicarse de ninguna manera sin el consentimiento previo por
                escrito de Suass. Todos los derechos de propiedad intelectual sobre los materiales de los Vendedores
                pertenecerán a los Vendedores.</span></h2>
          </li>
          <li>
            <h2><span>Al vender en la Plataforma, los Vendedores se comprometen a:</span>
            </h2>
          </li>
          <ol>
            <li>
              <h3><span>verificar que la información incluida en el Listado de Artículos es
                  exacta;</span></h3>
            </li>
            <li>
              <h3><span>no eliminar ninguna indicación de derecho de autor, «marca TM» u
                  otras indicaciones en el Listado de Artículos; y</span></h3>
            </li>
            <li>
              <h3><span>no utilizar el contenido del catálogo de forma que infrinja los
                  derechos de terceros, ni crear obras derivadas que utilicen dichos datos o información de ninguna
                  forma (salvo con el único fin de incluir dicha información en sus anuncios).</span></h3>
            </li>
          </ol>
          <li>
            <h2><span>Puede crear enlaces con la Plataforma, siempre que lo haga de manera
                leal y legal y no perjudique nuestra reputación.&nbsp;</span></h2>
          </li>
        </ol>
      </ol>
    </ol>
    <ul>
      <li>
        <h1><strong>Protección de Datos y Privacidad</strong></h1>
      </li>
    </ul>
    <ol>
      <ol>
        <ol>
          <li>
            <h2><span>Usted y Suass tratarán los datos personales recibidos en virtud de o
                en relación con estas Condiciones, en calidad, cada uno, de responsable del tratamiento de datos
                independiente. Bajo ninguna circunstancia usted y Suass tratarán datos personales en virtud de o en
                relación con estas Condiciones como corresponsables del tratamiento o en el marco una relación de
                responsable del tratamiento a encargado del tratamiento. Como responsable del tratamiento
                independiente, será usted responsable de manera individual y por separado del cumplimiento de sus
                obligaciones como responsable del tratamiento en virtud de las leyes de protección de datos
                aplicables.</span></h2>
          </li>
          <li>
            <h2><span>Deberá usted cumplir sus obligaciones derivadas de las leyes de
                protección de datos aplicables (incluido, a título enunciativo y no limitativo, el Reglamento (UE)
                2016/679 (Reglamento General de Protección de Datos de la Unión Europea «RGPD») y las leyes
                complementarias de la Unión Europea y de protección de datos locales, en particular la Ley Orgánica
                3/2018 de Protección de Datos de Carácter Personal y garantía de los derechos digitales. Esto incluye,
                entre otras cosas, la obligación de proporcionar garantías adecuadas para la transferencia de datos
                personales a un tercer país u organización internacional.</span></h2>
          </li>
          <li>
            <h2><span>Deberá usted tratar los datos personales recibidos de Suass
                exclusivamente para los fines para los que los haya recibido en virtud de o en relación con estas
                Condiciones. Deberá suprimir los datos personales recibidos en virtud de estas Condiciones
                inmediatamente después de que se hayan cumplido los fines correspondientes. No se permite ningún otro
                tratamiento de datos personales, a menos que lo exija la ley (por ejemplo, si se aplica una obligación
                de retención, incluido el derecho de conservación y bloqueo establecido en el artículo&nbsp;32 de la
                Ley Orgánica 3/2018 de Protección de Datos Personales y garantía de los derechos digitales, en
                particular).</span></h2>
          </li>
          <li>
            <h2><span>El incumplimiento de las obligaciones anteriores puede dar lugar a
                medidas disciplinarias, incluida la suspensión de su cuenta.</span></h2>
          </li>
        </ol>
      </ol>
    </ol>
    <ul>
      <li>
        <h1><strong>Acceso a la Información Personal</strong></h1>
      </li>
    </ul>
    <ol>
      <ol>
        <ol>
          <li>
            <h2><span>Para obtener información sobre el tratamiento por parte de Suass de
                datos personales sobre usted como persona física, incluido el intercambio de dichos datos con terceros
                y sus derechos como interesado, consulte nuestra </span><span>Política de
                Privacidad. </span><span>De conformidad con lo dispuesto en el
                artículo&nbsp;11 de la Ley Orgánica 3/2018 de Protección de Datos Personales y garantía de los
                derechos digitales, queda usted informado de que el responsable del tratamiento de sus datos será
              </span><span>SUASS S.L.</span><span>, que los fines
                del tratamiento serán la creación de una cuenta de usuario para acceder a la plataforma y poder
                acceder a nuestros servicios ofrecidos a través de la misma y que puede usted ejercer ante al
                responsable del tratamiento los derechos contemplados en los artículos 15 a 22 del Reglamento 2016/69
                de la UE («RGPD»).&nbsp;</span></h2>
          </li>
        </ol>
      </ol>
    </ol>
    <ul>
      <li>
        <h1><strong>Otras condiciones importantes</strong></h1>
      </li>
    </ul>
    <ol>
      <ol>
        <li>
          <h2><span>Nada de lo contenido en estas Condiciones tiene por objeto crear, ni se
              considerará que crea, ninguna asociación entre usted y Suass.&nbsp;</span></h2>
        </li>
        <li>
          <h2><span>Si alguna cláusula de estas Condiciones se considera ilegal, el resto
              mantendrá plena vigencia y eficacia. Incluso si usted o nosotros nos retrasamos en exigir el
              cumplimiento de cualquiera de estas Condiciones, la cláusula podrá seguir aplicándose más
              adelante.</span></h2>
        </li>
        <li>
          <h2><span>Ninguna otra persona tiene ningún derecho en virtud de estas
              Condiciones: solo se aplican entre usted y Suass.&nbsp;</span></h2>
        </li>
        <li>
          <h2><span>Se informa a los Vendedores de que también pueden vender sus productos y
              servicios a través de otras plataformas electrónicas y tiendas físicas.&nbsp;</span></h2>
        </li>
        <li>
          <h2><span>Se informa al Vendedor de que los Vendedores (y, en su caso, los
              Compradores) que tengan la naturaleza de empresas pueden tratar de resolver conflictos que puedan tener
              con Suass a través de un mediador en la Unión Europea</span></h2>
        </li>
        <li>
          <h2><span>Se informa a los consumidores de la existencia de una plataforma para la
              resolución en línea de litigios entre operadores comerciales/proveedores de servicios en línea y
              consumidores (http://ec.europa.eu/odr). No obstante, Suass no somete a ese mecanismo la resolución de
              controversias con los consumidores.&nbsp;</span></h2>
        </li>
        <li>
          <h2><span>Todos los honorarios y gastos derivados de la explotación de la
              plataforma correrán a cargo de los Vendedores según lo establecido en estas condiciones. Suass no
              asumirá ninguno de ellos, quedando liberada de la obligación establecida por el artículo 57.2, párrafo
              primero, de la Ley 7/1996 de Ordenación del Comercio Minorista. Suass quedará asimismo liberada de las
              obligaciones de custodia y tasación establecidas en el párrafo segundo del mismo Artículo, que recaerán
              sobre el Vendedor en cuestión.&nbsp;</span></h2>
        </li>
        <li>
          <h2><span>Estas Condiciones, su objeto y formación (y cualquier disputa o
              reclamación extracontractual) se rigen por la legislación española aplicada en la ciudad de Madrid.
              Ambas partes aceptan la jurisdicción exclusiva de los juzgados y tribunales de Madrid (España), a menos
              que sea usted un consumidor radicado en España, en cuyo caso puede presentar una reclamación en los
              juzgados tribunales de su lugar de residencia.&nbsp;</span></h2>
        </li>
      </ol>
    </ol>
    <p><strong>Apéndice 1 – Términos y Condiciones Generales</strong></p>
    <p>&nbsp;</p>
    <ol>
      <li>
        <h1><span>Las referencias a «usted» se refieren a Compradores, y las referencias a
            «nosotros» o «nuestro/a(s)» se refieren a Vendedores Individuales o Profesionales (según proceda). Estas
            condiciones conforman el contrato entre los Compradores y los Vendedores respecto a los Artículos
            adquiridos a través de la Plataforma, con sujeción a las condiciones adicionales acordadas entre el
            Comprador y el Vendedor, a la Ley 34/2002 de servicios de la sociedad de la información y de comercio
            electrónico (en particular, los artículos 27, 28 y 29) y, en su caso, al Real Decreto Legislativo 1/2007,
            por el que se aprueba el texto refundido de la Ley General para la Defensa de los Consumidores y Usuarios
            y otras leyes complementarias. Si es usted un Comprador radicado en España, se aplicarán estas
            condiciones.&nbsp;</span></h1>
      </li>
      <li>
        <h1><span>En nuestro perfil encontrará todo lo que necesita saber sobre
            nosotros.&nbsp;</span></h1>
      </li>
      <li>
        <h1><span>Nos pondremos en contacto con usted para confirmar que hemos aceptado su
            pedido. Si no podemos aceptar un pedido, se lo comunicaremos lo antes posible.&nbsp;</span></h1>
      </li>
      <li>
        <h1><span>Entregaremos su Artículo de acuerdo con la información que se le haya
            comunicado en el marco del proceso de ventas.&nbsp;</span></h1>
      </li>
      <li>
        <h1><span>Si la entrega de su Artículo se retrasa debido a un hecho ajeno a nuestro
            control, nos pondremos en contacto con usted lo antes posible para comunicárselo y hacer lo posible por
            reducir el retraso.&nbsp;</span></h1>
      </li>
      <li>
        <h1><span>El color verdadero de un Artículo puede no coincidir exactamente con el
            que aparece en el Listado de Artículos o en la transmisión en directo, o su embalaje puede ser ligeramente
            diferente.&nbsp;</span></h1>
      </li>
      <li>
        <h1><strong>Si ha comprado su Artículo a un Vendedor Profesional</strong><span>:&nbsp;</span></h1>
      </li>
    </ol>
    <ul>
      <li>
        <h1><span>Tiene usted derecho legalmente a cambiar de opinión sobre su compra,
            independientemente de que el artículo sea nuevo o de segunda mano, y a recibir un reembolso de lo que haya
            pagado por el mismo (incluidos los costes de entrega estándar), si bien esto está sujeto a algunas
            condiciones, según se establece a continuación.</span></h1>
      </li>
      <li>
        <h1><span>Si cambia de opinión con respecto a un Artículo, debe informarnos a más
            tardar 14 días después de la fecha en que le entreguemos el/los Artículo(s). Los gastos de devolución
            serán por su cuenta y los artículos deben devolverse a la dirección que figura en el Listado de
            Artículos.&nbsp;</span></h1>
      </li>
      <li>
        <h1><span>Podemos reducir su reembolso si ha utilizado o dañado un Artículo más allá
            de lo que la ley considera aceptable. En algunos casos, debido a la forma en que ha tratado usted un
            Artículo (por ejemplo, cuando el Artículo se ha desprecintado o retirado de su embalaje protector), puede
            no hacerse ningún reembolso.&nbsp;</span></h1>
      </li>
      <li>
        <h1><span>Le reembolsaremos en los 14 días posteriores a la recepción de los
            Artículos que haya devuelto usted.&nbsp;</span></h1>
      </li>
    </ul>
    <ol>
      <li>
        <h1><span>Cumplimos con nuestra obligación legal de proporcionarle Artículos que
            sean los descritos en la Plataforma y que cumplan con todos los requisitos impuestos por la ley; por
            ejemplo, que se correspondan con su descripción, sean de calidad satisfactoria y aptos para su
            propósito.&nbsp;</span></h1>
      </li>
      <li>
        <h1><span>Podemos resolver nuestro contrato con usted en relación con cualquier
            Artículo si no efectúa el pago a su vencimiento.&nbsp;</span></h1>
      </li>
      <li>
        <h1><span>Ninguna otra persona tiene ningún derecho u obligación en virtud de este
            contrato: se trata de un contrato entre usted y nosotros.&nbsp;</span></h1>
      </li>
      <li>
        <h1><span>Este contrato se rige por la legislación española aplicada en la ciudad de
            Madrid (España) y puede usted interponer demandas contra nosotros ante los juzgados y tribunales españoles
            de Madrid (España). Si es usted un consumidor radicado en España puede presentar reclamaciones ante los
            juzgados y tribunales de su lugar de residencia.&nbsp;</span></h1>
      </li>
    </ol>
  </div>
</div>
