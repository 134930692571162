<app-page-title title="Configuración">
  <button [disabled]="loading" class="btn btn-primary" (click)="save()"><app-loading-spinner *ngIf="loading" />
    Guardar</button>
</app-page-title>
<div class="card shadow-sm">

  <div class="card-body">
    <div class="d-flex align-items-center justify-content-between  mb-3">
      <h5 class="card-title">Overlay widget</h5>

    </div>
    <app-loading-indicator *ngIf="!settings" />
    <app-auction-widget-editor *ngIf="settings" [settings]="settings.widget" (onChange)="setWidgetSettings($event)" />
  </div>


</div>
