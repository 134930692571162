<div class="card card-sm-compact"
  [ngClass]="{'border-warning':paymentMethods.length === 0, 'border-2':paymentMethods.length === 0}">
  <div class="card-header d-flex justify-content-between align-items-center">
    Métodos de pago
    <button class="btn btn-outline-primary btn-sm" (click)="openAddPaymentMethodModal()">Añadir</button>
  </div>
  <app-user-payment-method-list [paymentMethods]="paymentMethods" [cardEmbedList]="true" />
  <div class="card-footer" *ngIf="paymentMethods.length> 0">

      La tarjeta marcada como <span class="badge bg-success">Principal</span> será cargada
      automáticamente tras ganar una subasta.

  </div>
</div>
