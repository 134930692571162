<ng-container *ngIf="user;else noUser">


  <form class="bid-form" [formGroup]="bidForm"
    [ngClass]="{'border-2':!isOnboardingCompleted, 'border-warning':!isOnboardingCompleted, 'force-h': forceHorizontal}">
    <div class="onboarding-needed" *ngIf="!isOnboardingCompleted">
      Para participar tienes que <a class="alert-link" routerLink="/viewer/cuenta">añadir dirección y método de pago</a>.
    </div>
    <div class="form-wrapper">


      <!-- Input helpers -->
      <div class="input-and-helpers flex-grow-1 align-items-center">
        <label class="input-wrapper flex-grow-1 d-flex flex-column" [ngClass]="{focused: amountFocused}">
          <div class="amount-label">Cantidad</div>
          <input (focus)="amountFocused=true" (focusout)="amountFocused=false" formControlName="amount"
            class="amount-input" type="number" placeholder="Cantidad" />
        </label>
        <div>
          <button [disabled]="nextBidAmount.isEqualTo(amount)" class="btn btn-outline-secondary"
            (click)="setAmountToMin()">
            Min
          </button>
        </div>
        <div>
          <button type="button" class="btn btn-outline-secondary" (click)="incAmountBy(+minBidIncrement)"
            *ngIf="minBidIncrement">
            +{{minBidIncrement}}{{auction?.currencySymbol || '€'}}
          </button>
        </div>
      </div>
      <!-- The button -->
      <div class="btn-wrapper" placement="left"
        [ngbTooltip]="userIsLastBidder ? 'No puedes superar tu propia puja' : ''">
        <button (click)="placeBid(amount.toFixed(2))" class="btn btn-primary" [disabled]="bidDisabled">
          <ng-container *ngIf="processing$|async;else bidBtnContent">
            <app-loading-spinner />
          </ng-container>
          <ng-template #bidBtnContent>
            <fa-icon [icon]="bidIcon" /> {{ bidBtnText }}
          </ng-template>
        </button>
      </div>

    </div>
    <!-- <p class="fast-bid-help">Toca el botón dos veces</p> -->
  </form>
  <!-- Delivery price -->
  <div *ngIf="auction && auction.deliveryPrice" class="text-end px-2">
    <small>+ gastos de envío {{auction.deliveryPrice|price}}{{auction.currencySymbol}}</small>
  </div>


</ng-container>

<ng-template #noUser>
  <div class="alert alert-warning mt-2">
    Para pujar debes <a routerLink="/login">acceder a tu cuenta</a> o <a routerLink="/crear-cuenta">crear una!</a>
  </div>
</ng-template>
