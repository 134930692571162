<form [formGroup]="form" (ngSubmit)="save()" class="auction-form">
  <div class="row">

    <div class="col-12 col-xl-10">
      <div class="row">
        <!-- Title -->
        <div class="col-8 col-xl-7">
          <app-form-input [form]="form" controlName="title" label="Título" marginBottom="3" />
          <app-form-input type="textarea" [form]="form" controlName="description" label="Descripción"
            marginBottom="3" />
            <div class="row">
              <div class="col-4">
                <app-form-input [form]="form" controlName="startingPrice" label="Precio de salida" sufix="€"
                  formText="Puja mínima inicial" marginBottom="3" />
              </div>
              <div class="col-4">
                <app-form-input [form]="form" controlName="minBidIncrement" label="Incremento mínimo" sufix="€"
                  formText="Cantidad minima para superar la puja anterior." marginBottom="3" />
              </div>
              <div class="col-4">
                <app-form-input [form]="form" controlName="deliveryPrice" label="Gastos de envío"
                  formText="Se cobrará al usuario por encima de la puja" sufix="€" marginBottom="3" />
              </div>
            </div>
        </div>
        <!-- Img start -->
        <div class="col-4 col-xl-5 d-flex flex-column">
          <div class="form-label mb-1">Imágen</div>
          <div class="bg-body-secondary rounded p-2 flex-grow-1 mb-3 upload-box">
            <label class="btn w-100 btn-sm btn-outline-primary btn-file">
              <fa-icon [icon]="addImageIcon" /> Añadir imagen
              <input type="file" accept="image/*" (change)="handleUpload($event)">
            </label>
            <div class="text mt-1">Soporta JPG, PNG, WEBP.<br /> Tamaño máximo 10MB.</div>
            <img *ngIf="pictureSrc" [src]="pictureSrc" class="product-picture mt-2 rounded" />
          </div>
        </div>
        <!-- Img end -->

      </div>
      <!-- Dineros -->
      <div class="row">

      </div>
      <!-- Fin Dineros -->
      <!-- Tiempos y cantidad -->
      <div class="row mb-3">
        <div class="col-4">
          <app-form-input [form]="form" controlName="quantity" label="Cantidad" type="number"
            formText="Copias de la subasta" marginBottom="3" />
        </div>
        <div class="col-4">
          <app-form-input [form]="form" type="number" controlName="duration" label="Duración inicial"
            dropdownControlName="durationUnit" [dropdownOptions]="timeUnitOptions" dropdownMaxWidth="80px"
            marginBottom="3" />
        </div>
        <div class="col-4">
          <app-form-input [form]="form" type="number" controlName="extPeriod" label="Reinicio de contador"
            dropdownControlName="extPeriodUnit" [dropdownOptions]="timeUnitOptions" dropdownMaxWidth="80px" />
        </div>
        <!-- Fin Tiempos y cantidad -->
      </div>
      <!-- Modos  -->
      <form [formGroup]="auxForm">
        <div class="list-group-radio">
          <div class="list-group-radio-item" [ngClass]="{selected: hasLiveChannel}">
            <label class="list-group-item d-flex gap-3 align-items-center">
              <input type="checkbox" formControlName="live" />
              <app-stream-platform-icon platform="twitch" />
              <div class="flex-grow-1">
                <b>Subastar en directo</b>
                <div>Podrás crear tantas subastas como quieras con esta opción, pero solo podrás tener una en curso
                  simultáneamente.</div>
              </div>
            </label>
          </div>

          <div class="list-group-radio-item" [ngClass]="{selected: isScheduling}">
            <label class="list-group-item ">
              <div class="d-flex gap-3 align-items-center">
                <input type="checkbox" formControlName="isScheduling" />
                <div>📅 </div>
                <div class="flex-grow-1">
                  <b>Programar para después</b>
                  <div>
                    La subasta empezará en el día y hora que gustes.
                    <div class="alert alert-warning p-1 mt-1">
                      No es compatible con subastas en directo.
                      Solo se puede pujar desde <app-stream-platform-icon platform="suass" [withText]="true" />.
                    </div>
                    <div class="alert alert-warning p-1" *ngIf="quantity > 1 && isScheduling">
                      Cuidado! Si repites varias veces subastas iguales podrías tener poca competencia en las pujas y
                      vender por debajo del precio deseado. Prueba a ajustar el precio de salida ☺️.
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="isScheduling" class="p-2 bg-body-secondary rounded">
                <div class="row">
                  <div class="col-6 col-xl-7">
                    <app-form-input [form]="form" label="Día" controlName="startDate" type="date" />
                  </div>
                  <div class="col-6 col-xl-5">
                    <app-form-input [form]="form" label="Hora" controlName="startTime" type="time" />
                  </div>
                </div>
              </div>

            </label>
          </div>
        </div>
      </form>
      <!-- Fin Modos -->
      <app-api-error-block [error]="(error$|async)" />
    </div>
    <!-- <div class="hidden col-lg-4">
          <div class="">
            <h6>Consejos</h6>
            <ul class="list-group">
              <li class="list-group-item">
                <b>Desactivar delay</b> durante el periodo que tengas la puja activa para mejorar la experiencia del
                comprador.
              </li>
              <li class="list-group-item">
                <b>Ocultar foto producto</b> en el widget del stream si vas a tener el producto presente contigo.
              </li>
            </ul>
          </div>
        </div> -->




    <div class="text-end mt-3 cta-wrapper">
      <div class="row">
        <div class="col-12 col-xl-8">
          <button class="btn btn-primary btn-lg" [disabled]="isSaveDisabled" (click)="save()">
            {{ctaText|async}}
          </button>

        </div>
      </div>
    </div>
  </div>
</form>
