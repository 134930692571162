import { Component, OnInit } from '@angular/core';
import { MerchantService } from '../../../../api/services/MerchantService';
import { MerchantSettingsDto, MerchantWidgetSettings } from '../../../../models/Order';

@Component({
  selector: 'app-merchant-settings-page',
  templateUrl: './merchant-settings-page.component.html',
  styleUrls: ['./merchant-settings-page.component.scss']
})
export class MerchantSettingsPageComponent  implements OnInit {

  settings: MerchantSettingsDto | null = null
  loading = false;

  constructor(
    public merchantSvc: MerchantService
  ) {}

  ngOnInit(): void {
    this.merchantSvc.getSettings().subscribe(settings=>this.settings = settings)
  }

  setWidgetSettings(widget: MerchantWidgetSettings){
    if(!this.settings){
      return
    }
    this.settings.widget = widget
  }

  save(){
    if(this.loading || !this.settings){
      return
    }
    this.loading = true
    this.merchantSvc.saveSettings(this.settings).subscribe(()=>{
      this.loading =false
    })
  }
}
