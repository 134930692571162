import { Injectable } from "@angular/core";
import { ListBaseEffects } from "../utils/list.base.effects";
import { pMerchantProductList } from "./merchant.profile.store";
import { HttpClient } from "@angular/common/http";
import { Actions } from "@ngrx/effects";
import { AppStore } from "../app.store";
import { IProduct } from "../../models/Product";

@Injectable()
export class MerchantProductListEffects extends ListBaseEffects<IProduct> {
  constructor(
    actions$: Actions,
    store: AppStore,
    http: HttpClient
  ) {
    super(pMerchantProductList, `/public/merchants/:merchantId/products`, actions$, store, http)
  }
}
