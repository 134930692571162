import { Component, OnDestroy } from '@angular/core';
import { AuctionState, IAuction } from '../../../../models/Auction';
import { UiService } from '../../../../ui/services/ui.service';
import { AppStore } from '../../../../store';
import { mAuctionListFeat, mAuctionListActions } from '../../../../store/merchant.store';
import { createSubs, unsubAll } from '../../../../utils/rxjs.utils';
import { debounceTime, distinctUntilChanged, map } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faCopy, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { userFeat } from '../../../../store/user.store';
import { getApiErrorMessage } from '../../../../utils/api.utils';
import { Actions, ofType } from '@ngrx/effects';

@Component({
  selector: 'app-merchant-auctions-page',
  templateUrl: './merchant-auctions-page.component.html',
  styleUrls: ['./merchant-auctions-page.component.scss']
})
export class MerchantAuctionsPageComponent implements OnDestroy {
  constructor(
    private uiCommonSvc: UiService,
    private store: AppStore,
    private uiSvc: UiService,
    private route: ActivatedRoute,
    private actions$: Actions
  ){

  }
  refreshIcon= faRefresh
  copyIcon = faCopy
  loading = false

  loading$ = this.store.select(mAuctionListFeat.selectLoading)
  items$ = this.store.select(mAuctionListFeat.selectAuctions)
  count$ = this.items$.pipe(map(items=>items ? items.length : 0))

  filterForm = new FormGroup({
    list: new FormControl(''),
    search: new FormControl('')
  })
  loadAuctionsLoading = mAuctionListFeat.selectLoading
  loadAuctionsAction = mAuctionListActions.loadAuctions

  liveWidgetUrl: string|null = null

  subs$ = createSubs()

  ngOnInit(): void {
    this.uiCommonSvc.setTitle('Subastas')

    this.subs$.routeParams = this.route.params.subscribe(({list})=>{
      this.filterForm.patchValue({list:  list || 'live'})
      this.filterForm.get('search')!.valueChanges.pipe(debounceTime(400),distinctUntilChanged()).subscribe(()=>this.search())
      this.filterForm.get('list')!.valueChanges.subscribe(()=> this.search())
      this.search()
    })

    this.subs$.user = this.store.select(userFeat.selectUser).subscribe((user) => {
      if(!user){
        return
      }
      this.liveWidgetUrl = `${location.origin}/widgets/auction/live?merchant=${user.id}`
    })


    this.subs$.repeatError =  this.actions$.pipe(ofType(mAuctionListActions.duplicateAuctionKO)).subscribe((error)=>{
      this.notifyError(error)
    })

    this.subs$.startError =  this.actions$.pipe(ofType(mAuctionListActions.startAuctionKO)).subscribe((error)=>{
      this.notifyError(error)
    })
}

notifyError(error: any) {
  const errMsg = getApiErrorMessage(error.error) || ''
  this.uiSvc.showErrorToast({ text: errMsg })

  }

  ngOnDestroy(): void {
    unsubAll(this.subs$)
  }

  private search(){
    this.loadOrderList(this.filters)
  }

  get filters() {
    let {list, search} = this.filterForm.getRawValue()

    search = search || ''

    const filters: any = {
      search,
      list
    }

    return { filters }
  }

  loadOrderList(filters: any) {
    this.store.dispatch(mAuctionListActions.loadAuctions(filters))
  }

  refresh(){
    this.search()
  }


  onOrderTabChange(list: string) {
    this.filterForm.patchValue({ list })
  }
  get status(){
    return this.filterForm.get('status')?.getRawValue()
  }

  get isRunningStatusTab(){
    return this.status===AuctionState.Started
  }


  displayLiveWidgetCopiedNotification(){
    this.uiSvc.showToast({text: 'URL copiada al portapapeles', type: 'success'})
  }
}
