import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MerchantOrderDto, OrderStatus } from '../../../models/Order';
import { MerchantService } from '../../../api/services/MerchantService';

@Component({
  selector: 'app-manage-order-delivery',
  templateUrl: './manage-order-delivery.component.html',
  styleUrls: ['./manage-order-delivery.component.scss']
})
export class ManageOrderDeliveryComponent {
  @Input({ required: true }) order!: MerchantOrderDto
  @Output() onError = new EventEmitter()
  @Output() onSuccess = new EventEmitter<MerchantOrderDto>()
  disableActions = false
  _showDeliveryForm = false

  constructor(private merchantSvc: MerchantService) {

  }
  providers = ['correos','correos_express', 'dhl','mrw']

  ngOnInit(): void {
    if(this.order.sendLater?.rejectedByCustomerAt){
      this._showDeliveryForm = true
    }
  }

  displayForm(){
    this._showDeliveryForm = true
  }
  hideForm(){
    this._showDeliveryForm = false
  }

  get isOrderCanceled() {
    return this.order.status === OrderStatus.Canceled
  }
  get showDeliveryForm(){
    return this._showDeliveryForm || this.order.delivery
  }
  sendLaterRequest(){

    if(!confirm(`¿Estás seguro? El cliente recibirá un email para que apruebe o rechace la solicitud`)){
      this.disableActions = false
      return
    }
    this.disableActions = true
    return this.merchantSvc.setOrderSendLater(this.order.id).subscribe({
      next: order=>{
        this.onSuccess.emit(order)
        this._showDeliveryForm = false
        this.disableActions = false
      },
      error: (error) =>{
        this.onError.emit(error)
        this.disableActions = false
      }
    })
  }

  onDeliveryInfoSaved(order: MerchantOrderDto) {
    this.onSuccess.emit(order)
  }

}
