<div class="text-center" *ngIf="isAuctionOpened">
  <button class="btn w-100 btn-outline-primary" [ngClass]="{'btn-sm': size === 'sm'}"
    (dblclick)="placeBid(nextBidAmount.toFixed(2))" [disabled]="bidDisabled || (processing$ | async)">

    <div class="d-flex align-items-center justify-content-center gap-2">
      <ng-container *ngIf="(processingThis$ | async); else notProcessing">
        <app-loading-spinner /> Procesando
      </ng-container>
      <ng-template #notProcessing>


        <div class="icon"><fa-icon [icon]="bidIcon" /></div>
        <div class="bid-text text-start">
          <ng-container *ngIf="isUserLastBidder; else bidText">
            Pujaste {{lastBid?.amount | price}} {{auction.currencySymbol}}
          </ng-container>
          <ng-template #bidText>
            <div>Puja rápida {{nextBidAmount | price}}{{auction.currencySymbol}}</div>
          </ng-template>
          <div class="delivery-price" *ngIf="hasDeliveryPrice">+{{auction.deliveryPrice|price}}{{auction.currencySymbol}} de
            envío</div>
        </div>
      </ng-template>
    </div>

  </button>
  <p class="fast-bid-help">Toca el botón dos veces</p>
</div>
