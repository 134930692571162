
  <div class="container-fluid">
    <h1>Política de privacidad</h1>
    <div>

      <p><span style="font-weight: 400;">Suass («nosotros» o «nos») ofrece un mercado en línea en el que los
          usuarios pueden comprar y vender artículos («nuestros servicios»). Esta Política de Privacidad explica cómo
          recogemos, utilizamos y compartimos datos personales durante la prestación de nuestros servicios y el
          funcionamiento de nuestra plataforma </span><span style="font-weight: 400;">(</span><span
          style="font-weight: 400;">https://www.</span><span style="font-weight: 400;">suass.live)
          («plataforma»)&nbsp;</span></p>
      <p><span style="font-weight: 400;">Podremos modificar esta Política de Privacidad en cualquier momento para
          mantenerla actualizada con arreglo a los requisitos legales y la forma en que explotamos nuestro negocio.
          Consulte regularmente estas páginas para acceder a la última versión.&nbsp;</span></p>

          <h2><span style="font-weight: 400;">Acerca de nosotros (como responsable del tratamiento)</span></h2>

      <p><span style="font-weight: 400;">Suass es una marca bajo cual opera Sergio López Rico, dado de alta como autónomo.
          </span></p>
      <p><span style="font-weight: 400;">Puede ponerse en contacto con nosotros directamente utilizando los datos de
          contacto que figuran a continuación.&nbsp;</span></p>

          <h2><span style="font-weight: 400;">Recogida y uso por nuestra parte de datos personales</span></h2>

      <h3><span style="font-weight: 400;">¿Qué datos personales recogemos?</span></h3>
      <p><span style="font-weight: 400;">Tratamos datos personales de las siguientes personas:</span></p>
      <ul>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">visitantes a nuestra
            plataforma;</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">compradores y vendedores de
            artículos; y</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">socios comerciales, incluidos
            clientes y proveedores, cuya información de contacto tratamos para gestionar nuestras relaciones
            comerciales.&nbsp;</span></li>
      </ul>
      <p><span style="font-weight: 400;">Recogemos datos personales cuando completa usted formularios en nuestra
          plataforma, incluido el formulario de registro de cuenta y el formulario de pedido y pago.&nbsp;</span></p>
      <p><span style="font-weight: 400;">Nuestra plataforma recoge automáticamente información sobre todos los
          visitantes; consulte la sección 8 a continuación para obtener más información.&nbsp;</span></p>
      <p><span style="font-weight: 400;">A menos que se indique lo contrario, todas las categorías de información que
          figuran en nuestro registro de cuenta y formularios de pedido y pago son obligatorias para, respectivamente,
          crear una cuenta o completar un pedido. Si no nos facilita la información requerida, no podremos ofrecerle
          acceso a la plataforma ni facilitarle la compra y venta de artículos.</span></p>
      <h3><span style="font-weight: 400;">¿Para qué utilizamos sus datos personales?</span></h3>
      <p><span style="font-weight: 400;">En la tabla siguiente hemos facilitado una descripción de los diferentes fines
          para los que tratamos datos personales. Para cada fin, hemos indicado el </span><em><span
            style="font-weight: 400;">fundamento jurídico</span></em><span style="font-weight: 400;"> en el que nos
          basamos conforme a la legislación en materia de protección de datos (Reglamento General de Protección de Datos
          de la Unión Europea [RGPD]) y cualquier otra ley aplicable en materia de protección de datos):</span></p>
      <table>
        <tbody>
          <tr>
            <td>
              <p><strong>Objeto</strong></p>
            </td>
            <td>
              <p><strong>Datos Personales</strong></p>
            </td>
            <td>
              <p><strong>Fundamento jurídico</strong></p>
            </td>
          </tr>
          <tr>
            <td>
              <p><span style="font-weight: 400;">Creación de una cuenta para acceder a la plataforma</span></p>
            </td>
            <td>
              <p><span style="font-weight: 400;">Nombre; correo electrónico; contraseña, número de teléfono
                  (opcional)</span></p>
            </td>
            <td>
              <p><span style="font-weight: 400;">Cumplimiento de nuestras condiciones/ejecución del contrato (artículo
                  6, apartado 1, letra b), del RGPD)</span></p>
            </td>
          </tr>
          <tr>
            <td>
              <p><span style="font-weight: 400;">Pago de facilitación</span></p>
            </td>
            <td>
              <p><span style="font-weight: 400;">Nombre; información de la tarjeta de pago&nbsp;</span></p>
            </td>
            <td>
              <p><span style="font-weight: 400;">Cumplimiento de nuestras condiciones/ejecución del contrato (artículo
                  6, apartado 1, letra b), del RGPD)</span></p>
            </td>
          </tr>
          <tr>
            <td>
              <p><span style="font-weight: 400;">Para entregar artículos pedidos</span></p>
            </td>
            <td>
              <p><span style="font-weight: 400;">Nombre, dirección</span></p>
            </td>
            <td>
              <p><span style="font-weight: 400;">Cumplimiento de nuestras condiciones (artículo 6, apartado 1, letra b),
                  del RGPD)</span></p>
            </td>
          </tr>
          <tr>
            <td>
              <p><span style="font-weight: 400;">Responder a las consultas o quejas de los usuarios</span></p>
            </td>
            <td>
              <p><span style="font-weight: 400;">Nombre; información de contacto; datos de la consulta o queja</span>
              </p>
            </td>
            <td>
              <p><span style="font-weight: 400;">Cumplimiento de una obligación legal (por ejemplo, en virtud de la
                  legislación sobre derechos de los consumidores) (artículo 6, apartado 1, letra c), del RGPD junto con
                  los requisitos legales nacionales correspondientes)</span></p>
              <p><span style="font-weight: 400;">El interés legítimo en prestar servicio al cliente y cumplir una
                  obligación legal y sus derechos e intereses en la protección de sus datos personales en el sentido del
                  artículo 6, apartado 1, letra f), del RGPD no prevalecen</span></p>
              <br>
              <p><span style="font-weight: 400;">Cumplimiento de nuestras condiciones/ejecución del contrato (artículo
                  6, apartado 1, letra b), del RGPD)</span></p>
            </td>
          </tr>
          <tr>
            <td>
              <p><span style="font-weight: 400;">Para hacer cumplir nuestros Términos, condiciones y políticas</span>
              </p>
            </td>
            <td>
              <p><span style="font-weight: 400;">Información de contacto; información sobre su uso de los
                  servicios</span></p>
            </td>
            <td>
              <p><span style="font-weight: 400;">Cumplimiento de nuestras condiciones/ejecución del contrato (artículo
                  6, apartado 1, letra b), del RGPD)&nbsp;</span></p>
            </td>
          </tr>
          <tr>
            <td>
              <p><span style="font-weight: 400;">Para enviar mensajes de marketing directo</span></p>
            </td>
            <td>
              <p><span style="font-weight: 400;">Información de contacto; información sobre su uso de los
                  servicios</span></p>
            </td>
            <td>
              <p><span style="font-weight: 400;">Consentimiento (cuando lo exija la ley) (artículo 6, apartado 1, letra
                  a), del RGPD)</span></p>
              <p><span style="font-weight: 400;">Interés legítimo (facilitar información que pueda ser de interés y
                  enriquecer los datos que utilizamos para facilitar contenidos de marketing de una manera mejor y más
                  personalizada y sus derechos e intereses en la protección de sus datos personales en el sentido del
                  artículo 6, apartado 1, letra f), del RGPD no prevalecen)&nbsp;</span></p>
            </td>
          </tr>
          <tr>
            <td>
              <p><span style="font-weight: 400;">Recoger información utilizando cookies y tecnologías similares para
                  proporcionar nuestra plataforma</span></p>
            </td>
            <td>
              <p><span style="font-weight: 400;">Dirección IP; información generada por el dispositivo y el navegador;
                  información sobre la actividad del sitio web</span></p>
            </td>
            <td>
              <p><span style="font-weight: 400;">Cuando almacene o acceda a información en sus dispositivos que utilice
                  para visitar nuestra plataforma:</span></p>
              <p><span style="font-weight: 400;">Consentimiento (art. 6, apartado 1, letra a) del RGPD y artículo 22,
                  apartado 2 de la Ley 34/2002 sobre servicios de la sociedad de la información y comercio electrónico
                  («LSSI»)</span></p>
              <p><span style="font-weight: 400;">Cuando recogemos datos personales:</span></p>
              <p><span style="font-weight: 400;">Consentimiento (en relación con cookies no esenciales – ver a
                  continuación) (artículo 6, apartado 1, letra a), del RGPD) y artículo 22, apartado 2, de la
                  LSSI.</span></p>
              <p><span style="font-weight: 400;">El interés legítimo en ofrecer una plataforma operativa y segura y sus
                  derechos e intereses en la protección de sus datos personales en el sentido del artículo 6, apartado
                  1, letra f), del RGPD no prevalecen (en relación con las cookies esenciales – ver a
                  continuación)</span></p>
            </td>
          </tr>
          <tr>
            <td>
              <p><span style="font-weight: 400;">Realizar análisis de servicios</span></p>
            </td>
            <td>
              <p><span style="font-weight: 400;">Información de contacto; información sobre su uso de los
                  servicios</span></p>
            </td>
            <td>
              <p><span style="font-weight: 400;">Consentimiento (en relación con cookies no esenciales; véase a
                  continuación) (artículo 6, apartado 1, letra a), del RGPD y artículo 22, apartado 2, de la
                  LSSI.</span></p>
              <br><br>
            </td>
          </tr>
          <tr>
            <td>
              <p><span style="font-weight: 400;">Gestionar nuestras relaciones comerciales con nuestros clientes y
                  proveedores</span></p>
            </td>
            <td>
              <p><span style="font-weight: 400;">Nombre; información de contacto comercial</span></p>
            </td>
            <td>
              <p><span style="font-weight: 400;">El interés legítimo en desarrollar nuestras actividades comerciales,
                  incluida la organización de la entrega y la recepción de servicios y el pago de dichos servicios y sus
                  derechos e intereses en la protección de sus datos personales en el sentido del artículo 6, apartado
                  1, letra f), del RGPD no prevalecen</span></p>
            </td>
          </tr>
          <tr>
            <td>
              <p><span style="font-weight: 400;">Solicitud de comentarios</span></p>
            </td>
            <td>
              <p><span style="font-weight: 400;">Información de contacto; información sobre su uso de los
                  servicios</span></p>
            </td>
            <td>
              <p><span style="font-weight: 400;">El interés legítimo en comprender cómo se utilizan nuestros servicios
                  para introducir mejoras y sus derechos e intereses en la protección de sus datos personales en el
                  sentido del artículo 6, apartado 1, letra f), del RGPD no prevalecen</span></p>
            </td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <p><span style="font-weight: 400;">Tiene usted derecho a retirar su consentimiento en cualquier momento siempre
          que este sea el fundamento jurídico para tratar sus datos personales. La retirada del consentimiento no afecta
          a la licitud del tratamiento basada en el consentimiento previo a su retirada. Lo mismo se aplica en los casos
          en los que se introduce información en su dispositivo que utiliza usted para visitar nuestra plataforma o
          cuando accedemos a dicha información.&nbsp;</span></p>
      <p><span style="font-weight: 400;">Para retirar su consentimiento, póngase en contacto con el equipo de
          asistencia: </span><span style="font-weight: 400;">sergio@suass.live</span></p>

          <h2><span style="font-weight: 400;">Compartir datos personales con terceros</span></h2>

      <p><span style="font-weight: 400;">En ocasiones, recurrimos a socios comerciales para que nos ayuden a prestar
          nuestros servicios. En concreto, recurrimos a:</span></p>
      <ul>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Un proveedor externo de servicios
            de pago, a saber, Stripe (Stripe Spain, SL con domicilio social en
            Calle Zurbaran, 9 - LOC DR, Madrid, 28010, Madrid e inscrita en el Registro Mercantil de España con el
            número B88501820), a quien externalizamos totalmente el manejo y el procesamiento de la información de su
            tarjeta de pago. Este proveedor de servicios de pago recoge información de su tarjeta de pago para asignar
            los datos de su orden a su pago y ejecutar el pago por usted. Este proveedor está sujeto a contratos que le
            exigen cumplir nuestras instrucciones y tratar solo sus datos personales para prestar el servicio que hemos
            solicitado. Comunicamos sus datos personales sobre la base de los requisitos del artículo 28 del
            RGPD.</span></li>
      </ul>
      <p><span style="font-weight: 400;">Para facilitar la compra de artículos en nuestra plataforma, proporcionamos a
          los vendedores (que se encuentran en países del Espacio Económico Europeo [EEE] así como en el Reino Unido )
          que utilizan nuestra plataforma sus datos personales (es decir, su nombre y dirección de envío) para que
          puedan entregar los artículos que ha solicitado usted. Facilitamos a los vendedores sus datos personales sobre
          la base de nuestro interés legítimo en prestar nuestros servicios en la plataforma, y sus derechos e intereses
          en la protección de sus datos personales en el sentido del artículo 6, apartado 1, letra f), del RGPD no
          prevalecen.&nbsp;</span></p>
      <p><span style="font-weight: 400;">Asimismo, podremos comunicar sus datos personales a terceros en las siguientes
          circunstancias:</span></p>
      <ul>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">En caso de que vendamos o compremos
            una empresa o activo, en cuyo caso podremos comunicar sus datos personales al vendedor o comprador potencial
            de dicha empresa o activo; cualquier comunicación de datos personales está justificada por nuestro interés
            legítimo en adaptar nuestra forma corporativa a las circunstancias económicas y legales según sea necesario
            y no prevalecerán sus derechos e intereses en la protección de sus datos personales en el sentido del
            artículo 6, apartado 1, letra f), del RGPD.</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Si estamos obligados a comunicar o
            compartir sus datos personales para cumplir una obligación legal, solicitud judicial o policial, o para
            hacer cumplir o aplicar nuestras condiciones de uso y otros acuerdos, o para proteger los derechos, la
            propiedad o la seguridad de Suass S.L., nuestros clientes u otros. Lo anterior incluye el intercambio de
            información con otras empresas y organizaciones para los fines de protección contra el fraude y reducción
            del riesgo de crédito; y Cualquier comunicación de los datos personales está justificada por (1) que el
            tratamiento es necesario para el cumplimiento de una obligación legal a la que estamos sujetos de
            conformidad con el artículo 6, apartado 1, letra c), del RGPD junto con los requisitos legales nacionales
            para comunicar los datos a las autoridades encargadas de velar por el cumplimiento de la ley, o (2) tenemos
            un interés legítimo en comunicar los datos a los terceros antes mencionados si existen indicios de
            comportamiento abusivo o para hacer valer nuestros términos de uso, otras condiciones o reclamaciones
            legales, y no prevalecen sus derechos e intereses en la protección de sus datos personales en el sentido del
            artículo 6, apartado 1, letra f), del RGPD.</span></li>
      </ul>

          <h2><span style="font-weight: 400;">Transferencias internacionales</span></h2>

      <p><span style="font-weight: 400;">Conservamos sus datos personales en servidores que se encuentran en el
          EEE.&nbsp;</span></p>
      <p><span style="font-weight: 400;">Podemos contratar a proveedores de servicios tecnológicos que accedan a sus
          datos personales desde fuera del EEE, por ejemplo, para fines de asistencia y mantenimiento, y podrán
          conservarse en un destino que no ofrece un nivel de protección de los datos equivalente al que se garantiza
          dentro del EEE. Asimismo, los proveedores de cookies utilizadas en nuestra plataforma también pueden tratar
          sus datos en el extranjero, incluidos los Estados Unidos de América. En estos casos, aplicamos garantías
          adecuadas para proteger sus datos personales, como las cláusulas contractuales tipo (artículo 46, apartado 2,
          letra c), del RGPD), a menos que se aplique una exención pertinente. Si es necesario, le facilitaremos los
          documentos pertinentes. En tal caso, póngase en contacto con nosotros a través de los datos de contacto que
          figuran a continuación.&nbsp;</span></p>

          <h2><span style="font-weight: 400;">Seguridad</span></h2>

      <p><span style="font-weight: 400;">Reconocemos la importancia de mantener la seguridad de sus datos y tomamos las
          medidas de seguridad apropiadas (incluidas medidas físicas, electrónicas y de procedimiento) para ayudar a
          proteger sus datos personales contra el acceso y la comunicación no autorizados.&nbsp;</span></p>
      <p><span style="font-weight: 400;">No asumimos ninguna responsabilidad por la interceptación, alteración o uso
          indebido de información transmitida por Internet.</span></p>

          <h2><span style="font-weight: 400;">Sus derechos</span></h2>

      <p><span style="font-weight: 400;">Sin perjuicio de determinadas exenciones, y en algunos casos dependiendo de
          nuestro fundamento jurídico (véase el apartado 2 anterior), tiene usted determinados derechos en relación con
          sus datos personales. Estos son:</span></p>
      <ul>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Acceder a datos personales. Esto
            implica que tiene usted derecho a solicitar una copia de los datos personales que estamos tratando sobre
            usted, copia que le facilitaremos en formato electrónico</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Rectificar/suprimir datos
            personales. Esto significa que tiene usted derecho a solicitar que se rectifique cualquier dato personal
            incompleto o inexacto que tratemos sobre usted. Asimismo, tiene usted derecho a solicitar que suprimamos los
            datos personales que tratamos sobre usted. Tenga en cuenta que no estamos obligados a hacerlo si necesitamos
            conservar dichos datos para cumplir una obligación legal o para formular, ejercer o defender
            reclamaciones.</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Limitar el Tratamiento de sus Datos
            Personales. Tiene usted derecho a limitar el tratamiento de sus datos personales cuando considere que dichos
            datos son inexactos, que nuestro tratamiento es ilícito o que ya no necesitamos tratar dichos datos para un
            fin en concreto. En caso de que no podamos suprimir los datos debido a una obligación legal o de. otro tipo
            o porque no desee usted que los suprimamos, marcaremos los datos personales conservados con el fin de
            limitar un tratamiento en particular para fines específicos de acuerdo con su solicitud, o de restringir el
            tratamiento.</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Transferir sus datos personales a
            otro responsable del tratamiento («portabilidad de los datos»). Tiene usted derecho a obtener y reutilizar
            los datos personales que nos haya facilitado. Esto le permite trasladar fácilmente datos personales a otra
            organización o solicitarnos que lo hagamos por usted.</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Retirar el consentimiento que nos
            ha sido otorgado: Cuando tratemos determinados datos personales sobre la base de su consentimiento, tiene
            usted derecho a retirar su consentimiento </span><span style="font-weight: 400;">en cualquier
            momento</span><span style="font-weight: 400;">, también en relación con el marketing directo. </span><span
            style="font-weight: 400;">La retirada del consentimiento no afecta a la licitud del tratamiento basado en el
            consentimiento previo a su retirada. Lo mismo se aplica en los casos en los que se introduce información en
            su dispositivo que utiliza usted para visitar nuestra plataforma o cuando accedemos a dicha
            información.</span></li>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Presentar una reclamación ante su
            autoridad de control local: si considera usted que no hemos cumplido la legislación vigente en materia de
            protección de datos. La autoridad de control de protección de datos española es la Agencia Española de
            Protección de Datos </span><strong>(«AEPD</strong><span style="font-weight: 400;">»), con domicilio en C/
            Jorge Juan, 6. 28001 – Madrid (Tel. 900 293 183) y página web disponible en: https://www.aepd.es/es.</span>
        </li>
      </ul>
      <p><strong>Tiene usted derecho a oponerse en cualquier momento, por motivos relacionados con su situación
          particular, al tratamiento de datos personales que le conciernen, que se lleva a cabo, entre otras cosas,
          sobre la base del artículo 6, apartado 1, letras e) o f), del RGPD, de conformidad con lo dispuesto en el
          artículo 21 del RGPD. Dejaremos de tratar sus datos personales a menos que podamos demostrar motivos legítimos
          imperiosos para el tratamiento que prevalezcan sobre sus intereses, derechos y libertades, o si el tratamiento
          sirve para reivindicar, ejercer o defender reclamaciones</strong><span style="font-weight: 400;">.</span></p>
      <p>&nbsp;</p>
      <p><span style="font-weight: 400;">Puede usted ejercer sus derechos poniéndose en contacto con nosotros a través
          de los datos de contacto que figuran a continuación.&nbsp;</span></p>
      <p>&nbsp;</p>

          <h2><span style="font-weight: 400;">Conservación de sus datos personales</span></h2>

      <p><span style="font-weight: 400;">Como norma básica, conservaremos sus datos personales mientras estemos
          legalmente obligados a hacerlo o durante el tiempo que sea necesario para los fines para los que se recogieron
          y solo en la medida en que lo permita la legislación aplicable, tal y como se explica en esta Política de
          Privacidad. Asimismo, conservaremos sus datos personales mientras estemos legalmente obligados a hacerlo para
          cumplir nuestras obligaciones legales.&nbsp;</span></p>
      <p><span style="font-weight: 400;">Una vez transcurrido el referido periodo, sus datos se conservarán, debidamente
          bloqueados en un archivo no accesible, al amparo de lo dispuesto en el artículo 32 de la nueva Ley Orgánica
          3/2018 de Protección de Datos Personales y garantía de los derechos digitales
          («</span><strong>LOPDGDD</strong><span style="font-weight: 400;">»), por un periodo adicional de 3 años. Así,
          durante este periodo de bloqueo, se adoptarán todas las medidas técnicas y organizativas necesarias para
          evitar nuevos tratamientos de los datos, incluida su visualización, salvo para ponerlos a disposición de
          jueces y tribunales, de la Fiscalía Española o de las Administraciones Públicas competentes, en particular las
          autoridades de protección de datos, para la ejecución de posibles responsabilidades derivadas del tratamiento.
          Una vez transcurrido este periodo adicional de tres años, los datos serán destruidos.</span></p>
      <p><span style="font-weight: 400;">Póngase en contacto con nosotros a través de la información de contacto que
          figura a continuación para obtener más información sobre los plazos de conservación.&nbsp;</span></p>
      <p>&nbsp;</p>

          <h2><span style="font-weight: 400;">Cookies y tecnologías similares</span></h2>

      <p><span style="font-weight: 400;">Cada vez que accede usted a nuestra plataforma, recogemos la siguiente
          información sobre su ordenador: la dirección IP de su ordenador, la solicitud de su navegador y el momento de
          dicha solicitud. También recogemos el estado y la cantidad de datos transferidos en el marco de dicha
          solicitud. La dirección IP de su ordenador solo se conserva durante el tiempo que utilice usted la plataforma
          y posteriormente se elimina o anonimiza inmediatamente. Utilizamos estos datos para el funcionamiento de
          nuestra plataforma, en particular para detectar y eliminar errores de la plataforma, determinar la utilización
          de la plataforma y realizar ajustes o mejoras.</span></p>
      <p><span style="font-weight: 400;">Al igual que la mayoría de las plataformas, utilizamos cookies y tecnologías
          similares para obtener información o almacenar información en su dispositivo. Esa información puede
          corresponder a sus datos personales, por ejemplo, cuando esté asociada con usted como usuario registrado o
          usuario identificado que envía una orden. Obtendremos su consentimiento para el uso de cookies y tecnologías
          similares en nuestra plataforma cuando sea necesario.</span></p>
      <p><span style="font-weight: 400;">Algunas cookies que utilizamos son «esenciales», lo que significa que son
          necesarias para que la plataforma funcione según lo previsto. Otras cookies son «no esenciales», lo que
          significa que no son necesarias para que la plataforma funcione, sino que se utilizan para mejorar su
          funcionalidad o para ayudarnos a analizar cómo se utiliza.</span></p>
      <p><span style="font-weight: 400;">Puede usted ampliar la información sobre el uso de cookies en nuestro sitio web
          así como sobre cómo desactivarlas en: […]</span></p>
      <p>&nbsp;</p>
          <h2><span style="font-weight: 400;">Toma de decisiones automatizadas y elaboración de perfiles</span></h2>
      <p>&nbsp;</p>
      <p><span style="font-weight: 400;">No llevamos a cabo ninguna actividad de tratamiento de datos que dé lugar a
          decisiones basadas exclusivamente en el tratamiento automatizado, incluida la elaboración de
          perfiles.&nbsp;</span></p>
      <p>&nbsp;</p>

          <h2><span style="font-weight: 400;">Información de contacto</span></h2>

      <p><span style="font-weight: 400;">Si tiene alguna duda en relación con el uso que hacemos de sus datos
          personales, póngase en contacto con nosotros o con nuestro Delegado de Protección de Datos directamente a
          través de los datos que figuran a continuación.</span></p>
      <p><span style="font-weight: 400;">Puede ponerse en contacto con nosotros por correo electrónico (sergio@suass.live).</span></p>
      <p><span style="font-weight: 400;">Nuestro Delegado de Protección de Datos es Kevin Loiseau, Director de
          Operaciones, y puede ponerse en contacto con él por correo electrónico en </span><span
          style="font-weight: 400;">sergio@suass.live</span></p>
      <p>&nbsp;</p>

          <h2><span style="font-weight: 400;">Modificaciones de esta Política de Privacidad</span></h2>

      <p>&nbsp;</p>
      <p><span style="font-weight: 400;">Podremos modificar esta Política de Privacidad en cualquier momento. En tal
          caso, publicaremos la Política de Privacidad actualizada en nuestra plataforma y actualizaremos la fecha de
          nuestra última actualización al comienzo de la Política de Privacidad. Si realizamos algún cambio que altere
          sustancialmente sus derechos u obligaciones, haremos cuanto razonablemente esté en nuestra mano por
          notificarle el cambio. Le sugerimos que consulte regularmente nuestra plataforma para ver si se ha producido
          algún cambio en esta Política de Privacidad.&nbsp;</span></p>
      <p><span style="font-weight: 400;">Esta política se actualizó por última vez en septiembre de 2022</span></p>
      <p>&nbsp;</p>
    </div>
  </div>
