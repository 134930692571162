import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { map, exhaustMap, catchError, withLatestFrom, switchMap } from 'rxjs/operators';
import { pAuctionFeatActions } from '../public/auction.store';
import { CustomerService } from '../../api/services/CustomerService';
import { pMerchantAuctionList, pMerchantProfile, pMerchantProfileList, pMerchantReviewList } from '../public/merchant.profile.store';
import { PublicService } from '../../api/services/PublicService';
import { AppStore } from '../app.store';
import { HttpClient } from '@angular/common/http';
import { IApiPaginatedResponse } from '../../api/services/ApiService';
import { environment } from '../../../environments/environment';
import { IPaginationParams } from '../../api/services/MerchantService';
import { ListStore } from './factory.list.store';
import { objectToQueryParam } from '../../utils/common.utils';

export class ListBaseEffects<T> {
  onLoad$ = createEffect(() => this.actions$.pipe(
    ofType(this.listStore.actions.load),
    withLatestFrom(
      this.store.select(this.listStore.feat.selectPagination),
    ),
    switchMap(( [{params, filters}, pagination] ) => this.get<T>(params, filters, pagination)
      .pipe(
        map((paginatedData) => this.listStore.actions.loadOK(paginatedData)),
        catchError((error) => of(this.listStore.actions.loadKO({ error }))))
      )
  ))

  onSetPagination$ = createEffect(() => this.actions$.pipe(
    ofType(this.listStore.actions.setPagination),
    withLatestFrom(
      this.store.select(this.listStore.feat.selectParams),
      this.store.select(this.listStore.feat.selectFilters)
    ),
    map(([ pagination, params]) => this.listStore.actions.load({ params }))
  ))

  onSetFilters$ = createEffect(() => this.actions$.pipe(
    ofType(this.listStore.actions.setFilters),
    withLatestFrom(this.store.select(this.listStore.feat.selectParams)),
    map(([ {filters}, params]) => this.listStore.actions.load({ params, filters }))
  ))

  onSetFiltersAndPagination$ = createEffect(() => this.actions$.pipe(
    ofType(this.listStore.actions.setFiltersAndPagination),
    withLatestFrom(this.store.select(this.listStore.feat.selectParams)),
    map(([ {pagination, filters}, params]) => this.listStore.actions.load({ params, filters }))
  ))

  private get<T>(params: Record<string, any>, filters: any, {page, pageSize, sortBy}: IPaginationParams): Observable<IApiPaginatedResponse<T>> {
    const path = Object.entries(params || {}).reduce((path, [param, value])=>{
      return path.replace(`:${param}`, value)
    }, this.pathModel)
    const qp = '?'+objectToQueryParam({q: filters, page, pageSize, sortBy})
    return this.http.get<IApiPaginatedResponse<T>>(`${environment.apiBaseUrl}${path}${qp}`);
  }


  constructor(
    private listStore: ListStore,
    private pathModel: string,
    private actions$: Actions,
    private store: AppStore,
    private http: HttpClient
  ) { }
}
