import { Appearance } from '@stripe/stripe-js';

export const stripeElementsAppearanceLight: Appearance = {
  // theme: 'none' as Appearance['theme'],
  rules: {
    '.Input':{
      padding: '0.375rem 0.75rem',
      borderRadius: '0.375rem',
      fontSize: '1rem',
      lineHeight: '1',

    }
  },
};

export const stripeElementsAppearanceDark: Appearance = {
  // theme: 'none' as Appearance['theme'],
  rules: {
    '.Label':{
      color: '#dee2e6'
    },
    '.option:disabled':{
      color: '#dee2e6'
    },
    '.Input':{
      borderColor: '#352644',
      color: '#dee2e6',
      backgroundColor: 'rgb(15, 23, 42)',
      padding: '0.375rem 0.75rem',
      borderRadius: '0.375rem',
      fontSize: '1rem',
      lineHeight: '1',

    }
  },
};

export const stripeElementsAppearance = {
  dark: stripeElementsAppearanceDark,
  light: stripeElementsAppearanceLight
}
