import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-isolated-layout',
  templateUrl: './isolated-layout.component.html',
  styleUrls: ['./isolated-layout.component.scss']
})
export class IsolatedLayoutComponent {
  @Input() margin: string|null = null
  get marginStyle(){
    return this.margin ? {margin: this.margin } : {}
  }
}
