<div class="card">
  <div class="card-header">Top compradores</div>
  <div class="card-body" *ngIf="data && data.length === 0">
    <div class="alert alert-secondary">No tienes ventas todavía</div>
  </div>
  <app-loading-indicator *ngIf="!data" />
  <ul class="list-group list-group-flush" *ngIf="data">
    <li class="list-group-item align-items-center d-flex p-2" *ngFor="let entry of data">
      <div class="flex-grow-1">
        <app-customer-tag [customer]="entry.customer" />
      </div>
      <div class="d-flex gap-2 stats justify-content-between">
        <div class="bg-secondary rounded px-2 py-1">
          <div class="title">Gastado</div>
          <div class="value text-primary">{{ entry.amountSpent }}€</div>
        </div>
        <!-- <div class="bg-secondary rounded px-2 py-1">
        <div class="title">Pujas totales</div>
        <div class="value">{{ entry.bidCount }}</div>
      </div> -->
        <!-- <div class="bg-secondary rounded px-2 py-1" ngbTooltip="Subastas ganadas / participadas">
        <div class="title">
          <fa-icon [icon]="helpIcon" />
          Subastas
        </div>
        <div class="value">{{ entry.winningAuctions }}/{{ entry.auctionsInvolved }}</div>
      </div> -->
      </div>
    </li>
  </ul>
</div>
