<div class="modal-header">
  <h5 class="modal-title" id="modal-basic-title">Añadir elemento</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.dismiss('Cross click')"></button>

</div>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="modal-body">
    <app-form-input label="Descripción" controlName="description" [form]="form" marginBottom="3" />
    <app-form-input type="checkbox" [form]="form" marginBottom="3">
      Son gastos de envío
    </app-form-input>
    <div class="d-flex gap-2 mb-3">
      <app-form-input label="Cantidad neta" controlName="netAmount" [form]="form" prefix="€" />
      <app-form-input label="Con impuestos" controlName="totalAmount" [form]="form" prefix="€" />
    </div>
  </div>
  <div class="modal-footer">
    <button [disabled]="isSaveDisabled" type="submit" class="btn btn-primary">
      <ng-container *ngIf="isLoading">
        <app-loading-spinner />
        Procesando
      </ng-container>
      <ng-container *ngIf="!isLoading">
        Añadir
      </ng-container>
    </button>
  </div>
</form>
