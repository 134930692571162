
<div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">Reembolsar</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.dismiss('Cross click')"></button>

</div>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="modal-body">
    <div class="mb-3">
      <app-small-order-item-list [items]="[orderItem]" />
    </div>

        <div class="mb-3">
            <app-form-input label="Cantidad" controlName="amount" [form]="form"  prefix="€"/>
        </div>

        <app-api-error-block [error]="error" />
    </div>
    <div class="modal-footer">
        <button [disabled]="isSaveDisabled" type="submit" class="btn btn-primary">
            <ng-container *ngIf="isLoading">
                <app-loading-spinner />
                Procesando
            </ng-container>
            <ng-container *ngIf="!isLoading">
                Procesar
            </ng-container>
        </button>
    </div>
</form>

