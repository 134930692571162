<app-isolated-layout>
    <div class="d-flex justify-content-center z-4">

        <div class=" mt-5" style="max-width: 400px; width: 100%;">
            <div class="card-body">
                <ng-container *ngIf="isLoading$|async">
                    <h5 class="card-title text-center">Procesando</h5>
                    <h6 class="card-subtitle mb-3 text-center"><strong>Un segundito...</strong>, estamos comprobando la solicitud.</h6>
                    <img src="assets/img/process.svg" class="img-fluid mb-3" alt="Cargando" />
                </ng-container>
                <ng-container *ngIf="isSuccessful$|async">
                    <h5 class="card-title text-center">Enhorabuena!</h5>
                    <h6 class="card-subtitle mb-3 text-center"><strong></strong> Ya tienes tu cuenta lista.</h6>
                    <img src="assets/img/verified-account.svg" class="img-fluid mb-3" alt="Cuenta confirmada" />
                    En breves serás redirigido a tu cuenta, <a routerLink="/panel" class="mt-3 mb-3">pincha aquí</a> si no ocurre en unos segundos.
                </ng-container>
                <ng-container *ngIf="error$|async">
                    <h5 class="card-title text-center">Hubo un error</h5>
                    <h6 class="card-subtitle mb-3 text-center"><strong>Lo sentimos!</strong> Parece que el enlace es incorrecto.</h6>

                    <p class="card-text mt-3 mb-3">
                        En breves serás redirigido al inicio para intentarlo de nuevo, <a routerLink="/panel" class="mt-3 mb-3">pincha aquí</a> si no ocurre en unos segundos.
                    </p>
                    <img src="assets/img/uknown-error.svg" class="img-fluid mb-3" alt="Error" />
                </ng-container>
            </div>
        </div>

    </div>
</app-isolated-layout>
